const allowCartPreviousStep = (pathName: String) => {
  const allowedPreviousSteps = [
    "visit-consultation",
    "product-detail",
    "shipping",
    "id-upload",
    "selfie-upload",
    "shipping-confirmation",
    "order-verification",
  ];
  const splitedPathName = pathName.split("/");

  return (
    splitedPathName.includes("checkout") &&
    allowedPreviousSteps.includes(splitedPathName[2])
  );
};

export { allowCartPreviousStep };
