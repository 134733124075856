import { uniqBy } from "lodash";
import React from "react";

type Props = {
  queriedMeds: any;
  setQueriedMeds: any;
  searchText: any;
  addedMeds: any;
  setAddedMeds: any;
  setShowResults: any;
  setSearchText: any;
  questionId: any;
};

const MedSearchResults = ({
  queriedMeds,
  setQueriedMeds,
  searchText,
  addedMeds,
  setAddedMeds,
  setShowResults,
  setSearchText,
  questionId,
}: Props) => {
  if (queriedMeds?.length != 0) {
    const uniqueRes = uniqBy(queriedMeds, 'NameWithRouteDoseForm').map((item: any) => {
      const { Strength, ...rest } = item;
      return rest;
    })
    setQueriedMeds(uniqueRes);
  }
  
  return (
    <ul className="med-search-results">
      {queriedMeds?.length === 0 && !!searchText?.length && (
        <li>
          <a href="#" onClick={(e: any) => e.preventDefault()}>
            No medications found. Click the 'Add' button to add a custom
            medication.
          </a>
        </li>
      )}
      {queriedMeds &&
        queriedMeds?.map((qm: any, idx: number) => (
          <li key={idx}>
            <a
              href="#"
              onMouseDown={(e: any) => {
                e.preventDefault();
                setQueriedMeds([]);
                setAddedMeds([
                  ...addedMeds,
                  {
                    name: qm["NameWithRouteDoseForm"],
                    strength: "",
                    duration: "",
                    condition: "",
                    question_id: questionId,
                    lexi_gen_drug_id: qm["LexiGenDrugId"],
                    lexi_drug_syn_id: qm["LexiDrugSynId"],
                    dispensable_drug_id: qm["DispensableDrugId"],
                    routed_dose_form_drug_id: qm["RoutedDoseFormDrugId"],
                    rxcui: qm["RXCUI"],
                    local_id: addedMeds?.length + 1,
                  },
                ]);
                setShowResults(false);
                setSearchText("");
              }}
            >
              {qm["NameWithRouteDoseForm"]}
            </a>
          </li>
        ))}
    </ul>
  );
};

export default MedSearchResults;
