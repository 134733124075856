import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";

const TeleMedicineRequest = (cart: any) => {
  const navigate = useNavigate();

  return (
    <div className="account-card__status">
      <h3>Order Status:</h3>
      <p>Your Medical Visit is being reviewed.</p>
      <p className="info">A PocketMed health provider is reviewing your visit.</p>
      <Button
        color="secondary"
        size="medium"
        classes="text-initial"
        onClick={() => navigate("/account/medical-visits")}
      >
        View Visit Details
      </Button>
    </div>
  )
};

export default TeleMedicineRequest;
