import React from "react";
import Footer from "components/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="content mt-5 py-5">
        <p className="text-center">
          <strong>
            <u>PRIVACY POLICY</u>
          </strong>
        </p>
        <p className="text-center">&nbsp;</p>
        <p>
          Sildenafil.com, LLC (“Sildenafil.com”) is committed to protecting the
          privacy of the users of (“you,” “your,” the “User” and/or the “Users”)
          of Sildenafil.com (the “Site”), which is operated by Sildenafil.com.
          &nbsp;This Privacy Policy and the safeguards it affords are consistent
          with the Health Insurance Portability and Accountability Act (“HIPAA”)
          privacy regulations, and the Health Information for Economic and
          Clinical Health Act (“HITECH”). &nbsp;This Privacy Policy governs the
          manner in which Sildenafil.com collects, uses, maintains, and
          discloses information collected from Users of the Site. &nbsp;This
          Privacy Policy, as it is incorporated into the Sildenafil.com Terms of
          Use as well as any other related agreement, applies to the Site and
          all products and services offered by Sildenafil.com.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>ABOUT THIS NOTICE</strong>
        </p>
        <p>
          This Privacy Policy discusses the ways in which we may use and
          disclose a User's personal information. &nbsp;This Privacy Policy also
          describes a User's rights and certain obligations we have regarding
          the use and disclosure of medical information.
        </p>
        <p>&nbsp;</p>
        <p>We are required by law to:</p>
        <p>&nbsp;</p>
        <ul className="ml-4">
          <li className="lplh-22">
            Make sure that medical information that identifies you is kept
            private;
          </li>
          <li className="lplh-22">
            Give you this notice of our legal duties and privacy practices with
            respect to your medical information;
          </li>
          <li className="lplh-22">
            Follow the terms of the notice that is currently in effect; and
          </li>
          <li className="lplh-22">
            Notify any User, either known or reasonably believed to be affected,
            following a breach of unsecured protected health information.
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>
          <strong>PERSONAL IDENTIFICATION INFORMATION</strong>
        </p>
        <p>
          Sildenafil.com may collect personal identification information
          (“Personal Information”) from Users in a variety of ways, including,
          but not limited to, when Users visit the Site, fill out registration
          or other forms, and in connection with other activities, services,
          features or resources Sildenafil.com makes available through its Site.
          &nbsp;Users may be asked for, as appropriate, demographic and contact
          information for purposes of submitting this information to the
          healthcare providers that the User has chooses to schedule
          appointments with. &nbsp;Personal Information may also include the
          following categories of information: (1) insurance data (such as your
          insurance carrier and insurance plan); and (2) medical data (such as
          the previous doctors, dentists or other health care providers you
          visited, date and reason for the visit, your medical history, and
          other medical and health information you choose to share with us).
        </p>
        <p>&nbsp;</p>
        <p>
          Users may always choose to visit the Sildenafil.com's Site
          anonymously. &nbsp;Sildenafil.com will collect personal identification
          information from Users only if Users voluntarily submit such
          information pursuant to their registration with the Site or use of the
          services provided by Sildenafil.com. &nbsp;Users can always refuse to
          supply any personal identification information they do not feel
          comfortable disclosing, however, such refusal may prevent such User
          from engaging in certain Site related activities.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>NON-PERSONAL IDENTIFICATION INFORMATION</strong>
        </p>
        <p>
          Sildenafil.com may collect non-personal identification information
          about Users whenever they interact with its Site. &nbsp;Non-personal
          identification information may include the browser name, IP addresses,
          domain servers, the type of computer accessing the Site, and technical
          information about a User's means of connecting to the Site, such as
          the operating system and the Internet Service Providers utilized,
          referral source which may have sent the User to the Site, and other
          similar information.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>INTERACTIVE TOOLS AND FUNCTIONS</strong>
        </p>
        <p>
          We may also collect other information, that you voluntarily provide to
          us when you choose to use the various tools and functions provided by
          Sildenafil.com, such as searching for healthcare providers, scheduling
          appointments, and completing medical history forms to be submitted to
          the providers. We also collect information you provide voluntarily in
          free-form text boxes on the Site and through responses to surveys,
          questionnaires and the like. &nbsp;Some of the free interactive tools
          on our Site ask you for health-related Personal Information. &nbsp;You
          may elect to use certain Services available through the Site that
          require you to download and install software on your computer.
          &nbsp;These tools may collect and transmit information from your
          computer system solely relating to use of the tools and for the
          purpose of providing you the relevant services, for example, by
          informing Sildenafil.com when a user is logged on and available to
          receive updates or alerts.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>WEB BROWSER COOKIES</strong>
        </p>
        <p>
          The Site may use “cookies” to enhance User experiences. &nbsp;Users'
          web browser places cookies on Users' hard drive for record-keeping
          purposes and sometimes to track information about Users. User may
          choose to set their web browser to block cookies, or to alert the User
          when cookies are being sent. &nbsp;If Users opt to disable its cookie
          function, Users should note that some parts of the Site may not
          function properly.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>WEBSITE ANALYTICS</strong>
        </p>
        <p>
          Sildenafil.com may use third party website analytics services in
          connection with the Site to record mouse clicks, mouse movements,
          scrolling activity, as well as any text that you type into the Site.
          &nbsp;Sildenafil.com uses the information collected from these
          services to identify usability problems and make the Site
          user-friendly. &nbsp;Only anonymous information is documented through
          these services. &nbsp;These website analytics services do not collect
          Personal Information that you do not voluntarily enter into the Site,
          nor do these services track your browsing habits across websites which
          do not use the services.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>HOW SILDENAFIL.COM MAY USE COLLECTED INFORMATION</strong>
        </p>
        <p>
          Sildenafil.com may collect and use User's Personal Information for the
          following purposes:
        </p>
        <p>&nbsp;</p>
        <ul className="ml-4">
          <li className="lplh-22">
            <em>To improve customer service</em>. Personal Information Users
            provide helps Sildenafil.com respond to Users' customer service
            requests and support needs more efficiently.
          </li>
          <li className="lplh-22">
            <em>To personalize user experiences</em>. Sildenafil.com may use
            information in the aggregate to understand how Sildenafil.com' Users
            as a group use the services and resources provided on its Site.
          </li>
          <li className="lplh-22">
            <em>To improve its Site</em>. Sildenafil.com may use feedback Users
            provide to improve its products and services. &nbsp;However, any
            feedback publicly available will be posted using only a User's
            username and will not disclose any personal information.
          </li>
          <li className="lplh-22">
            <em>To send periodic emails</em>. Sildenafil.com may use the email
            address provided by Users to respond to Users' inquiries, questions,
            and other requests. &nbsp;In addition, Sildenafil.com may
            occasionally send you information about the site, or Sildenafil.com
            products or services.
          </li>
          <li className="lplh-22">
            <em>To send appointment reminders.</em>
            Sildenafil.com may use and disclose Users' information to contact
            and remind User of an appointment for treatment or medical care.
          </li>
          <li className="lplh-22">
            <em>Health-Related Benefits and Services.</em>
            Sildenafil.com may use and disclose contact information to tell
            Users about health-related benefits or services that may be of
            interest to you. &nbsp;However, at no time will your contact
            information be shared with, rented, or sold to any other
            entity.&nbsp;
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>
          Any other uses and disclosures not described in this Privacy Policy
          will be made only with a User's written authorization &nbsp;and
          consent, and the User may revoke such authorization at any time,
          provided that the revocation is in writing, except to the extent that
          Sildenafil.com has taken action in reliance upon your authorization.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>HOW SILDENAFIL.COM PROTECTS YOUR INFORMATION</strong>
        </p>
        <p>
          Sildenafil.com adopts appropriate data collection, storage and
          processing practices, and security measures to protect against
          unauthorized access, alteration, disclosure or destruction of Users'
          Personal Information, usernames, passwords, transaction information
          and data stored on its Site. &nbsp;Only those Sildenafil.com employees
          who are actively participating in ensuring you are appropriately
          connected to the healthcare providers of their choosing can view your
          Personal Information.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>SHARING USERS PERSONAL IDENTIFICATION INFORMATION</strong>
        </p>
        <p>
          Sildenafil.com will never use data in a way that may compromise your
          identity or your privacy. &nbsp;Sildenafil.com will only share data
          that identifies Users to the healthcare providers selected by the
          Users, unless Sildenafil.com is required by law or has its Users'
          consent to do otherwise. &nbsp;Sildenafil.com will keep Users'
          Personal Information private and will not share it with third parties,
          unless it believes, in good faith, that disclosure of the Personal
          Information or any other information collected about the User is
          necessary to: (1) comply with a court order or other legal process;
          (2) protect the rights, property or safety of Sildenafil.com or
          another party; (3) enforce our Terms of Use; or (4) respond to claims
          that any posting or other content violates the rights of
          third-parties.
        </p>
        <p>&nbsp;</p>
        <p>
          Sildenafil.com does not sell, trade, or rent Users Personal
          Information to others. &nbsp;Sildenafil.com may share generic
          aggregated demographic information not linked to any Personal
          Information regarding visitors and Users with its business partners,
          trusted affiliates and advertisers for the purposes outlined above.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>SECURITY OF PERSONAL IDENTIFICATION INFORMATION</strong>
        </p>
        <p>
          Sildenafil.com follows generally accepted industry standards to
          protect the Personal Information submitted through the Site. &nbsp;
          When a User enters sensitive information on the Site, Sildenafil.com
          encrypts the information using secure socket layer technology (“SSL”).
        </p>
        <p>&nbsp;</p>
        <p>
          While Sildenafil.com makes every good faith effort to securely store
          all Personal Information collected through its Site, Sildenafil.com
          cannot guarantee that there will be no unintended disclosures of
          Personal Information. &nbsp;However, if Sildenafil.com becomes aware
          that a User's Personal Information has been compromised or disclosed
          in a manner not in accordance with this Privacy Policy, Sildenafil.com
          will use reasonable efforts to notify the affected User of the nature
          and extent of the disclosure (to the extent Sildenafil.com knows that
          information) as soon as reasonably possible and as permitted by law.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>THIRD PARTY WEBSITES</strong>
        </p>
        <p>
          Users may find advertising or other content on the Site that link to
          the sites and services of Sildenafil.com's partners, suppliers,
          advertisers, sponsors, licensors and other third parties.
          &nbsp;Sildenafil.com does not control the content or links that appear
          on these sites and are not responsible for the practices employed by
          websites linked to or from the Site. In addition, these sites or
          services, including their content and links, may be constantly amended
          and updated. &nbsp;These sites and services have their own privacy
          policies and customer service policies. &nbsp;Browsing and interaction
          on any other website, including websites which have a link to the
          Site, is subject to that website's own terms and policies.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>PUBLIC INFORMATION</strong>
        </p>
        <p>
          Users should take note that any information that a User reveals in a
          review posting or other online discussion or forum is intentionally
          open to the public and is not in any way private. &nbsp;Users should
          think carefully before disclosing any Personally Information in a
          public forum as the Users' information may be seen and/or collected by
          third parties and may be used by others in ways that Sildenafil.com is
          unable to control or predict, and is not responsible for.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>CONTROLLING YOUR INFORMATION</strong>
        </p>
        <p>
          You can modify Personal Information included in your profile.
          &nbsp;Upon your request, Sildenafil.com will use commercially
          reasonable efforts to delete your account and the Personal Information
          in your profile; however, it may be impossible to remove your account
          without some residual information being retained by Sildenafil.com.
          &nbsp;Sildenafil.com has no obligation to delete any data or
          information you provide on any medical history forms or to a medical
          provider following termination of your account.
        </p>
        <p>&nbsp;</p>
        <p>
          If you learn that your Personal Information is lost, stolen, or used
          without your permission, you are asked to promptly notify
          Sildenafil.com. &nbsp;Sildenafil.com will make every best effort to
          remove that data from the account and update its records.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>NOTICE TO HEALTHCARE PROVIDERS</strong>
        </p>
        <p>
          Healthcare providers, their employees, and agents should be
          particularly aware of their obligations of patient confidentiality,
          including without limitation their obligations under HIPAA and HITECH,
          both in communicating with Sildenafil.com and in responding to a
          review of their services posted on the Site. &nbsp;Sildenafil.com does
          not have any obligations of confidentiality with respect to any
          communications other than those expressly stated in this Privacy
          Policy.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>CHANGES TO THIS PRIVACY POLICY</strong>
        </p>
        <p>
          <strong>Notification of Change</strong>
        </p>
        <p>
          We reserve the right to change this Privacy Policy from time-to-time
          as we see fit and your continued use of the Site will signify your
          acceptance of any adjustment to these terms. &nbsp;If there are any
          changes to this Privacy Policy, we will announce that these changes
          have been made on our home page. &nbsp;Any changes to the Privacy
          Policy will be posted on our website 30 days prior to these changes
          taking place. &nbsp;You are therefore advised to re-read this
          statement on a regular basis.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>YOUR ACCEPTANCE OF THESE TERMS</strong>
        </p>
        <p>
          By using this Site and/or by registering with Sildenafil.com, you
          signify your acceptance of this Privacy Policy and acknowledge that
          Sildenafil.com may track, collect, use, and share your information in
          the manner described herein. &nbsp;If you are registering an account
          or using the services on behalf of an individual or entity other than
          yourself, you represent that you are authorized by such individual or
          entity to accept this privacy policy on such individual's or entity's
          behalf. &nbsp;If you do not agree to this policy, please do not use
          this Site. &nbsp;Your continued use of the Site following the posting
          of changes to this policy will be deemed your acceptance of those
          changes.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>CONTACTING US</strong>
        </p>
        <p>
          If you have any questions about this Privacy Policy, the practices of
          this Site, or your dealings with this site, please contact
          Sildenafil.com at: 1-844-745-3362
        </p>
        <p>&nbsp;</p>
        <p>
          <em>This document was last updated on&nbsp;</em>
          <em>October 1, 2020.</em>
        </p>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
