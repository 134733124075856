import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import DayJS from "dayjs";
import PatientRequest from "./Patientrequest";
import PharmacyRequest from "./PharmacyRequest";
import Mailed from "./Mailed";
import TeleMedicineRequest from "./TelemedicineRequest";
import TelemedicineOpen from "./TelemedicineOpen";
import closeIcon from "../../../assets/images/home/close.svg";
import ChevronDown from "../../../assets/images/home/chevron-down.svg";
import { isEmpty } from "lodash";
import { currencyFormatter } from "helpers/Formatter";

const Default = ({ cart }: any) => {
  const [expand, setExpand] = useState(true);
  const handleOrderBranch = () => {
    if (cart.stage === "lost") {
      return;
    }

    switch (cart.branch) {
      case "patient_request":
        return <PatientRequest />;
      case "pharmacy_request":
        return <PharmacyRequest cart={cart} />;
      case "mailed":
        return <Mailed />;
      case "telemedicine":
        switch (cart.stage) {
          case "request":
            return <TeleMedicineRequest cart={cart} />;
          case "open":
            return <TelemedicineOpen />;
          default:
            return;
        }
      default:
        return;
    }
  };

  return (
    <div className={`account-card ${expand && "is-open"}`}>
      <div className="account-card__header">
        <div className="account-card__header-box">
          <strong> Date Ordered</strong>
          <p>{DayJS(cart.created_at).format("MM/DD/YYYY")}</p>
        </div>
        <div className="account-card__header-box">
          <strong>Amount</strong>
          <p>{`${currencyFormatter(cart?.total_price)}`}</p>
        </div>
        <button
          className="account-card__toggle"
          onClick={() => setExpand(!expand)}
        >
          <ReactSVG
            src={expand ? closeIcon : ChevronDown}
            wrapper="div"
            className={`account-card__toggle-control account-card__toggle`}
          />
        </button>
      </div>
      <div className="account-card__body">
        {!isEmpty(cart) && (
          <React.Fragment>{handleOrderBranch()}</React.Fragment>
        )}
        <div className="account-card__container">
          <p>
            <strong>Drug: </strong>
            {cart?.product_variant?.fullname}
          </p>
          <p>
            <strong>Quantity: {cart.quatity}</strong>
            {cart.quantity > 0 && (
              <React.Fragment>
                {cart?.quantity} {cart?.product_variant?.unit}
                {"s"}
              </React.Fragment>
            )}
          </p>
          <p>
            <strong>Price: </strong>
            {`$${Number(cart?.final_price).toFixed(2)}`}
          </p>
        </div>
        <div className="account-card__shipping">
          {cart?.delivery_type && (
            <>
              <strong>Shipping type: </strong>
              {cart?.delivery_label}
            </>
          )}
          <p>
            <strong>Ship To:</strong>
            {cart?.shipping_address && (
              <span className="no-mouseflow mouseflow-hidden">
                {" "}
                {cart.shipping_address.street_1},{" "}
                {cart.shipping_address.stree_2}
                <span>{cart?.shipping_address?.state},</span>
                <span className="no-mouseflow mouseflow-hidden">
                  {" "}
                  {cart?.shipping_address?.zip}
                </span>
              </span>
            )}
          </p>
        </div>
        <div className="account-card__payment">
          <div className="info">
            <p>
              <strong>Payment Method: Pending</strong>
            </p>
          </div>
          <div className="summary">
            <p>Sub Total: {`${currencyFormatter(cart?.price)}`}</p>
            {cart?.applied_drug_price_discount && (
              <p>
                {cart?.applied_drug_price_discount?.code ? (
                  `Discount ${cart?.applied_drug_price_discount?.code}`
                ) : (
                  <>
                    Discount:
                    <br />{" "}
                    {`$${Number(cart?.price - cart?.final_price).toFixed(2)}`}
                  </>
                )}
              </p>
            )}
            <p>
              Shipping:{" "}
              {cart.final_delivery_price > 0
                ? `${currencyFormatter(cart?.final_delivery_price)}`
                : "Free"}
            </p>
            <p>Sales tax: {currencyFormatter(0)}</p>
            <hr />
            <p>
              Grand Total:{" "}
              <strong>{`$${Number(cart?.total_price).toFixed(2)}`}</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Default;
