import React from "react";
import { Modal } from "react-bootstrap";

const PharmacyModal = ({
  pharmacy,
  showPharmacyModal,
  setShowPharmacyModal,
}: any) => {
  return (
    <Modal
      show={showPharmacyModal}
      onHide={() => setShowPharmacyModal(false)}
      className="pharmacy-info-dialog"
      centered>
      <Modal.Body>
        <p>
          Your order is being fulfilled by <strong>{pharmacy?.name}</strong> and
          will be delivered to your home address.
        </p>
        <p>
          Pharmacy Phone Number: <strong>7142762040</strong>
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default PharmacyModal;
