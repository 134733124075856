import React, { useContext, useEffect } from "react";
import { useLocation, useNavigationType } from "react-router-dom";
import MainNavbar from "./MainNavbar";
import SecondaryNavBar from "./SecondaryNavBar";
import { calculateProgressBar } from "helpers/ProgressBarCalculator";
import { allowCartPreviousStep } from "helpers/CartPreviousStep";
import { CartContext } from "Contexts/CartContext";
import { CartsPreviousStepRequest } from "apis";
import { isEmpty } from "lodash";

const NavBar = () => {
  const mainNavBarRouteRegex =
    /\/account|product-selection|finish-setup|refill-product-detail|order-shipping-confirmation|privacy-policy|hipaa|terms-of-use|order\/|\/(?!\w)/gi;
  const { questionProgressBar, setQuestionProgressBar, latestCart } =
    useContext(CartContext);

  const location = useLocation();
  const navigationType = useNavigationType();

  useEffect(() => {
    const navTyp = navigationType;

    if (
      navTyp === "POP" &&
      !isEmpty(latestCart) &&
      allowCartPreviousStep(location.pathname)
    ) {
      CartsPreviousStepRequest(latestCart?.id);
    }

    if (!location.pathname.includes("consultation")) {
      setQuestionProgressBar(0);
    }
  }, [location]);

  const calculatePrgressBarSize = () => {
    if (questionProgressBar > 0) {
      return questionProgressBar;
    } else {
      return calculateProgressBar(location.pathname);
    }
  };

  return location.pathname.match(mainNavBarRouteRegex) ? (
    <MainNavbar />
  ) : (
    <SecondaryNavBar progressBarSize={calculatePrgressBarSize()} />
  );
};

export default NavBar;
