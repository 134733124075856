import React, { useState, useEffect, useContext } from "react";

import { first, isEmpty } from "lodash";
import {
  FetchVisitConsentDataRequest,
  FetchStatesRequest,
  CreateVisitRequest,
  UpdateIntroQuestionsRequest,
} from "apis";
import Loader from "../Shared/Loader";
import { CartContext } from "Contexts/CartContext";
import Translator from "components/Translator";
import Button from "components/Button";
import TermsOfUseModal from "./Modals/TermsOfUseModal";
import TelehealthModal from "./Modals/TelehealthModal";
import PrivacyPolicyModal from "./Modals/PrivacyPolicyModal";
import PrivacyPracticesModal from "./Modals/PrivacyPracticesModal";

import { AuthContext } from "Contexts/AuthContext";
import "./styles.scss";

type Props = {
  user: any;
  cart: any;
  productVariant: any;
};

const VisitConsent = ({ user, cart, productVariant }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>([]);
  const [states, setStates] = useState<[]>([]);
  const [termsCheck, setTermsCheck] = useState<boolean>(false);
  const [locationCheck, setLocationCheck] = useState<boolean>(false);
  const [activeState, setActiveState] = useState<any>(null);
  const { VisitConsentNextStep } = useContext(CartContext);
  const { setLoading } = useContext(AuthContext);
  const [showTermsOfUseModal, setShowTermsOfUseModal] =
    useState<boolean>(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState<boolean>(false);
  const [showTelehealthModal, setShowTelehealthModal] =
    useState<boolean>(false);
  const [showPrivacyPractices, setShowPrivacyPractices] =
    useState<boolean>(false);
  const { reloadUser } = useContext(AuthContext);

  useEffect(() => {
    if (
      !isEmpty(user) &&
      user.pocketmed_uuid &&
      !isEmpty(cart) &&
      !isEmpty(states)
    ) {
      setLoading(false);

      if (!isEmpty(cart.visit_uuid)) {
        FetchVisitConsentDataRequest(user.pocketmed_uuid, cart.id)
          .then((res) => {
            const visit = res.data.visit;
            const selectedState = first(
              states.filter((state: any) => state.code === visit.state)
            );

            setActiveState(selectedState);
            setTermsCheck(visit.terms);
            setLocationCheck(visit.state_ack);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [states, cart]);

  useEffect(() => {
    reloadUser();

    FetchStatesRequest()
      .then((response) => {
        setStates(response.data.states);
      })
      .catch((error: string) => setErrors([error]))
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const continueProcess = () => {
    let visitData = {
      state: activeState.code,
      user_uuid: user.pocketmed_uuid,
      cart_id: cart.id,
      drug: cart.product_name_with_brand,
      dosage: cart?.product_variant?.dosage_value,
      terms: termsCheck,
      state_ack: locationCheck,
      quantity: undefined,
    };

    if (cart.marketing) {
      visitData["quantity"] = cart?.final_quantity;
    }

    const questionnaireData = {
      selected_answers: localStorage.getItem("intro-answers"),
    };

    // Disable the button
    setIsProcessing(true);

    CreateVisitRequest(visitData, user.pocketmed_uuid)
      .then((res) => {
        // Send the questions data on success
        const visitUuid = res.data.visit_uuid;

        UpdateIntroQuestionsRequest(visitUuid, questionnaireData).then(() => {
          // For moving on to the next step
          VisitConsentNextStep({ user, visitUuid });
        });
      })
      .catch((err) => {
        setErrors([err.response?.data?.error]);
        setIsProcessing(false);
      });
  };

  const getStateByCode = (stateCode: any) =>
    states.filter((state: any) => state.code === stateCode)[0];

  const handleOnStateChange = (state: any) => {
    setErrors([]);
    setActiveState(getStateByCode(state));
  };

  return (
    <>
      {isLoading ? (
        <div className="mt-5">
          <Loader />
        </div>
      ) : (
        <>
          <div className="main">
            <div className="container checkout-page product-page visit-consent">
              <div className="content false">
                <div className="row pt-0 pt-md-3 justify-content-center">
                  <div className="col col-lg-6">
                    <div className="consent-content__heading">
                      <section>
                        <h3 className="consent-heading-label mb-3">
                          <Translator path="visitConsent.currentLocation" />
                        </h3>
                        <h6 className="font-weight-normal mb-3 consent-helper-text">
                          <Translator path="visitConsent.confirmThatYouAreEligible" />
                        </h6>
                        <div className="current-state-dropdown-wrapper mt-3">
                          <p className="mb-0 font-weight-normal">
                            <Translator path="visitConsent.selectState" />
                          </p>
                          <label
                            className="consent-state-label"
                            htmlFor="state-select">
                            {activeState && activeState.name}
                          </label>
                          <select
                            id="state-select"
                            onChange={(e: any) =>
                              handleOnStateChange(e.target.value)
                            }
                            className="consent-state-dropdown"
                            value={activeState?.code || ""}>
                            <option value="" disabled></option>
                            {states &&
                              states.map((state: any, idx: number) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <option key={idx} value={state.code}>
                                  {state.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        {!isEmpty(errors) &&
                          errors.map((err: string, idx: number) => (
                            <div className="error-label" key={idx}>
                              {err}
                            </div>
                          ))}
                        <div className="field custom-checkbox mt-4">
                          <input
                            type="checkbox"
                            value="1"
                            id="terms-and-privacy-policy"
                            onChange={(e: any) =>
                              setTermsCheck(e.target.checked)
                            }
                            checked={termsCheck}
                          />
                          <label
                            className="checkbox-label input-label"
                            htmlFor="terms-and-privacy-policy">
                            <Translator path="visitConsent.iAgree" />{" "}
                            <button
                              className="visit-consent-modals-btn"
                              type="button"
                              onClick={() =>
                                setShowTermsOfUseModal(!showTermsOfUseModal)
                              }>
                              <Translator path="visitConsent.termsOfUse" />
                            </button>
                            <TermsOfUseModal
                              show={showTermsOfUseModal}
                              setShow={setShowTermsOfUseModal}
                              setShowPrivacyModal={setShowPrivacyModal}
                            />{" "}
                            <Translator path="visitConsent.and" />{" "}
                            <button
                              className="visit-consent-modals-btn"
                              type="button"
                              onClick={() =>
                                setShowPrivacyModal(!showPrivacyModal)
                              }>
                              <Translator path="visitConsent.privacyPolicy" />
                            </button>
                            <PrivacyPolicyModal
                              show={showPrivacyModal}
                              setShow={setShowPrivacyModal}
                            />
                            . <Translator path="visitConsent.permitProvider" />
                          </label>
                        </div>

                        <div className="field custom-checkbox mt-4">
                          <input
                            type="checkbox"
                            value="1"
                            id="location-agreement"
                            onChange={(e: any) =>
                              setLocationCheck(e.target.checked)
                            }
                            checked={locationCheck}
                          />
                          <label
                            className="checkbox-label input-label"
                            htmlFor="location-agreement">
                            <Translator path="visitConsent.iAcknowledge" />{" "}
                            {activeState ? activeState.code : "CA"}{" "}
                            <Translator path="visitConsent.readAndAgreeTo" />{" "}
                            <button
                              className="visit-consent-modals-btn"
                              type="button"
                              onClick={() =>
                                setShowTelehealthModal(!showTelehealthModal)
                              }>
                              <Translator path="visitConsent.telehealthInformedConsent" />
                            </button>
                            <TelehealthModal
                              show={showTelehealthModal}
                              setShow={setShowTelehealthModal}
                            />{" "}
                            <Translator path="visitConsent.and" />{" "}
                            <button
                              className="visit-consent-modals-btn"
                              type="button"
                              onClick={() =>
                                setShowPrivacyPractices(!showPrivacyPractices)
                              }>
                              <Translator path="visitConsent.noticeOfPrivacyPractices" />
                            </button>
                            <PrivacyPracticesModal
                              show={showPrivacyPractices}
                              setShow={setShowPrivacyPractices}
                            />
                            .
                          </label>
                        </div>

                        <Button
                          fullWidth
                          classes="mt-4"
                          color="secondary"
                          disabled={
                            !activeState ||
                            !termsCheck ||
                            !locationCheck ||
                            isProcessing
                          }
                          onClick={continueProcess}>
                          {isProcessing ? (
                            "Processing"
                          ) : (
                            <Translator path="visitConsent.continueButtonText" />
                          )}
                        </Button>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default VisitConsent;
