import React, { useContext } from "react";
import { ReactSVG } from "react-svg";
import { isEmpty } from "lodash";
import visitIntroIcon from "../../../assets/images/icons/visit-intro-icon.svg";
import Button from "../../../components/Button";
import useLatestCart from "../../../hooks/useLatestCart";
import { CartContext } from "../../../Contexts/CartContext";
import Translator from "../../../components/Translator";
import "./styles.scss";

const VisitIntro = () => {
  const latestCart = useLatestCart();
  const { VisitIntroNextStep } = useContext(CartContext);

  const continueProcess = () => {
    if (!isEmpty(latestCart)) {
      VisitIntroNextStep(latestCart?.id);
    }
  };
  return (
    <div className="container checkout-page product-page">
      <div className="row shipping-address-content justify-content-center pt-0 pt-md-3">
        <div className="col col-lg-6">
          <div className="visit-intro-container">
            <div className="visit-info-label mb-3">
              <Translator path="visit-intro.talkAboutHealth" />
            </div>
            <div className="card">
              <div className="card-body">
                <ReactSVG src={visitIntroIcon} className="svgbottom" />
                <p className="card-text">
                  <Translator path="visit-intro.doctorNeedsToKnow" />
                </p>
              </div>
            </div>
            <Button
              fullWidth
              classes="mt-4"
              color="secondary"
              onClick={continueProcess}
            >
              <Translator path="visit-intro.continueButtonText" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisitIntro;
