import React from "react";
import Translator from "components/Translator";
import NewVisitButton from "./Shared/NewVisitButton";

type Props = {
  defaultProductVariant:
    | {
        id?: any;
      }
    | undefined;
  slug?: boolean;
};

const BottomCta = ({ defaultProductVariant, slug = false }: Props) => {
  return (
    <>
      <div className="ab-wrapper ab-1">
        <div className="homepage-container">
          <div className="bottom-cta">
            <div className="content">
              <h2 className="h2">
                <Translator path="homepage.bottomCta.headings.main.startSaving" />
                <a href="#">
                  <Translator path="homepage.bottomCta.headings.main.sildenafil" />
                </a>
                <Translator path="homepage.bottomCta.headings.main.today" />
              </h2>
              {(defaultProductVariant?.id || slug) && (
                <NewVisitButton
                  defaultProductVariant={defaultProductVariant}
                  position="center"
                  slug={slug}
                  color="white"
                />
              )}
              <p>
                <strong>
                  <Translator path="homepage.bottomCta.text.haveAnyQuestions" />
                </strong>
                <Translator path="homepage.bottomCta.text.speakToRepresentative" />
              </p>
              <span>
                <a className="btn-aum btn-ghost" href="tel:(844) 745-3362">
                  (844) 745-3362
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BottomCta;
