import React, { useState, useRef } from "react";
import { ReactSVG } from "react-svg";
import Slider from "react-slick";
import Translator from "components/Translator";
import { useTranslation } from "react-i18next";

import PatientTestimonialsModal from "../Home/Modals/PatientTestimonialsModal";

import RatingStarsIcon from "assets/images/home/rating-stars.svg";

import "./styles.scss";

const settings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1366,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const MarketingTestimonials = () => {
  const { t } = useTranslation();
  const [activeTestimonial, setActiveTestimonial] = useState({});
  const [show, setShow] = useState<boolean>(false);
  const sliderRef = useRef<any>(null);
  const testimonials = document.querySelectorAll(
    ".testimonial-card .card-text"
  );

  testimonials.forEach((testimonial) => {
    if (testimonial.scrollHeight > testimonial.clientHeight) {
      testimonial.classList.add("clamp");
    }
  });

  const userTestimonials = [
    {
      name: <Translator path="homepage.testimonials.cards.one.name" />,
      content: t("homepage.testimonials.cards.one.content"),
    },
    {
      name: <Translator path="homepage.testimonials.cards.two.name" />,
      content: t("homepage.testimonials.cards.two.content"),
    },
    {
      name: <Translator path="homepage.testimonials.cards.three.name" />,
      content: t("homepage.testimonials.cards.three.content"),
    },
    {
      name: <Translator path="homepage.testimonials.cards.four.name" />,
      content: t("homepage.testimonials.cards.four.content"),
    },
    {
      name: <Translator path="homepage.testimonials.cards.five.name" />,
      content: t("homepage.testimonials.cards.five.content"),
    },
    {
      name: <Translator path="homepage.testimonials.cards.six.name" />,
      content: t("homepage.testimonials.cards.six.content"),
    },
    {
      name: <Translator path="homepage.testimonials.cards.seven.name" />,
      content: t("homepage.testimonials.cards.seven.content"),
    },
    {
      name: <Translator path="homepage.testimonials.cards.eight.name" />,
      content: t("homepage.testimonials.cards.eight.content"),
    },
  ];

  return (
    <div className="marketing-testimonials-wrapper">
      <h3 className="mt-5 mb-4 testimonials-heading">Patient Testimonials</h3>

      <Slider ref={sliderRef} {...settings}>
        {userTestimonials.map((testimonial, index) => (
          <div className="happyPatientsCardMobile" key={index}>
            <div className="testimonial-card card testimonial-item">
              <div className="card-body">
                <p className="card-text">{testimonial.content}</p>
                <button
                  onClick={() => {
                    setActiveTestimonial(testimonial);
                    setShow(true);
                  }}
                  className="read-more">
                  <Translator path="homepage.testimonials.readMore" />
                </button>
                <div className="credit">
                  <ReactSVG src={RatingStarsIcon} wrapper="div" />
                  <p>{testimonial.name}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <PatientTestimonialsModal
        show={show}
        setShow={setShow}
        testimonial={activeTestimonial}
      />
    </div>
  );
};

export default MarketingTestimonials;
