import React, { useContext, useEffect } from "react";
import * as Yup from "yup";
import Button from "components/Button";
import { AuthContext } from "Contexts/AuthContext";
import { Field, Form, Formik } from "formik";
import useCurrentUser from "hooks/useCurrentUser";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";

const UserFormVerification = Yup.object().shape({
  first_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  terms_of_service: Yup.boolean().oneOf(
    [true],
    "Please check the box to continue"
  ),
});

const FinishSetup = () => {
  const { FinishOauthSetup } = useContext(AuthContext);
  const navigate = useNavigate();
  const user = useCurrentUser();

  useEffect(() => {
    if (!(user?.provider === "facebook" && !user.terms_of_service)) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div className="main">
      <div className="session-page">
        <div className="content">
          <h3>Account creation</h3>
          <p className="setup-helper-text">
            To finish creating your account on AUM, verify the information below
            please check the box.
          </p>
          <div className="my-4">
            {!isEmpty(user) && (
              <React.Fragment>
                <Formik
                  initialValues={{
                    first_name: user.first_name ?? "",
                    last_name: user.last_name ?? "",
                    terms_of_service: false,
                  }}
                  validationSchema={UserFormVerification}
                  onSubmit={(values) => {
                    FinishOauthSetup(values);
                  }}
                >
                  {({ errors, touched, values, isValid, dirty }) => (
                    <Form className="user-form">
                      <div className="form-row">
                        <div className="form-group col mb-0">
                          <div className="field">
                            <label className="input-label" htmlFor="first_name">
                              First Name
                            </label>
                            <div className="input-group flex-nowrap">
                              <div className="input__icon user email-icon user-icon"></div>
                              <Field
                                className="first_name no-mouseflow"
                                name="first_name"
                                id="first_name"
                              />
                            </div>
                            {errors.first_name && touched.first_name ? (
                              <div className="input-errors">
                                {errors.first_name}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="form-group col mb-0">
                          <div className="field">
                            <label className="input-label" htmlFor="last_name">
                              Last Name
                            </label>
                            <div className="input-group flex-nowrap">
                              <div className="input__icon user email-icon user-icon"></div>
                              <Field
                                className="last_name no-mouseflow"
                                name="last_name"
                                id="last_name"
                              />
                            </div>
                            {errors.last_name && touched.last_name ? (
                              <div className="input-errors">
                                {errors.last_name}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="input-group terms-wrapper mt-4">
                        <Field
                          className={"terms-and-conditions"}
                          type="checkbox"
                          name="terms_of_service"
                          id="terms-check"
                        />
                        <label
                          className="input-label checkbox-label terms-label"
                          htmlFor="terms-check"
                        >
                          By creating an account you agree to{" "}
                          <a href="/term-of-use">condition of use</a> and{" "}
                          <a href="/privacy-policy">privacy notice</a>.
                        </label>
                      </div>
                      {errors.terms_of_service && touched.terms_of_service ? (
                        <div className="terms-conditions-errors">
                          {errors.terms_of_service}
                        </div>
                      ) : null}
                      <Button
                        classes={"mt-4"}
                        type="submit"
                        fullWidth={true}
                        color="secondary"
                        disabled={!(isValid && dirty)}
                      >
                        Create Account
                      </Button>
                    </Form>
                  )}
                </Formik>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinishSetup;
