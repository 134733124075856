import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import chevronDown from '../../assets/images/home/chevron-down.svg'
import closeIcon from '../../assets/images/home/close.svg'
import Translator from '../../components/Translator';
import Accordion from 'react-bootstrap/Accordion';

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState("");

  const handleHeaderIcon = (key: string) => {
    return activeIndex === key ? closeIcon : chevronDown;
  }

  return (
    <>
      <div className="ab-wrapper ab-1">
        <div className="homepage-container">
          <div className="home-faq section" id="help">
            <div className="content">
              <h2 className="h2">
                <Translator
                  path="homepage.faq.headings.frequentlyAsked"
                />
              </h2>
              <Accordion className="accordion-wrapper" onSelect={(activeIndex: any) => setActiveIndex(activeIndex)}>
                <Accordion.Item className={`${activeIndex === "0" && 'is-open'}`} eventKey="0">
                  <Accordion.Header as={"h3"}>
                    <Translator
                      path="homepage.faq.accordions.zero.isfdaApproved"
                    />
                    <ReactSVG src={handleHeaderIcon("0")} wrapper="span" />
                  </Accordion.Header>
                  <Accordion.Body>
                    <Translator
                      path="homepage.faq.accordions.zero.isfdaApprovedAnswer"
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className={`${activeIndex === "1" && 'is-open'}`} eventKey="1">
                  <Accordion.Header as={"h3"}>
                    <Translator
                      path="homepage.faq.accordions.one.isLicensedAndCertified"
                    />
                    <ReactSVG src={handleHeaderIcon("1")} wrapper="span" />
                  </Accordion.Header>
                  <Accordion.Body>
                    <Translator
                      path="homepage.faq.accordions.one.isLicensedAndCertifiedAnswer"
                    />
                    <a href="https://safe.pharmacy/buy-safely/?url=sildenafil.com">
                      <Translator
                        path="homepage.faq.accordions.one.links.nabp"
                      />
                    </a>
                    <Translator
                      path="homepage.faq.accordions.one.links.cojunction"
                    />
                    <a href="https://www.legitscript.com/websites/sildenafil.com/">
                      <Translator
                        path="homepage.faq.accordions.one.links.legitScripts"
                      />
                    </a>
                    <Translator
                      path="homepage.faq.dot"
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className={`${activeIndex === "2" && 'is-open'}`} eventKey="2">
                  <Accordion.Header as={"h3"}>
                    <Translator
                      path="homepage.faq.accordions.two.doIneedPrescription"
                    />
                    <ReactSVG src={handleHeaderIcon("2")} wrapper="span" />
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      <Translator
                        path="homepage.faq.accordions.two.body.paragraphs.prescriptionIsRequired"
                      />
                    </p>
                    <p>
                      <Translator
                        path="homepage.faq.accordions.two.body.paragraphs.ifRequireNewPrescription"
                      />
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className={`${activeIndex === "3" && 'is-open'}`} eventKey="3">
                  <Accordion.Header as={"h3"}>
                    <Translator
                      path="homepage.faq.accordions.three.howMuchIsShipping"
                    />
                    <ReactSVG src={handleHeaderIcon("3")} wrapper="span" />
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      <Translator
                        path="homepage.faq.accordions.three.howMuchIsShippingAnswer"
                      />
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className={`${activeIndex === "4" && 'is-open'}`} eventKey="4">
                  <Accordion.Header as={"h3"}>
                    <Translator
                      path="homepage.faq.accordions.four.deliveryTime"
                    />
                    <ReactSVG src={handleHeaderIcon("4")} wrapper="span" />
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      <Translator
                        path="homepage.faq.accordions.four.deliveryTimeAnswer"
                      />
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className={`${activeIndex === "5" && 'is-open'}`} eventKey="5">
                  <Accordion.Header as={"h3"}>
                    <Translator
                      path="homepage.faq.accordions.five.isThisSubscription"
                    />
                    <ReactSVG src={handleHeaderIcon("5")} wrapper="span" />
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      <Translator
                        path="homepage.faq.accordions.five.isThisSubscriptionAnswer"
                      />
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className={`${activeIndex === "6" && 'is-open'}`} eventKey="6">
                  <Accordion.Header as={"h3"}>
                    <Translator
                      path="homepage.faq.accordions.six.anyAdditionalFee"
                    />
                    <ReactSVG src={handleHeaderIcon("6")} wrapper="span" />
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      <Translator
                        path="homepage.faq.accordions.six.anyAdditionalFeeAnswer"
                      />
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className={`${activeIndex === "7" && 'is-open'}`} eventKey="7">
                  <Accordion.Header as={"h3"}>
                    <Translator
                      path="homepage.faq.accordions.seven.paymentOptions"
                    />
                    <ReactSVG src={handleHeaderIcon("7")} wrapper="span" />
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      <Translator
                        path="homepage.faq.accordions.seven.paymentOptionsAnswer"
                      />
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className={`${activeIndex === "8" && 'is-open'}`} eventKey="8">
                  <Accordion.Header as={"h3"}>
                    <Translator
                      path="homepage.faq.accordions.eight.acceptReturns"
                    />
                    <ReactSVG src={handleHeaderIcon("8")} wrapper="span" />
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      <Translator
                        path="homepage.faq.accordions.eight.acceptReturnsAnswer"
                      />
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Faq;
