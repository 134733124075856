import React, { useEffect, useState, useContext } from "react";
import {
  filter,
  sortBy,
  map,
  flatMap,
  find,
  first,
  isEmpty,
  last,
  compact,
  flatten,
  isNil,
  differenceBy,
  findIndex,
} from "lodash";

import Loader from "../Shared/Loader";
import SingleQuestion from "../Shared/Questionnaire/SingleQuestion";
import MultipleQuestions from "../Shared/Questionnaire/MultipleQuestions";
import ExtraOptions from "../Shared/Questionnaire/ExtraOptions";
import {
  FetchQuestionsRequest,
  SendUserResponseToPocketmedRequest,
  QuestionnaireBackRequest,
  addActivelyTakingMedicationsRequest,
  deleteActivelyTakingMedicationsRequest,
  addTreatmentsRequest,
  deleteTreatmentsRequest,
} from "apis";
import { CartContext } from "Contexts/CartContext";
import Button from "components/Button";
import "../Shared/Questionnaire/styles.scss";
import { useLocation, useNavigate, useNavigationType } from "react-router-dom";
import useLatestCart from "hooks/useLatestCart";
import { calculateProgressBar } from "helpers/ProgressBarCalculator";

const VisitConsultation = () => {
  const cart = useLatestCart();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [textAreaBorder, setTextAreaBorder] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>([]);
  const [questions, setQuestions] = useState<any>(null);
  const [activeQuestion, setActiveQuestion] = useState<any>(null);
  const [selectedAnswers, setSelectedAnswers] = useState<any>([]);
  const [allowText, setAllowText] = useState<boolean>(false);
  const [allowAllergy, setAllowAllergy] = useState<boolean>(false);
  const [queriedAllergies, setQueriedAllergies] = useState(null);
  const [addedAllergies, setAddedAllergies] = useState<[]>([]);
  const [allowMed, setAllowMed] = useState<boolean>(false);
  const [queriedMeds, setQueriedMeds] = useState(null);
  const [addedMeds, setAddedMeds] = useState<[]>([]);
  const [extraText, setExtraText] = useState(null);
  const [userAction, setUserAction] = useState<any>(null);
  const [subQuestions, setSubQuestions] = useState<[]>([]);
  const [questionRenderType, setQuestionRenderType] = useState(null);
  const [overrideMultiDisplay, setOverrideMultiDisplay] =
    useState<boolean>(false);
  const [activeSubQuestions, setActiveSubQuestions] = useState<[]>([]);
  const [showSideEffectText, setShowSideEffectText] = useState<boolean>(false);
  const [sideEffectsQuestionLabel, setSideEffectsQuestionLabel] =
    useState<string>("");
  const [showContinueButton, setShowContinueButton] = useState<boolean>(true);
  const [continueButtonDisable, setContinueButtonDisable] =
    useState<boolean>(true);

  const {
    QuestionnaireNextStep,
    setShouldQuestionnaireBack,
    shouldQuestionnaireBack,
    setCurrentQuestionId,
    currentQuestionId,
    questionProgressBar,
    setQuestionProgressBar,
  } = useContext(CartContext);

  const location = useLocation();
  const navigate = useNavigate();
  const navigationType = useNavigationType();

  useEffect(() => {
    if (!isEmpty(activeQuestion)) {
      if (activeQuestion.question_type === "radio") {
        const selectdAns = find(selectedAnswers, [
          "question_id",
          activeQuestion.id,
        ]);
        setShowContinueButton(!isEmpty(selectdAns));
        setContinueButtonDisable(isEmpty(selectdAns));
      }

      setAllowMed(
        activeQuestion.med_api || activeQuestion.answer_options[0]?.allow_med
      );
    }

    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuestion]);

  useEffect(() => {
    const currentBarSize = Number(
      calculateProgressBar(location.pathname)
    ).toFixed(2);
    const activeQueNumber: any = Number(
      activeQuestion?.label?.split(".")[0]
    ).toFixed(2);

    if (currentBarSize && activeQueNumber) {
      const subQueLevel1Number: any = Number(
        activeQuestion?.label?.split(".")[1]
      ).toFixed(2);
      const perQuestionSize = 0.625 * (activeQueNumber - 1);
      let subLevel1QueSize = 0;
      let subLevel2QueSize = 0;
      let subQueLevel2Number: any = 0;

      if (!isEmpty(activeSubQuestions)) {
        const mainSubQue: any = first(activeSubQuestions);
        if (!isEmpty(mainSubQue)) {
          subQueLevel2Number = Number(mainSubQue?.label?.split(".")[2]).toFixed(
            2
          );
        }
      }

      if (subQueLevel1Number > 1) {
        subLevel1QueSize = 0.625 / subQueLevel1Number;
      }

      if (subQueLevel2Number > 1) {
        subLevel2QueSize = subLevel1QueSize / subQueLevel2Number;
      }

      let totalBar =
        Number(currentBarSize) +
        perQuestionSize +
        subLevel1QueSize +
        subLevel2QueSize;

      setQuestionProgressBar(totalBar);
    }
  }, [activeQuestion, activeSubQuestions]);

  useEffect(() => {
    if (!isEmpty(cart) && cart.visit_uuid) {
      FetchQuestionsRequest(cart.visit_uuid)
        .then((response) => {
          const { finish_questionnaire, questions } = response.data;

          if (finish_questionnaire) {
            QuestionnaireNextStep(cart.id, {});
          } else {
            setQuestions(sortBy(questions, "label"));
          }
        })
        .catch((error) => {
          setErrors([error]);
        })
        .finally(() => {
          setContinueButtonDisable(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  useEffect(() => {
    if (shouldQuestionnaireBack) {
      handleBack();
    }
  }, [shouldQuestionnaireBack]);

  useEffect(() => {
    const navTyp = navigationType;

    if (!isEmpty(questions)) {
      if (navTyp === "POP") {
        handleBack();
      }
    }

    if (cart?.branch === "refill_request") {
      if (navTyp === "POP") {
        navigate("/account/order-refill");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    if (!isEmpty(selectedAnswers)) {
      const currentSelectedAnswer = selectedAnswers.filter(
        (sa: any) => sa?.question_id === activeQuestion?.id
      )[0];
      const hasNoTextOption = !selectedAnswers.filter(
        (sa: any) => sa?.allow_text
      ).length;
      const hasExtraTextOption =
        currentSelectedAnswer?.label === "Yes" &&
        currentSelectedAnswer?.allow_text;
      const hasAllergyApi =
        currentSelectedAnswer?.label === "Yes" &&
        currentSelectedAnswer?.allow_allergy;
      const userSelectedARadioOption =
        activeQuestion?.question_type === "radio" &&
        userAction === "optionSelect";
      const userSelectedASoloCheckboxOption =
        activeQuestion?.question_type === "multi" &&
        currentSelectedAnswer?.solo;

      if (userAction !== "optionSelect") {
        return;
      }

      if (
        (userSelectedARadioOption || userSelectedASoloCheckboxOption) &&
        hasNoTextOption
      ) {
        if (hasExtraTextOption || hasAllergyApi) return;
        handleContinue();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAnswers, userAction]);

  // Snap to the first available question when 'questions' change and set default selected answer(s)
  useEffect(() => {
    setErrors([]);
    if (questions) {
      let selectedAnswerIds: any,
        defaultSelectedAnswer: any,
        currentQuestionResponse: any,
        currentQuestion: any,
        extraTextValue: any,
        sideEffectsTextarea: any,
        allergyFlag: any,
        shouldShowText: any;
      let allergies = [],
        meds: any = [];
      const allResponses: any = questions.map(
        (question: any) => question.responses
      );
      const allAnswerOptions: any = flatMap(questions, (q) =>
        flatMap(q.answer_options)
      );

      // Handle moving back and forth in continuous questions
      if (userAction === "back" && questions[0].question_type !== null) {
        currentQuestion = last(sortBy(questions, "label"));
      } else {
        currentQuestion = first(sortBy(questions, "label"));
        navigate("/checkout/visit-consultation", {
          state: { question_id: currentQuestion.id },
          replace: true,
        });
      }

      // Handle sub-questions VS singular question values
      if (!isEmpty(questions) && questions[0].question_type === null) {
        const ansOptionsIdsArray: any = [];

        // Extracting Ids from All Responses and pusshing them in array
        compact(
          flatten(
            // eslint-disable-next-line array-callback-return
            allResponses.map((response: any) => {
              if (!isEmpty(response)) {
                // eslint-disable-next-line array-callback-return
                response[0].answer_option_ids?.map((id: any) => {
                  ansOptionsIdsArray.push(id);
                });
              }
            })
          )
        );
        selectedAnswerIds = ansOptionsIdsArray;

        defaultSelectedAnswer = allAnswerOptions.filter((ao: any) =>
          selectedAnswerIds.includes(String(ao.id))
        );

        if (currentQuestion.label === "16.01") {
          meds = flatten(
            questions.map((question: any) => question.medications)
          );

          meds = !isEmpty(currentQuestion) && [
            ...currentQuestion.visit_medications,
          ];
        }
      } else {
        selectedAnswerIds =
          currentQuestion?.responses[0]?.answer_option_ids || [];
        defaultSelectedAnswer = currentQuestion?.answer_options.filter(
          (answer: any) => selectedAnswerIds.includes(String(answer.id))
        );
        allergyFlag =
          !isEmpty(defaultSelectedAnswer) &&
          defaultSelectedAnswer[defaultSelectedAnswer.length - 1]
            ?.allow_allergy;
        currentQuestionResponse =
          !isEmpty(currentQuestion) &&
          currentQuestion.responses.filter(
            (response: any) => response.question_id === currentQuestion.id
          )[0];
        shouldShowText =
          allAnswerOptions &&
          !!currentQuestionResponse?.text?.length &&
          currentQuestion?.question_type !== "text" &&
          !!defaultSelectedAnswer[0]?.allow_text;
        extraTextValue = currentQuestionResponse?.text;
        allergies =
          (!isEmpty(defaultSelectedAnswer) &&
            defaultSelectedAnswer[defaultSelectedAnswer.length - 1]
              ?.allergies) ||
          [];
        meds = flatten(questions.map((question: any) => question.medications));
        meds = !isEmpty(currentQuestion) && [
          ...currentQuestion.visit_medications,
        ];

        setAllowText(shouldShowText);
      }

      // For Question6 scenario
      const { indexOfActiveSubQuestion } = handleQuestionChunking(
        defaultSelectedAnswer
      );

      sideEffectsTextarea = find(defaultSelectedAnswer, [
        "question_id",
        filter(questions, ["data_type", "side_effects"])[
          indexOfActiveSubQuestion
        ]?.id,
      ]);

      // Map extra text value to answer option(s) to enforce textarea value to match stored answer
      // eslint-disable-next-line array-callback-return
      !isEmpty(defaultSelectedAnswer) &&
        defaultSelectedAnswer.map((dsa: any) => {
          if (questions[0].question_type === null) {
            currentQuestionResponse = flatMap(allResponses).filter(
              (res: any) => res.question_id === dsa.question_id
            )[0];
            dsa.text_value = currentQuestionResponse?.text;
          } else {
            dsa.text_value = extraTextValue;
          }
          return dsa;
        });

      // Map the local_id for ordering the allergies
      // eslint-disable-next-line array-callback-return
      allergies?.map((a: any, idx: number) => {
        a.local_id = idx;
      });

      // Map the local_id for ordering the meds
      if (currentQuestion?.label === "16.01") {
        meds = currentQuestion?.medications;
        defaultSelectedAnswer = currentQuestion?.answer_options;
      }

      if (!isEmpty(meds)) {
        meds.map((med: any, idx: number) => {
          med.local_id = idx;
          med.question_id = med?.question_id || currentQuestion.id;
          med.condition = currentQuestion.condition;
          return med;
        });
      }

      setExtraText(extraTextValue);
      setActiveQuestion(currentQuestion);
      !isEmpty(currentQuestion) && setQuestionRenderType(currentQuestion.type);
      setSelectedAnswers(defaultSelectedAnswer);
      !isEmpty(defaultSelectedAnswer) &&
        setAllowAllergy(
          defaultSelectedAnswer[defaultSelectedAnswer.length - 1]?.allow_allergy
        );
      setAddedAllergies(allergies);

      if (!isEmpty(defaultSelectedAnswer)) {
        const shouldAllowMeds =
          defaultSelectedAnswer[defaultSelectedAnswer.length - 1]?.allow_med ||
          currentQuestion.med_ap;

        setAllowMed(shouldAllowMeds);
      }

      setAddedMeds(meds);
      setSideEffectsQuestionLabel(sideEffectsTextarea?.question_label);
      setShowSideEffectText(!!sideEffectsTextarea?.text_value?.length);
      setShowContinueButton(
        currentQuestion?.question_type !== "radio" ||
          shouldShowText ||
          allergyFlag
      );

      setCurrentQuestionId(currentQuestion.id);
    }
    setErrors([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions]);

  const sendDataToPocketMed = () => {
    const anyMedApiQuestion = !!questions.filter(
      (question: any) => question.med_api
    ).length;

    setIsProcessing(true); // Disable the button

    // Send user's response over to PocketMed
    SendUserResponseToPocketmedRequest(cart?.visit_uuid, {
      patient_answer: selectedAnswers,
      extra_text: extraText,
      allergies: activeQuestion?.allergy_api ? addedAllergies : [],
      update_allergies: activeQuestion?.allergy_api,
      meds: anyMedApiQuestion ? addedMeds : [],
      update_meds: anyMedApiQuestion,
    })
      .then((response: any) => {
        const {
          finish_questionnaire,
          rejection_reason,
          previous_question_label,
          questions,
          next_visit_step,
        } = response.data;

        if (
          finish_questionnaire ||
          (["7.01", "8.01"].includes(previous_question_label) &&
            next_visit_step === "dosage")
        ) {
          const values = {
            rejection_reason: rejection_reason,
            previous_question_label: previous_question_label,
          };
          if (!isEmpty(cart)) {
            QuestionnaireNextStep(cart.id, values);
          }
        } else {
          setQuestions(questions);
        }
      })
      .catch((error) => {
        setErrors([error]);
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const handleContinue = () => {
    /*
      Commenting this temporarily as we intend to show a loader on moving forward but this
      is not the right place to be turning the loader on.
    */
    setIsLoading(true);

    const indexOfNextQuestion =
      questions.findIndex((item: any) => item.id === activeQuestion.id) + 1;
    const nextQuestion = questions[indexOfNextQuestion];
    const indexOfNextSubQuestions =
      findIndex(subQuestions, (tq) => tq === activeSubQuestions) + 1;
    const nextSubQuestions = subQuestions[indexOfNextSubQuestions];
    const nextQuestionHasExtraOptions =
      nextQuestion?.med_api ||
      nextQuestion?.allergy_api ||
      nextQuestion?.allow_text ||
      nextQuestion?.fetch_next_question;
    const shouldShowContinueButton =
      (nextQuestion && nextQuestion?.question_type !== "radio") ||
      nextQuestionHasExtraOptions;
    let meds: [] = [];
    if (shouldDisableButton()) {
      if (
        activeQuestion.question_type !== "radio" ||
        activeQuestion.med_api ||
        activeQuestion.allergy_api
      ) {
        setErrors(["Please choose or type a valid answer to move forward."]);
      }
      setTextAreaBorder(true);
      setIsLoading(false);
      return;
    }
    setUserAction("next");
    setErrors([]);
    const lastQuestion: any = last(questions);

    if (
      overrideMultiDisplay &&
      indexOfNextSubQuestions !== subQuestions?.length
    ) {
      //== QUESTION 6 SUBQUESTIONS FLOW ==//
      const defaultSelectedAnswer: [] = [];

      const sideEffectsTextarea: any = find(selectedAnswers, [
        "question_id",
        filter(questions, ["data_type", "side_effects"])[
          indexOfNextSubQuestions
        ]?.id,
      ]);

      setActiveSubQuestions(nextSubQuestions);
      setQuestionRenderType(nextQuestion?.question_type);
      setSelectedAnswers([...selectedAnswers, ...defaultSelectedAnswer]);
      setAllowText(false);
      setSideEffectsQuestionLabel(sideEffectsTextarea?.question_label);
      setShowSideEffectText(!!sideEffectsTextarea?.text_value?.length);
      setIsLoading(false);
    } else if (
      activeQuestion.question_type !== null &&
      lastQuestion.id !== activeQuestion.id
    ) {
      //== NORMAL QUESTIONNAIRE FLOW ==//
      const selectedAnswerIds =
        nextQuestion.responses[0]?.answer_option_ids || [];
      const defaultSelectedAnswer = nextQuestion.answer_options.filter(
        (answer: any) => selectedAnswerIds.includes(String(answer.id))
      );
      const extraTextValue =
        nextQuestion.responses.filter(
          (response: any) => response.question_id === nextQuestion.id
        )[0]?.text || "";

      // Map extra text value to answer option(s) to enforce textarea value to match stored answer
      defaultSelectedAnswer.map((dsa: any) => {
        dsa.text_value = extraTextValue;
        return dsa;
      });

      // Map the local_id for ordering the meds
      meds?.map((med: any, idx: number) => {
        med.local_id = idx;
        med.question_id = nextQuestion.id;
        med.condition = nextQuestion.condition;
        return med;
      });

      setActiveQuestion(nextQuestion);
      setQuestionRenderType(nextQuestion.question_type);
      setSelectedAnswers([...selectedAnswers, ...defaultSelectedAnswer]);
      setAllowText(false);
      setAllowMed(
        defaultSelectedAnswer[defaultSelectedAnswer.length - 1]?.allow_med ||
          nextQuestion.med_api
      );
      setAddedMeds([...addedMeds, ...meds]);
    } else {
      sendDataToPocketMed();
    }

    if (questions.length > 1) {
      setShowContinueButton(shouldShowContinueButton);
    }

    //handle actively taking medications

    if (activeQuestion.label === "6.02") {
      handleDeleteMedications();
      handleDeleteTreatments();
    } else {
      handleAddActivelyTakingMeds();
    }
    // To prevent the mediation search and allerfy search apears rendomly in other questions
    setAllowMed(false);
    setAllowAllergy(false);

    navigate("/checkout/visit-consultation", {
      state: { question_id: activeQuestion?.id },
    });

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleDeleteTreatments = () => {
    const allAnswerOptions: any = flatMap(questions, (q) =>
      flatMap(q.answer_options)
    );

    const treatmentOptions: any = map(
      filter(allAnswerOptions, (opt: any) => isNil(opt?.routed_dose_form_drug_id)),
      "label"
    );

    const selectedOptions: any = map(
      filter(selectedAnswers, (opt: any) => isNil(opt?.routed_dose_form_drug_id)),
      "label"
    );

    const removedOptions = differenceBy(treatmentOptions, selectedOptions);

    const deleteTreatmentData = {
      visit_id: cart?.visit_uuid || "",
      treatment_names: removedOptions,
      other_treatment: removedOptions.includes("Other treatment"),
    };
    deleteTreatmentsRequest(deleteTreatmentData);
  };

  const handleDeleteMedications = () => {
    const allAnswerOptions: any = flatMap(questions, (q) =>
      flatMap(q.answer_options)
    );

    const medicationOptions: any = filter(
      allAnswerOptions,
      (opt: any) => !isNil(opt?.routed_dose_form_drug_id)
    );

    const selectedAnsWithLexiId = filter(
      selectedAnswers,
      (ans) => !isNil(ans?.routed_dose_form_drug_id)
    );

    const allIds = map(medicationOptions, "routed_dose_form_drug_id");
    const selectedIds = map(selectedAnsWithLexiId, "routed_dose_form_drug_id");

    const finalArray: any = allIds.filter((x) => !selectedIds.includes(x));

    const values: any = {
      visit_uuid: cart?.visit_uuid,
      ids: finalArray,
    };

    deleteActivelyTakingMedicationsRequest(values);
  };

  const handleAddActivelyTakingMeds = () => {
    const firstActiveSubQue: any = first(activeSubQuestions);
    let strength;
    let current = false;
    let sideEffects: any;
    let lexiGenSynId: any;
    let singleMedIds: any;
    let medQuestion;
    let deselectedMedsIds;

    if (
      !isEmpty(firstActiveSubQue) &&
      firstActiveSubQue?.label.includes("6.0")
    ) {
      const subQueResponses: any = filter(selectedAnswers, (ans: any) =>
        find(activeSubQuestions, (q: any) => q.id === ans.question_id)
      );
      const strengthQue: any = find(activeSubQuestions, [
        "data_type",
        "strength",
      ]);
      const currentQue: any = find(activeSubQuestions, [
        "data_type",
        "current",
      ]);
      const sideEffectsQue: any = find(activeSubQuestions, [
        "data_type",
        "side_effects_options",
      ]);

      strength = find(subQueResponses, ["question_id", strengthQue?.id])?.label;

      lexiGenSynId = find(subQueResponses, [
        "question_id",
        strengthQue?.id,
      ])?.routed_dose_form_drug_id;

      current =
        find(subQueResponses, ["question_id", currentQue?.id])?.value === "1";

      if (current) {
        const sideEffectsArray = filter(
          subQueResponses,
          (res) =>
            res.question_id === sideEffectsQue?.id && res.label !== "Other"
        );

        const otherEffects: any = find(
          subQueResponses,
          (res) =>
            res.question_id === sideEffectsQue?.id && res.label === "Other"
        );

        if (!isEmpty(sideEffectsArray)) {
          sideEffects = sideEffectsArray
            .map((effect) => effect.label)
            .join(", ")
            .toString();
        }

        if (!isEmpty(otherEffects)) {
          if (isEmpty(sideEffects)) {
            sideEffects = `Other: ${otherEffects.text_value}`;
          } else {
            sideEffects = `${sideEffects}, Other: ${otherEffects.text_value}`;
          }
        }
      }

      medQuestion = firstActiveSubQue;
    } else {
      const selectedAnsWithLexiId = filter(
        selectedAnswers,
        (ans) => !isNil(ans?.routed_dose_form_drug_id)
      );

      const allAnswerOptions: any = flatMap(questions, (q) =>
        flatMap(q.answer_options)
      );

      const medicationOptions: any = filter(
        allAnswerOptions,
        (opt: any) => !isNil(opt?.routed_dose_form_drug_id)
      );

      const allAnsWithLexiId = map(medicationOptions, "routed_dose_form_drug_id");

      singleMedIds = map(selectedAnsWithLexiId, "routed_dose_form_drug_id");
      deselectedMedsIds = allAnsWithLexiId.filter(
        (x) => !singleMedIds.includes(x)
      );
      medQuestion = activeQuestion;
    }

    const medData: any = {
      single_med_ids: singleMedIds,
      deselected_meds_ids: deselectedMedsIds,
      strength: strength,
      current: current,
      side_effects: sideEffects,
      routed_dose_form_drug_id: lexiGenSynId,
      question_id: medQuestion?.id,
      visit_uuid: cart?.visit_uuid,
    };

    if (
      isEmpty(strength) &&
      isEmpty(lexiGenSynId) &&
      firstActiveSubQue?.label?.includes("6.")
    ) {
      const sideEffectQue: any = find(activeSubQuestions, [
        "data_type",
        "side_effects",
      ]);

      const sideEffect: any = find(
        selectedAnswers,
        (ans) => ans.question_id === sideEffectQue.id
      ).text_value;

      const treatmentQue: any = find(activeSubQuestions, ["data_type", "name"]);

      let name = treatmentQue?.answer_options[0]?.label;
      let otherTreatment = false;

      if (name === "Other treatment") {
        const que: any = find(
          activeSubQuestions,
          (q: any) => q.data_type === "name" && q.question_type === "textfield"
        );
        name = find(selectedAnswers, ["question_id", que.id])?.text_value;
        otherTreatment = true;
      }

      const treatmentData: any = {
        name: name,
        current: current,
        side_effects: sideEffect,
        question_id: medQuestion?.id,
        visit_id: cart?.visit_uuid,
        other_treatment: otherTreatment,
      };

      addTreatmentsRequest(treatmentData);
    }

    if (!medQuestion.med_api) {
      addActivelyTakingMedicationsRequest(medData);
    }
  };

  const handleBack = () => {
    setIsLoading(true);

    if (currentQuestionId === 1) {
      navigate("/checkout/visit-intro");
    }

    const currentIndex = questions.findIndex(
      (question: any) => question.id === activeQuestion.id
    );
    const previousQuestion = questions[currentIndex - 1];
    const indexOfPreviousSubQuestions =
      findIndex(subQuestions, (tq) => tq === activeSubQuestions) - 1;
    const previousSubQuestions = subQuestions[indexOfPreviousSubQuestions];
    const previousQuestionHasExtraOptions =
      previousQuestion?.med_api ||
      previousQuestion?.allergy_api ||
      previousQuestion?.allow_text ||
      previousQuestion?.fetch_next_question;
    const shouldShowContinueButton =
      (previousQuestion && previousQuestion?.question_type !== "radio") ||
      previousQuestionHasExtraOptions ||
      previousSubQuestions;

    setUserAction("back");

    if (overrideMultiDisplay && indexOfPreviousSubQuestions >= 0) {
      const sideEffectsTextarea: any = find(selectedAnswers, [
        "question_id",
        filter(questions, ["data_type", "side_effects"])[
          indexOfPreviousSubQuestions
        ]?.id,
      ]);

      setActiveSubQuestions(previousSubQuestions);
      setQuestionRenderType(previousQuestion?.question_type);
      setSelectedAnswers([...selectedAnswers]);
      setAllowText(false);
      setSideEffectsQuestionLabel(sideEffectsTextarea?.question_label);
      setShowSideEffectText(!!sideEffectsTextarea?.text_value?.length);
      setIsLoading(false);
    } else if (questions.length > 1 && previousQuestion) {
      const selectedAnswerIds =
        previousQuestion.responses[0]?.answer_option_ids ||
        map(selectedAnswers, (sa) => String(sa.id));

      let defaultSelectedAnswer = previousQuestion.answer_options.filter(
        (answer: any) => selectedAnswerIds.includes(String(answer.id))
      );

      const localDefaultAnswers = selectedAnswers.filter((answer: any) =>
        selectedAnswerIds.includes(String(answer.id))
      );

      let extraTextValue: any;

      extraTextValue = previousQuestion.responses.filter(
        (response: any) => response.question_id === previousQuestion.id
      )[0]?.text;

      if (isEmpty(extraTextValue)) {
        extraTextValue = selectedAnswers.filter(
          (ans: any) => ans.question_id === previousQuestion.id
        )[0]?.text_value;
      }

      // Map extra text value to answer option(s) to enforce textarea value to match stored answer
      defaultSelectedAnswer.map((dsa: any) => {
        return (dsa.text_value = extraTextValue);
      });

      if (selectedAnswerIds.length !== defaultSelectedAnswer.length) {
        defaultSelectedAnswer = localDefaultAnswers;
      }

      setActiveQuestion(previousQuestion);
      setQuestionRenderType(previousQuestion.question_type);
      setSelectedAnswers(defaultSelectedAnswer);
      setAllowMed(
        defaultSelectedAnswer[defaultSelectedAnswer.length - 1]?.allow_med ||
          previousQuestion.med_api
      );
    } else {
      if (activeQuestion?.label === "1.01") {
        navigate("/checkout/visit-intro");
      } else {
        QuestionnaireBackRequest(cart?.visit_uuid, activeQuestion.label)
          .then((response) => {
            setQuestions(response.data.questions);
          })
          .catch((error) => {
            setErrors([error]);
          })
          .finally(() => {
            setIsProcessing(false);
          });
      }
    }

    if (questions.length > 1) {
      setShowContinueButton(shouldShowContinueButton);
    }

    setShouldQuestionnaireBack(false);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleQuestionChunking = (answers: any) => {
    let sortedChunkedArray: any, indexOfActiveSubQuestion: any;
    let response = [];
    let head = 0,
      tail = 1;

    // Chunk the questions array
    for (const question of questions) {
      if (question === questions[0]) {
        continue;
      }

      if (
        question.question_id !== null &&
        question !== questions[questions.length - 1]
      ) {
        tail += 1;
        continue;
      } else {
        response.push(questions.slice(head, tail));
        head = tail;
        tail += 1;
      }

      if (question === questions[questions.length - 1]) {
        last(response).push(question);
      }
    }

    // check if the first question is a part of the Q6 series

    if (!isEmpty(questions) && questions[0].label.includes("6.0")) {
      // Split the questions array at the 7th element
      sortedChunkedArray = response;
      indexOfActiveSubQuestion =
        userAction === "back" ? sortedChunkedArray.length - 1 : 0;

      setSubQuestions(sortedChunkedArray);
      setActiveQuestion(null); // remove the normal activeQuestion
      setActiveSubQuestions(sortedChunkedArray[indexOfActiveSubQuestion]);
      setOverrideMultiDisplay(true);
    } else {
      setSubQuestions([]);
      setActiveSubQuestions([]);
      setOverrideMultiDisplay(false);
    }

    return { sortedChunkedArray, indexOfActiveSubQuestion };
  };

  const shouldDisableButton = () => {
    let condition =
      (!selectedAnswers?.length || isProcessing) &&
      activeQuestion?.label !== "16.01";

    if (isProcessing) return true;

    if (activeQuestion?.label === "8.01") {
      condition =
        (selectedAnswers?.length !== 2 || isProcessing) &&
        activeQuestion?.label !== "16.01";
    }

    if (
      !["multi", "textfield_disabled"].includes(
        activeQuestion?.question_type
      ) &&
      !["16.01", "11.49"].includes(activeQuestion?.label)
    ) {
      const textValue = filter(selectedAnswers, [
        "question_label",
        activeQuestion?.label,
      ])[0]?.text_value;
      const shouldBeDisabled =
        continueButtonDisable ||
        (activeQuestion?.question_type === "text" && !textValue?.length);
      if (shouldBeDisabled) return true;
    } else if (activeQuestion?.label === "11.49") {
      return continueButtonDisable;
    }

    return condition;
  };

  const handleAnswerSelection = (value: any) => {
    setErrors([]);
    setTextAreaBorder(false);
    setSelectedAnswers(value);
  };

  const buttonText = isEmpty(addedMeds) && activeQuestion?.button_text;

  return (
    <>
      {isLoading ? (
        <div className="mt-5">
          <Loader />
        </div>
      ) : (
        <>
          <div className="main">
            <div className="container checkout-page">
              <div className="content false">
                <div className="row justify-content-center pt-0 pt-md-3">
                  <div className="col col-lg-6 col-md-8">
                    <section>
                      {isLoading ? (
                        <Loader />
                      ) : (
                        <>
                          {activeQuestion?.question_type === null &&
                          activeQuestion.label !== "16.01" ? (
                            <>
                              <MultipleQuestions
                                selectedAnswers={selectedAnswers}
                                setSelectedAnswers={handleAnswerSelection}
                                questions={questions}
                                allowText={allowText}
                                setAllowText={setAllowText}
                                extraText={extraText}
                                setExtraText={setExtraText}
                                allowAllergy={allowAllergy}
                                setAllowAllergy={setAllowAllergy}
                                allowMed={allowMed}
                                setAllowMed={setAllowMed}
                                isProcessing={isProcessing}
                                setIsProcessing={setIsProcessing}
                                queriedMeds={queriedMeds}
                                setQueriedMeds={setQueriedMeds}
                                addedMeds={addedMeds}
                                setAddedMeds={setAddedMeds}
                                errors={errors}
                                setErrors={setErrors}
                                subQuestions={subQuestions}
                                setSubQuestions={setSubQuestions}
                                questionRenderType={questionRenderType}
                                overrideMultiDisplay={overrideMultiDisplay}
                                setOverrideMultiDisplay={
                                  setOverrideMultiDisplay
                                }
                                activeSubQuestions={activeSubQuestions}
                                showSideEffectText={showSideEffectText}
                                setShowSideEffectText={setShowSideEffectText}
                                sideEffectsQuestionLabel={
                                  sideEffectsQuestionLabel
                                }
                                setSideEffectsQuestionLabel={
                                  setSideEffectsQuestionLabel
                                }
                                setUserAction={setUserAction}
                                setShowContinueButton={setShowContinueButton}
                                setContinueButtonDisable={
                                  setContinueButtonDisable
                                }
                                textAreaBorder={textAreaBorder}
                              />
                            </>
                          ) : (
                            <>
                              <SingleQuestion
                                selectedAnswers={selectedAnswers}
                                setSelectedAnswers={handleAnswerSelection}
                                setAllowText={setAllowText}
                                extraText={extraText}
                                setExtraText={setExtraText}
                                setAllowAllergy={setAllowAllergy}
                                activeQuestion={activeQuestion}
                                questionsLength={questions?.length}
                                setUserAction={setUserAction}
                                handleContinue={handleContinue}
                                setShowContinueButton={setShowContinueButton}
                                textAreaBorder={textAreaBorder}
                                setContinueButtonDisable={
                                  setContinueButtonDisable
                                }
                              />
                              <ExtraOptions
                                allowText={allowText}
                                allowAllergy={allowAllergy}
                                allowMed={allowMed}
                                activeQuestion={activeQuestion}
                                isProcessing={isProcessing}
                                setIsProcessing={setIsProcessing}
                                selectedAnswers={selectedAnswers}
                                setSelectedAnswers={handleAnswerSelection}
                                queriedMeds={queriedMeds}
                                setQueriedMeds={setQueriedMeds}
                                addedMeds={addedMeds}
                                setAddedMeds={setAddedMeds}
                                addedAllergies={addedAllergies}
                                setAddedAllergies={setAddedAllergies}
                                queriedAllergies={queriedAllergies}
                                setQueriedAllergies={setQueriedAllergies}
                                errors={errors}
                                setErrors={setErrors}
                                setTextAreaBorder={setTextAreaBorder}
                                textAreaBorder={textAreaBorder}
                                setContinueButtonDisable={
                                  setContinueButtonDisable
                                }
                                extraText={null}
                                setExtraText={null}
                              />
                            </>
                          )}
                          {errors?.map((err: any, idx: number) => (
                            <div
                              className={`error-label ${
                                textAreaBorder
                                  ? "checkout-error-label-background"
                                  : ""
                              }`}
                              key={idx}
                            >
                              {err}
                            </div>
                          ))}
                          {showContinueButton && (
                            <Button
                              fullWidth
                              classes={`mt-4 ${
                                shouldDisableButton() ? "disabled" : ""
                              }`}
                              color="secondary"
                              onClick={handleContinue}
                            >
                              {isProcessing
                                ? "Processing..."
                                : `${
                                    !isEmpty(buttonText)
                                      ? buttonText
                                      : "Continue"
                                  }`}
                            </Button>
                          )}
                        </>
                      )}
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default VisitConsultation;
