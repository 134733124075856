import React, { useContext } from "react";
import { isEmpty } from "lodash";
import Modal from "react-bootstrap/Modal";
import Iframe from "react-iframe";

import { AuthContext } from "Contexts/AuthContext";
import crossIcon from "assets/images/icons/entypo-cross.svg";

import "./styles.scss";

type ChatModalProps = {
  show: boolean;
  setShow: any;
  url: string;
};

const ChatModal = ({ show, setShow, url = "" }: ChatModalProps) => {
  const { ReadChatMessages } = useContext(AuthContext);

  const markMessagesAsRead = () => {
    setShow(false);
    ReadChatMessages();
  };

  if (isEmpty(url)) return <></>;

  return (
    <Modal
      show={show}
      onHide={markMessagesAsRead}
      dialogClassName="chat-messages-modal"
      aria-labelledby="example-custom-modal-styling-title"
      className="drawer">
      <Modal.Header>
        <Modal.Title id="example-custom-modal-styling-title">
          Message Center
        </Modal.Title>
        <button className="close-btn" 
                onClick={markMessagesAsRead}>
          <img src={crossIcon} alt="Close" style={{ width: "100%" }} />
        </button>
      </Modal.Header>
      <Modal.Body>
        <Iframe url={url} width="100%" height="55%" />
      </Modal.Body>
    </Modal>
  );
};

export default ChatModal;
