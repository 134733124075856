import React, { useContext } from "react";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "components/Button";
import { AuthContext } from "Contexts/AuthContext";
import { CartContext } from "Contexts/CartContext";

import modalCloseIcon from "assets/images/controls/modal-close.svg";
import "../styles.scss";

type Props = {
  defaultProductVariant:
    | {
        id?: any;
      }
    | undefined;
  slug?: boolean;
};

const RefillAvailableModal = ({
  defaultProductVariant,
  slug = false,
}: Props) => {
  const navigate = useNavigate();
  const { showRefillAvailableModal, setShowRefillAvailableModal } =
    useContext(AuthContext);
  const { CreateCart } = useContext(CartContext);

  return (
    <Modal
      centered
      className="new-modal"
      id="resume-visit-modal"
      show={showRefillAvailableModal}
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => setShowRefillAvailableModal(false)}
      animation={false}>
      <Modal.Dialog>
        <Modal.Header>
          <button
            className="ml-auto"
            onClick={() => setShowRefillAvailableModal(false)}>
            <img src={modalCloseIcon} alt="" />
          </button>
        </Modal.Header>

        <Modal.Body>
          <div className="refill-text mb-2 mt-2">
            <h4 className="modal-title">
              Do you want to refill your medication for Sildenafil?
            </h4>
          </div>
          <div className="modal-actions d-flex flex-md-row flex-column mt-1">
            <Formik
              initialValues={{
                variant: defaultProductVariant?.id ?? null,
              }}
              onSubmit={(values) => {
                setShowRefillAvailableModal(false);
                slug ? navigate("/welcome") : CreateCart(values);
              }}>
              {() => {
                return (
                  <Form className="w-100">
                    <Button
                      fullWidth
                      classes="text-initial medium border btn-outline mr-md-2 mb-2 mb-md-0 new-visit-link"
                      color="white"
                      type="submit">
                      No, I don't want to
                    </Button>
                  </Form>
                );
              }}
            </Formik>
            <Button
              fullWidth
              color="secondary"
              classes="medium ml-md-2"
              onClick={() => {
                setShowRefillAvailableModal(false);
                navigate("/account/order-refill");
              }}>
              Yes
            </Button>
          </div>
        </Modal.Body>
      </Modal.Dialog>
    </Modal>
  );
};

export default RefillAvailableModal;
