import React, { useContext, useEffect, useState } from "react";
import useCurrentUser from "hooks/useCurrentUser";
import { AuthContext } from "Contexts/AuthContext";
import ShippingAddressItem from "./ShippingAddressItems/ShippingAddressItem";
import NewAddressTile from "./ShippingAddressItems/NewAddressTile";
import { DeleteShippingAddressRequest } from "apis";
import { isEmpty } from "lodash";
import { ReactSVG } from "react-svg";
import shippingAddress from "assets/images/dashboard/shippingAddress.svg";
import ShippingAddressModal from "./Modals/ShippingAddressModal";

const ShippingAddress = () => {
  const user = useCurrentUser();

  const [selectableAddresses, setSelectableAddresses] = useState<any>([]);
  const [activeAddress, setActiveAddress] = useState<{}>({});
  const [editAddress, setEditAddress] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { reloadUser } = useContext(AuthContext);

  const handleEdit = (address: {}) => {
    setActiveAddress(address);
    setShowModal(true);
    setEditAddress(true);
  };
  const handleNew = () => {
    setActiveAddress({});
    setEditAddress(false);
    setShowModal(true);
  };

  const handleDelete = (address: {}) => {
    DeleteShippingAddressRequest(address)
      .then((res) => {
        reloadUser();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setSelectableAddresses(user?.shipping_addresses);
  }, [user]);

  return (
    <>
      {!isEmpty(selectableAddresses) ? (
        <div className="account-card padded shipping-address-cards">
          {selectableAddresses?.map((address: any, index: number) => (
            <ShippingAddressItem
              key={address.id}
              address={address}
              index={index}
              onEdit={() => handleEdit(address)}
              onDelete={() => handleDelete(address)}
            />
          ))}
          <NewAddressTile
            activeAddress={activeAddress}
            setActiveAddress={setActiveAddress}
            editAddress={editAddress}
            setEditAddress={setEditAddress}
            showModal={showModal}
            setShowModal={setShowModal}
          />
        </div>
      ) : (
        <div className="empty-screen">
          <div className="empty-illustration-ellipse">
            <ReactSVG className="dashboard-svg" src={shippingAddress} />
          </div>
          <span className="title-box">Shipping Address</span>
          <span className="detail-box">
            Where should we send your orders? Review and edit your shipping address here.
          </span>
          <div className="button-here">
            <button
              className="btn-aum secondary text-capitalize get-started-btn"
              onClick={() => handleNew()}
              type="button"
            >
              {" "}
              Add Shipping Address
            </button>
            {showModal && (
              <ShippingAddressModal
                address={activeAddress}
                show={showModal}
                setShow={setShowModal}
                editAddress={editAddress}
                setAddress={setActiveAddress}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ShippingAddress;
