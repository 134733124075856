import React from "react";
import Modal from "react-bootstrap/Modal";
import crossIcon from "assets/images/icons/entypo-cross.svg";

type PrivacyPracticesModalProps = {
  show: boolean;
  setShow: any;
};

const PrivacyPracticesModal = ({
  show,
  setShow,
}: PrivacyPracticesModalProps) => {
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      className="drawer"
      dialogClassName="visit-consent-modals">
      <Modal.Header>
        <h3>Privacy Practice</h3>
        <button onClick={() => setShow(false)} className="close-button">
          <img src={crossIcon} alt="Close" />
        </button>
      </Modal.Header>
      <Modal.Body>
        <p className="privacy-practice-subheading">
          NOTICE OF PRIVACY PRACTICES – ENJOY HEALTH MEDICAL AFFILIATED COVERED
          ENTITY
        </p>
        <p className="privacy-practice-subheading">
          THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED
          AND DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE
          REVIEW IT CAREFULLY.
        </p>
        <p>
          This Notice of Privacy Practices (the “Notice”) describes how Enjoy
          Health Medical, P.A. and all members of its Affiliated Covered Entity
          (collectively, “Enjoy Health Medical,” “we” or “our”) may use and
          disclose your protected health information to carry out treatment,
          payment or business operations and for other purposes that are
          permitted or required by law. An Affiliated Covered Entity is a group
          of health care providers under common ownership or control that
          designates itself as a single entity for purposes of compliance with
          the Health Insurance Portability and Accountability Act (“HIPAA”). We
          have elected to voluntarily substantially comply with the standards
          set forth in HIPAA. The members of the Enjoy Health Medical Affiliated
          Covered Entity will share protected health information with each other
          for the treatment, payment, and health care operations of the Enjoy
          Health Medical Affiliated Covered Entity and as permitted by HIPAA and
          this Notice of Privacy Practices. For a complete list of the members
          of the Enjoy Health Medical Affiliated Covered Entity, please contact
          the Enjoy Health Medical Privacy Office.
        </p>
        <p>
          “Protected health information” or “PHI” is information about you,
          including demographic information, that may identify you and that
          relates to your past, present or future physical health or condition,
          treatment or payment for health care services. This Notice also
          describes your rights to access and control your protected health
          information.
        </p>
        <p>
          <strong className="subheading">
            USES AND DISCLOSURES OF PROTECTED HEALTH INFORMATION:
          </strong>
        </p>
        <p>
          Your protected health information may be used and disclosed by our
          health care providers, our staff, and others outside of our office
          that are involved in your care and treatment for the purpose of
          providing health care services to you, to support our business
          operations, to obtain payment for your care, and any other use
          authorized or required by law.
        </p>
        <p>
          <strong className="subheading">TREATMENT:</strong>
        </p>
        <p>
          We will use and disclose your protected health information to provide,
          coordinate, or manage your health care and any related services. This
          includes the coordination or management of your health care with a
          third party. For example, your protected health information may be
          provided to a health care provider to whom you have been referred to
          ensure the necessary information is accessible to diagnose or treat
          you.
        </p>
        <p>
          <strong className="subheading">PAYMENT:</strong>
        </p>
        <p>
          Your protected health information may be used to bill or obtain
          payment for your health care services. This may include certain
          activities that your health insurance plan may undertake before it
          approves or pays for your services, such as: making a determination of
          eligibility or coverage for insurance benefits and reviewing services
          provided to you for medical necessity.
        </p>
        <p>
          <strong className="subheading">HEALTH CARE OPERATIONS:</strong>
        </p>
        <p>
          We may use or disclose, as needed, your protected health information
          in order to support the business activities of this office. These
          activities include, but are not limited to, improving quality of care,
          providing information about treatment alternatives or other
          health-related benefits and services, development or maintaining and
          supporting computer systems, legal services, and conducting audits and
          compliance programs, including fraud, waste and abuse investigations.
        </p>
        <p>
          <strong className="subheading">
            USES AND DISCLOSURES THAT DO NOT REQUIRE YOUR AUTHORIZATION:
          </strong>
        </p>
        <p>
          We may use or disclose your protected health information in the
          following situations without your authorization. These situations
          include the following uses and disclosures: as required by law; for
          public health purposes; for health care oversight purposes; for abuse
          or neglect reporting; pursuant to Food and Drug Administration
          requirements; in connection with legal proceedings; for law
          enforcement purposes; to coroners, funeral directors and organ
          donation agencies; for certain research purposes; for certain criminal
          activities; for certain military activity and national security
          purposes; for workers’ compensation reporting; relating to certain
          inmate reporting; and other required uses and disclosures. Under the
          law, we must make certain disclosures to you upon your request, and
          when required by the Secretary of the Department of Health and Human
          Services to investigate or determine our compliance with the
          requirements of the Health Insurance Portability and Accountability
          Act (HIPAA). State laws may further restrict these disclosures.
        </p>
        <p>
          <strong className="subheading">
            USES AND DISCLOSURES THAT REQUIRE YOUR AUTHORIZATION:
          </strong>
        </p>
        <p>
          Other permitted and required uses and disclosures will be made only
          with your consent, authorization or opportunity to object unless
          permitted or required by law. Without your authorization, we are
          expressly prohibited from using or disclosing your protected health
          information for marketing purposes. We may not sell your protected
          health information without your authorization. Your protected health
          information will not be used for fundraising. If you provide us with
          an authorization for certain uses and disclosures of your information,
          you may revoke such authorization, at any time, in writing, except to
          the extent that we have taken an action in reliance on the use or
          disclosure indicated in the authorization..
        </p>
        <p>
          <strong className="subheading">
            YOUR RIGHTS WITH RESPECT TO YOUR PROTECTED HEALTH INFORMATION:
          </strong>
        </p>
        <p>
          You have the right to inspect and copy your protected health
          information.
        </p>
        <p>
          You may request access to or an amendment of your protected health
          information.
        </p>
        <p>
          You have the right to request a restriction on the use or disclosure
          of your protected health/personal information. Your request must be in
          writing and state the specific restriction requested and to whom you
          want the restriction to apply. We are not required to agree to a
          restriction that you may request, except if the requested restriction
          is on a disclosure to a health plan for a payment or health care
          operations purpose regarding a service that has been paid in full
          out-of-pocket.
        </p>
        <p>
          You have the right to request to receive confidential communications
          from us by alternative means or at an alternate location. We will
          comply with all reasonable requests submitted in writing which specify
          how or where you wish to receive these communications.
        </p>
        <p>
          You have the right to request an amendment of your projected health
          information. If we deny your request for amendment, you have the right
          to file a statement of disagreement with us. We may prepare a rebuttal
          to our statement and we will provide you with a copy of any such
          rebuttal.
        </p>
        <p>
          You have the right to receive an accounting of certain disclosures of
          your protected health information that we have made, paper or
          electronic, except for certain disclosures which were pursuant to an
          authorization, for purposes of treatment, payment, healthcare
          operations (unless the information is maintained in an electronic
          health record); or for certain other purposes.
        </p>
        <p>
          You have the right to obtain a paper copy of this Notice, upon
          request, even if you have previously requested its receipt
          electronically by e-mail.
        </p>
        <p>
          <strong className="subheading">REVISIONS TO THIS NOTICE:</strong>
        </p>
        <p>
          We reserve the right to revise this Notice and to make the revised
          Notice effective for protected health information we already have
          about you as well as any information we receive in the future. You are
          entitled to a copy of the Notice currently in effect. Any significant
          changes to this Notice will be posted on our web site. You then have
          the right to object or withdraw as provided in this Notice.
        </p>
        <p>
          <strong className="subheading">BREACH OF HEALTH INFORMATION:</strong>
        </p>
        <p>
          We will notify you if a reportable breach of your unsecured protected
          health information is discovered. Notification will be made to you no
          later than 60 days from the breach discovery and will include a brief
          description of how the breach occurred, the protected health
          information involved and contact information for you to ask questions.
        </p>
        <p>
          <strong className="subheading">COMPLAINTS:</strong>
        </p>
        <p>
          Complaints about this Notice or how we handle your protected health
          information should be directed to our HIPAA Privacy Officer. If you
          are not satisfied with the manner in which a complaint is handled you
          may submit a formal complaint to the Department of Health and Human
          Services, Office for Civil Rights by sending a letter to 200
          Independence Avenue, S.W., Washington, D.C. 20201, calling
          1-877-696-6775, or visiting{" "}
          <a href="https://www.hhs.gov/ocr/privacy/hipaa/complaints/">
            www.hhs.gov/ocr/privacy/hipaa/complaints/
          </a>
          . We will not retaliate against you for filing a complaint.
        </p>
        <p>
          We must follow the duties and privacy practices described in this
          Notice. We will maintain the privacy of your protected health
          information and to notify affected individuals following a breach of
          unsecured protected health information. If you have any questions
          about this Notice, please contact us at 714-464-7557 and ask to speak
          with our HIPAA Privacy Officer.
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default PrivacyPracticesModal;
