import React from "react";
import { ReactSVG } from "react-svg";
import { Modal } from "react-bootstrap";
import Button from "components/Button";

import CameraInstructionsPath from "assets/images/home/camera-instructions.svg";

type CameraInstructionsProps = {
  isOpen: any;
  onClose: any;
};

const CameraInstructions = ({ isOpen, onClose }: CameraInstructionsProps) => (
  <Modal
    id="cameraInstructionsModal"
    show={isOpen}
    onHide={onClose}
    aria-labelledby="info-dialog__title"
    className={`camera-instruction-modal-dialog modal-md`}
    centered>
    <Modal.Body>
      <button
        type="button"
        className="close"
        aria-label="Close"
        onClick={onClose}>
        <span aria-hidden="true">&times;</span>
      </button>

      <div id="cameraInstructionsModal">
        <div className="body-text">
          <h5>Unable to access webcam</h5>
          <span>
            To grant access to your webcam, click the video icon in your
            navigation bar and select allow
          </span>
          <div className="d-flex justify-content-center">
            <ReactSVG src={CameraInstructionsPath} className="my-3 mx-auto" />
          </div>
          <span>Once you have granted access try again.</span>{" "}
          <span>
            You may choose to upload a photo from your library by clicking
            upload photo.
          </span>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button
        onClick={() => {
          onClose();
        }}>
        Upload Photo
      </Button>
      <Button
        classes="btn-aum"
        size="medium"
        color="secondary"
        onClick={() => window.location.reload()}>
        Try again
      </Button>
    </Modal.Footer>
  </Modal>
);

export default CameraInstructions;
