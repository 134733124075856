import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "components/Button";
import { AuthContext } from "Contexts/AuthContext";
import useCurrentUser from "hooks/useCurrentUser";

import modalCloseIcon from "assets/images/controls/modal-close.svg";
import "../styles.scss";

const ResumeRefillInProgressModal = () => {
  const navigate = useNavigate();
  const user = useCurrentUser();

  const { showRefillInProgress, setShowRefillInProgress } =
    useContext(AuthContext);

  return (
    <Modal
      centered
      className="new-modal"
      id="refill-in-progress-modal"
      show={showRefillInProgress}
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => setShowRefillInProgress(false)}
      animation={false}>
      <Modal.Dialog>
        <Modal.Header>
          <button
            className="ml-auto"
            onClick={() => setShowRefillInProgress(false)}>
            <img src={modalCloseIcon} alt="" />
          </button>
        </Modal.Header>

        <Modal.Body>
          <div className="refill-text mb-2 mt-2">
            <h5 className="modal-title">
              You have an Erectile Dysfunction (ED) diagnosis and treatment
              refill visit in progress
            </h5>
            <p className="modal-text">
              You can resume your refill visit from where you left off or start
              a new one.
            </p>
          </div>
          <div className="modal-actions d-flex flex-md-row flex-column mt-1">
            <Button
              fullWidth
              color="secondary"
              classes="medium ml-md-2"
              onClick={() => {
                navigate("/order-shipping-confirmation");
                setShowRefillInProgress(false);
              }}>
              Continue Refill
            </Button>
          </div>
          <Link
            onClick={() => setShowRefillInProgress(false)}
            to="/account/current-order"
            className="d-flex mx-auto text-secondary text-center small mt-4">
            Neither, go to your Account
          </Link>
        </Modal.Body>
      </Modal.Dialog>
    </Modal>
  );
};

export default ResumeRefillInProgressModal;
