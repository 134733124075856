import React, { useContext } from "react";
import { ReactSVG } from "react-svg";
import { Formik, Form } from "formik";
import { CartContext } from "Contexts/CartContext";
import { AuthContext } from "Contexts/AuthContext";
import useCurrentUser from "hooks/useCurrentUser";
import Translator from "components/Translator";
import Button from "components/Button";
import { handleShowModals } from "../helpers";
import arrowLeft from "assets/images/home/arrow-left.svg";
import discountTag from "assets/images/home/discount_tag.svg";
import { useNavigate } from "react-router-dom";
import useLatestCart from "hooks/useLatestCart";

type NewVisitButtonProps = {
  defaultProductVariant:
    | {
        id?: any;
      }
    | undefined;
  showDiscountLine?: boolean;
  position?: "start" | "center" | "end";
  slug?: boolean;
  color?: "primary" | "white";
  isDashboardBtn?: boolean;
};

const NewVisitButton = ({
  defaultProductVariant,
  showDiscountLine = false,
  position = "center",
  color = "primary",
  slug = false,
  isDashboardBtn = false,
}: NewVisitButtonProps) => {
  const user = useCurrentUser();
  const navigate = useNavigate();
  const { CreateCart } = useContext(CartContext);
  const {
    setShowResumeVisitModal,
    setShowUnderReviewVisitModal,
    retakePending,
    setShowRetakeModal,
    setShowRefillAvailableModal,
    setShowRefillInProgress,
    setShowDuplicateCartModal,
  } = useContext(AuthContext);

  const latestCart = useLatestCart();

  return (
    <Formik
      initialValues={{
        variant: defaultProductVariant?.id ?? null,
      }}
      onSubmit={(values) => {
        const allowCartCreation = handleShowModals({
          showRetake: retakePending,
          showUnderReview: user?.has_under_review_visit,
          showResumeVisit: user?.has_cart_in_progress,
          showRefillAvailableModal: user?.has_refill,
          showRefillInProgress: user?.has_in_progress_refill,
          is_order_being_processed: user?.is_order_being_processed,
          setShowRefillAvailableModal,
          setShowRetakeModal,
          setShowUnderReviewVisitModal,
          setShowResumeVisitModal,
          setShowRefillInProgress,
          setShowDuplicateCartModal,
        });

        if (allowCartCreation) {
          if (slug) {
            navigate("/welcome");
          } else {
            const newValues = {
              ...values,
              variant: defaultProductVariant?.id,
            };
            CreateCart(newValues);
          }
        }
      }}>
      {() => (
        <Form className="refillChecks w-100">
          <div
            className={`d-flex justify-content-${position} ${
              !isDashboardBtn && "mt-5"
            }`}>
            <div className="button-wrapper d-flex flex-column align-items-md-end">
              <Button
                type={"submit"}
                id={"getStartedBtn"}
                size={`${isDashboardBtn ? "medium" : "large"}`}
                color={`${isDashboardBtn ? "secondary" : color}`}
                dataAttribute={defaultProductVariant?.id}
                disabled={false}
                fullWidth={false}
                classes={"get-started-btn text-capitalize"}>
                <Translator
                  path={`${
                    isDashboardBtn
                      ? "account.profile.getStartedButton"
                      : "homepage.hero.startVisit"
                  }`}
                />
                {!isDashboardBtn && (
                  <ReactSVG src={arrowLeft} wrapper="span" className="ml-2" />
                )}
              </Button>
              {showDiscountLine && (
                <div className="discount-icon mt-2 text-center">
                  <ReactSVG src={discountTag} wrapper="span" />
                  <small className="font-semi-bold ml-1">
                    <Translator path="homepage.labTested.discountText" />
                  </small>
                </div>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default NewVisitButton;
