import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import "./styles.scss";

const Unmatched = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const invalidToken = location.state?.invalid_token;

  return (
    <div className="mt-5">
      <section className="page-404">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-6">
              {!invalidToken && (
                <div className="four-zero-four-bg">
                  <h1 className="text-center">404</h1>
                </div>
              )}

              <div
                className={`content-box text-center ${
                  invalidToken ? "mt-5" : ""
                }`}
              >
                {invalidToken ? (
                  <>
                    <span className="mt-10 text-danger">
                      Your email confirmation token is invalid/expired.
                    </span>
                  </>
                ) : (
                  <>
                    <h3 className="h2">Looks like you're lost</h3>

                    <p>The page you are looking for not available!</p>
                  </>
                )}

                <Button
                  classes="mt-5"
                  color="secondary"
                  fullWidth
                  onClick={() => navigate("/sign-up")}
                >
                  Go to Home
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Unmatched;
