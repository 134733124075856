import React, { useEffect, useState } from "react";
import { Popover } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import TooltipIcon from "assets/images/icons/tooltip_icon.svg";
import TextOption from "./TextOption";
import { map, filter } from "lodash";

type CheckboxOptionProps = {
  selectedAnswers: any;
  setSelectedAnswers: any;
  option: any;
  questionType: any;
  optionIsSelected: any;
  activeQuestion: any;
  setUserAction: any;
  questionsLength: any;
  setShowContinueButton: any;
  userResponses: any;
  setAllowText?: any;
  extraText?: any;
  setExtraText?: any;
  textAreaBorder?: any;
  setContinueButtonDisable?: any;
  question?: any;
};

const CheckboxOption = ({
  selectedAnswers,
  setSelectedAnswers,
  option,
  questionType,
  optionIsSelected,
  activeQuestion,
  setUserAction,
  questionsLength,
  setShowContinueButton,
  userResponses,
  setAllowText,
  extraText,
  setExtraText,
  textAreaBorder,
  setContinueButtonDisable,
  question,
}: CheckboxOptionProps) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isOtherOptions, setIsOtherOption] = useState<boolean>(false);

  useEffect(() => {
    if (option?.label === "Other") {
      const otherSelectedAns = map(
        filter(selectedAnswers, (ans) => ans.question_id === question?.id),
        "label"
      )?.includes("Other");

      setIsOtherOption(otherSelectedAns);
    } else {
      setIsOtherOption(false);
    }
  }, [selectedAnswers]);

  const nonSoloOptions = (answers: any) => {
    return answers?.filter((sa: any) => sa?.solo !== true);
  };

  // For handling checkbox interactions
  const handleCheckboxChange = (e: any, selectedOption: any) => {
    const nextQuestionHasExtraOptions =
      selectedOption?.allow_med ||
      selectedOption?.allow_allergy ||
      selectedOption?.allow_text;
    const otherAnswers = selectedAnswers.filter(
      (sa: any) => sa.question_id !== activeQuestion?.id
    );
    let newAnswers = selectedAnswers.filter(
      (sa: any) => sa.id !== selectedOption.id
    );

    if (!isProcessing) {
      setIsProcessing(true);
      setUserAction("optionSelect");

      if (e.target.checked) {
        if (selectedOption?.solo) {
          newAnswers = [...otherAnswers, selectedOption];
        } else {
          newAnswers = [...nonSoloOptions(newAnswers), selectedOption];
        }
      }

      if (
        (questionsLength > 1 && nextQuestionHasExtraOptions) ||
        selectedOption?.solo
      ) {
        setShowContinueButton(
          nextQuestionHasExtraOptions ||
            option.super_parent_question_type === null
        );
      }

      setSelectedAnswers(newAnswers);
    }

    setIsProcessing(false);
  };

  const shouldShowExtraText = activeQuestion?.label === "13.01";

  return (
    <>
      <div className="answer-option-wrapper">
        <input
          type="checkbox"
          name={`option_${questionType}_${option.id}`}
          id={`option_${option.id}`}
          onChange={(e: any) => handleCheckboxChange(e, option)}
          checked={optionIsSelected()}
        />
        <label
          className={`answer-option d-flex ${
            shouldShowExtraText ? "option-extra-label" : ""
          } ${questionType} ${optionIsSelected() && "active"}`}
          htmlFor={`option_${option.id}`}>
          <div>
            {option.label}
            {option.tooltip && (
              <button
                onClick={() => setShowTooltip(true)}
                onMouseOver={() => setShowTooltip(true)}
                onMouseOut={() => setShowTooltip(false)}
                className="answer-option-tooltip">
                <ReactSVG className="tooltip-icon" src={TooltipIcon} />
                {showTooltip && (
                  <Popover
                    body
                    placement={"left-start"}
                    className="modal-tooltip">
                    <div dangerouslySetInnerHTML={{ __html: option.tooltip }} />
                  </Popover>
                )}
              </button>
            )}
          </div>
          {shouldShowExtraText && <span>{option.extra_label}</span>}
        </label>
      </div>
      {isOtherOptions && (
        <TextOption
          selectedAnswers={selectedAnswers}
          setSelectedAnswers={setSelectedAnswers}
          option={option}
          userResponses={userResponses}
          extraText={extraText}
          setExtraText={setExtraText}
          questionType={questionType}
          extraLabel={option?.extra_label}
          textAreaBorder={textAreaBorder}
          setContinueButtonDisable={setContinueButtonDisable}
          questionId={question ? question.id : activeQuestion.id}
          isOtherOptions={isOtherOptions}
        />
      )}
    </>
  );
};

export default CheckboxOption;
