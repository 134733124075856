import React, { useContext } from "react";
import NoCheckupIllust from "assets/images/checkout/no_checkup_illust.svg";
import { CartsPreviousStepRequest, NoCheckupNextStepRequest } from "apis";
import useLatestCart from "hooks/useLatestCart";
import Button from "components/Button";
import { useNavigate } from "react-router-dom";
import { CartContext } from "Contexts/CartContext";

const NoCheckup = () => {
  const cart = useLatestCart();
  const navigate = useNavigate();
  const { PreviousCartStep } = useContext(CartContext);

  // TODO will keep this commented just in case if we have to revert these changes in future
  const finishVisit = () => {
    NoCheckupNextStepRequest(cart?.id)
      .then((res) => {
        navigate(res.data.redirect_path);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleContinueLaterOn = () => {
    CartsPreviousStepRequest(cart?.id).then(() => {
      navigate("/account/current-order");
    });
  };

  return (
    <div className="main">
      <div className="container checkout-page product-page">
        <div className="content false">
          <div className="row justify-content-center pt-0 pt-md-3">
            <div className="col col-lg-6">
              <section>
                <div className="questionnaire-exit-container">
                  <h4 className="mt-0 mb-4">See a healthcare provider</h4>
                  <div className="card w-100 mw-100 mb-3">
                    <div className="card-body">
                      <img src={NoCheckupIllust} alt="no-checkup" />
                      <p>
                        It is important that you see a healthcare provider in
                        person for a physical exam. After completing your
                        in-person visit return to the site and one of our
                        clinical providers may be able to assist you.
                      </p>
                    </div>
                  </div>
                  {cart && (
                    <>
                      <Button
                        classes="btn-secondary w-100 mt-4"
                        fullWidth
                        color="secondary"
                        onClick={handleContinueLaterOn}
                      >
                        continue later on
                      </Button>
                      <Button
                        classes="btn-secondary w-100 mt-4 back-to-health-question"
                        fullWidth
                        color="white"
                        onClick={() => PreviousCartStep()}
                      >
                        back to health questions
                      </Button>
                    </>
                  )}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoCheckup;
