import React from "react";
import "./styles.scss";

const Loader = () => (
  <div className="d-flex justify-content-center">
    <div className="animated-loader"></div>
  </div>
);

export default Loader;
