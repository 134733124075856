import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import Button from "../../../components/Button";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "Contexts/AuthContext";

import "./styles.scss";

type RetakeModalProps = {
  setShow: any;
  show: boolean;
};

const RetakeModal = ({ setShow, show }: RetakeModalProps) => {
  const navigate = useNavigate();
  const { setShowChatModal, ReadChatMessages, setShowChatReminderModal } = useContext(AuthContext);

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="retake-photos-modal"
      animation={false}
      onHide={() => setShow(false)}
    >
      <Modal.Header>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => setShow(false)}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <h5 className="modal-title px-2">
          There is an issue with the quality of your Selfie and/or ID Card
          image.
        </h5>
        <p className="modal-text mt-4 mb-0">
          If you have any questions please contact us by phone at (714) 276-2040
          or start a chat by clicking{" "}
          <Link id="showChatPanel"
            to="#"
            onClick={() => {
              setShowChatReminderModal(false);
              setShow(false)
              setShowChatModal(true);
              ReadChatMessages();
            }}
          >
            here
          </Link>
        </p>
        <div className="modal-actions d-flex flex-md-row flex-column mt-4 mb-4">
          <Button
            classes="btn-outline mr-md-2 mb-2 mb-md-0"
            fullWidth={true}
            onClick={() => setShow(false)}
          >
            Cancel
          </Button>
          <Button
            classes="ml-md-2"
            fullWidth={true}
            color="secondary"
            onClick={() => navigate("/retake-photos")}
          >
            RETAKE NEW PHOTO(S)
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RetakeModal;
