import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import Accordion from "react-bootstrap/Accordion";
import Translator from "components/Translator";
import aboutEdPercentage from "assets/images/home/about_ed_percentage.svg";
import chevronDown from "assets/images/home/chevron-down.svg";
import closeIcon from "assets/images/home/close.svg";

const AboutEd = () => {
  const [activeIndex, setActiveIndex] = useState("");

  const handleHeaderIcon = (key: string) => {
    return activeIndex === key ? closeIcon : chevronDown;
  };

  return (
    <>
      <div className="ab-wrapper ab-1">
        <div className="homepage-container">
          <div className="about-ed">
            <div className="content">
              <div className="about-ed-text">
                <h2 className="h2">
                  <Translator path="homepage.aboutEd.headings.aboutEd" />
                </h2>
                <div className="row justify-content-between no-gutters">
                  <div className="col-12 col-md-5">
                    <div className="pic-block">
                      <img
                        src={aboutEdPercentage}
                        className="stats-img"
                        alt=""
                      />
                      <p className="text">
                        <Translator path="homepage.aboutEd.percentOfMen" />
                        <a
                          href="https://pubmed.ncbi.nlm.nih.gov/8254833/"
                          target="_blank"
                          rel="noreferrer">
                          <Translator path="homepage.aboutEd.source" />
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="text-wrapper">
                      <p className="mb-0">
                        <Translator path="homepage.aboutEd.erectileDysfunction" />
                      </p>
                    </div>
                    <h5 className="mb-0 text-brand-primary text-uppercase my-4">
                      <strong>
                        <Translator path="homepage.aboutEd.questions.commonQuestions" />
                      </strong>
                    </h5>
                    <Accordion
                      className="accordion-wrapper"
                      onSelect={(activeIndex: any) =>
                        setActiveIndex(activeIndex)
                      }>
                      <Accordion.Item
                        className={`${activeIndex === "0" && "is-open"}`}
                        eventKey="0">
                        <Accordion.Header as={"h3"}>
                          <Translator path="homepage.aboutEd.questions.one.edTreatment" />
                          <ReactSVG
                            src={handleHeaderIcon("0")}
                            wrapper="span"
                          />
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>
                            <Translator path="homepage.aboutEd.questions.one.edTreatmentAnswer" />
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item
                        className={`${activeIndex === "1" && "is-open"}`}
                        eventKey="1">
                        <Accordion.Header as={"h3"}>
                          <Translator path="homepage.aboutEd.questions.two.causesEd" />
                          <ReactSVG
                            src={handleHeaderIcon("1")}
                            wrapper="span"
                          />
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>
                            <Translator path="homepage.aboutEd.questions.two.causesEdAnswer" />
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item
                        className={`${activeIndex === "2" && "is-open"}`}
                        eventKey="2">
                        <Accordion.Header as={"h3"}>
                          <Translator path="homepage.aboutEd.questions.three.seekHealthCare" />
                          <ReactSVG
                            src={handleHeaderIcon("2")}
                            wrapper="span"
                          />
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>
                            <Translator path="homepage.aboutEd.questions.three.seekHealthCareAnswer" />
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutEd;
