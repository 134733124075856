import React from "react";
import { ReactSVG } from "react-svg";
import Translator from "../../components/Translator";
import realResults from "../../assets/images/home/real_results.svg";
import realResultsPng from "../../assets/images/home/real_results_png.png";

const Realresults = () => {
  return (
    <>
      <div className="ab-wrapper ab-1">
        <div className="homepage-container">
          <div className="real-results">
            <div className="content flex-column">
              <h2 className="h2 mx-md-3 mx-lg-0">
                <Translator path="homepage.realResults.realResults" />
              </h2>
              <div className="row justify-content-between">
                <div className="col mx-md-3 mx-lg-0 d-flex flex-column">
                  <p className="mb-5 mt-3 mt-md-0">
                    <Translator path="homepage.realResults.fdaApproved" />
                  </p>
                  <div className="pic-block mt-auto">
                    <img
                      className="stats-img"
                      src={realResults}
                      alt="real results"
                    />
                    <p className="text">
                      <Translator path="homepage.realResults.percentOfMEn" />
                      <a
                        href="https://labeling.pfizer.com/ShowLabeling.aspx?format=PDF&id=652"
                        target="_blank"
                        rel="noreferrer">
                        <Translator path="homepage.realResults.source" />
                      </a>
                    </p>
                  </div>
                  <div className="couple-image-first-col">
                    <img
                      className="couple-image-first"
                      src={realResultsPng}
                      alt="couple img"
                    />
                  </div>
                </div>
                <div className="col d-flex flex-column overflow-hidden couple-image-second">
                  <img
                    className="couple-image mt-auto"
                    src={realResultsPng}
                    alt="couple img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Realresults;
