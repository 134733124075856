import { isEmpty } from "lodash";
import React, { useState } from "react";
import { AddressType } from "types";

type AddressSuggestionProps = {
  selectedAddress: any;
  setAddress: any;
  suggestedAddress: any;
  setDeliveryInfoModal: any;
  handleSubmitAddress: (values: AddressType) => void;
  handleResetFormikValues: (values: AddressType) => void;
};

const AddressSuggestion = ({
  selectedAddress,
  setAddress,
  suggestedAddress,
  setDeliveryInfoModal,
  handleSubmitAddress,
  handleResetFormikValues,
}: AddressSuggestionProps) => {
  const [selectedValue, setSelectedValue] = useState<string>("suggested");
  const [enteredAddress, setEnteredAddress] = useState<any>(selectedAddress);

  const handleContinue = () => {
    switch (selectedValue) {
      case "added":
        handleSubmitAddress(enteredAddress);
        break;
      case "suggested":
        let newAddress = enteredAddress;

        newAddress.street_1 = suggestedAddress.street_1;
        newAddress.street_2 = suggestedAddress?.street_2 || "";
        newAddress.city = suggestedAddress.city;
        newAddress.state = suggestedAddress.state;
        newAddress.zip = suggestedAddress.zip;

        handleSubmitAddress(newAddress);
        break;
    }
  };

  const handleReEnterAddress = () => {
    if (selectedValue === "suggested")
      handleResetFormikValues({ ...selectedAddress, ...suggestedAddress });
    setDeliveryInfoModal(false);
  };
  return (
    <>
      <div>
        <h5 className="sub-header suggested-header">
          If you know the address doesn’t have an apartment or site number then
          select one of the address below and press continue
        </h5>
        <input
          type="radio"
          className="address-radio-btn"
          id="added-address"
          name="choice"
          value="added"
          checked={selectedValue === "added"}
          onChange={(e) => setSelectedValue(e.target.value)}
        />
        <label
          className={`address-box d-flex ${
            selectedValue === "added" ? "active" : ""
          }`}
          htmlFor="added-address"
        >
          <div className="d-flex flex-column">
            <span className="entered-address">Address entered</span>
            <span>
              {enteredAddress.street_1} {enteredAddress.street_2}
            </span>
            <span>
              {enteredAddress.city}, {enteredAddress.state} {enteredAddress.zip}
            </span>
          </div>
        </label>
        {!isEmpty(suggestedAddress.street_1) && (
          <>
            <input
              type="radio"
              className="address-radio-btn"
              id="suggested-address"
              name="choice"
              value="suggested"
              checked={selectedValue === "suggested"}
              onChange={(e) => setSelectedValue(e.target.value)}
            />
            <label
              className={`address-box d-flex ${
                selectedValue === "suggested" ? "active" : ""
              }`}
              htmlFor="suggested-address"
            >
              <div className="d-flex flex-column">
                <span className="entered-address">Suggested Address</span>
                <span>
                  {suggestedAddress.street_1} {suggestedAddress.street_2}
                </span>
                <span>
                  {suggestedAddress.city}, {suggestedAddress.state}{" "}
                  {suggestedAddress.zip}
                </span>
              </div>
            </label>
          </>
        )}
      </div>
      <div className="button-options">
        <button className="re-enter-btn" onClick={handleReEnterAddress}>
          Re-enter address with apt/suite number
        </button>
        <button className="re-enter-btn continue-btn" onClick={handleContinue}>
          Continue with selected address
        </button>
      </div>
    </>
  );
};

export default AddressSuggestion;
