import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import crossIcon from "assets/images/icons/entypo-cross.svg";
import EditCartOptions from "../EditCartOptions";

import "./styles.scss";

type EditModalProps = {
  show: boolean;
  setShow: any;
  cart: any;
  products: any;
  quantities: any;
  current_variant: any;
  variantsPerProduct: any;
  allowEdit?: any;
  updateCart?: any;
  handleProductChange: any;
  handleDosageChange: any;
  handleQuantityChange: any;
  disabledOptions: any;
  discountPercentage: any;
  showEditOptions: boolean;
  toggleEditOptions: any;
  errors?: any;
  setErrors?: any;
};

const EditModal = ({
  show,
  setShow,
  cart,
  products,
  quantities,
  current_variant,
  variantsPerProduct,
  handleProductChange,
  handleDosageChange,
  handleQuantityChange,
  disabledOptions,
  discountPercentage,
  showEditOptions,
  toggleEditOptions,
  errors,
  setErrors,
}: EditModalProps) => {
  // Remove all errors if the modal gets closed
  useEffect(() => {
    if (setErrors && !show) {
      setErrors({
        ...errors,
        quantity: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      dialogClassName="edit-modal"
      contentClassName="px-1">
      <Modal.Header>
        <h4 className="pt-2">Edit Information</h4>
        <button
          onClick={() => setShow(false)}
          className="close-button"
          style={{ width: "40px" }}>
          <img src={crossIcon} alt="Close" style={{ width: "100%" }} />
        </button>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <EditCartOptions
          setShow={setShow}
          cart={cart}
          products={products}
          quantities={quantities}
          current_variant={current_variant}
          showEditOptions={showEditOptions}
          variantsPerProduct={variantsPerProduct}
          handleProductChange={(product: any) => handleProductChange(product)}
          handleDosageChange={(dosage: any) => handleDosageChange(dosage)}
          handleQuantityChange={(quantity: any) =>
            handleQuantityChange(quantity)
          }
          toggleEditOptions={toggleEditOptions}
          disabledOptions={disabledOptions}
          discountPercentage={discountPercentage}
          errors={errors}
          setErrors={setErrors}
        />
      </Modal.Body>
    </Modal>
  );
};

export default EditModal;
