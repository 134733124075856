import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import crossIcon from "assets/images/icons/entypo-cross.svg";

type TermsOfUseModalProps = {
  show: boolean;
  setShow: any;
  showPrivacyModal?: boolean;
  setShowPrivacyModal?: any;
};

const TermsOfUseModal = ({ show, setShow, showPrivacyModal, setShowPrivacyModal }: TermsOfUseModalProps) => {

  const openPocketMedPrivacy = () => {
    setShow(false);
    setShowPrivacyModal(true);
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      dialogClassName="visit-consent-modals"
      className="drawer">
      <Modal.Header>
        <h3>Terms of Use</h3>
        <button onClick={() => setShow(false)} className="close-button">
          <img src={crossIcon} alt="Close" />
        </button>
      </Modal.Header>
      <Modal.Body>
        <p className="text-align-justify"></p>
        These terms and conditions of use (“
        <strong>Terms of Use</strong>
        ”) govern your use of our online interfaces and properties (e.g.,
        websites and mobile applications) owned and controlled by Tura Health
        LLC d/b/a PocketMed (“
        <strong>PocketMed</strong>
        ,” “<strong>we</strong>
        ,” “<strong>us</strong>
        ,” and “<strong>our</strong>
        ”), including the <a href="/">/</a> website (“
        <strong>Site</strong>
        ”). PocketMed is a telemedicine platform facilitating patient connection
        to licensed clinicians who will diagnose and treat various community
        based diseases states and ailments with prescription strength, over the
        counter, and cognitive based therapies (“
        <strong>Services</strong>
        ”). The terms “<strong>you</strong>” and “<strong>your</strong>” means
        you, your dependents if any, and any other person accessing your
        PocketMed Account.
        <p>
          <strong>
            Your acceptance of, and compliance with, these Terms of Use is a
            condition to your use of the Site and Services. By clicking
            “accept”, you acknowledge that you have read, understand, and accept
            all terms and conditions contained within these Terms of Use, and
            our
            <a target="_blank" href="/privacy-policy"> Privacy Policy </a>. If you do not agree
            to be bound by these terms, you are not authorized to access or use
            this Site or Services; promptly exit this Site.
          </strong>
        </p>
        <p>
          <strong>Binding Arbitration.</strong>
          These Terms of Use provide that all disputes between you and PocketMed
          that in any way relate to these Terms of Use or your use of the Site
          will be resolved by BINDING ARBITRATION. ACCORDINGLY, YOU AGREE TO
          GIVE UP YOUR RIGHT TO GO TO COURT (INCLUDING IN A CLASS ACTION
          PROCEEDING) to assert or defend your rights under these Terms of Use.
          Your rights will be determined by a NEUTRAL ARBITRATOR and NOT a judge
          or jury and your claims cannot be brought as a class action. Please
          review the Section below entitled
          <a href="#"> Dispute Resolution; Arbitration Agreement</a> for the
          details regarding your agreement to arbitrate any disputes with
          PocketMed.
        </p>
        <p>
          <strong className="subheading">1. Privacy Practices</strong>
        </p>
        <p>
          You agree that information provided by you in connection with the
          Services and Site shall be governed by the
          <button className="open-pocket-med-privacy-modal" onClick={ openPocketMedPrivacy}> PocketMed Privacy Policy </button>, which is
          hereby incorporated and made a part of this Agreement.
        </p>
        <p>
          <strong className="subheading">
            2. Services Provided - No Medical Care or Advice by PocketMed
          </strong>
        </p>
        <p>
          The content of the Site and the Services, including without
          limitation, text, copy, audio, video, photographs, illustrations,
          graphics, and other visuals, is for informational purposes only and
          does not constitute professional medical advice, diagnosis, treatment,
          or recommendations of any kind by PocketMed. PocketMed provides
          clinically relevant information and instructive videos for general
          information purposes only. You should always seek the advice of your
          qualified health care professionals with any questions or concerns you
          may have regarding your individual needs and any medical conditions.
          All information provided by PocketMed, or in connection with any
          communications supported by PocketMed, is intended to be for general
          information purposes only, and is in no way intended to create a
          provider-patient relationship as defined by state or federal law.
        </p>
        <p>
          Your interactions with the Site are not intended to take the place of
          your relationship with your regular health care practitioners or
          primary care physician. Neither PocketMed, nor any of its subsidiaries
          or affiliates or any third party who may promote the Site or Service
          or provide a link to the Service, shall be liable for any information
          or advice obtained from the Site or Service, nor any information
          obtained on the Site. PocketMed does not recommend or endorse any
          specific tests, physicians, medications, products, or procedures. You
          acknowledge that your reliance on any information received through the
          Site or Service is solely at your own risk and you assume full
          responsibility for all risks associated herewith.
        </p>
        <p>
          <strong>Not for Emergencies</strong>
        </p>
        <p>
          <strong>
            IF YOU ARE EXPERIENCING A MEDICAL EMERGENCY, YOU SHOULD DIAL “911”
            IMMEDIATELY.
          </strong>
        </p>
        <p>
          PocketMed’s Site and Services are not for medical emergencies or
          urgent situations. You should not disregard or delay to seek medical
          advice based on anything that appears or does not appear on the Site.
          If you believe you have an emergency, call 9-1-1 immediately.
        </p>
        <p>
          You should seek emergency help or follow up care when recommended by a
          physician or qualified healthcare provider or when otherwise needed.
          You should continue to consult with your primary provider and other
          healthcare professionals as recommended. Always seek the advice of a
          physician or other qualified healthcare provider concerning questions
          you have regarding a medical condition and before stopping, starting,
          or modifying any treatment or modification.
        </p>
        <p>
          <strong>Not an Insurance Product</strong>
        </p>
        <p>
          PocketMed is not an insurer. The Services are not insurance products,
          and the amounts you pay to PocketMed is not insurance premiums. If you
          desire any type of health or other insurance, you will need to
          purchase such insurance separately.
        </p>
        <p>
          <strong className="subheading">3. Availabilty of Services</strong>
        </p>
        <p>
          You represent that you are not a person barred from enrolling for or
          receiving the Services under the laws of the United States or other
          applicable jurisdictions in which you may be located. Access to and
          use of the Site or the Services is limited exclusively to users
          located in States within the United States where the Services are
          available. Services are not available to users located outside the
          United States. Accessing the Site or Services from jurisdictions where
          content is illegal, or where we do not offer Services, is prohibited.
        </p>
        <p>
          <strong className="subheading">
            4. Ordering and Purchasing of Services
          </strong>
        </p>
        <p>
          <strong className="subheading">
            Typographical Errors and Incorrect Pricings
          </strong>
        </p>
        <p>
          In the event a Service is listed at an incorrect price due to
          typographical error or error in pricing information received from a
          third party, we shall have the right to refuse or cancel any orders
          placed for the Services listed at the incorrect price. We shall have
          the right to refuse or cancel any such orders whether or not the order
          has been confirmed and your credit or debit card charged. If your
          credit or debit card has already been charged for the purchase and
          your order is canceled, we will promptly issue a credit to your credit
          or debit card account in the amount of the charge.
        </p>
        <p>
          <strong className="subheading">Online Payments</strong>
        </p>
        <p>
          You can purchase the Services on the Site. We accept credit and debit
          cards issued by U.S. banks ( your “payment method”). You authorize and
          agree that the payment method you submit may be used automatically by
          PocketMed or its payment processors for any of your responsibilities
          for payment. If a credit card account is being used for a transaction,
          PocketMed may obtain preapproval for an amount up to the amount of the
          payment. You agree to allow PocketMed or its payment processor to
          securely store your payment method. You understand and acknowledge
          that Services may be cancelled or withheld if you revoke this
          authorization, and that you are still responsible for all charges
          incurred by you or are otherwise owed to PocketMed.
        </p>
        <p>
          You represent and warrant that if you are making online payments that
          (a) any credit card, debit card and bank account information you
          supply is true, correct, and complete, (b) charges incurred by you
          will be honored by your credit/debit card company or bank, (c) you
          will pay the charges incurred by you in the amounts posted, including,
          without limitation, any applicable taxes, and (d) you are the person
          in whose name the card was issued and you are authorized to make a
          purchase or other transaction with the relevant credit card and credit
          card information. If PocketMed is unable to secure funds from the
          payment method you provide for any reason, including insufficient
          funds in the payment method or insufficient or inaccurate information
          provided by you when submitting electronic payment, PocketMed may
          undertake further collection action, including application of fees to
          the extent permitted by law. You acknowledge and agree that you will
          not dispute the charges from PocketMed with the payment method
          company, provided the transactions correspond to the terms indicated
          in these Terms of Use.
        </p>
        <p>
          <strong className="subheading">
            5. Eligibility; Site Access, Security and Restrictions; Passwords
          </strong>
        </p>
        <p>
          You agree to fully, accurately, and truthfully create your PocketMed
          Account (“Account”), including but not limited to your name, mailing
          address, phone number, email address, and password, which become your
          PocketMed ID and credentials. The PocketMed ID or credentials are
          personal to you, and you are solely responsible for maintaining the
          confidentiality of your PocketMed ID or credentials, and for all
          activities that occur under such PocketMed ID or credentials. You
          agree to prohibit anyone else from using your PocketMed ID or
          credentials other than your eligible dependents and agree to
          immediately notify PocketMed of any actual or suspected unauthorized
          use of your PocketMed ID or credentials or other security concerns of
          which you become aware. Your access to the Site may be revoked by
          PocketMed at any time with or without cause.
        </p>
        <p>
          You are prohibited from violating or attempting to violate the
          security of the Site, including, without limitation: (a) accessing
          data not intended for such user or logging onto a server or an account
          which the user is not authorized to access; (b) attempting to probe,
          scan, or test the vulnerability of a system or network or to breach
          security or authentication measures without proper authorization; or
          (c) accessing or using the Site or any portion thereof without
          authorization, in violation of these Terms of Use or in violation of
          applicable law.
        </p>
        <p>
          You may not use any scraper, crawler, spider, robot, or other
          automated means of any kind to access or copy data on the Site,
          deep-link to any feature or content on the Site, bypass our robot
          exclusion headers, or other measures we may use to prevent or restrict
          access to the Site. Violations of system or network security may
          result in civil or criminal liability. PocketMed may investigate
          occurrences that may involve such violations and may involve, and
          cooperate with, law enforcement authorities in prosecuting users who
          are involved in such violations. You agree not to use any device,
          software, or routine to interfere or attempt to interfere with the
          proper working of this Site or any activity being conducted on this
          Site.
        </p>
        <p>
          <strong className="subheading">6. Electronic Communications</strong>
        </p>
        <p>
          When you use the Site or Services, or send e-mails, messages, and
          other communications from your desktop or mobile device to us, you are
          communicating with us electronically. You consent to receive
          communications from us electronically. You agree that (a) all
          agreements and consents can be signed electronically and (b) all
          notices, disclosures, and other communications that we provide to you
          electronically satisfy any legal requirement that such notices and
          other communications be in writing. PocketMed may contact you by
          telephone, mail, or email to verify your Account information.
          PocketMed may request further information from you and you agree to
          provide such further information to ensure that you have not
          fraudulently created your Account. If you do not provide this
          information in the manner requested within 14 days of the request, we
          reserve the right to suspend, discontinue, or deny your access to and
          use of the Site and the Services until you provide the information to
          us as requested.
        </p>
        <p>
          <strong className="subheading">
            7. Ownership Of The Site And Related Materials; Additional
            Restrictions
          </strong>
        </p>
        <p>
          All pages within this Site and any material made available for
          download are the property of PocketMed, or its licensors or suppliers,
          as applicable. The Site is protected by United States and
          international copyright and trademark laws. Subject to these Terms of
          Use and the payment of all applicable fees, PocketMed grants you a
          revocable, nontransferable (except as provided below), personal,
          nonexclusive license to use the object code version of the Site. All
          rights not expressly granted to you in these Terms of Use are reserved
          and retained by PocketMed or its licensors, suppliers, publishers,
          rights holders, or other content providers.
        </p>
        <p>
          Neither the Site and Services, nor any part of the Site and Services,
          may be reproduced, duplicated, copied, sold, resold, visited, or
          otherwise exploited for any commercial purpose without express written
          consent of PocketMed. You may not frame or utilize framing techniques
          to enclose any trademark, logo, or other proprietary information
          (including images, text, page layout, or form) of PocketMed without
          express written consent. You may not use any meta tags or any other
          "hidden text" utilizing PocketMed’s name or trademarks without the
          express written consent of PocketMed. You may not misuse the Site or
          Services. You may use the Site and Services only as permitted by law.
          The content of the Site, including without limitation the files,
          documents, text, photographs, images, audio, and video, and any
          materials accessed through or made available for use or download
          through this Site may not be copied, distributed, modified,
          reproduced, published, or used, in whole or in part, except for
          purposes authorized or approved in writing by PocketMed. You may not
          frame or utilize framing techniques to enclose, or deep linking to,
          any name, trademarks, service marks, logo, content, or other
          proprietary information (including, without limitation, images, text,
          page layout, or form) of PocketMed without our express written
          consent.
        </p>
        <p>
          <strong className="subheading">
            8. No Users Under 18 Years Old without Parental Consent
          </strong>
        </p>
        <p>
          If you are under the age of eighteen 18 and wish to create an account
          with PocketMed, your parent or legal guardian must create the account,
          submit your personal information, and agree to this privacy policy on
          your behalf. If you are under the age of 18, you may only use our
          services and access our Website and Application with the supervision
          and consent of your parents or legal guardians. If we learn that we
          have collected personal information from someone under the age of 18
          that was not provided with the supervision and consent of the minor’s
          parents or legal guardian, we will promptly delete that information.
          If you believe we have impermissibly collected personal information
          from someone under the age of 18, please contact us using the
          information below.
        </p>
        <p>
          <strong className="subheading">
            9. Accuracy of Information; Functionality
          </strong>
        </p>
        <p>
          Although PocketMed attempts to ensure the integrity and accurateness
          of the Site and Service descriptions, it makes no representations,
          warranties, or guarantees whatsoever as to the correctness or accuracy
          of the Site, Service descriptions and other content on the Site. It is
          possible that the Site could include typographical errors,
          inaccuracies or other errors, and that unauthorized additions,
          deletions, and alterations could be made to the Site by third parties.
          In the event that an inaccuracy arises, please inform PocketMed so
          that it can be corrected. Information contained on the Site may be
          changed or updated without notice. Additionally, PocketMed shall have
          no responsibility or liability for information or content posted to
          the Site from any non-PocketMed affiliated third party.
        </p>
        <p>
          PocketMed each reserves complete and sole discretion with respect to
          the operation of the Site and the Services. We may withdraw, suspend,
          or discontinue any functionality or feature of the Site or the
          Services among other things. We are not responsible for transmission
          errors, corruption, or compromise of information carried over local or
          interchange telecommunications carrier. We are not responsible for
          maintaining information arising from use of the Site or with respect
          to the Services. We reserve the right to maintain, delete, or destroy
          all communications or information posted or uploaded to the Site or
          the Services in accordance with our internal record retention or
          destruction policies.
        </p>
        <p>
          <strong className="subheading">10. Links to Other Sites</strong>
        </p>
        <p>
          PocketMed makes no representations whatsoever about any other website
          that you may access through this Site. When you access a non-PocketMed
          site, please understand that it is independent from PocketMed, and
          that PocketMed has no control over the content on that website. In
          addition, a link to a non-PocketMed website does not mean that
          PocketMed endorses or accepts any responsibility for the content, or
          the use, of the linked site. It is up to you to take precautions to
          ensure that whatever you select for your use or download is free of
          such items as viruses, worms, Trojan horses, and other items of a
          destructive nature. If you decide to access any of the third party
          sites linked to this Site, you do this entirely at your own risk.
        </p>
        <p>
          <strong className="subheading">11. User Information</strong>
        </p>
        <p>
          If you submit, upload, post, or transmit any health information,
          medical history, conditions, problems, symptoms, personal information,
          consent forms, agreements, requests, comments, ideas, suggestions,
          information, files, videos, images, or other materials to us or our
          Site (“User Information”), you agree not to provide any User
          Information that (a) is false, inaccurate, defamatory, abusive,
          libelous, unlawful, obscene, threatening, harassing, fraudulent,
          pornographic, or harmful, or that could encourage criminal or
          unethical behavior, (b) violates or infringes the privacy, copyright,
          trademark, trade dress, trade secrets, or intellectual property rights
          of any person or entity, or (c) contains or transmits a virus or any
          other harmful component. You agree not to contact other Site users
          through unsolicited e-mail, telephone calls, mailings, or any other
          method of communication. You represent and warrant to PocketMed that
          you have the legal right and authorization to provide all User
          Information to PocketMed for use as set forth herein and required by
          PocketMed.
        </p>
        <p>
          PocketMed may de-identify your information such that it is no longer
          considered personally identifiable information. PocketMed may
          disclose, aggregate, sell, or otherwise use such de-identified
          information to third parties for analytics, research, or other
          purposes.
        </p>
        <p>
          You agree not to: (i) access the Site or use the Services in any
          unlawful way or for any unlawful purpose; (ii) post or transmit (1) a
          message under a false name, or (2) any data, materials, content, or
          information (including, without limitation, advice, and
          recommendations) (collectively “Information”) which is (A) libelous,
          defamatory, obscene, fraudulent, false, or contrary to the ownership
          or intellectual property rights of any other person, or (B) contains
          or promotes any virus, worm, Trojan horse, time bomb, malware, or
          other computer programing or code that is designed or intended to
          damage, destroy, intercept, download, interfere, manipulate, or
          otherwise interrupt or expropriate the Site or the Services, personal
          information, software, equipment, servers, or Information or
          facilitate or promote hacking or similar conduct; (iii) impersonate or
          misrepresent your identity or falsely state or misrepresent your
          affiliation with a person or entity; (iv) tamper, hack, spoof, copy,
          modify, or otherwise corrupt the administration, security, or proper
          function of the Site or the Services; (v) use robots or scripts with
          the Site; (vi) attempt to reverse engine, reverse assemble, reverse
          compile, decompile, disassemble, translate, or otherwise alter,
          defraud, or create false results from any executable code, information
          on, or received by this Site; (vii) to have any antivirus or
          antispyware software running that is set to override the internet
          browser’s cookies setting; (viii) incorrectly identify the sender of
          any message transmitted to PocketMed; (ix) alter the attribution or
          origin of electronic mail, messages, or posting; (x) harvest or
          collect personal information about any other individual who uses the
          Site or the Services; and (xi) infringe or facilitate infringement on
          any copyright, patent, trademark, trade secret, or other proprietary,
          publicity, or privacy rights of any party, including but not limited
          to, such rights of third parties.
        </p>
        <p>
          You agree to defend, indemnify, and hold harmless PocketMed from and
          against all third party claims, damages, and expenses (including, but
          not limited to, reasonable attorneys’ fees) against or incurred by us
          arising out of any User Information you upload to or transmit through
          the Site or any breach of this Section 11.
        </p>
        <p>
          <strong className="subheading">
            12. Claims of Copyright Infringement
          </strong>
        </p>
        <p>
          We disclaim any responsibility or liability for copyrighted materials
          posted on our Site. If you believe that your work has been copied in a
          manner that constitutes copyright infringement, please follow the
          procedures set forth below.
        </p>
        <p>
          PocketMed respects the intellectual property rights of others and
          expects its users to do the same. In accordance with the Digital
          Millennium Copyright Act ("DMCA"), we will respond promptly to notices
          of alleged infringement that are reported to PocketMed’s Designated
          Copyright Agent, identified below.
        </p>
        <p>
          <strong className="subheading">
            13. Notices of Alleged Infringement for Content Made Available on
            the Site
          </strong>
        </p>
        <p>
          If you are a copyright owner, authorized to act on behalf of one, or
          authorized to act under any exclusive right under copyright, please
          report alleged copyright infringements taking place on or through our
          Site by sending us a notice ("Copyright Notice") complying with the
          following requirements.
        </p>
        <ol>
          <li>
            Identify the copyrighted works that you claim have been infringed.
          </li>
          <li>
            Identify the material or link you claim is infringing (or the
            subject of infringing activity) and that access to which is to be
            disabled, including at a minimum, if applicable, the URL of the link
            shown on the Site where such material may be found.
          </li>
          <li>
            Provide your mailing address, telephone number, and, if available,
            email address.
          </li>
          <li>
            Include both of the following statements in the body of the
            Copyright Notice:
          </li>
          <ul>
            <li className="list-unstyled">
              "I hereby state that I have a good faith belief that the disputed
              use of the copyrighted material is not authorized by the copyright
              owner, its agent, or the law (e.g., as a fair use)."
            </li>
            <li className="list-unstyled">
              "I hereby state that the information in this Copyright Notice is
              accurate and, under penalty of perjury, that I am the owner, or
              authorized to act on behalf of the owner, of the copyright or of
              an exclusive right under the copyright that is allegedly
              infringed."
            </li>
          </ul>
          <li>
            Provide your full legal name and your electronic or physical
            signature.
          </li>
        </ol>
        <p></p>
        <p>
          Deliver this Copyright Notice, with all items completed, to our
          Copyright Agent:
        </p>
        <p className="text-center">Tura Health LLC</p>
        <p className="text-center">1100 Town &amp; Country Road, Suite 1250</p>
        <p className="text-center">Orange, CA 92868</p>
        <p className="text-center">
          <a href="care@pocketmed.com">care@pocketmed.com</a>
        </p>
        <p>
          <strong className="subheading">14. Intellectual Property</strong>
        </p>
        <p>
          PocketMed retains all right, title, and interest in and to the Site,
          the Services and any information, products, documentation, software,
          or other materials on the Site, and any patent, copyright, trade
          secret, trademark, service mark, or other intellectual property, or
          proprietary right in any of the foregoing, except for information on
          the Site licensed by PocketMed (in that case, the license provider
          retains all right, title, and interest therein). The information
          available through the Site and the Services is the property of
          PocketMed. You agree not to store, copy, modify, reproduce,
          retransmit, distribute, disseminate, rent, lease, loan, sell, publish,
          broadcast, display, or circulate such information to anyone. Use,
          reproduction, copying, or redistribution of PocketMed trademarks,
          service marks, and logos are strictly prohibited without the prior
          written permission of PocketMed. The immediately foregoing sentence
          also applies to any third party trademarks, service marks, and logos
          posted on the Site. Nothing contained on the Site should be construed
          as granting, by implication, estoppel, waiver, or otherwise, any
          license or right to use any trademarks, service marks, or logos
          displayed on the Site without the written grant thereof by PocketMed
          or the third party owner of such trademarks, service marks, or logos.
          The Site may contain other proprietary notices and copyright
          information, the terms of which you agree to follow.
        </p>
        <p>
          PocketMed may delete any information provided by you that it deems in
          its sole discretion fraudulent, abusive, defamatory, obscene, or in
          violation of copyright, trademark, or other intellectual property or
          ownership right of any other person or entity.
        </p>
        <p>
          <strong className="subheading">15. CAN-SPAM Act Compliance</strong>
        </p>
        <p>
          PocketMed is committed to being compliant with the Controlling the
          Assault of Non-Solicited Pornography and Marketing Act (“CAN-SPAM
          ACT”). Emails and newsletters received from us are intended to fully
          comply with the CAN-SPAM ACT. In the event you receive an email from
          us which you do not believe is fully compliant with the CAN-SPAM ACT,
          please contact us immediately at the address listed below under the
          section “How to Contact Us.”
        </p>
        <p>
          You shall not use or permit any of your employees, agents, or
          affiliates to market, promote, or solicit PocketMed products or
          services in ways that would violate the CAN-SPAM ACT or any other
          laws. You shall not: (a) infringe on the rights of others; distribute
          chain letters or unsolicited bulk electronic mail (“spamming”); (b)
          propagate computer worms or viruses; (c) use a false identity; (d)
          attempt to gain unauthorized entry to any site or network; or (e)
          infringe copyrights, trademarks, or other intellectual property
          rights.
        </p>
        <p>
          <strong className="subheading">16. Disclaimer of Warranties</strong>
        </p>
        <p>
          POCKETMED DOES NOT WARRANT THAT ACCESS TO OR USE OF THE SITE WILL BE
          UNINTERRUPTED OR ERROR-FREE OR THAT DEFECTS IN THE SITE WILL BE
          CORRECTED. THIS SITE, INCLUDING ANY CONTENT OR INFORMATION CONTAINED
          WITHIN IT OR ANY SITE-RELATED SERVICE, IS PROVIDED "AS IS," WITH ALL
          FAULTS, WITH NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EITHER
          EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
          QUALITY OF INFORMATION, QUIET ENJOYMENT, AND TITLE/NON-INFRINGEMENT.
          POCKETMED DOES NOT WARRANT THE ACCURACY, COMPLETENESS, OR TIMELINESS
          OF THE INFORMATION OBTAINED THROUGH THE SITE.
        </p>
        <p>
          YOU ASSUME TOTAL RESPONSIBILITY AND RISK FOR YOUR USE OF THIS SITE,
          SITE-RELATED SERVICES, AND LINKED WEBSITES. POCKETMED DOES NOT WARRANT
          THAT FILES AVAILABLE FOR DOWNLOAD WILL BE FREE OF VIRUSES, WORMS,
          TROJAN HORSES, OR OTHER DESTRUCTIVE PROGRAMMING. YOU ARE RESPONSIBLE
          FOR IMPLEMENTING PROCEDURES SUFFICIENT TO SATISFY YOUR NEEDS FOR DATA
          BACK UP AND SECURITY.
        </p>
        <p>
          WARRANTIES RELATING TO SERVICES OFFERED, SOLD, AND DISTRIBUTED BY
          POCKETMED ARE SUBJECT TO SEPARATE WARRANTY TERMS AND CONDITIONS, IF
          ANY, PROVIDED BY POCKETMED OR THIRD PARTIES WITH OR IN CONNECTION WITH
          THE APPLICABLE SERVICES. YOU ACKNOWLEDGE AND UNDERSTAND THAT THE USE
          OR MISUSE OF THE SERVICES OBTAINED THROUGH OUR SITE MAY RESULT IN
          UNDESIRABLE OR UNEXPECTED CONSEQUENCES. POCKETMED DOES NOT ACCEPT ANY
          LIABILITY FOR THE CONSEQUENCES ARISING FROM THE APPLICATION, USE, OR
          MISUSE OF ANY SERVICES CONTAINED ON OR MADE AVAILABLE THROUGH THE
          SITE, INCLUDING ANY INJURY OR DAMAGE TO ANY PERSON OR PROPERTY AS A
          MATTER OF NEGLIGENCE, OR OTHERWISE.
        </p>
        <p>
          <strong className="subheading">
            17. Limitation of Liability Regarding Use of Site
          </strong>
        </p>
        <p>EXCEPT AS PROVIDED BY LAW, AND WITHOUT LIMITATION:</p>
        <p>
          POCKETMED AND ANY THIRD PARTIES MENTIONED ON THIS SITE ARE NEITHER
          RESPONSIBLE NOR LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
          CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE, OR OTHER DAMAGES
          WHATSOEVER (INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM LOST
          PROFITS, LOST DATA, OR BUSINESS INTERRUPTION) ARISING OUT OF OR
          RELATING IN ANY WAY TO THE SITE, SITE-RELATED SERVICES, CONTENT OR
          INFORMATION CONTAINED WITHIN THE SITE, OR ANY LINKED WEBSITE, WHETHER
          BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY AND
          WHETHER OR NOT ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOUR SOLE
          REMEDY FOR DISSATISFACTION WITH THE SITE, SITE-RELATED SERVICES, OR
          LINKED WEBSITES IS TO STOP USING THE SITE OR THOSE SERVICES.
        </p>
        <p>
          TO THE EXTENT ANY ASPECTS OF THE FOREGOING LIMITATIONS OF LIABILITY
          ARE NOT ENFORCEABLE, THE MAXIMUM LIABILITY OF POCKETMED TO YOU WITH
          RESPECT TO YOUR USE OF THIS SITE IS $500 (FIVE HUNDRED DOLLARS). YOU
          HEREBY AGREE TO WAIVE, TO THE FULLEST EXTENT PERMITTED BY LAW, ALL
          LAWS THAT LIMIT THE EFFICACY OF SUCH INDEMNIFICATIONS OR RELEASES.
        </p>
        <p>
          <strong className="subheading">18. No Third Party Rights</strong>
        </p>
        <p>
          Unless expressly stated in the Terms of Use to the contrary, nothing
          herein is intended to confer any rights or remedies on any persons
          other than you, PocketMed and its affiliates. Nothing in the Terms of
          Use is intended to relieve or discharge the obligation or liability of
          any third persons to you, PocketMed and its affiliates, nor shall any
          provision give any third parties any right of subrogation or action
          over against you, PocketMed and its affiliates.
        </p>
        <p>
          <strong className="subheading">19. Assignment</strong>
        </p>
        <p>
          You may not assign, transfer, or delegate the Terms of Use or any part
          thereof without PocketMed’s prior written consent. PocketMed may
          freely transfer, assign, or delegate all or any part of the Terms of
          Use, and any rights or duties hereunder or thereunder. The Terms of
          Use will be binding upon and inure to the benefit of the heirs,
          successors, and permitted assignees of the parties.
        </p>
        <p>
          <strong className="subheading">
            20.Dispute Resolution; Arbitration Agreement
          </strong>
        </p>
        <h6 className="paragraph-detail consent-paragrap text-gray">
          We will try to work in good faith to resolve any issue you have with
          the Site, including without limitation, Services ordered or purchased
          through the Site, if you bring that issue to the attention of our
          customer service department. However, we realize that there may be
          rare cases where we may not be able to resolve an issue to a
          customer's satisfaction.
        </h6>
        <h6 className="paragraph-detail consent-paragrap text-gray">
          You and PocketMed agree that any dispute, claim, or controversy
          arising out of or relating in any way to these Terms of Use or your
          use of the Site, including without limitation, Services ordered or
          purchased through the Site, shall be determined by binding arbitration
          instead of in courts of general jurisdiction. Arbitration is more
          informal than bringing a lawsuit in court. Arbitration uses a neutral
          arbitrator instead of a judge or jury, and is subject to very limited
          review by courts. Arbitration allows for more limited discovery than
          in court, however, we agree to cooperate with each other to agree to
          reasonable discovery in light of the issues involved and amount of the
          claim. Arbitrators can award the same damages and relief that a court
          can award, but in so doing, the arbitrator shall apply substantive law
          regarding damages as if the matter had been brought in court,
          including without limitation, the law on punitive damages as applied
          by the United States Supreme Court. You agree that, by agreeing to
          these Terms of Use, the U.S. Federal Arbitration Act governs the
          interpretation and enforcement of this provision, and that you and
          PocketMed are each waiving the right to a trial by jury or to
          participate in a class action. This arbitration provision shall
          survive termination of these Terms of Use and any other contractual
          relationship between you and PocketMed.
        </h6>
        <h6 className="paragraph-detail consent-paragrap text-gray">
          If you desire to assert a claim against PocketMed, and you therefore
          elect to seek arbitration, you must first send to PocketMed, by
          certified mail, a written notice of your claim ("Notice"). The Notice
          to PocketMed should be addressed to: Tura Health LLC, Attn: Legal
          Dept., 1100 Town &amp; Country Road, Suite 1250 Orange, CA 92868
          ("Notice Address"). If PocketMed desires to assert a claim against you
          and therefore elects to seek arbitration, it will send, by certified
          mail, a written Notice to the most recent address we have on file or
          otherwise in our records for you. A Notice, whether sent by you or by
          PocketMed, must (a) describe the nature and basis of the claim or
          dispute, and (b) set forth the specific relief sought ("Demand"). If
          PocketMed and you do not reach an agreement to resolve the claim
          within 30 days after the Notice is received, you or PocketMed may
          commence an arbitration proceeding or file a claim in small claims
          court. During the arbitration, the amount of any settlement offer made
          by PocketMed or you shall not be disclosed to the arbitrator. You may
          download or copy a form Notice and a form to initiate arbitration from
          the American Arbitration Association at www.adr.org. If you are
          required to pay a filing fee, after PocketMed receives notice at the
          Notice Address that you have commenced arbitration, it will promptly
          reimburse you for your payment of the filing fee, unless your claim is
          for more than US $2,000.
        </h6>
        <h6 className="paragraph-detail consent-paragrap text-gray">
          The arbitration will be governed by the Commercial Arbitration Rules
          and the Supplementary Procedures for Consumer Related Disputes
          (collectively, "AAA Rules") of the American Arbitration Association
          ("AAA"), as modified by these Terms of Use, and will be administered
          by the AAA. The AAA Rules and Forms are available online at
          www.adr.org, by calling the AAA at 1-800-778-7879, or by requesting
          them from us by writing to us at the Notice Address. The arbitrator is
          bound by the terms of these Terms of Use. All issues are for the
          arbitrator to decide, including, but not limited to, issues relating
          to the scope and enforceability of these Terms of Use, including, but
          not limited to, this arbitration agreement. Unless PocketMed and you
          agree otherwise, any arbitration hearings will take place in Broward
          County, Florida. (If you reside outside of the United States, any
          arbitration hearings will take place in your country of residence at a
          location reasonably convenient to you, but will remain subject to the
          AAA Rules including, but not limited to, the AAA rules regarding the
          selection of an arbitrator). If your claim is for US $10,000 or less,
          we agree that you may choose whether the arbitration will be conducted
          solely on the basis of documents submitted to the arbitrator, through
          a telephonic hearing, or by an in-person hearing as established by the
          AAA Rules. If your claim exceeds US $10,000, the right to a hearing
          will be determined by the AAA Rules. Regardless of the manner in which
          the arbitration is conducted, the arbitrator shall issue a reasoned
          written decision sufficient to explain the essential findings and
          conclusions on which the award is based. If the arbitrator issues you
          an award that is greater than the value of PocketMed’s last written
          settlement offer made before an arbitrator was selected (or if
          PocketMed did not make a settlement offer before an arbitrator was
          selected), then PocketMed will pay you the amount of the award or US
          $1,000, whichever is greater. Except as expressly set forth herein,
          the payment of all filing, administration, and arbitrator fees will be
          governed by the AAA Rules. Each party shall pay for its own costs and
          attorneys' fees, if any. However, if any party prevails on a statutory
          claim that affords the prevailing party attorneys' fees, or if there
          is a written agreement providing for payment or recovery attorneys’
          fees, the arbitrator may award reasonable fees to the prevailing
          party, under the standards for fee shifting provided by law.
        </h6>
        <h6 className="paragraph-detail consent-paragrap text-gray">
          YOU AND POCKETMED AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER
          ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR
          CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.
          Further, unless both you and PocketMed agree otherwise, the arbitrator
          may not consolidate more than one person's claims with your claims,
          and may not otherwise preside over any form of a representative or
          class proceeding. The arbitrator may award declaratory or injunctive
          relief only in favor of the individual party seeking relief and only
          to the extent necessary to provide relief warranted by that party's
          individual claim.
        </h6>
        <h6 className="paragraph-detail consent-paragrap text-gray">
          If the arbitration terms and conditions of this Section 20 are found
          to be unenforceable, then (i) the entirety of this arbitration
          provision shall be null and void, but the remaining provisions of
          these Terms of Use shall remain in full force and effect, and (ii)
          exclusive jurisdiction and venue for any claims will be in state or
          federal courts located in and for Los Angeles County, California.
        </h6>
        <p>
          <strong className="subheading">21. Force Majeure</strong>
        </p>
        <p>
          MajeureWe will not be deemed to be in breach of these terms or liable
          for any breach of these terms or our privacy policy due to any event
          or occurrence beyond our reasonable control, including without
          limitation, acts of God, terrorism war, invasion, failures of any
          public networks, electrical shortages, earthquakes or floods, civil
          disorder, strikes, fire, or other disaster.
        </p>
        <p>
          <strong className="subheading">22. Indemnification</strong>
        </p>
        <p>
          You agree to defend, indemnify, and hold harmless PocketMed and any
          affiliates from and against any and all rights, demands, losses,
          liabilities, damages, claims, causes of action, actions, and suits (no
          matter whether at law or equity), fees, costs, and attorney’s fees of
          any kind whatsoever arising directly or indirectly out of or in
          connection with:(i) your use or misuse of the Site, Services or any
          information posted on the Site; (ii) your breach of the Terms of Use
          or Privacy Policy; (iii) the content or subject matter of any
          information you provide to PocketMed or customer service agent; or
          (iv) any negligent or wrongful act or omission by you in your use or
          misuse of the Site, Services, or any information on the Site,
          including without limitation, infringement of third party intellectual
          property rights, privacy rights, or negligent or wrongful conduct.
        </p>
        <p>
          <strong className="subheading">
            23. Application Support; Functionality
          </strong>
        </p>
        <p>
          All questions and requests relating to Site support must be directed
          to PocketMed. To submit a support request, please email us at (714)
          464-7557 and care@pocketmed.com. The Select Third Parties, as defined
          in Section 25, are not responsible for providing support for the
          application portions of the Site and may not be contacted for support.
          PocketMed will use commercially reasonable efforts to respond to
          questions and provide support. Please note that we may change or
          remove functionality and other features of the Site at any time,
          without notice.
        </p>
        <p>
          <strong className="subheading">
            24. Modified Devices and Operating Systems
          </strong>
        </p>
        <p>
          PocketMed will have no liability for errors, unreliable operation, or
          other issues resulting from use of the Site on or in connection with
          rooted or jail broken devices or use on any mobile device that is not
          in conformance with the manufacturer’s original specifications,
          including, but not limited to, use of modified versions of the
          operating system (collectively, “<strong>Modified Devices</strong>
          ”). Use of the Site on Modified Devices will be at your sole and
          exclusive risk and liability.
        </p>
        <p>
          <strong className="subheading">
            25. No Liability for Select Third Parties
          </strong>
        </p>
        <p>
          Your wireless carrier, the manufacturer and retailer of your mobile
          device, the developer of the operating system for your mobile device,
          the operator of any application store (e.g., Apple, Google, etc.),
          marketplace, or similar service through which you obtain the Site, and
          their respective affiliates, suppliers, and licensors (collectively,
          the “<strong>Select Third Parties</strong>
          ”) are not parties to these Terms of Use and they do not own and are
          not responsible for the Site or maintenance and support of the Site.
          PocketMed, and not any Select Third Parties, is responsible for
          addressing any claims raised by you or any third party regarding
          2021080901the Site or your use or possession thereof, including, but
          not limited to, claims related to product liability, legal, or
          regulatory requirements, and consumer protection or similar
          legislation. You are responsible for complying with all application
          store and other applicable Select Third Parties’ terms and conditions.
          YOU AGREE: (A) THE SELECT THIRD PARTIES DISCLAIM ALL WARRANTIES,
          EXPRESS AND IMPLIED, WITH RESPECT TO THE SITE, INCLUDING, BUT NOT
          LIMITED TO, THE IMPLIED WARRANTIES OF NON-INFRINGEMENT, TITLE,
          MERCHANTABILITY, QUIET ENJOYMENT, QUALITY OF INFORMATION, AND FITNESS
          FOR A PARTICULAR PURPOSE; (B) IN NO EVENT WILL THE SELECT THIRD
          PARTIES BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
          PUNITIVE, EXEMPLARY, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES
          (WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE)
          ARISING OUT OF THIS AGREEMENT OR THE SITE, EVEN IF THEY HAVE BEEN
          ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES; AND (C) IN ANY
          EVENT, THE MAXIMUM LIABILITY OF ANY SELECT THIRD PARTY FOR ALL CLAIMS
          (WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE) OF
          EVERY KIND WILL IN NOT EXCEED FIVE DOLLARS ($5.00); AND (D) YOU WAIVE
          ANY AND ALL CLAIMS, NOW KNOWN OR LATER DISCOVERED, THAT YOU MAY HAVE
          AGAINST THE SELECT THIRD PARTIES ARISING OUT OF THE SITE AND THESE
          TERMS OF USE. THE SELECT THIRD PARTIES ARE INTENDED THIRD PARTY
          BENEFICIARIES OF THESE TERMS OF USE, AND ARE CAPABLE OF DIRECTLY
          ENFORCING ITS TERMS. NOTHING CONTAINED IN THESE TERMS OF USE WILL BE
          CONSTRUED AS MODIFYING OR AMENDING ANY AGREEMENTS OR OTHER TERMS
          BETWEEN YOU AND THE SELECT THIRD PARTIES WITH REGARD TO THEIR SUBJECT
          MATTER. In the event of any claim that the Site or your possession and
          use of the Site infringes a third party’s intellectual property
          rights, the Select Third Parties are not responsible for the
          investigation, defense, settlement, or discharge of the infringement
          claim.
        </p>
        <p>
          <strong className="subheading">26. Export Compliance</strong>
        </p>
        <p>
          You further agree to comply with U.S. export laws concerning the
          transmission of technical data and regulated materials via the
          Internet. You represent and warrant that (i) you are not located in a
          country that is subject to a U.S. Government embargo, or that has been
          designated by the U.S. Government as a “terrorist supporting” country;
          and (ii) your not listed on any U.S. Government list of prohibited or
          restricted parties. You agree to indemnify and hold PocketMed and its
          affiliates harmless for any and all acts found or suspected to be in
          violation hereof. You shall indemnify and hold PocketMed and its
          affiliates harmless against and from losses, damages, costs, and
          reasonable attorney fees incurred in defending or resolving any suits
          brought against PocketMed or any of its affiliates by anyone arising
          out of any alleged violation of any anti-spamming rules, regulations,
          laws, statutes. Your Account will be terminated for any of the above
          infractions.
        </p>
        <p>
          <strong className="subheading">27. Revisions; General</strong>
        </p>
        <p>
          PocketMed reserves the right, in its sole discretion, to terminate
          your access to all or part of this Site, with or without cause, and
          with or without notice. PocketMed reserves the right to modify these
          Terms of Use at any time, effective upon posting. Any use of this
          website after such changes will be deemed an acceptance of those
          changes. You agree to review the Terms of Use each time you access
          this website so that you may be aware of any changes to these Terms.
          In the event that any of the Terms of Use are held by a court or other
          tribunal of competent jurisdiction to be unenforceable, such
          provisions shall be limited or eliminated to the minimum extent
          necessary so that these Terms of Use shall otherwise remain in full
          force and effect. These Terms of Use constitute the entire agreement
          between PocketMed and you pertaining to the subject matter hereof. In
          its sole discretion, PocketMed may from time-to-time revise these
          Terms of Use by updating this posting. You should, therefore,
          periodically visit this page to review the current Terms of Use, so
          you are aware of any such revisions to which you are bound. Certain
          provisions of these Terms of Use may be superseded by expressly
          designated legal notices or terms located on particular pages within
          this Site. For purposes of these Terms of Use, (a) the words
          "include," "includes," and "including" are deemed to be followed by
          the words "without limitation"; (b) the word "or" is not exclusive;
          and (c) the words "herein," "hereof," "hereby," "hereto," and
          "hereunder" refer to these Terms of Use as a whole.
        </p>
        <p>
          <u>Copyright/Trademark Information.</u>
          Copyright ©2021 Tura Health LLC. All rights reserved. All trademarks,
          logos, and service marks (“Marks”) displayed on the Site are our
          property or the property of other third parties. You are not permitted
          to use these Marks without our prior written consent or the consent of
          such third party which may own the Marks.
        </p>
        <p className="text-center">
          <strong>How to Contact Us:</strong>
        </p>
        <p className="text-center">Tura Health LLC</p>
        <p className="text-center">1100 Town &amp; Country Road, Suite 1250</p>
        <p className="text-center">Orange, CA 92868</p>
        <p className="text-center">Telephone: (714) 464-7557</p>
        <p className="text-center">
          Email: <a href="care@pocketmed.com">care@pocketmed.com</a>
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default TermsOfUseModal;
