import React, { useEffect, useState, useContext } from "react";
import { isEmpty } from "lodash";
import { FetchOrderRefillsRequest } from "apis";
import { AuthContext } from "Contexts/AuthContext";
import { CartContext } from "Contexts/CartContext";
import { OrderContext } from "Contexts/OrderContext";
import useCurrentUser from "hooks/useCurrentUser";
import ActivePrescription from "./ActivePrescription";
import { ReactSVG } from "react-svg";
import orderRefill from "assets/images/dashboard/order-refill.svg";
import NewVisitButton from "../Home/Shared/NewVisitButton";

const OrderRefill = () => {
  const {
    reloadUser,
    defaultProductVariant,
    setShowResumeVisitModal,
    setShowRefillInProgress,
  } = useContext(AuthContext);
  const { setLatestCart } = useContext(CartContext);
  const { setCurrentOrder } = useContext(OrderContext);
  const [activePrescriptions, setActivePrescriptions] = useState<[]>([]);
  const [expiredPrescriptions, setExpiredPrescriptions] = useState<[]>([]);

  const user = useCurrentUser();

  useEffect(() => {
    if (!isEmpty(user)) {
      FetchOrderRefillsRequest()
        .then((res) => {
          setActivePrescriptions(res.data.active_prescriptions);
          setExpiredPrescriptions(res.data.expired_prescriptions);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user]);

  const onRemoveFromCart = () => {
    reloadUser().then(() => {
      setLatestCart(undefined);
      setCurrentOrder(undefined);
      setShowResumeVisitModal(false);
      setShowRefillInProgress(false);
    });
  };

  return (
    <>
      {!isEmpty(activePrescriptions)? (
      <div className="account-content order-refills">
        <h5 className="mb-3">Active Rx</h5>
        <div className="card-cols header d-none d-md-flex">
          <div className="card-col">
            <p>Name</p>
          </div>
        </div>
        <div className="account-content order-refills">
          {activePrescriptions.map((prescription: {}, idx: number) => {
            return (
              <React.Fragment key={idx}>
                <ActivePrescription
                  prescription={prescription}
                  onRemoveFromCart={onRemoveFromCart}
                />
              </React.Fragment>
            );
          })}
        </div>
      </div>
      ) : (
      <div className="empty-screen">
        <div className="empty-illustration-ellipse">
          <ReactSVG src={orderRefill} />
        </div>
        <span className="title-box">Order Refill</span>
        <span className="detail-box">
        Ready to reorder? This section is where you can quickly refill past orders.
        </span>
        <NewVisitButton
          defaultProductVariant={defaultProductVariant}
          position="center"
          isDashboardBtn={true}
        />
      </div>
      )}
    </>
  );
};

export default OrderRefill;
