import React, { useContext, useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { ReactSVG } from "react-svg";
import emailIcon from "assets/images/login/email.svg";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { AuthContext } from "Contexts/AuthContext";
import Translator from "components/Translator";
import HOCPasswordField from "components/HOCPasswordField";
import { isEmpty } from "lodash";
import Button from "components/Button";
import InputMask from "react-text-mask";
import { useRollbar } from "@rollbar/react";
import OtpModal from "containers/Account/Modals/OtpModal";
import PrivacyPolicyModal from "../Checkout/VisitConsent/Modals/PrivacyPolicyModal";
import TermsOfUseModal from "../Checkout/VisitConsent/Modals/TermsOfUseModal";
import phoneVibrateIcon from "assets/images/login/phone-vibrate.svg";
import emailCodeIcon from "assets/images/login/email-code.svg";
import arrowLeftIcon from "assets/images/home/arrow-left.svg";

import "./styles.scss";
import {
  LoginViaEmailRequest,
  LoginViaMobileRequest,
  EmailCodeRequest,
} from "apis";

const SignInSchema = Yup.object().shape({
  password: Yup.string(),
});

const SignInEmailSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email"),
});

const SignInPhoneSchema = Yup.object().shape({
  phone: Yup.string().min(14, "Must enter proper number"),
});

const Login = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const {
    LoginUser,
    loginError,
    resetPasswordMessage,
    invalidPasswordTokenError,
    setLoginError,
    setResetPasswordMessage,
    setInvalidPasswordTokenError,
  } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const rollbar = useRollbar();

  const [showLoginWithPhoneForm, setShowLoginWithPhoneForm] = useState(false);
  const [limitExceededError, setLimitExceededError] = useState<boolean>(false);
  const [openOtpModal, setOpenOtpModal] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [showTermsOfUseModal, setShowTermsOfUseModal] =
    useState<boolean>(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [showPasswordForm, setShowPasswordForm] = useState<boolean>(false);
  const [isEmailCode, setIsEmailCode] = useState<boolean>(false);
  const [phoneCodeLimitCounter, setPhoneCodeLimitCounter] = useState<number>(0);
  const [emailCodeLimitCounter, setEmailCodeLimitCounter] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const state = location.state;

    if (state?.loginWithPhone) {
      setShowLoginWithPhoneForm(true);
    } else {
      setShowLoginWithPhoneForm(false);
      if (isEmpty(email)) {
        setShowPasswordForm(false);
      } else {
        setShowPasswordForm(state?.showPassword);
        setInvalidPasswordTokenError("");
      }
    }

    setLoginError("");
  }, [location]);

  const phoneMask = [
    "(",
    /[1-9]/,
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  const handleLoginWithPhone = () => {
    setShowLoginWithPhoneForm(true);
    setInvalidPasswordTokenError("");
    setLoginError("");
    setLimitExceededError(false);
    setIsEmailCode(false);
    setEmailCodeLimitCounter(0);

    if (!isEmpty(redirectUri) && redirectUri.includes("account")) {
      navigate(`/sign-in?redirect_uri=${encodeURIComponent(redirectUri)}`, { state: { loginWithPhone: true, loginWithEmail: false  } });
    } else {
      navigate("/sign-in", { state: { loginWithPhone: true, loginWithEmail: false  } });
    }
  };

  const handleLoginViaMobileRequest = (values?: any) => {
    const newValues: any = { phone: values?.phone || phoneNumber };

    LoginViaMobileRequest(newValues)
      .then((res) => {
        const { limit_exceeded, error_message } = res.data;

        if (!error_message) {
          setOpenOtpModal(true);
          setPhoneCodeLimitCounter(1);
        } else if (limit_exceeded) {
          setLimitExceededError(limit_exceeded);
        } else if (error_message) {
          setLoginError(error_message);
        }
      })
      .catch((err) => {
        console.log(err);
        rollbar.error(err);
      });
  };

  const handleEmailCode = () => {
    const values: any = { email: email };

    EmailCodeRequest(values).then((res) => {
      setIsEmailCode(true);
      setOpenOtpModal(true);
      setEmailCodeLimitCounter(1);
    });
  };

  const handleLoginWithEmail = () => {
    setShowLoginWithPhoneForm(false);
    setLoginError("");
    setInvalidPasswordTokenError("");
    setLimitExceededError(false);

    if (!isEmpty(redirectUri) && redirectUri.includes("account")) {
      navigate(`/sign-in?redirect_uri=${encodeURIComponent(redirectUri)}`, { state: { loginWithPhone: false, loginWithEmail: true } });
    } else {
      navigate("/sign-in", { state: { loginWithPhone: false, loginWithEmail: true } });
    }
  };

  const redirectUri = searchParams.get("redirect_uri") ?? "";
  const phoneUrl = searchParams.get('phone') || "";

  const formatPhoneNumber = (phoneUrl: string): string => {
    if (isEmpty(phoneUrl)) {
      return phoneUrl;
    }

    const areaCode = phoneUrl.slice(0, 3);
    const firstPart = phoneUrl.slice(3, 6);
    const secondPart = phoneUrl.slice(6, 10);

    return `(${areaCode}) ${firstPart}-${secondPart}`;
  };

  const preLoadedEmail = searchParams.get('email') || "";
  const preLoadedPhone = formatPhoneNumber(phoneUrl) || "";

  return (
    <div className="main">
      <div className="session-page pt-5">
        <div className="content login-card">
          <h3>
            {!showPasswordForm ? (
              <Translator path="signIn.headings.welcomeBack" />
            ) : (
              <Translator path="signIn.headings.letsMakeSure" />
            )}
          </h3>
          <p className="enter-email-text">
            {!showLoginWithPhoneForm &&
              !showPasswordForm &&
              "Enter your email address to sign in to your account"}
            {showLoginWithPhoneForm &&
              "Enter your phone number to sign in to your account"}
            {showPasswordForm && "Choose how you want to verify your identity"}
          </p>
          {!isEmpty(resetPasswordMessage) && (
            <p className="signin-info">{`${resetPasswordMessage}`}</p>
          )}
          {!isEmpty(invalidPasswordTokenError) && (
            <p className="signin-error">{`${invalidPasswordTokenError}`}</p>
          )}

          {showPasswordForm && (
            <Formik
              initialValues={{
                email: email,
                password: "",
                phone: "",
                becomeToken: "",
              }}
              validationSchema={SignInSchema}
              onSubmit={(values) => {
                setLoading(true);
                setLoginError("");
                setResetPasswordMessage("");
                setInvalidPasswordTokenError("");
                LoginUser({ ...values }).finally(() => setLoading(false));
              }}
            >
              {({ errors, touched, values, setFieldValue }) => (
                <Form className="user-form-mobile">
                  <div className="field">
                    <HOCPasswordField
                      name="password"
                      placeholder="Enter your password"
                    />
                    {errors.password && touched.password ? (
                      <div className="input-errors">{errors.password}</div>
                    ) : null}
                    <p
                      className={`signin-error mb-0 ${!loginError && "d-none"}`}
                    >
                      {loginError}
                    </p>
                  </div>
                  <p className="session__row mt-0 mb-0 d-flex justify-content-start">
                    <span>Forgot your password?</span>{" "}
                    <Link
                      className="password-recovery ml-1"
                      to={"/forgot-password"}
                    >
                      Reset it
                    </Link>
                  </p>
                  <button className="btn submit-btn text-uppercase" type="submit">
                    {loading ? "Processing..." : "Continue"}
                  </button>
                </Form>
              )}
            </Formik>
          )}

          {!showLoginWithPhoneForm && !showPasswordForm && (
            <Formik
              initialValues={{
                email: preLoadedEmail,
              }}
              validationSchema={SignInEmailSchema}
              onSubmit={(values) => {
                setResetPasswordMessage("");
                setEmail(values.email);
                LoginViaEmailRequest(values).then((res) => {
                  const { error_message, phone_number } = res.data;

                  if (!error_message) {
                    setPhoneNumber(phone_number);
                    if (!isEmpty(redirectUri) && redirectUri.includes("account")) {
                      navigate(`/sign-in?redirect_uri=${encodeURIComponent(redirectUri)}`, { state: { showPassword: true } });
                    } else {
                      navigate("/sign-in", { state: { showPassword: true } });
                    }
                    setShowPasswordForm(true);
                  } else if (error_message) {
                    setLoginError(error_message);
                  }
                });
              }}
            >
              {({ errors, touched, values, setFieldValue }) => (
                <Form className="user-form-mobile">
                  <div className="field">
                    <div className="input-group row mx-0">
                      <ReactSVG
                        className="email-icon input__icon"
                        src={emailIcon}
                      />
                      <Field
                        className="input with-icon"
                        name="email"
                        type="email"
                        placeholder="Enter your email"
                        onKeyUp={() => {
                          setLoginError("");
                          setInvalidPasswordTokenError("");
                        }}
                      />
                    </div>
                    {errors.email && touched.email ? (
                      <div className="input-errors">{errors.email}</div>
                    ) : null}
                    <p
                      className={`signin-error mb-0 ${!loginError && "d-none"}`}
                    >
                      {loginError}
                    </p>
                  </div>
                  <div className="terms-and-conditions">
                    By Continuing, you agree to our{" "}
                    <button
                      className="visit-consent-modals-btn"
                      type="button"
                      onClick={() =>
                        setShowTermsOfUseModal(!showTermsOfUseModal)
                      }
                    >
                      Terms
                    </button>{" "}
                    and{" "}
                    <button
                      className="visit-consent-modals-btn"
                      type="button"
                      onClick={() => setShowPrivacyModal(!showPrivacyModal)}
                    >
                      Privacy Policy
                    </button>
                  </div>
                  <button
                    className="btn submit-btn text-uppercase"
                    type="submit"
                    disabled={limitExceededError}
                  >
                    {loading ? "Processing..." : "Continue"}
                  </button>
                </Form>
              )}
            </Formik>
          )}
          {showLoginWithPhoneForm && !showPasswordForm && (
            <Formik
              initialValues={{
                email: "",
                password: "",
                phone: preLoadedPhone,
                becomeToken: "",
              }}
              validationSchema={SignInPhoneSchema}
              onSubmit={(values) => {
                setLoginError("");
                setPhoneNumber(values.phone);
                handleLoginViaMobileRequest(values);
              }}
            >
              {({ errors, touched, values, setFieldValue }) => (
                <Form className="user-form-mobile">
                  <div className="field">
                    <div className="input-group row mx-0">
                      <div className="input__icon phone phone-icon"></div>
                      <Field
                        name="phone"
                        className="input lg no-mouseflow phone-number-input"
                        type="tel"
                      >
                        {({ field }: any) => (
                          <InputMask
                            {...field}
                            mask={phoneMask}
                            guide={false}
                            type="tel"
                            value={values.phone}
                            className={"input lg phone-input no-mouseflow"}
                            placeholder="Mobile phone number"
                            onChange={(e: any) => {
                              setFieldValue("phone", e.target.value);
                            }}
                          />
                        )}
                      </Field>
                    </div>
                    {errors.phone && touched.phone ? (
                      <div className="input-error">{errors.phone}</div>
                    ) : null}
                    <p
                      className={`signin-error mb-0 ${!loginError && "d-none"}`}
                    >
                      {loginError}
                    </p>
                  </div>

                  <p className="enter-email-text mb-1">
                    Your mobile number must be able to receive text messages.
                  </p>
                  <div className="terms-and-conditions">
                    By Continuing, you agree to our{" "}
                    <button
                      className="visit-consent-modals-btn"
                      type="button"
                      onClick={() =>
                        setShowTermsOfUseModal(!showTermsOfUseModal)
                      }
                    >
                      Terms
                    </button>{" "}
                    and{" "}
                    <button
                      className="visit-consent-modals-btn"
                      type="button"
                      onClick={() => setShowPrivacyModal(!showPrivacyModal)}
                    >
                      Privacy Policy
                    </button>
                  </div>
                  <button
                    className="btn submit-btn text-uppercase"
                    type="submit"
                    disabled={limitExceededError}
                  >
                    {loading ? "Processing..." : "Continue"}
                  </button>
                </Form>
              )}
            </Formik>
          )}
          <div className="options-divider mt-0">
            <Translator path="signIn.labels.divider" />
          </div>
          {!showLoginWithPhoneForm && !showPasswordForm && (
            <>
              <Button
                classes="btn-ghost login-via-phone-btn"
                fullWidth={true}
                color="ghost"
                type="button"
                onClick={() => {
                  setResetPasswordMessage("");
                  setIsEmailCode(false);
                  setEmail("");
                  handleLoginWithPhone();
                }}
              >
                Sign in with my phone number
              </Button>
            </>
          )}

          {showPasswordForm && (
            <>
              <p>Sign in without your password. Select an option below:</p>
              {phoneNumber && (
                <div
                  className="text-email-code"
                  onClick={() => {
                    setIsEmailCode(false);
                    setEmailCodeLimitCounter(0);
                    handleLoginViaMobileRequest();
                  }}
                >
                  <div className="ellipse">
                    <ReactSVG
                      className="phone-vibrate-icon"
                      src={phoneVibrateIcon}
                    />
                  </div>
                  <div className="text-code">
                    <span className="code">Text a code</span>
                    <span className="phone-number">
                      (***) *** **{phoneNumber.slice(-2)}
                    </span>
                  </div>
                  <ReactSVG className="left-arrow-icon" src={arrowLeftIcon} />
                </div>
              )}
              <div
                className="text-email-code email-section"
                onClick={() => {
                  setIsEmailCode(true);
                  setPhoneCodeLimitCounter(0);
                  handleEmailCode();
                }}
              >
                <ReactSVG className="phone-vibrate-icon" src={emailCodeIcon} />
                <div className="text-code">
                  <span className="code">Email a code</span>
                  <span className="phone-number">{email}</span>
                </div>
                <ReactSVG className="left-arrow-icon" src={arrowLeftIcon} />
              </div>
              <div className="website-support">
                <span>Having Trouble Signing In? Call </span>
                <a href="tel:(844) 745-3362">(844) 745-3362</a>
              </div>
            </>
          )}

          {showLoginWithPhoneForm && !showPasswordForm && (
            <>
              <Button
                classes="btn-ghost login-via-phone-btn"
                fullWidth={true}
                color="ghost"
                type="button"
                onClick={() => handleLoginWithEmail()}
              >
                Sign in with my email
              </Button>
            </>
          )}
          {!showPasswordForm && (
            <p className="dont-have-account signup mb-0">
              <Translator path="signIn.headings.dontHaveAccount" />
              <button
                className=""
                type="button"
                onClick={() => navigate("/sign-up")}
              >
                <Translator path="signIn.headings.signUp" />
              </button>
            </p>
          )}

          <OtpModal
            openOtpModal={openOtpModal}
            setOpenOtpModal={setOpenOtpModal}
            limitExceededError={limitExceededError}
            setLimitExceededError={setLimitExceededError}
            isProfileModal={true}
            isLoginOtpModal={true}
            phoneNumber={phoneNumber}
            setLoginError={setLoginError}
            email={email}
            isEmailCode={isEmailCode}
            phoneCodeLimitCounter={phoneCodeLimitCounter}
            setPhoneCodeLimitCounter={setPhoneCodeLimitCounter}
            emailCodeLimitCounter={emailCodeLimitCounter}
            setEmailCodeLimitCounter={setEmailCodeLimitCounter}
            setShowLoginWithPhoneForm={setShowLoginWithPhoneForm}
            setShowPasswordForm={setShowPasswordForm}
            setPhoneNumber={setPhoneNumber}
          />
        </div>
      </div>
      <PrivacyPolicyModal
        show={showPrivacyModal}
        setShow={setShowPrivacyModal}
      />
      <TermsOfUseModal
        show={showTermsOfUseModal}
        setShow={setShowTermsOfUseModal}
        setShowPrivacyModal={setShowPrivacyModal}
      />
    </div>
  );
};

export default Login;
