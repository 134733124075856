import React, { useContext, useEffect, useState } from "react";
import { CartContext, CartType } from "Contexts/CartContext";
import BottleSection from "./BottleSection";
import ProductDetail from "containers/Checkout/MarketingProductDetail";
import LabTested from "containers/Home/LabTested";
import Process from "containers/Home/Process";
import AboutEd from "containers/Home/AboutEd";
import WhatIsIncluded from "containers/Home/WhatIsIncluded";
import Realresults from "containers/Home/RealResults";
import ViagraVsSildenafil from "containers/Home/ViagraVsSildenafil";
import HappyPatients from "containers/Home/HappyPatients";
import Faq from "containers/Home/Faq";
import BottomCta from "containers/Home/BottomCta";
import Footer from "components/Footer";
import ChatReminderModal from "containers/Home/Modals/ChatReminderModal";
import ResumeRefillInProgressModal from "components/Modals/ResumeRefllModal";
import DuplicateCartModal from "components/Modals/DuplicateCartModal";
import RefillAvailableModal from "components/Modals/RefillAvailableModal";
import ResumeVisitModal from "components/Modals/ResumeVisitModal";
import UnderReviewVisitModal from "components/Modals/UnderReviewVisitModal";
import RetakeModal from "containers/Home/Modals/RetakeModal";

import useLatestCart from "hooks/useLatestCart";
import { AuthContext } from "Contexts/AuthContext";

import { isEmpty } from "lodash";
import { useSearchParams } from "react-router-dom";
import { CreateSlugCartRequest } from "apis";
import { useRollbar } from "@rollbar/react";

import { FbApiDataExtraction } from "helpers/FbApiDataExtraction";

const MarketingLandingPage = () => {
  const { setLatestCart, setCartProductVariant } = useContext(CartContext);
  const { ReloadCart } = useContext(CartContext);

  const {
    showChatReminderModal,
    showRetakeModal,
    setShowRetakeModal,
    setShowChatReminderModal,
    reloadUser,
  } = useContext(AuthContext);

  let [searchParams, setSearchParams] = useSearchParams();
  const [slug, setSlug] = useState<string>("");
  const [utmData, setUtmData] = useState<any>();
  const [guestCart, setGuestCart] = useState<CartType>();
  const latestCart = useLatestCart();
  const rollbar = useRollbar();

  useEffect(() => {
    localStorage.setItem("isMarketing", "marketing");
    // eslint-disable-next-line no-restricted-globals
    location?.pathname?.split("/")[2]
      ? // eslint-disable-next-line no-restricted-globals
        setSlug(location?.pathname?.split("/")[2])
      : setSlug("sildenafil-citrate-20-mg");

    // eslint-disable-next-line no-restricted-globals
    setUtmData(FbApiDataExtraction(location.href));
  }, []);

  useEffect(() => {
    if (!isEmpty(slug) && !latestCart?.marketing) {
      const CartToken = localStorage.getItem("CartToken") ?? "";

      CreateSlugCartRequest(
        {
          slug: slug,
          qty: searchParams.get("qty"),
          cart_token: CartToken,
        },
        utmData
      )
        .then((res) => {
          const cart = res.data.cart;

          setLatestCart(cart);
          setGuestCart(cart);
          setCartProductVariant(res.data.product_variant);

          localStorage.setItem("CartToken", cart.token);
          localStorage.removeItem("intro-answers");
        })
        .catch((err) => {
          rollbar.error(err);
        });
    }
  }, [slug]);

  useEffect(() => {
    ReloadCart();
    reloadUser();
  }, []);

  return (
    <div className="main">
      <div className="row flex-column flex-md-row">
        <div className="col-lg-6 col-12" id="bottle-section">
          <BottleSection latestCart={latestCart} />
        </div>
        <div className="col-lg-6 col-12" id="product-detail-section">
          <ProductDetail cart={latestCart} marketingPage={true} />
        </div>
      </div>
      <LabTested slug={true} defaultProductVariant={undefined} />
      <Process slug={true} defaultProductVariant={undefined} />
      <AboutEd />
      <WhatIsIncluded />
      <Realresults />
      <ViagraVsSildenafil />
      <RetakeModal setShow={setShowRetakeModal} show={showRetakeModal} />
      <ChatReminderModal
        setChatReminderModal={setShowChatReminderModal}
        show={showChatReminderModal}
      />
      <ResumeRefillInProgressModal />
      <ResumeVisitModal slug={true} defaultProductVariant={undefined} />
      <RefillAvailableModal slug={true} defaultProductVariant={undefined} />
      <UnderReviewVisitModal />
      <DuplicateCartModal />
      <HappyPatients slug={true} defaultProductVariant={undefined} />
      <Faq />
      <BottomCta slug={true} defaultProductVariant={undefined} />
      <Footer />
    </div>
  );
};

export default MarketingLandingPage;
