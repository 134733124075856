const CartPreviousPath = (
  currentLocation: any,
  isMarketingCart: boolean,
  slug: string
) => {
  let cartSteps = [
    "welcome",
    "intro-questions",
    "visit-consent",
    "patient-info",
    "visit-intro",
    "visit-consultation",
    "product-detail",
    "shipping",
    "identity-verification",
    "id-upload",
    "selfie-upload",
    "shipping-confirmation",
    "order-verification",
  ];

  const cartMarketingSteps = [
    "product-selection",
    "welcome",
    "intro-questions",
    "visit-consent",
    "patient-info",
    "visit-intro",
    "visit-consultation",
    "shipping",
    "identity-verification",
    "id-upload",
    "selfie-upload",
    "shipping-confirmation",
    "order-verification",
  ];

  cartSteps = isMarketingCart ? cartMarketingSteps : cartSteps;

  const splitedPathName = currentLocation.pathname.split("/");

  if (
    currentLocation.pathname === "/edit/shipping" ||
    currentLocation.pathname === "/edit/patient-info"
  ) {
    const pathData = {
      path: currentLocation.state?.order_view
        ? "/order-shipping-confirmation"
        : "/checkout/shipping-confirmation",
      state: { editingPaths: true },
    };
    return pathData;
  }

  if (splitedPathName.includes("checkout")) {
    const currentStepIndex = cartSteps.indexOf(splitedPathName[2]);
    const cartPreviousStep = cartSteps[currentStepIndex - 1];
    let path = "";
    let state: {} = {};
    let pathData: {} = { path };

    if (
      splitedPathName[2] === "no-checkup" ||
      splitedPathName[2] === "no-blood-pressure"
    ) {
      path = "/checkout/visit-consultation";
      state = { cancelVisitRoute: true };
      pathData = { path, state };
    } else if (splitedPathName.includes("product-detail")) {
      path = "/checkout/visit-consultation";
      state = { canResetQuestionnaire: true };
      pathData = { path, state };
    } else if (isMarketingCart && splitedPathName.includes("shipping")) {
      path = "/checkout/visit-consultation";
      state = { canResetQuestionnaire: true };
      pathData = { path, state };
    } else if (splitedPathName.includes("visit-consultation")) {
      path = "/checkout/visit-consultation";
      state = { isQuestionnaire: true };
      pathData = { path, state };
    } else if (!splitedPathName.includes("visit-consent")) {
      path = `/checkout/${cartPreviousStep}`;
      pathData = { path };
    } else {
      path = `/${cartPreviousStep}`;
      state = { intro_question_id: "2.01" };
      pathData = { path, state };
    }

    return pathData;
  } else {
    const currentStepIndex = cartSteps.indexOf(splitedPathName[1]);
    const cartPreviousStep = cartSteps[currentStepIndex - 1];
    let path = "";
    let state: {} = {};
    let pathData: {} = { path };

    if (splitedPathName[1] === "intro-questions") {
      if (currentLocation.state?.intro_question_id === "2.01") {
        state = { intro_question_id: "1.01" };
        path = "/intro-questions";
        pathData = { path, state };
      } else if (cartPreviousStep === "welcome") {
        path = `${cartPreviousStep}`;
        pathData = { path };
      }
    } else if (splitedPathName[1] === "welcome") {
      path = isMarketingCart ? `/product-selection/${slug}` : "/";
      pathData = { path };
    } else if (splitedPathName[1].includes("retake-photos")) {
      path = "/";
      pathData = { path };
    }

    return pathData;
  }
};

export { CartPreviousPath };
