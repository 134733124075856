import React from "react";
import { Modal } from "react-bootstrap";

type CameraModalProps = {
  isOpen: any;
  onClose: any;
  children: any;
};

const CameraModal = ({ isOpen, onClose, children }: CameraModalProps) => (
  <Modal
    show={isOpen}
    onHide={onClose}
    className={`camera-modal-dialog`}
    size="lg">
    <Modal.Body>{children}</Modal.Body>
  </Modal>
);

export default CameraModal;
