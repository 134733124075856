import React from "react";
import AnswerOption from "./AnswerOption";
import { ReactSVG } from "react-svg";
import MedLogo from "assets/images/icons/extra_box_med.svg";

type Prop = {
  selectedAnswers: any;
  setSelectedAnswers: any;
  setAllowText: any;
  extraText: any;
  setExtraText: any;
  setAllowAllergy: any;
  activeQuestion: any;
  questionsLength: any;
  handleContinue: any;
  setUserAction: any;
  setShowContinueButton: any;
  textAreaBorder: any;
  setContinueButtonDisable: any;
};

const SingleQuestion = ({
  selectedAnswers,
  setSelectedAnswers,
  setAllowText,
  extraText,
  setExtraText,
  setAllowAllergy,
  activeQuestion,
  questionsLength,
  handleContinue,
  setUserAction,
  setShowContinueButton,
  textAreaBorder,
  setContinueButtonDisable,
}: Prop) => {
  return (
    <>
      <h4 className="question-heading mb-3">{activeQuestion?.text}</h4>
      {activeQuestion?.subtitle && (
        <p className="answer-option-extra-label mt-2 mb-3">
          {activeQuestion?.subtitle}
        </p>
      )}
      {activeQuestion?.label === "16.01" && (
        <div className="drug-selection-extra-box">
          <ReactSVG src={MedLogo} />
          <p>
            Our Clinicians use this information in determining a safe and
            effective treatment.
          </p>
        </div>
      )}
      <div className="answer-options-container">
        {activeQuestion?.answer_options?.map((option: any, idx: number) => (
          <React.Fragment key={idx}>
            <AnswerOption
              question={null}
              selectedAnswers={selectedAnswers}
              setSelectedAnswers={setSelectedAnswers}
              option={option}
              questionType={activeQuestion?.question_type}
              setAllowText={setAllowText}
              userResponses={activeQuestion?.responses}
              questionsLength={questionsLength}
              extraText={extraText}
              setExtraText={setExtraText}
              setAllowAllergy={setAllowAllergy}
              activeQuestion={activeQuestion}
              handleContinue={handleContinue}
              setUserAction={setUserAction}
              setShowContinueButton={setShowContinueButton}
              textAreaBorder={textAreaBorder}
              setContinueButtonDisable={setContinueButtonDisable}
              questionId={activeQuestion.id}
              setShowSideEffectText={null}
              setSideEffectsQuestionLabel={null}
              badge={null}
            />
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default SingleQuestion;
