import React, { useState } from "react";
import { ReactSVG } from "react-svg";

import Translator from "components/Translator";
import PatientTestimonialsModal from "../Home/Modals/PatientTestimonialsModal";
import MarketingTestimonials from "./MarketingTestimonials";
import usFlag from "assets/images/home/us-flag.svg";
import sildenafilBottle from "assets/images/home/sildenafil_bottle_new_with_shadow.png";
import tadalafilBottle from "assets/images/home/tadalafil_bottle_new_with_shadow.png";
import checkMarkBullet from "assets/images/home/check-mark-bullet.svg";
import ratingStars from "assets/images/home/rating-stars.svg";

import "./styles.scss";
import { isEmpty } from "lodash";

type ProductDetailProps = {
  latestCart?: any;
};

const BottleSection = ({ latestCart }: ProductDetailProps) => {
  const [showTestimonialModal, setShowTestimonialModal] =
    useState<boolean>(false);
  const selectedProduct =
    latestCart?.product_variant?.product_name.toLowerCase();

  const bulletPoints = [
    "Expert Pharmacist Guidance",
    "No Monthly Subscriptions",
    "Free Online Doctor Visit",
    "FDA Approved",
    "Low Price Guarantee",
  ];

  const bottleImage =
    selectedProduct === "tadalafil" ? tadalafilBottle : sildenafilBottle;

  const formatProductName = (cart: any) => {
    if (isEmpty(cart)) return "";

    const { product_variant } = cart;

    if (
      product_variant.product_name === "Sildenafil" &&
      product_variant.dosage_value === 20
    ) {
      return `${product_variant.dosage} ${product_variant.product_name}`;
    }

    return `${product_variant.dosage} ${cart.product_name_with_brand}`;
  };

  return (
    <div className="bottle-container">
      <div className="main-content non-tablet d-none d-lg-block">
        <div className="us-official-source">
          <img src={usFlag} alt="us-flag" />
          <span className="us-text">US Lab Tested & Official Source</span>
        </div>
        <h2 className="heading mt-2">{formatProductName(latestCart)}</h2>
        <div className="d-flex">
          <img
            src={bottleImage}
            alt="Sildenafil Bottle"
            className="mx-auto mt-3 medicine-bottle"
          />
        </div>
        <div className="row bottom-section platform-features">
          <div className="col-6">
            {bulletPoints.map((bp, idx) => {
              if ([0, 1, 4].includes(idx)) {
                return (
                  <div className="feature" key={idx}>
                    <img src={checkMarkBullet} alt="check-mark-bulllet" />
                    <span>{bp}</span>
                  </div>
                );
              }
            })}
          </div>
          <div className="col-6">
            {bulletPoints.map((bp, idx) => {
              if ([2, 3].includes(idx)) {
                return (
                  <div className="feature" key={idx}>
                    <img src={checkMarkBullet} alt="check-mark-bulllet" />
                    <span>{bp}</span>
                  </div>
                );
              }
            })}
          </div>
        </div>
        <div className="testimonials">
          <MarketingTestimonials />
        </div>
      </div>

      <div
        className={`main-content tablet d-block d-lg-none ${selectedProduct}`}
        id="non-desktop">
        <div className="row">
          <div className="col-11">
            <div className="us-official-source">
              <img src={usFlag} alt="us-flag" />
              <span className="us-text">US Lab Tested & Official Source</span>
            </div>
            <h2 className="heading mt-2">{formatProductName(latestCart)}</h2>
            <button
              className="sublink px-0 testimonial-link testimonail-modal-btn mt-0"
              type="button"
              onClick={() => setShowTestimonialModal(!showTestimonialModal)}>
              <Translator path="homepage.hero.testimonialLink" />
            </button>
            <ReactSVG src={ratingStars} wrapper={"span"} className="ml-2" />
            <PatientTestimonialsModal
              show={showTestimonialModal}
              setShow={setShowTestimonialModal}
            />

            <div className="platform-features flex-column">
              {bulletPoints.map((bp, idx) => {
                return (
                  <div className="feature" key={idx}>
                    <img src={checkMarkBullet} alt="check-mark-bulllet" />
                    <span>{bp}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="testimonials">
          <MarketingTestimonials />
        </div>
      </div>
    </div>
  );
};

export default BottleSection;
