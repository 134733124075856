import React from "react";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";
import { ResumeCartRequest } from "apis";
import { useNavigate } from "react-router-dom";

const TelemedicineOpen = () => {
  const navigate = useNavigate();

  return (
    <div className="account-card__status">
      <h3>Order Status:</h3>
      <p>Complete Your Order</p>
      <p className="info">You're almost done! Resume your visit from where you left off.</p>
      <Button
        color="secondary"
        size="medium"
        classes="text-initial"
        onClick={() => {
          ResumeCartRequest()
            .then((res) => {
              navigate(res.data.redirect_path);
            })
            .catch((err) => {
              console.log(err);
            })
        }}
      >
        Complete Your Order
      </Button>
    </div>
  )
};

export default TelemedicineOpen;
