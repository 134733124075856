import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import { Form, Formik } from "formik";
import { GetProductVariantRequest } from "apis";
import Button from "components/Button";
import Translator from "components/Translator";
import { AuthContext } from "Contexts/AuthContext";
import { CartContext } from "Contexts/CartContext";
import useCurrentOrder from "hooks/useCurrentOrder";
import useCurrentUser from "hooks/useCurrentUser";
import CartDropdownItem from "./CartDropdownItem";
import useLatestCart from "hooks/useLatestCart";
import { handleShowModals } from "containers/Home/helpers";

interface CartDropdownProps {
  validLatestCartSteps: string[];
  handleContinueProcess: () => void;
  handleDeleteCart: () => void;
}

const CartDropdown = ({
  handleContinueProcess,
  handleDeleteCart,
  validLatestCartSteps,
}: CartDropdownProps) => {
  const [defaultProductVariant, setDefaultProductVariant] = useState<any>({});
  const [count, setCount] = useState(0);
  const {
    setShowResumeVisitModal,
    setShowUnderReviewVisitModal,
    retakePending,
    setShowRetakeModal,
    setShowRefillAvailableModal,
    setShowRefillInProgress,
    setShowDuplicateCartModal,
  } = useContext(AuthContext);
  const { CreateCart } = useContext(CartContext);
  const cart = useLatestCart();
  const user = useCurrentUser();
  const order = useCurrentOrder();
  const navigate = useNavigate();
  const location = useLocation();

  const isMarketingPage = location.pathname.includes("product-selection");

  useEffect(() => {
    if (isEmpty(defaultProductVariant)) {
      GetProductVariantRequest().then((res: any) => {
        setDefaultProductVariant(res.data.default_product_variant);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const latestCartStep = cart?.step ?? "";

    if (!isEmpty(order)) {
      return setCount(order.carts.length);
    }

    switch (cart?.branch) {
      case "refill_request":
        setCount(1);
        return;
      case "telemedicine":
        const tempCount = validLatestCartSteps.includes(latestCartStep) ? 1 : 0;
        setCount(tempCount);
        return;
      default:
        setCount(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, order]);

  return (
    <>
      <span className="cart-count">{count}</span>
      <div className="dropdown dropdown-menu">
        {!isEmpty(order)
          ? order.carts.map((cart: any, index: number) => (
              <CartDropdownItem
                key={index}
                cart={cart}
                showDeleteIcon={cart.branch === "refill_request"}
                handleDeleteCart={handleDeleteCart}
              />
            ))
          : !isEmpty(cart) && (
              <CartDropdownItem
                cart={cart}
                handleDeleteCart={handleDeleteCart}
              />
            )}
        {isEmpty(order) &&
          (!isEmpty(cart) && !isMarketingPage ? (
            <Button
              type="button"
              fullWidth={true}
              section="secondary"
              size="small"
              classes="continue-cart-btn"
              onClick={handleContinueProcess}
            >
              <Translator path="navbar.continueButtonText" />
            </Button>
          ) : (
            <>
              {!isEmpty(defaultProductVariant) && (
                <Formik
                  initialValues={{
                    variant: defaultProductVariant?.id ?? null,
                  }}
                  onSubmit={(values) => {
                    const allowCartCreation = handleShowModals({
                      showRetake: retakePending,
                      showUnderReview: user?.has_under_review_visit,
                      showResumeVisit: user?.has_cart_in_progress,
                      showRefillAvailableModal: user?.has_refill,
                      showRefillInProgress: user?.has_in_progress_refill,
                      is_order_being_processed: user?.is_order_being_processed,
                      setShowRefillAvailableModal,
                      setShowRetakeModal,
                      setShowUnderReviewVisitModal,
                      setShowResumeVisitModal,
                      setShowRefillInProgress,
                      setShowDuplicateCartModal,
                    });

                    if (allowCartCreation) CreateCart(values);
                  }}
                >
                  {() => (
                    <Form className="refillChecks">
                      <Button
                        type="submit"
                        fullWidth={true}
                        section="primary"
                        size="small"
                        dataAttribute={defaultProductVariant.id}
                      >
                        <Translator path="navbar.addToCartButtonText" />
                      </Button>
                    </Form>
                  )}
                </Formik>
              )}
            </>
          ))}
        {!isEmpty(order) && (
          <Button
            type="button"
            fullWidth={true}
            section="secondary"
            size="small"
            id="order-continue-btn"
            onClick={() => navigate("/order-shipping-confirmation")}
          >
            <Translator path="navbar.continueButtonText" />
          </Button>
        )}
      </div>
    </>
  );
};

export default CartDropdown;
