import React from "react";
import { isEmpty, maxBy } from "lodash";

import { calculatePrices } from "../Shared/Utils";

type PackageItemProps = {
  quantity: any;
  variant: any;
  currentQuantity: any;
  onQuantitySelect: any;
  onCloseInput: any;
  validCustomInput: any;
  customInputFieldOpen: any;
  cartDiscounts: any;
  index: number;
  showPrice?: boolean;
};
const getUnitPrice = (quantity: any, variant: any, cartDiscounts: any) => {
  const possibleThresholds = variant.price_thresholds.filter(
    (thd: any) => quantity >= thd.min_quantity
  );

  const basePrice = variant.price_thresholds[0].price;
  const threshold = maxBy(possibleThresholds, (thd: any) => thd.min_quantity);
  const thdPercentage = calculatePrices(variant, quantity);
  let price = threshold ? Number(threshold.price) : 0.0;

  if (
    cartDiscounts?.length &&
    cartDiscounts[0].percent > thdPercentage.discount
  ) {
    const discount = 1 - cartDiscounts[0].percent / 100;
    price = basePrice * discount;
  }

  return price;
};

const PackageItem = ({
  quantity,
  variant,
  currentQuantity,
  onQuantitySelect,
  onCloseInput,
  validCustomInput,
  customInputFieldOpen,
  cartDiscounts,
  index,
  showPrice = true,
}: PackageItemProps) => {
  const unitPrice = getUnitPrice(quantity, variant, cartDiscounts);
  const price = Number(unitPrice * quantity).toFixed(2);
  const discountPercent = !isEmpty(cartDiscounts)
    ? cartDiscounts[0]?.percent
    : 0;
  const { discount: savePercent } = calculatePrices(variant, quantity);

  const selectBtnClasses = ["package-item__add"];
  const basePrice = variant.price_thresholds[0].price;

  const handlePackageSelect = (e: any) => {
    e.preventDefault();
    onQuantitySelect(quantity);
    onCloseInput(false);
  };

  if (Number(quantity) === Number(currentQuantity) && !customInputFieldOpen) {
    selectBtnClasses.push("package-item__add--selected");
  }

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      className={`package-item__row ${selectBtnClasses.join(" ")}`}
      href="#"
      onClick={(e: any) => handlePackageSelect(e)}
    >
      <div className="package-item__radio"></div>
      <p className="package-item__quantity">
        {quantity === "Other"
          ? "Other"
          : `${quantity} ${variant.unit}${quantity > 1 && "s"}`}

        {variant.popular_qty === quantity && <span className="popular-text">Popular</span>}
      </p>
      {showPrice && (
        <p className="package-item__price">
          <span className={`${index !== 0 ? "base-price" : ""}`}>
            {(index === 0 || savePercent > 0 || discountPercent > 0) && (
              <>
                <span className="price">
                  ${Number(quantity * basePrice).toFixed(2)}
                </span>{" "}
              </>
            )}
            {index !== 0 && <span className="discounted-price">${price}</span>}
          </span>
          {(savePercent > 0 || discountPercent > 0) && (
            <span className="package-item__save-percent mt-1">
              You save{" "}
              {discountPercent > savePercent
                ? Number(discountPercent).toFixed()
                : savePercent}
              %
            </span>
          )}
        </p>
      )}
    </a>
  );
};

export default PackageItem;
