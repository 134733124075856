import React from "react";
import { orderBy } from "lodash";

type Props = {
  addedAllergies: any;
  setActiveAllergy: any;
  setShowAllergyModal: any;
};
const AddedAllergies = ({
  addedAllergies,
  setActiveAllergy,
  setShowAllergyModal,
}: Props) => {
  return (
    <div className="added-allergies">
      <ul>
        {orderBy(addedAllergies, "local_id")?.map((allergy, idx) => (
          <li key={idx}>
            <button
              className="edit-allergy-modal"
              onClick={() => {
                setActiveAllergy(allergy);
                setShowAllergyModal(true);
              }}
            >
              <span className="d-flex justify-content-between">
                <span className="font-weight-bold">{allergy.name}</span>
                <span className="edit-text">Edit</span>
              </span>
              <span className="font-weight-light details">
                {allergy?.description && (
                  <p className="mb-0">{allergy.description}</p>
                )}
              </span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AddedAllergies;
