import React, { useContext } from "react";
import { ReactSVG } from "react-svg";
import { AuthContext } from "Contexts/AuthContext";
import VisitCompletedIcon from "../../../../assets/images/account/green-checkmark-circle.svg";
import backButtonIcon from "../../../../assets/images/account/back-button-arrow.svg";
import Button from "../../../../components/Button";

const CompletedRejected = ({ removeActiveVisit }: any) => {
  const { showChatModal, setShowChatModal } = useContext(AuthContext);

  return (
    <>
      <div className="active-visit-heading d-flex">
        <button
          className="back-button mb-4"
          onClick={() => removeActiveVisit()}
        >
          <ReactSVG src={backButtonIcon} />
        </button>
        <h3 className="mb-4">Further In-Person Health Evaluation is Needed</h3>
      </div>
      <div className="account-card">
        <div className="row visitDetailStatusRow">
          <div className="col d-flex flex-column justify-content-start pt-1 visitDetailStatusCol">
            <ReactSVG src={VisitCompletedIcon} />
          </div>
          <div className="col d-flex flex-column justify-content-center">
            <p className="mb-2 completion-text">
              After reviewing the health information you provided, a licensed
              clinician has determined a diagnosis for your requested health
              condition is not possible through our services
            </p>
            <p className="mb-0 completion-text">
              We recommend you schedule an in-person appointment with a provider
              to assist you further evaluation
            </p>
          </div>
        </div>
        <div className="row">
          <div className="bg-light-blue d-flex align-items-center w-100 p-3 m-3 mt-4 br-5px">
            <Button
              classes="btn-outline btn-outline-white medium ml-0 mr-2 text-initial"
              size="medium"
              onClick={() => setShowChatModal(!showChatModal)}
            >
              Review treatment plan
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompletedRejected;
