import React, { useState, useEffect, useRef, useContext } from "react";

import Translator from "components/Translator";
import IdPhotoPath from "assets/images/home/id-upload-three.png";
import TroubleModal from "../Shared/Modals/TroubleModal";
import CameraInstructions from "../Shared/Modals/CameraInstructions";
import WhyNeedPhoto from "./WhyNeedPhoto";
import LockIcon from "assets/images/icons/lock-icon.svg";
import CameraIcon from "assets/images/icons/camera-icon.svg";
import { ReactSVG } from "react-svg";
import CameraModal from "./CameraModal";
import Webcam from "react-webcam";
import { getCameraPermissions } from "../Shared/Helpers/PhotoUploadHelpers";
import PermissionsRequiredModal from "../Shared/Modals/PermissionsRequiredModal";
import { detect } from "detect-browser";
import { isMobile } from "react-device-detect";
import useLatestCart from "hooks/useLatestCart";
import useCurrentUser from "hooks/useCurrentUser";
import { CartContext } from "Contexts/CartContext";
import { FetchIdFromPockedmedRequest, UploadVisitIdRequest } from "apis";
import { isEmpty } from "lodash";
import Button from "components/Button";

import "./styles.scss";
import SkipAndSubmitModal from "../Shared/Modals/SkipAndSubmitModal";
import { Link } from "react-router-dom";
import { AuthContext } from "Contexts/AuthContext";
import Loader from "../Shared/Loader";

const VisitIdUpload = () => {
  const user = useCurrentUser();
  const cart = useLatestCart();
  const { IdUploadNextStep } = useContext(CartContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [errors, setErrors] = useState<[]>([]);
  const [openCamera, setOpenCamera] = useState<boolean>(false);
  const [cameraInstructions, setCameraInstructions] = useState<boolean>(false);
  const [imgSrc, setImgSrc] = useState<any>(undefined);
  const [file, setFile] = useState<any>(undefined);
  const [nextStep, setNextStep] = useState<boolean>(false);
  const [mobileCam, setMobileCam] = useState<boolean>(false);
  const [permModal, setPermModal] = useState<boolean>(false);
  const [fbWebViewDetected, setFbWebViewDetected] = useState<boolean>(false);
  const [showSkipAndSubmitModal, setSkipAndSubmitModal] =
    useState<boolean>(false);

  const { setLoading } = useContext(AuthContext);

  let videoRef = useRef(null);
  const webcamRef: any = React.useRef(null);
  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "environment",
  };

  useEffect(() => {
    if (!isEmpty(user) && !isEmpty(cart)) {
      FetchIdFromPockedmedRequest(cart?.id, user?.pocketmed_uuid)
        .then((res: any) => {
          setImgSrc(res.data.id_card_url);
          setNextStep(true);
        })
        .catch((err: any) => {
          setErrors(err.response.data.error);
          console.log(errors);
        })
        .finally(() => {
          handleMobileCam();
          setIsProcessing(false);
          detectFbWebView();
          setIsLoading(false);
          setLoading(false);
        });
    }
  }, [user, cart, fbWebViewDetected]);

  const detectFbWebView = () => {
    const browser = detect();

    if (browser?.name === "chromium-webview") {
      setFbWebViewDetected(true);
    }
  };

  const handleMobileCam = () => {
    if (window.innerWidth <= 800) {
      setMobileCam(true);
    }
  };

  const getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: true,
      })
      .then((stream) => {
        window.innerWidth > 800 && setOpenCamera(true);
        let video: any = videoRef.current;
        video.srcObject = stream;
        video.play();
      })
      .catch((err) => {
        if (err.name === "NotAllowedError") {
          handleCloseCameraModal();
          setCameraInstructions(true);
        }
        setMobileCam(true);
        setErrors(err?.response?.data?.error);
        console.log(err?.message);
      })
      .finally(() => {
        setPermModal(false);
      });
  };

  const handleCloseCameraModal = () => {
    setOpenCamera(false);
  };

  useEffect(() => {
    if (permModal) {
      getVideo();
    }
  }, [permModal]);

  const handleOpenCameraModal = () => {
    // Need to check for perms here
    getCameraPermissions(setPermModal);
  };

  const handleCloseCameraInstructions = () => {
    setCameraInstructions(false);
  };

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    setFile(imageSrc);
    handleCloseCameraModal();
    setNextStep(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webcamRef, nextStep]);

  const handleIdUploadFile = (e: any) => {
    const temp_path = URL.createObjectURL(e.target.files[0]);
    setFile(e.target.files[0]);
    setImgSrc(temp_path);
    setNextStep(false);
  };

  const handleImageUploadRequest = () => {
    const formData: any = new FormData();
    formData.append("photo", file);

    // Disable the button
    setIsProcessing(true);

    if (nextStep) {
      IdUploadNextStep(cart?.id, false);
    } else if (!isEmpty(cart)) {
      UploadVisitIdRequest(cart?.id, user?.pocketmed_uuid, formData)
        .then((res: any) => {
          setImgSrc(res.data.id_card_url);
          setLoading(true);
          IdUploadNextStep(cart?.id, false);
        })
        .catch((err: any) => {
          setErrors(err.response.data.error);
        })
        .finally(() => {
          setNextStep(true);
        });
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="mt-5">
          <Loader />
        </div>
      ) : (
        <>
          <div className="container checkout-page product-page px-0">
            <div className="content false">
              <div className="row pt-0 pt-md-3 justify-content-center">
                <div className="col-lg-6 col-12">
                  <div className="photos-content">
                    {permModal && <PermissionsRequiredModal />}
                    {!imgSrc && (
                      <>
                        <button
                          className="skip-heading mb-4"
                          onClick={() => setSkipAndSubmitModal(true)}
                        >
                          <Translator path="checkout.idUpload.skipThisStep" />
                        </button>
                        <SkipAndSubmitModal
                          show={showSkipAndSubmitModal}
                          setShow={setSkipAndSubmitModal}
                          view="idUpload"
                          cartId={cart?.id}
                        />
                        <div className="photo-headings lines-adjustment">
                          <h4>
                            <Translator path="checkout.idUpload.uploadAPhoto" />
                          </h4>
                          <p>
                            <Translator path="checkout.idUpload.telemedicineRegulations" />
                          </p>
                        </div>
                        <div className="id-image d-flex flex-column align-items-center">
                          <p className="secondary-photo-heading lines-adjustment">
                            <Translator path="checkout.idUpload.dontFeelComfortable" />
                          </p>
                          <div>
                            <span className="line-one"></span>
                            <span className="line-two">
                              <span className="arrow left position-absolute"></span>
                            </span>
                          </div>
                          {/* <ReactSVG src={IdPhotoPath} /> */}
                          <img src={IdPhotoPath} alt="Upload Your ID Card" />
                        </div>
                        <p className="note-text">
                          <Translator path="checkout.idUpload.note" />
                        </p>
                        <Link
                          to={""}
                          data-toggle="modal"
                          data-target="#whyNeedPhotoModal"
                          className="why-do-you lines-adjustment"
                        >
                          {" "}
                          {window.innerWidth <= 768 ? (
                            <Translator path="checkout.idUpload.whyDoYou" />
                          ) : (
                            <Translator path="checkout.idUpload.whyDoYouNeedAPhoto" />
                          )}
                        </Link>
                        <div className="action-buttons d-flex">
                          <div className="upload-wrapper mb-2 w-100">
                            <Button
                              classes="select-photo-btn ml-0 mr-5"
                              fullWidth
                              color="secondary"
                            >
                              <Translator path="checkout.idUpload.selectPhotoButtonText" />
                            </Button>
                            <input
                              type="file"
                              id="upload-id-image"
                              name="upload-id-image"
                              accept="image/png, image/jpeg"
                              onChange={(e: any) => handleIdUploadFile(e)}
                            />
                          </div>
                          {!fbWebViewDetected && (
                            <>
                              <div className="take-wrapper w-100 ml-md-2">
                                {isMobile ? (
                                  <label
                                    className="btn btn-secondary mr-0"
                                    htmlFor="mobile-image"
                                  >
                                    <input
                                      className="mobile-image-input"
                                      accept="image/*"
                                      id="mobile-image"
                                      type="file"
                                      capture="environment"
                                      onChange={(e: any) =>
                                        handleIdUploadFile(e)
                                      }
                                    />
                                    <Translator path="checkout.idUpload.takePhoto" />
                                  </label>
                                ) : (
                                  <>
                                    <Button
                                      classes="take-photo-btn mr-0"
                                      fullWidth
                                      color="secondary"
                                      data-toggle="modal"
                                      data-target="#cameraModal"
                                      onClick={() => handleOpenCameraModal()}
                                    >
                                      <Translator path="checkout.idUpload.takePhoto" />
                                    </Button>
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    )}

                    {imgSrc && (
                      <div className="photo-review">
                        <h4>
                          <Translator path="checkout.idUpload.reviewAndConfirm" />
                        </h4>
                        <p>
                          <Translator path="checkout.idUpload.telemedicineRegulations" />
                          <strong>
                            {" "}
                            <Translator path="checkout.idUpload.driverLiscense" />{" "}
                          </strong>
                        </p>
                        <a
                          className="mb-5"
                          href="#"
                          data-toggle="modal"
                          data-target="#whyNeedPhotoModal"
                        >
                          <Translator path="checkout.idUpload.whyYouNeedThis" />
                        </a>
                        <div className="uploaded-image no-mouseflow mouseflow-hidden">
                          <img
                            className="no-mouseflow mouseflow-hidden"
                            alt=""
                            src={imgSrc}
                          />
                          <button
                            className="btn btn-ghost retake-btn"
                            onClick={() => setImgSrc(null)}
                          >
                            <ReactSVG src={CameraIcon} />
                            <span>
                              <Translator path="checkout.idUpload.reTake" />
                            </span>
                          </button>
                        </div>
                        <div className="ensure-tex">
                          <strong>
                            <Translator path="checkout.idUpload.ensureThat" />
                          </strong>
                          <ul>
                            <li>
                              <Translator path="checkout.idUpload.photoIsNotBlurry" />
                            </li>
                            <li>
                              <Translator path="checkout.idUpload.idIsNotCutOff" />
                            </li>
                            <li>
                              <Translator path="checkout.idUpload.idIsGovernment" />
                            </li>
                          </ul>
                        </div>
                        <Button
                          fullWidth
                          color="secondary"
                          onClick={() => handleImageUploadRequest()}
                          disabled={isProcessing}
                        >
                          {!isProcessing
                            ? nextStep
                              ? "Continue"
                              : "Upload"
                            : "Processing..."}
                        </Button>
                      </div>
                    )}
                    <div className="trouble-link">
                      <a
                        href="#"
                        data-toggle="modal"
                        data-target="#troubleModal"
                      >
                        {window.innerWidth >= 768 ? (
                          <Translator path="checkout.idUpload.havingTroubleWithYour" />
                        ) : (
                          <Translator path="checkout.idUpload.havingTrouble" />
                        )}
                      </a>
                      <span>
                        <ReactSVG src={LockIcon} />
                        <Translator path="checkout.idUpload.128Bit" />
                      </span>
                    </div>

                    <CameraModal
                      isOpen={openCamera}
                      onClose={() => handleCloseCameraModal()}
                    >
                      <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={() => handleCloseCameraModal()}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>

                      <div className="render-camera no-mouseflow mouseflow-hidden">
                        <Webcam
                          audio={false}
                          height={500}
                          ref={webcamRef}
                          screenshotFormat="image/jpeg"
                          width={550}
                          videoConstraints={videoConstraints}
                        />
                        <Button
                          classes="medium capture-button"
                          color="secondary"
                          onClick={capture}
                        >
                          <Translator path="checkout.idUpload.capturePhoto" />
                        </Button>
                      </div>
                    </CameraModal>

                    <CameraInstructions
                      isOpen={cameraInstructions}
                      onClose={() => handleCloseCameraInstructions()}
                      data-id="cameraInstructionsModal"
                    />

                    <TroubleModal />
                    <WhyNeedPhoto />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default VisitIdUpload;
