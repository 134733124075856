import React from "react";
import ShippingAddressModal from "../Modals/ShippingAddressModal";

type NewAddressTileProp = {
  setActiveAddress: any;
  editAddress: Boolean;
  showModal: Boolean;
  setShowModal: any;
  setEditAddress: any;
  activeAddress: {};
};

const NewAddressTile = ({
  setActiveAddress,
  editAddress,
  showModal,
  setShowModal,
  setEditAddress,
  activeAddress,
}: NewAddressTileProp) => {
  const handleNew = () => {
    setActiveAddress({});
    setEditAddress(false);
    setShowModal(true);
  };

  return (
    <>
      <button
        type="button"
        className="shipping-address-item shipping-address__add-new"
        onClick={() => handleNew()}
      >
        <span className="shipping-address-item__text">
          + Add new shipping address
        </span>
      </button>
      {showModal && (
        <ShippingAddressModal
          address={activeAddress}
          show={showModal}
          setShow={setShowModal}
          editAddress={editAddress}
          setAddress={setActiveAddress}
        />
      )}
    </>
  );
};

export default NewAddressTile;
