import React from "react";
import { Modal } from "react-bootstrap";
import NewShippingAddressForm from "../ShippingAddressItems/NewShippingAddressForm";
import { AddressType } from "types";

type ShippingAddressModalProp = {
  show: any;
  setShow?: any;
  address?: AddressType;
  editAddress?: Boolean;
  setAddress: any;
};

const ShippingAddressModal = ({
  show,
  setShow,
  address,
  editAddress,
  setAddress,
}: ShippingAddressModalProp) => {
  return (
    <Modal
      show={show}
      className="shipping-address-modal no-mouseflow"
      animation={false}>
      <Modal.Header>
        <Modal.Title>Enter a new Shipping Address</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <NewShippingAddressForm
          address={address}
          handleClose={() => setShow(false)}
          editAddress={editAddress}
          setAddress={setAddress}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ShippingAddressModal;
