import React, { useState, useEffect } from "react";
import { Modal, Popover } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import Button from "components/Button";
import TooltipIcon from "assets/images/icons/tooltip_icon.svg";
import "./styles.scss";

type Props = {
  med: any;
  showMedModal: any;
  setShowMedModal: any;
  addedMeds: any;
  setAddedMeds: any;
  condition: any;
  questionId: any;
};

const MedModal = ({
  med,
  showMedModal,
  setShowMedModal,
  addedMeds,
  setAddedMeds,
  condition,
  questionId,
}: Props) => {
  const [medName, setMedName] = useState(med?.name);
  const [medStrength, setMedStrength] = useState(med?.strength);
  const [medDuration, setMedDuration] = useState(med?.duration);
  const [showStrengthTooltip, setStrengthShowTooltip] = useState(false);
  const [showFrequencyTooltip, setFrequencyShowTooltip] = useState(false);

  useEffect(() => {
    let defaultName = addedMeds?.filter(
      (m: any) => m?.local_id === med?.local_id
    )[0]?.name;
    let defaultStrength = addedMeds?.filter(
      (m: any) => m?.local_id === med?.local_id
    )[0]?.strength;
    let defaultDuration = addedMeds?.filter(
      (m: any) => m?.local_id === med?.local_id
    )[0]?.duration;

    defaultName = defaultName !== med?.name ? defaultName : med?.name;
    defaultStrength =
      defaultStrength !== med?.strength ? defaultStrength : med?.strength;
    defaultDuration =
      defaultDuration !== med?.duration ? defaultDuration : med?.duration;

    setMedName(defaultName);
    setMedStrength(defaultStrength);
    setMedDuration(defaultDuration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMedModal]);

  const onSave = () => {
    const updatedMed = {
      name: medName,
      strength: medStrength,
      duration: medDuration,
      condition,
      question_id: questionId,
      lexi_drug_syn_id: med?.lexi_drug_syn_id,
      lexi_gen_drug_id: med?.lexi_gen_drug_id,
      routed_dose_form_drug_id: med?.routed_dose_form_drug_id,
      local_id: med?.local_id,
    };
    const otherMeds = addedMeds.filter(
      (m: any) => m?.local_id !== med?.local_id
    );

    setAddedMeds([...otherMeds, updatedMed]);
    setShowMedModal(false);
  };

  const onRemove = (local_id: any) => {
    const otherMeds = addedMeds?.filter((m: any) => m?.local_id !== local_id);

    setAddedMeds(otherMeds);
    setShowMedModal(false);
  };

  return (
    <Modal
      show={showMedModal}
      onHide={() => setShowMedModal(false)}
      aria-labelledby="info-dialog__title"
      className="info-dialog med-dialog"
      centered
      fullWidth={true}>
      <Modal.Header className="form-dialog__title" style={{ border: 0 }}>
        Edit Medication Details
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={() => setShowMedModal(false)}>
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>

      <Modal.Body>
        <div className="med-form">
          <div className="d-flex justify-content-between">
            <div className="form-group w-100 mr-3">
              <label htmlFor="med-name">Name</label>
              <input
                type="text"
                id="med-name"
                value={medName || ""}
                onChange={(e: any) => setMedName(e.target.value)}
              />
            </div>

            <div className="form-group w-100">
              <label htmlFor="med-strength">
                Strength
                <button
                  onClick={() => setStrengthShowTooltip(true)}
                  onMouseOver={() => setStrengthShowTooltip(true)}
                  onMouseOut={() => setStrengthShowTooltip(false)}
                  className="tool-tip-button position-relative">
                  <ReactSVG className="tooltip-icon" src={TooltipIcon} />
                  {showStrengthTooltip && (
                    <Popover
                      body
                      placement={"left-start"}
                      className="modal-tooltip">
                      <p>
                        Strength is usually a number that is written to the
                        right of the medication name on your prescription
                        bottle.
                      </p>
                    </Popover>
                  )}
                </button>
              </label>
              <input
                type="text"
                id="med-strength"
                value={medStrength || ""}
                onChange={(e: any) => setMedStrength(e.target.value)}
              />
            </div>
          </div>

          <div className="d-flex justify-content-between">
            <div className="form-group w-100 mr-3">
              <label htmlFor="med-duration">
                Duration
                <button
                  // onClick={() => setFrequencyShowTooltip(true)}
                  // onMouseOver={() => setFrequencyShowTooltip(true)}
                  // onMouseOut={() => setFrequencyShowTooltip(false)}
                  className="tool-tip-button position-relative">
                  <ReactSVG className="tooltip-icon d-none" src={TooltipIcon} />
                  {showFrequencyTooltip && (
                    <Popover
                      body
                      placement={"left-start"}
                      className="modal-tooltip">
                      <p>
                        Frequency and Directions of Use are how much and how
                        often the medication is taken.
                      </p>
                      <p className="my-2">For example:</p>
                      <ul className="pl-4 mb-3 tooltip-list">
                        <li>
                          2 tablets taken in the morning and in the evening
                        </li>
                        <li>1 tablet taken once every week</li>
                        <li>5 ml taken 4 times a day</li>
                      </ul>
                      <p>
                        This is seen in the center of the prescription label on
                        the medication bottle.
                      </p>
                    </Popover>
                  )}
                </button>
              </label>
              <input
                type="text"
                id="med-duration"
                value={medDuration || ""}
                onChange={(e: any) => setMedDuration(e.target.value)}
              />
            </div>

            <div className="form-group w-100">
              <label htmlFor="med-condition">Condition</label>
              <input
                type="text"
                id="med-condition"
                value={condition || ""}
                readOnly
              />
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="px-4" style={{ border: 0, flexWrap: "nowrap" }}>
        <Button
          classes="inverted w-100"
          fullWidth={true}
          color="secondary"
          onClick={() => onRemove(med?.local_id)}>
          Remove this med
        </Button>
        <Button
          classes="w-100"
          fullWidth={true}
          color="secondary"
          onClick={onSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MedModal;
