import React from "react";
import { isEmpty } from "lodash";
import useCurrentUser from "hooks/useCurrentUser";
import useCurrentOrder from "hooks/useCurrentOrder";
import OrderEditor from "./OrderEditor";

import "../Checkout/OrderVerification/styles.scss";
import "./styles.scss";

const OrderConfirmation = () => {
  const user = useCurrentUser();
  const order = useCurrentOrder();

  if (isEmpty(order) || isEmpty(user)) return <></>;

  return (
    <div className="container checkout-page order-page cart-page product-page order-confirmation-content">
      <div className="content px-0 px-md-2">
        <div className="row justify-content-center pt-0 pt-md-3 flex-md-row">
          <div className="col-lg-8 col-md-8">
            <h3 className="mb-4">Almost Done!</h3>
            <div className="order-verification">
              <section className="cart product-card no-styling minimal mb-0">
                <OrderEditor order={order} user={user} />
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderConfirmation;
