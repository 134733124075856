import React from 'react';

import addNewCard from "assets/images/checkout/add_new_card.svg";

type Props = {
  setShowForm: (value: boolean) => void;
}

const NewCardItem = ({ setShowForm }: Props) => {
  return(
    <li className="credit-card-item" onMouseDown={() => setShowForm(true)}>
      <img src={addNewCard} alt="" className='addNewCard'/>
      <label className="d-flex w-100">Add Payment Method</label>
    </li>
  )
}

export default NewCardItem;
