import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { capitalize, isEmpty } from "lodash";
import dayjs from "dayjs";
import { UserType } from "Contexts/AuthContext";
import { CartType, OrderType } from "Contexts/CartContext";
import { ReactSVG } from "react-svg";
import uspsSvg from "assets/images/checkout/usps.svg";

type PatientInfoCardProps = {
  cart?: CartType;
  order?: OrderType;
  user: UserType;
  showChangeLinks?: boolean;
};

const PatientInfoCard = ({
  cart,
  user,
  order,
  showChangeLinks = false,
}: PatientInfoCardProps) => {
  const data: any = !isEmpty(order) ? order : cart;
  const navigate = useNavigate();

  const handleChangeShipping = () => {
    if (cart?.order_id || order?.id) {
      navigate("/edit/shipping", {
        state: { order_view: true },
      });
    } else {
      navigate("/edit/shipping");
    }
  };

  const handleChangePatientInfo = () => {
    if (cart?.order_id) {
      navigate("/edit/patient-info", {
        state: { order_view: true },
      });
    } else {
      navigate("/edit/patient-info");
    }
  };

  return (
    <div className="card-info">
      <div className="card-info__body">
        <div className="patient-information card-info-bordered mb-4">
          <div className="card-info__header mt-0">
            <h6>Patient Information</h6>
            {showChangeLinks && (
              <button onClick={() => handleChangePatientInfo()}>Change</button>
            )}
          </div>
          <ul className="no-mouseflow mouseflow-hidden mb-0">
            <li className="no-mouseflow mouseflow-hidden">{user.fullname}</li>
            <li className="no-mouseflow mouseflow-hidden">
              {dayjs(user.date_of_birth).format("MMMM D, YYYY")}
            </li>
            <li className="no-mouseflow mouseflow-hidden">
              {capitalize(user.gender)}
            </li>
            <li className="no-mouseflow mouseflow-hidden">
              {user.mobile_phone}
            </li>
          </ul>
        </div>
        <div className="shipping-address card-info-bordered">
          <div className="card-info__header mt-0">
            <h6>Shipping Address</h6>
            <button onClick={() => handleChangeShipping()}>Change</button>
          </div>
          <ul className="no-mouseflow mouseflow-hidden mb-0">
            <li className="no-mouseflow mouseflow-hidden">
              {data?.shipping_address.street_1}
            </li>
            <li>
              <span className="no-mouseflow mouseflow-hidden">
                {data?.shipping_address.street_2}
              </span>{" "}
              {`${data?.shipping_address.city}, ${data?.shipping_address.state}`}
            </li>
            <li className="no-mouseflow mouseflow-hidden">
              {data?.shipping_address.zip}
            </li>
          </ul>
        </div>
        <div className="checkout-delivery-option">
          <div className="option-info">
            <div className="d-flex">
              <ReactSVG src={uspsSvg} className="delivery-image" />
              <div className="d-flex flex-column">
                <span>{data?.delivery_option.label}</span>
                <span className="delivery-days">
                  {data?.delivery_option.delivery_days_label}
                </span>
              </div>
            </div>
            <div className="delivery-actions">
              {!isEmpty(data?.final_delivery_price) &&
              data?.final_delivery_price > 0 ? (
                <span>${Number(data?.final_delivery_price).toFixed(2)}</span>
              ) : (
                <span className="free-text">Free</span>
              )}

              <button onClick={() => handleChangeShipping()}>Change</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientInfoCard;
