import React from "react";
import Footer from "components/Footer";

const TermsOfUse = () => {
  return (
    <>
      <div className="content mt-5 py-5">
        <p className="text-center">
          <strong>
            <u>TERMS OF USE</u>
          </strong>
        </p>
        <p className="text-center">Last Updated on July 18, 2016.</p>
        <p>&nbsp;</p>
        <p>
          You and Sildenafil.com, LLC (“Sildenafil.com,” “we,” or “us”), the
          operator of Sildenafil.com.com (the “Site and/or Services”) agree to
          the following terms and conditions constituting this agreement
          (“Agreement”). &nbsp;Please read these Terms of Use thoroughly, to
          ensure a proper understanding of your rights and responsibilities in
          connection with the Site and/or Services, as both a visitor and
          registered user.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>1.</strong>
          <strong>Sildenafil.com.</strong>
        </p>
        <p>
          You are being provided a limited, revocable, non-transferrable,
          non-sublicensable and non-exclusive right to the limited access and
          use of the Site and/or Services.&nbsp; We may terminate the foregoing
          License at any time, with or without cause. By accessing and using the
          Site and/or Services, you are indicating your acknowledgement and
          acceptance of these Terms of Use and all other policies, guidelines
          and standards referenced herein. &nbsp;Please consult these Terms of
          Use regularly as they may change from time-to-time. &nbsp;All of the
          options and opportunities offered on the Site are collectively
          referred to herein as the "Services". You must sign up and authorize
          the use and disclosure of your personal and health information for
          purposes of allowing us to provide the Services and as otherwise
          disclosed in our Privacy Policy in order to actively participate in
          the Services.&nbsp;
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>2. Content For Informational Purposes Only</strong>
        </p>
        <p>
          The Information that you obtain or receive from Sildenafil.com, or its
          employees, contractors, partners, sponsors, advertisers, licensors or
          otherwise on the Site and/or Services is for informational purposes
          only. Sildenafil.com does not recommend or endorse any specific
          prescription drug or pharmacy that may be mentioned on the Site.
          Reliance on any information provided by Sildenafil.com, or its
          employees, contractors, partners, sponsors, advertisers, licensors or
          others is solely at your own risk.
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>
          NO INFORMATION PROVIDED ON THE SITE AND/OR THROUGH THE SERVICES AND IN
          ANY OTHER COMMUNICATIONS FROM OR PROVIDED THROUGH SILDENAFIL.COM IS OR
          SHALL BE CONSTRUED AS A SUBSTITUTE FOR, NOR DOES IT REPLACE,
          PROFESSIONAL MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT. &nbsp;DO NOT USE
          THIS SITE IN THE EVENT OF AN EMERGENCY OR TO OBTAIN EMERGENCY
          SERVICES; IF YOU EXPERIENCE A MEDICAL EMERGENCY, CALL 911
          IMMEDIATELY.&nbsp; DO NOT DISREGARD, AVOID OR DELAY OBTAINING MEDICAL
          ADVICE FROM A QUALIFIED HEALTH CARE PROFESSIONAL BECAUSE OF
          INFORMATION CONTAINED ON THE SITE AND/OR SERVICES. &nbsp;YOUR USE OF
          THE INFORMATION IS SOLELY AT YOUR OWN RISK. &nbsp;NOTHING STATED OR
          POSTED ON THE SITE AND/OR SERVICES IS INTENDED TO BE THE PRACTICE OF
          MEDICINE OR THE PROVISION OF MEDICAL CARE.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>3. Availability of Information</strong>
        </p>
        <p>
          Any information sent to Sildenafil.com by Internet, e-mail, or through
          the Site is not secure and is done so on a non-confidential basis.
          Transmission of information from this Site does not create an
          attorney-client or physician-patient relationship between you and
          Sildenafil.com, nor is it intended to do so. The transmission of the
          Site, in part or in whole, and/or any communication with us via
          Internet e-mail through this site does not constitute or create an
          attorney-client or physician-patient relationship between us and any
          recipients. Some links within the Site may lead to other web-sites,
          including those operated and maintained by third parties.
          Sildenafil.com includes these links solely as a convenience to you,
          and the presence of such a link does not imply a responsibility for
          the linked site or an endorsement of the linked site, its operator, or
          its contents.
        </p>
        <p>&nbsp;</p>
        <p>
          The information provided on this site is for your convenience
          only.&nbsp; We take no responsibility for and make no representation,
          guaranty or warranty, expressed or implied, about the material
          contained on this site. &nbsp;This Site and its contents are provided
          on an "as-is", “as available”, “where is” basis and subject to all
          flaws, without warranty of any kind, either expressed or implied,
          including, but not limited to, the implied warranties of
          merchantability, fitness for a particular purpose, or
          non-infringement. Furthermore, Sildenafil.com does not wish to
          represent anyone desiring representation based upon viewing this Site
          in a state where this Site fails to comply with all laws and ethical
          rules of that state.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>4.</strong>
          <strong>Website Accuracy</strong>
        </p>
        <p>
          It is important to note, that, while Sildenafil.com makes an effort to
          verify the accuracy of the Information, the timeliness and accuracy of
          any or all of the Information is not guaranteed. &nbsp;None of the
          parties who have been involved in the preparation or publication of
          the Site and/or Services can assure you that the Information contained
          herein is in every respect accurate or complete, and they are not
          responsible for any errors or omissions or for the results obtained
          from the use of such Information.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>5. Procedures/Products/Services</strong>
        </p>
        <p>
          The procedures, products, services and devices discussed and/or
          advertised within the Site and/or Services may not be suitable for or
          applicable to You. &nbsp;We make no claims as to the effectiveness of
          any such procedures, products, services or devices. &nbsp;Any products
          and/or services represented on the Site and/or Services by
          advertisers, sponsors, and other Site and/or Services participants,
          either paid or unpaid, are presented solely for your awareness.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>6. User Accountability</strong>
        </p>
        <p>
          You are responsible for all costs and expenses of all medical care
          rendered to you by Providers profiled on the Site and/or Services and
          any and all other providers who render services to you. &nbsp;You are
          responsible for all use of the Site and/or Services and for all use of
          your personal identifying information (“Credentials”), including use
          by others to whom you have given your Credentials. &nbsp;You may use
          the Site and/or Services for lawful, non-commercial purposes only.
          &nbsp;You may not use contact information provided by our users or
          Providers, or harvest such information for the purpose of sending, or
          to facilitate the sending, of unsolicited bulk communications such as
          SPAM. &nbsp;You may not allow others to use your account to violate
          the terms of this section. &nbsp;We may terminate your membership or
          access to the Site and/or Services immediately and take other legal
          action if you or anyone using your Credentials violates these
          provisions.&nbsp; You may not use the Site and/or Services in any
          manner that could damage, disable, overburden, or impair our servers
          or networks, or interfere with any other party's use and enjoyment of
          the Site and/or Services. &nbsp;You may not attempt to gain
          unauthorized access to any of the Services, user accounts, or computer
          systems or networks, through hacking, password mining or any other
          means. Without limiting any of the foregoing, you agree that you shall
          not:
        </p>
        <p>&nbsp;</p>
        <ul className="ml-4">
          <li className="lplh-22">
            copy, modify, adapt, translate, or reverse engineer any portion of
            the Site and/or Services, its content or materials and/or the
            Services;
          </li>
          <li className="lplh-22">
            remove, copy distribute, republish, sell or exploit any copyright,
            trademark or other proprietary rights notices contained in or on the
            Site and/or Services or in or on any content or other material
            obtained via the Site and/or Services for any commercial gain or
            purpose whatsoever;
          </li>
          <li className="lplh-22">
            use any robot, spider, Site and/or Services search/retrieval
            application, or other automated device, process or means to access,
            retrieve or index any portion of the Site and/or Services ;
          </li>
          <li className="lplh-22">
            access, retrieve or index any portion of the Site and/or Services
            for purposes of constructing or populating a searchable database of
            reviews related to the health care industry or Providers;
          </li>
          <li className="lplh-22">
            reformat or frame any portion of the web pages that are part of the
            Site and/or Services ;
          </li>
          <li className="lplh-22">
            fraudulently misuse the Services by scheduling an appointment with a
            Provider which you have no intention of keeping;
          </li>
          <li className="lplh-22">
            create user accounts by automated means or under false or fraudulent
            pretenses;
          </li>
          <li className="lplh-22">
            collect or store personal data about other users in connection with
            the prohibited activities described in this paragraph; or
          </li>
          <li className="lplh-22">
            use any means, including software means, to conduct web scraping of
            any portion of the Site and/or Services, its content or materials.
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>
          <strong>7. Copyright Notice</strong>
        </p>
        <p>
          All material included on the Site and/or Services, such as text,
          graphics, logos, images, photographs, audio clips, digital downloads,
          data compilations and software (the “Content”), is the property of
          Sildenafil.com, its subsidiaries, affiliated companies and/or
          third-party licensors and is protected by United States and
          international copyright laws. &nbsp;Modification or use of the Content
          except as expressly provided in these Terms of Use violates
          Sildenafil.com’s intellectual property rights.
        </p>
        <p>&nbsp;</p>
        <p>
          The Content may not be copied, distributed, republished, uploaded,
          posted or transmitted in any way without the prior written consent of
          Sildenafil.com, except that, you may download, print, distribute and
          use pages from the Site and/or Services for your own informational,
          non-commercial purposes, but not for commercial use or general
          distribution.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>8. Notification of Change</strong>
        </p>
        <p>
          We reserve the right to change these Terms of Use and Privacy Policy
          from time-to-time as we see fit and your continued use of the Site
          and/or Services will signify your acceptance of any adjustment to
          these terms. &nbsp;If there are any changes to these Terms of Use or
          our Privacy Policy, we will announce that these changes have been made
          on our home page.&nbsp; Any changes to the Terms of Use or our Privacy
          Policy will be posted on our website 30 days prior to these changes
          taking place. &nbsp;You are therefore advised to re-read this
          statement on a regular basis.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>9</strong>.&nbsp;
          <strong>Disclaimer of Warranties</strong>
        </p>
        <p>
          THIS SITE AND THE SERVICES ARE PROVIDED BY SILDENAFIL.COM ON AN “AS
          IS” AND “AS AVAILABLE” BASIS. SILDENAFIL.COM MAKES NO REPRESENTATIONS
          OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT
          LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, TITLE, OR NON-INFRINGEMENT OF THIRD-PARTY RIGHTS
          OR INTELLECTUAL PROPERTY. &nbsp;YOU EXPRESSLY AGREE THAT YOUR USE OF
          THIS SITE AND/OR SERVICES IS AT YOUR SOLE RISK. SILDENAFIL.COM DOES
          NOT WARRANT THAT THE INFORMATION IN THIS SITE IS ACCURATE, RELIABLE,
          UP-TO-DATE OR CORRECT, THAT THIS SITE AND/OR SERVICES WILL BE
          AVAILABLE AT ANY PARTICULAR TIME OR LOCATION, OR FOR ANY PERIOD OF
          TIME, OR THAT THE SITE IS FREE OF ERRORS, DEFECTS, VIRUSES OR OTHER
          HARMFUL COMPONENTS. THE CONTENT MAY INCLUDE TECHNICAL INACCURACIES OR
          TYPOGRAPHICAL ERRORS, AND SILDENAFIL.COM MAY MAKE CHANGES OR
          IMPROVEMENTS AT ANY TIME. YOU, AND NOT SILDENAFIL.COM, ASSUME THE
          ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION IN THE
          EVENT OF ANY LOSS OR DAMAGE ARISING FROM THE USE OF THIS SITE AND/OR
          SERVICES OR ITS CONTENT. SILDENAFIL.COM MAKES NO WARRANTIES THAT YOUR
          USE OF THE CONTENT WILL NOT INFRINGE THE RIGHTS OF OTHERS AND ASSUMES
          NO LIABILITY OR RESPONSIBILITY FOR ERRORS OR OMISSIONS IN SUCH
          CONTENT.
        </p>
        <p>&nbsp;</p>
        <p>
          WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, NEITHER
          SILDENAFIL.COM NOR ANY OF ITS PRINCIPALS, EMPLOYEES, INDEPENDENT
          CONTRACTORS, AGENTS OR REPRESENTATIVES HAS MADE ANY CLAIMS,
          REPRESENTATIONS OR WARRANTIES REGARDING THE SITE AND/OR SERVICES OR
          THE RESULTS, BENEFITS, BENEFITS, ASSURANCES, ADVANTAGES, UTILITY,
          SAFETY, EFFICACY, SUCCESS OR POPULARITY OF, OR RESULTS THAT MAY BE
          ATTAINED, BY USING OR ACCESSING THE SITE AND/OR SERVICES.&nbsp;
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>10</strong>
          <strong>. General Limitation of Liability</strong>
        </p>
        <p>
          Neither Sildenafil.com, any of its affiliates, directors, officers and
          employees, nor any other party involved in creating, producing or
          delivering the Site and/or Services is liable for any direct,
          incidental, consequential, special, indirect or punitive damages
          arising out of your access to, or use of, the Site and/or Services or
          the operation of the Site and/or Services or failure of the Site to
          operate or for the Services to be available or produce any specific
          result. &nbsp;In no event shall Sildenafil.com be liable for any
          direct, indirect, special, punitive, incidental, exemplary or
          consequential, damages or any damages whatsoever, even if
          Sildenafil.com has been previously advised of the possibility of such
          damages, whether in an action in contract, negligence, or any other
          theory, arising out of or in connection with the use, inability to use
          or performance of the information, services, products and materials
          available from this Site and/or Services. &nbsp;These limitations
          shall apply notwithstanding any failure of essential purpose of any
          limited remedy. Your acceptance of this limitation of liability is an
          essential term of this agreement and the parties acknowledge that
          Sildenafil.com would not grant access to the Site and/or Services
          without your agreement to this term.
          <strong>
            If you are a California resident, you waive your rights with respect
            to California civil code section 1542, which says
            <em>
              “a general release does not extend to claims which the creditor
              does not know or suspect to exist in his favor at the time of
              executing the release, which, if known by him must have materially
              affected his settlement with the debtor
            </em>
            .”
          </strong>
          &nbsp;
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>11. Indemnification</strong>
        </p>
        <p>
          You hereby agree to hold harmless, defend and indemnify us, our
          principals, owners, members, participants, Providers, employees,
          contractors, officers, directors, managers, agents, parents, other
          affiliated companies, suppliers, successors, and assigns from all
          liabilities, claims, demands and expenses, including attorney's fees,
          that arise from or are related to (a) your access to the Site, (b)
          your use of the Services, (c) the violation of these Terms of Use, or
          of any intellectual property or other right of any person or entity,
          by you or any third party using your Credentials, or (d) any other act
          or omission of any third party. The foregoing indemnification
          obligation does not apply to liabilities, claims and expenses arising
          as a result of our own gross negligence or intentional misconduct.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>12</strong>
          <strong>. Termination</strong>
        </p>
        <p>
          Upon any violation by You or someone using your Credentials of the
          terms and conditions of this Agreement or other related policies, we
          may terminate and/or suspend your registration immediately, with or
          without notice. &nbsp;We may also cancel or suspend your registration
          for any other reason, including inactivity for an extended period.
          Sildenafil.com shall not be liable to you or any third party for any
          termination of your access to the Site and/or Services. &nbsp;You
          agree not to attempt to use the Site and/or Services after any such
          deletion, deactivation or termination (provided, in the case of
          deactivation due exclusively to your inactivity, you may re-register).
          Sections 2, 3, 4, 8, 9, 11, 12, 14 through 16 shall survive any
          termination or expiration of this Agreement.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>13</strong>
          <strong>. Entire Agreement</strong>
        </p>
        <p>
          These Terms of Use and any supplemental terms, policies, rules and
          guidelines posted on the Site and/or Services, including the Privacy
          Policy, constitute the entire agreement between you and us and
          supersede all previous written or oral agreements with respect to the
          subject matter hereof. If any part of these Terms of Use is held
          invalid or unenforceable, that portion shall be construed in a manner
          consistent with applicable law to reflect, as nearly as possible, the
          original intentions of the parties, and the remaining portions shall
          remain in full force and effect. The failure of Sildenafil.com to
          exercise or enforce any right or provision of these Terms of Use shall
          not constitute a waiver of such right or provision. &nbsp;The failure
          of either party to exercise in any respect any right provided for
          herein shall not be deemed a waiver of any further rights hereunder.
        </p>
        <p>&nbsp;</p>
        <p>
          Your accessing of the Site and/or Services indicates your
          understanding, agreement and consent to, and acceptance, of the Terms
          of Use contained herein, including the Privacy Policy.
        </p>
        <p>
          <strong>14</strong>
          <strong>. Choice of Law and Dispute Resolution</strong>
        </p>
        <p>
          In the event of a dispute under this Agreement, said dispute shall be
          submitted to an arbitrator and conducted in Orange County, California,
          except to the extent that injunctive relief is sought. The appointment
          of the arbitrator and the arbitration process shall be governed by the
          American Arbitration Association in accordance with the Commercial
          Rules of the American Arbitration Association then in effect.&nbsp;
          Except as otherwise stated herein, the arbitrator may award attorneys’
          fees and costs to the prevailing party.&nbsp; The parties shall share
          the costs of the arbitrator equally between them.&nbsp; Each party
          shall bear its own expenses of preparation for and participation in
          arbitration.&nbsp; The statute of limitations applicable to any claim
          shall be determined as if such claim were being asserted in the State
          of California, and such statute of limitations shall apply to preclude
          arbitration of any claim hereunder not brought within the applicable
          limitation period.&nbsp; Notwithstanding anything herein to the
          contrary, the Parties reserve the right to proceed at any time in any
          court having jurisdiction or by self-help to exercise or prosecute the
          following remedies, as applicable:&nbsp; (i) a preliminary injunction
          or temporary restraining order to preserve the status quo or to
          enforce a party’s rights under any provision set forth in this
          Agreement and (ii)&nbsp;when applicable, a judgment by confession of
          judgment.&nbsp; Preservation of these remedies does not limit the
          power of the arbitrator to grant similar remedies that may be
          requested by a party in a dispute.&nbsp; The agreement to arbitrate
          set forth in this Section may only be enforced by the parties to this
          Agreement and their permitted successors and assigns, shall survive
          the termination, expiration or breach of this Agreement, and shall be
          construed pursuant to and governed by the provisions of the Federal
          Arbitration Act, 9 U.S.C. §1,
          <em>et seq.</em>
        </p>
        <p>
          <strong>15</strong>
          <strong>. Assignment</strong>
        </p>
        <p>
          This Agreement may not be assigned by You without the written consent
          of Sildenafil.com.&nbsp; Sildenafil.com shall have a right to assign
          this Agreement in connection with a transfer of all or substantially
          all of Sildenafil.com’ business, whether by sale, merger or
          otherwise.&nbsp; You specifically agree that Sildenafil.com shall have
          the right to perform its obligations hereunder through any affiliate
          without Your consent.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>16</strong>
          <strong>. Eligibility</strong>
        </p>
        <p>
          You must be 18 years of age or over, or the legal age to form a
          binding contract in your jurisdiction if that age is greater than 18
          years of age, to register with us or use the Site and/or Services and
          the Services. If you are between the ages of 13 and 18 or the
          applicable legal age in your jurisdiction, you can use the Site and/or
          Services or Services only in conjunction with, and under the
          supervision of, your parent or guardian who has agreed to the Terms of
          Use. If you are under the age of 13, you may not use the Site and/or
          Services or Services, in compliance with the Children's Online Privacy
          Protection Act. If you are the parent or legal guardian of a child
          under the age of 18, you may use the Site or Services on behalf of
          such minor child. By using the Site or Services on behalf of a minor
          child, you represent and warrant that you are the parent or legal
          guardian of such child and that all references in these Terms of Use
          to “you” shall refer to such child or such other individual for whom
          you have authorization to enter into these Terms of Use on their
          behalf, and you in your capacity as the parent or legal guardian of
          such child or as the authorized party of such individual. If you do
          not qualify under these terms, do not use the Site and/or Services.
          Membership in the Services is void where prohibited by applicable law,
          and the right to access the Site and/or Services is revoked in such
          jurisdictions. By using the Site and/or the Services, you represent
          and warrant that you have the right, authority, and capacity to enter
          into these Terms of Use and to abide by all of the terms and
          conditions set forth herein. The Site and/or Services is administered
          in the U.S. and intended for U.S. users; any use outside of the U.S.
          is at the users own risk and users are responsible for compliance with
          any local laws applicable to their use of the Services or the Site.
        </p>
        <p>&nbsp;</p>
        <p>Last Updated on October 1, 2020</p>
      </div>
      <Footer />
    </>
  );
};

export default TermsOfUse;
