import React from "react";
import { ReactSVG } from "react-svg";
import Button from "../../../../components/Button";
import CancelledIcon from "../../../../assets/images/account/cancelled-icon.svg";
import backButtonIcon from "../../../../assets/images/account/back-button-arrow.svg";

const Refunded = ({ removeActiveVisit, startNewVisit }: any) => {
  return (
    <>
      <div className="active-visit-heading d-flex">
        <button
          className="back-button mb-4"
          onClick={() => removeActiveVisit()}
        >
          <ReactSVG src={backButtonIcon} />
        </button>
        <h3 className="mb-4">Visit Refunded</h3>
      </div>
      <div className="account-card">
        <div className="row visitDetailStatusRow">
          <div className="col d-flex flex-column visitDetailStatusCol">
            <ReactSVG src={CancelledIcon} />
          </div>
          <div className="col d-flex flex-column justify-content-center">
            <p className="mb-0 completion-text">
              We have refunded the visit fee. Feel free to start a new visit
              when you need to.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="bg-light-blue d-flex align-items-center w-100 p-3 m-3 mt-4 br-5px">
            <Button
              classes="btn-outline btn-outline-white medium ml-0 mr-auto text-initial visitDetailStartNewVisit"
              size="medium"
              onClick={startNewVisit}
            >
              Start a new visit
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Refunded;
