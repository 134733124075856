import React, { useEffect, useState } from "react";
import { filter, find, isEmpty } from "lodash";
import Modal from "react-bootstrap/Modal";
import ReactMarkdown from "react-markdown";
import { ReactSVG } from "react-svg";
import remarkGfm from "remark-gfm";
import { GetProductDetailsRequest } from "apis";
import drugCross from "assets/images/icons/drug-detail-cross.svg";
import "./styles.scss";

type DrugInformationProps = {
  show: boolean;
  setShow: any;
  productName?: string;
};

const DrugInformation = ({
  show,
  setShow,
  productName,
}: DrugInformationProps) => {
  const [activeProduct, setActiveProduct] = useState<any>("");
  const [selectedTab, setSelectedTab] = useState<any>("overview");
  const isMarketing = localStorage.getItem("isMarketing") || "landing";
  const MODAL_TABS = [
    "Overview",
    "Description",
    "Usage",
    "Side Effects",
    "Drug Interactions",
    "Storage",
    "Other info",
  ];

  const snakeCase = (str: string) => {
    return str
      .replace(/\W+/g, " ")
      .split(/ |\B(?=[A-Z])/)
      .map((word) => word.toLowerCase())
      .join("_");
  };

  const formattedProductName = (productName: string) => {
    if (productName === "Sildenafil") {
      return "Sildenafil (Generic Viagra)";
    } else {
      return "Tadalafil (Generic Cialis)";
    }
  };

  useEffect(() => {
    GetProductDetailsRequest(isMarketing)
      .then((res: any) => {
        if (isEmpty(filter(res.data.products, { name: productName }))) return;
        setActiveProduct(find(res.data.products, { name: productName }));
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, [productName]);

  useEffect(() => {
    if (!show) {
      setSelectedTab(snakeCase("overview"));
    }
  }, [show]);

  if (isEmpty(activeProduct)) return <></>;

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      dialogClassName="drug-info-modal view-detail-modal"
      aria-labelledby="example-custom-modal-styling-title"
      animation={false}
      scrollable
    >
      <div className="product-detail-modal">
        <Modal.Header className="p-1">
          <div className="w-100">
            <div>
              <button
                onClick={() => setShow(false)}
                className="close-button"
                style={{ width: "40px" }}
              >
                <ReactSVG src={drugCross} />
              </button>
            </div>
            <Modal.Title id="example-custom-modal-styling-title">
              <h5
                className="modal-title title-text"
                id="drugInfoModalLongTitle"
              >
                {formattedProductName(productName || "")}
              </h5>
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body className=" detail-modal-body modal-body p-0">
          <div className="topics ">
            <ul className="topic-tabs nav-tabs d-flex flex-row">
              {MODAL_TABS.map((tab, key: number) => {
                return (
                  <li className="nav-item" key={key}>
                    <span
                      className={`nav-link ${
                        snakeCase(tab) === selectedTab && "active"
                      } sub-heading`}
                      onClick={() => setSelectedTab(snakeCase(tab))}
                    >
                      {tab}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="details p-2 markdown-container">
            <ReactMarkdown
              children={activeProduct[selectedTab]}
              remarkPlugins={[remarkGfm]}
            />
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default DrugInformation;
