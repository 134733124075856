import { find, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";

type DropdownOptionsProps = {
  question: any;
  selectedAnswers: [];
  setSelectedAnswers: any;
  option?: any;
  questionType: any;
  setContinueButtonDisable: any;
};

const DropdownOptions = ({
  selectedAnswers,
  setSelectedAnswers,
  question,
  questionType,
  setContinueButtonDisable,
}: DropdownOptionsProps) => {
  const [selectedDosageValue, setSelectedDosageValue] =
    useState<any>(undefined);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!isEmpty(selectedAnswers) && !isEmpty(question?.answer_options)) {
      setSelectedDosageValue(
        find(question.answer_options, (opt: any) =>
          find(selectedAnswers, ["id", opt.id])
        )
      );
    }
  }, [selectedDosageValue, question]);

  const filterAnswersDifferentFromLabel = (label: any) => {
    return selectedAnswers.filter((sa: any) => sa.question_label !== label);
  };

  // For handling radio button interactions
  const handleOptionChange = (optionId: string) => {
    const selectedOption = find(question.answer_options, [
      "id",
      Number(optionId),
    ]);

    const newAnswers = [
      ...filterAnswersDifferentFromLabel(selectedOption.question_label),
      selectedOption,
    ];
    setSelectedAnswers(newAnswers);
    setSelectedDosageValue(selectedOption.id);
  };

  return (
    <>
      {!isEmpty(question?.answer_options) && (
        <div className="answer-option-wrapper dosage-dropdown-wrapper">
          <label htmlFor="dropdown-options" onClick={() => setIsOpen(true)}>
            <div className="selectdropdown-options__input">
              <input
                id="dropdown-options"
                className={`input ${isOpen ? "touched" : ""}`}
                name="dropdown-options"
                value={
                  selectedDosageValue?.label ||
                  "Please select the correct strength"
                }
                onBlur={() => setIsOpen(false)}
                readOnly
              />
              <div className="select-dropdown">
                {question?.answer_options?.map((option: any) => (
                  <button
                    className="generic"
                    onMouseDown={(e: any) => {
                      setSelectedDosageValue(option?.id);
                      setIsOpen(false);
                      handleOptionChange(option?.id);
                    }}
                    data-selected={option?.id}
                    data-name={option?.label}
                    key={option?.id}
                  >
                    {option?.label}
                  </button>
                ))}
              </div>
            </div>
          </label>
        </div>
      )}
    </>
  );
};

export default DropdownOptions;
