import React, { useState, useEffect } from "react";
import { intersection, isEmpty } from "lodash";
import { FetchStatesRequest } from "../../apis";

type StateInputProps = {
  cart?: any;
  user?: any;
  handleStateChange?: any;
  showStateSuggestion?: any;
  value?: string;
  smartyState: string;
  setError: any;
};

type StateType = { name: string; code: string };

const StateInput = ({
  handleStateChange,
  value,
  smartyState,
  setError,
}: StateInputProps) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [textValue, setTextValue] = useState<string | undefined>(value || "");
  const [states, setStates] = useState<any>(undefined);
  const [suggestedItems, setSuggestedItems] = useState(states);

  useEffect(() => {
    setTextValue(smartyState || value);
  }, [smartyState]);

  useEffect(() => {
    FetchStatesRequest()
      .then((response) => {
        setStates(response.data.states);
      })
      .catch((error: string) => console.log(error));
  }, []);

  if (isEmpty(states)) return <></>;

  const suggestStates = (text: any) => {
    const stateCodeMatch = states?.filter((state: StateType) =>
      state.code.toLowerCase().includes(text.toLowerCase())
    );
    const stateNameMatch = states?.filter((state: StateType) =>
      state.name.toLowerCase().includes(text.toLowerCase())
    );
    let finalMatch;

    if (stateCodeMatch?.length && !stateNameMatch?.length) {
      finalMatch = stateCodeMatch;
    } else if (stateNameMatch?.length && !stateCodeMatch?.length) {
      finalMatch = stateNameMatch;
    } else {
      finalMatch = intersection(stateCodeMatch, stateNameMatch);
    }

    setSuggestedItems(finalMatch);
  };

  let input = (
    <div className={`text-field-wrapper position-relative`}>
      <div className={`input-group row mx-0 flex-row`}>
        <div className="input__icon state"></div>
        <input
          className={`input with-icon state-input no-mouseflow mosueflow-hidden text-uppercase`}
          onFocus={() => setShowDropdown(true)}
          onBlur={() => setShowDropdown(false)}
          autoComplete="off"
          onChange={(e: any) => {
            if (e.target.value.length > 2) return;
            setTextValue(e.target.value.toUpperCase());
            suggestStates(e.target.value);
            handleStateChange(e.target.value);
            setError("");
          }}
          value={textValue}
        />
      </div>

      {Boolean(suggestedItems?.length) && showDropdown && (
        <ul className="states-list-dropdown">
          {suggestedItems?.map((state: StateType, i: number) => {
            return (
              <li key={i}>
                <button
                  onMouseDown={() => {
                    handleStateChange(state.code);
                    setTextValue(state.code);
                    setShowDropdown(false);
                    setSuggestedItems(states);
                  }}
                >
                  {state.name}
                </button>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );

  return input;
};

export default StateInput;
