import React from "react";
import Button from "components/Button";

type NewShippingAddressItemProps = {
  address: {
    id: any;
    first_name: any;
    last_name: any;
    street_1: any;
    street_2: any;
    city: any;
    state: any;
    zip: any;
    phone: any;
    is_valid: any;
    has_no_active_carts: boolean;
  };
  onEdit?: any;
  onDelete?: any;
  onSelect?: any;
  selectedAddressId?: any;
  index: any;
};

const ShippingAddressItem = ({
  address: {
    id,
    first_name,
    last_name,
    street_1,
    street_2,
    city,
    state,
    zip,
    phone,
    is_valid,
    has_no_active_carts,
  },
  onEdit,
  onDelete,
  selectedAddressId,
  index: number,
}: NewShippingAddressItemProps) => {
  let itemClassName = "shipping-address-item";

  if (!is_valid) itemClassName += " shipping-address-item--invalid";
  if (id === selectedAddressId)
    itemClassName += " shipping-address-item--selected";

  return (
    <div className={itemClassName}>
      {!is_valid && (
        <div className="shipping-address-item__info">
          <b>We’re not available in your state just yet.</b>
        </div>
      )}
      <div className="shipping-address-item__fullname no-mouseflow">
        {`${first_name} ${last_name}`}
      </div>
      <div className="shipping-address-item__street no-mouseflow">
        {`${street_1} ${street_2}`}
      </div>
      <div className="shipping-address-item__region no-mouseflow">
        {`${city} ${state} ${zip}`}
      </div>
      <div className="shipping-address-item__phone no-mouseflow">{`Phone: ${phone}`}</div>
      <div className="shipping-address-item__actions">
        <Button
          size="small"
          color="ghost"
          classes="shipping-address-item__edit-btn dark mr-2"
          fullWidth
          onClick={onEdit}
        >
          Edit
        </Button>
        {has_no_active_carts && (
          <Button
            size="small"
            color="ghost"
            classes="shipping-address-item__delete-btn"
            fullWidth
            onClick={onDelete}
          >
            Delete
          </Button>
        )}
      </div>
    </div>
  );
};

export default ShippingAddressItem;
