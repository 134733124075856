import React, { useContext } from "react";

import { ReactSVG } from "react-svg";
import { AuthContext } from "Contexts/AuthContext";
import ChatMessageIcon from "../../../../assets/images/account/chat-message-icon.svg";
import VisitCompletedIcon from "../../../../assets/images/account/green-checkmark-circle.svg";
import backButtonIcon from "../../../../assets/images/account/back-button-arrow.svg";
import Button from "../../../../components/Button";

const Completed = ({ removeActiveVisit }: any) => {
  const { showChatModal, setShowChatModal } = useContext(AuthContext);
  return (
    <>
      <div className="active-visit-heading d-flex">
        <button
          className="back-button mb-4"
          onClick={() => removeActiveVisit()}
        >
          <ReactSVG src={backButtonIcon} />
        </button>
        <h3 className="mb-4">Visit Complete</h3>
      </div>
      <div className="account-card">
        <div className="row visitDetailStatusRow">
          <div className="col d-flex flex-column visitDetailStatusCol">
            <ReactSVG src={VisitCompletedIcon} />
          </div>
          <div className="col d-flex flex-column justify-content-center">
            <p className="mb-0 completion-text">
              Our doctors have finished assessing your visit. Check your
              messages to review your treatment plan, Let’s us know if you have
              questions.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="bg-light-blue completed-detail-mobile align-items-center w-100 p-3 m-3 mt-4 br-5px">
            <Button
              classes="btn-outline btn-outline-white medium ml-0 mr-2 text-initial"
              size="medium"
              onClick={() => setShowChatModal(!showChatModal)}
            >
              Review treatment plan
            </Button>

            <Button
              classes="btn-outline btn-outline-white medium text-initial d-flex"
              size="medium"
              onClick={() => setShowChatModal(!showChatModal)}
            >
              <ReactSVG src={ChatMessageIcon} className="mr-2" wrapper="span" />
              Chat with a doctor
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Completed;
