import React, { useContext, useEffect, useState } from "react";

import Hero from "./Hero";
import LabTested from "./LabTested";
import AboutEd from "./AboutEd";
import WhatIsIncluded from "./WhatIsIncluded";
import RealResults from "./RealResults";
import ViagraVsSildenafil from "./ViagraVsSildenafil";
import BottomCta from "./BottomCta";
import Process from "./Process";
import HappyPatients from "./HappyPatients";
import Faq from "./Faq";
import Footer from "components/Footer";
import ChatReminderModal from "./Modals/ChatReminderModal";
import RetakeModal from "./Modals/RetakeModal";
import ResumeVisitModal from "components/Modals/ResumeVisitModal";
import ResumeRefillInProgressModal from "components/Modals/ResumeRefllModal";
import RefillAvailableModal from "components/Modals/RefillAvailableModal";
import UnderReviewVisitModal from "components/Modals/UnderReviewVisitModal";
import DuplicateCartModal from "components/Modals/DuplicateCartModal";
import { GetProductVariantRequest } from "apis";
import { AuthContext } from "Contexts/AuthContext";
import { CartContext } from "Contexts/CartContext";

import "./styles.scss";

const Home = () => {
  const {
    showChatReminderModal,
    showRetakeModal,
    setShowRetakeModal,
    setShowChatReminderModal,
    authenticated,
    reloadUser,
  } = useContext(AuthContext);

  const { ReloadCart } = useContext(CartContext);
  const [defaultProductVariant, setDefaultProductVariant] = useState({});

  useEffect(() => {
    GetProductVariantRequest().then((res: any) => {
      setDefaultProductVariant(res.data.default_product_variant);
    });
  }, [authenticated]);

  useEffect(() => {
    ReloadCart();
    reloadUser();
  }, []);

  return (
    <div className="main">
      <Hero defaultProductVariant={defaultProductVariant} />
      <LabTested defaultProductVariant={defaultProductVariant} />
      <AboutEd />
      <WhatIsIncluded />
      <RealResults />
      <Process defaultProductVariant={defaultProductVariant} />
      <ViagraVsSildenafil />
      <RetakeModal setShow={setShowRetakeModal} show={showRetakeModal} />
      <ChatReminderModal
        setChatReminderModal={setShowChatReminderModal}
        show={showChatReminderModal}
      />
      <ResumeRefillInProgressModal />
      <ResumeVisitModal defaultProductVariant={defaultProductVariant} />
      <RefillAvailableModal defaultProductVariant={defaultProductVariant} />
      <UnderReviewVisitModal />
      <DuplicateCartModal />
      <HappyPatients defaultProductVariant={defaultProductVariant} />
      <Faq />
      <BottomCta defaultProductVariant={defaultProductVariant} />
      <Footer />
    </div>
  );
};

export default Home;
