import React, { useContext, useEffect, useState } from "react";
import { filter } from "lodash";
import { ReactSVG } from "react-svg";
import DrugInformation from "components/Modals/DrugInforamtionModal";
import LearnMoreModal from "./Modals/LearnMoreModal";
import LabReportModal from "./Modals/LabReportModal";
import Translator from "components/Translator";
import { GetProductDetailsRequest } from "apis";
import qualityApprovedBadge from "assets/images/home/quality-approved-badge.svg";
import signatureImage from "assets/images/home/signature.png";
import rightArrowSvg from "assets/images/home/right-arrow.svg";
import viagraBranded from "assets/images/home/competition_logos/viagra_branded.png";
import sildenafilLogo from "assets/images/home/logo.svg";
import walmartPharmacy from "assets/images/home/competition_logos/walmart_pharmacy.svg";
import csvLogo from "assets/images/home/competition_logos/csv.svg";
import romanLogo from "assets/images/home/competition_logos/roman.svg";
import himsLogo from "assets/images/home/competition_logos/for_hims.svg";
import cialisLogo from "assets/images/home/competition_logos/cialis_branded.png";
import tadalafilLogo from "assets/images/home/tadalafil/logo.svg";
import NewVisitButton from "./Shared/NewVisitButton";
import { find, isEmpty, map, min } from "lodash";
import { AuthContext } from "Contexts/AuthContext";
import { currencyFormatter } from "helpers/Formatter";

type Props = {
  defaultProductVariant:
    | {
        id?: any;
      }
    | undefined;
  slug?: boolean;
};

const LabTested = ({ defaultProductVariant, slug = false }: Props) => {
  const [activeProduct, setActiveProduct] = useState("Sildenafil");
  const [showViagraTable, setShowViagraTable] = useState<boolean>(true);
  const [showDrugInformation, setShowDrugInformation] = useState<boolean>(false);
  const [currentProduct, setCurrentProduct] = useState<any>();
  const isMarketing: any = slug
    ? "marketing"
    : localStorage.getItem("isMarketing");
  const { authenticated } = useContext(AuthContext);

  useEffect(() => {
    GetProductDetailsRequest(isMarketing)
      .then((res: any) => {
        if (isEmpty(filter(res.data.products, { name: activeProduct })))
          return;
        setCurrentProduct(
          filter(res.data.products, { name: activeProduct })[0]
        );
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, [activeProduct, authenticated]);

  const handleSwitch = (e: any) => {
    const value = e.target.value;
    switch (value) {
      case "viagra":
        setActiveProduct("Sildenafil");
        setShowViagraTable(true);
        break;
      case "cialis":
        setActiveProduct("Tadalafil");
        setShowViagraTable(false);
        break;
    }
  };

  const getVariantPriceThresholds = (dosage: string) => {
    if (!isEmpty(currentProduct)) {
      const prices = map(
        find(currentProduct.product_variants, {
          dosage: dosage,
        })?.price_thresholds,
        "price"
      );

      return min(prices.map(parseFloat)) || 0;
    }
  };

  return (
    <div className="ab-wrapper ab-1">
      <div className="homepage-container">
        <div
          className={`lab-tested ${
            isMarketing === "marketing" && "product-selection"
          }`}
        >
          <div className="content">
            <div className="lab-tested-text text-center">
              <h1 className="font-semi-bold">
                <Translator path="homepage.labTested.headings.main.labTested" />
              </h1>
              <h5 className="mb-5 red-highlight">
                <Translator path="homepage.labTested.headings.main.lowestPrice" />
              </h5>
            </div>
            <div className="row">
              <div className="col-12 col-lg-4 order-2 order-md-1 d-md-none d-lg-block">
                <div className="blue-background desktop d-flex flex-md-column">
                  <p className="font-semi-bold d-none d-md-block">
                    <Translator path="homepage.labTested.paragraphs.pillsTest" />
                  </p>
                  <p className="mr-2">
                    <span className="blue-highlight font-semi-bold">
                      <Translator path="homepage.labTested.paragraphs.guaranteeSafe" />
                    </span>
                    <Translator path="homepage.labTested.paragraphs.saveUpto" />
                  </p>
                </div>
                <div className="row flex-row no-gutters mt-4">
                  <div className="col">
                    <ReactSVG src={qualityApprovedBadge} />
                  </div>
                  <div className="col d-flex flex-column justify-content-end certificate-text">
                    <small>
                      <img
                        className="d-block"
                        alt="signture"
                        src={signatureImage}
                      />
                      <Translator path="homepage.labTested.certificateAnalysis" />
                      <br />
                      <Translator path="homepage.labTested.sildenafilCitrate" />
                      <br />
                      <a
                        data-target="#labReportModal"
                        data-toggle="modal"
                        data-type="button"
                        href="#"
                      >
                        <Translator path="homepage.labTested.viewTheReport" />
                      </a>
                      <LabReportModal />
                    </small>
                  </div>
                  <div className="mobile-form d-flex d-md-none w-100">
                    {(defaultProductVariant?.id || slug) && (
                      <NewVisitButton
                        defaultProductVariant={defaultProductVariant}
                        showDiscountLine={true}
                        position="end"
                        slug={slug}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-8 order-1 order-md-2 p-0 p-md-auto">
                <div className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-4">
                  <div className="pricing-toggles">
                    <input
                      defaultChecked={true}
                      id="viagra-pricing-toggle"
                      name="pricing-toggle"
                      type="radio"
                      value="viagra"
                      onClick={(e: any) => handleSwitch(e)}
                    />
                    <label
                      htmlFor="viagra-pricing-toggle"
                      className={`${showViagraTable && "active-viagra-table"}`}
                    >
                      Viagra ®
                    </label>
                    <input
                      id="cialis-pricing-toggle"
                      name="pricing-toggle"
                      type="radio"
                      value="cialis"
                      onClick={(e: any) => handleSwitch(e)}
                    />
                    <label
                      htmlFor="cialis-pricing-toggle"
                      className={`${!showViagraTable && "active-cialis-table"}`}
                    >
                      Cialis ®
                    </label>
                  </div>
                  <button
                    className="d-flex hyperlink text-brand-primary justify-content-between justify-content-md-end drug-modal-button"
                    onClick={() => setShowDrugInformation(true)}
                  >
                    <Translator path="homepage.labTested.safetyInfo" />
                    <ReactSVG src={rightArrowSvg} className="ml-2" />
                  </button>
                  <DrugInformation
                    show={showDrugInformation}
                    setShow={setShowDrugInformation}
                    productName={activeProduct}
                  />
                  <LearnMoreModal />
                </div>
                <div
                  className={`table-container viagra ${
                    !showViagraTable && "d-none"
                  }`}
                >
                  <div className="table-wrapper">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>
                            <Translator path="homepage.labTested.table.company" />{" "}
                          </th>
                          <th>20mg</th>
                          <th>25mg</th>
                          <th>50mg</th>
                          <th>100mg</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="grayed">
                          <td>
                            <Translator path="homepage.labTested.table.brandedDrug" />
                          </td>
                          <td className="text-transparent" colSpan={4} />
                        </tr>
                        <tr>
                          <td className="branded-logo">
                            <img
                              className="viagra-logo"
                              src={viagraBranded}
                              alt="viagra brander"
                            />
                          </td>
                          <td>na</td>
                          <td>$80.00</td>
                          <td>$80.00</td>
                          <td>$80.00</td>
                        </tr>
                        <tr className="grayed">
                          <td>
                            <Translator path="homepage.labTested.table.genericDrug" />
                          </td>
                          <td className="text-transparent" colSpan={4} />
                        </tr>
                        <tr
                          className={`promoted ${
                            !showViagraTable ? "cialis" : "viagra"
                          }`}
                        >
                          <td className="site-logo">
                            <ReactSVG
                              src={sildenafilLogo}
                              className="viagra-logo"
                            />
                          </td>
                          <td>
                            {currencyFormatter(
                              getVariantPriceThresholds("20 mg"),
                              2,
                              true
                            )}
                          </td>
                          <td>
                            {currencyFormatter(
                              getVariantPriceThresholds("25 mg"),
                              2,
                              true
                            )}
                          </td>
                          <td>
                            {currencyFormatter(
                              getVariantPriceThresholds("50 mg"),
                              2,
                              true
                            )}
                          </td>
                          <td>
                            {currencyFormatter(
                              getVariantPriceThresholds("100 mg"),
                              2,
                              true
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span>
                              <ReactSVG
                                src={walmartPharmacy}
                                className="walmart-logo"
                              />
                            </span>
                          </td>
                          <td>$4.00</td>
                          <td>$29.53</td>
                          <td>$30.50</td>
                          <td>$28.88</td>
                        </tr>
                        <tr>
                          <td>
                            <ReactSVG src={csvLogo} className="cvs-logo" />
                          </td>
                          <td>$28.00</td>
                          <td>$30.39</td>
                          <td>$35.00</td>
                          <td>$47.39</td>
                        </tr>
                        <tr>
                          <td>
                            <ReactSVG src={romanLogo} className="roman-logo" />
                          </td>
                          <td>na</td>
                          <td>$6.00</td>
                          <td>$6.00</td>
                          <td>$10.00</td>
                        </tr>
                        <tr>
                          <td>
                            <ReactSVG src={himsLogo} className="hims-logo" />
                          </td>
                          <td>na</td>
                          <td>$2.67</td>
                          <td>$4.33</td>
                          <td>$7.50</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex justify-content-between">
                    <h5>
                      <Translator path="homepage.labTested.onlineSource" />
                    </h5>
                    <small>
                      <a
                        href="#"
                        data-toggle="modal"
                        data-target="#learnMoreModal"
                      >
                        <Translator path="homepage.labTested.learnMore" />
                      </a>
                    </small>
                  </div>
                </div>
                <div
                  className={`table-container cialis ${
                    showViagraTable && "d-none"
                  }`}
                >
                  <div className="table-wrapper">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Company</th>
                          <th>2.5mg</th>
                          <th>5mg</th>
                          <th>10mg</th>
                          <th>20mg</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="grayed">
                          <td>Branded Drug</td>
                          <td className="text-transparent" colSpan={8} />
                        </tr>
                        <tr>
                          <td className="branded-logo">
                            <img src={cialisLogo} alt="cialis-logo" />
                          </td>
                          <td>$40.00</td>
                          <td>$80.00</td>
                          <td>$80.00</td>
                          <td>$80.00</td>
                        </tr>
                        <tr className="grayed">
                          <td>Generic Drug</td>
                          <td className="text-transparent" colSpan={8} />
                        </tr>
                        <tr
                          className={`promoted ${
                            !showViagraTable ? "cialis" : "viagra"
                          }`}
                        >
                          <td className="site-logo">
                            <ReactSVG
                              src={tadalafilLogo}
                              className="cialis-logo"
                            />
                          </td>
                          <td>
                            {currencyFormatter(
                              getVariantPriceThresholds("2.5 mg"),
                              2,
                              true
                            )}
                          </td>
                          <td>
                            {currencyFormatter(
                              getVariantPriceThresholds("5 mg"),
                              2,
                              true
                            )}
                          </td>
                          <td>
                            {currencyFormatter(
                              getVariantPriceThresholds("10 mg"),
                              2,
                              true
                            )}
                          </td>
                          <td>
                            {currencyFormatter(
                              getVariantPriceThresholds("20 mg"),
                              2,
                              true
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span>
                              <ReactSVG
                                src={walmartPharmacy}
                                className="walmart-logo"
                              />
                            </span>
                          </td>
                          <td>$11.54</td>
                          <td>$10.97</td>
                          <td>$66.50</td>
                          <td>$52.87</td>
                        </tr>
                        <tr>
                          <td>
                            <ReactSVG src={csvLogo} className="cvs-logo" />
                          </td>
                          <td>$12.00</td>
                          <td>$12.00</td>
                          <td>$55.00</td>
                          <td>$55.00</td>
                        </tr>
                        <tr>
                          <td>
                            <ReactSVG src={romanLogo} className="roman-logo" />
                          </td>
                          <td>$11.00</td>
                          <td>$11.00</td>
                          <td>$44.00</td>
                          <td>$44.00</td>
                        </tr>
                        <tr>
                          <td>
                            <ReactSVG src={himsLogo} className="hims-logo" />
                          </td>
                          <td>$2.73</td>
                          <td>$2.73</td>
                          <td>$4.33</td>
                          <td>$9.00</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex justify-content-between">
                    <h5>
                      Your safe online source for Cialis (generic Tadalafil).
                    </h5>
                    <small>
                      <a
                        href="#"
                        data-toggle="modal"
                        data-target="#learnMoreModal"
                      >
                        <Translator path="homepage.labTested.learnMore" />
                      </a>
                    </small>
                  </div>
                </div>
                <div className="desktop-form d-none d-md-flex">
                  {(defaultProductVariant?.id || slug) && (
                    <NewVisitButton
                      defaultProductVariant={defaultProductVariant}
                      showDiscountLine={true}
                      position="end"
                      slug={slug}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LabTested;
