const calculateProgressBar = (pathName: string) => {
  const progressBarSteps = [
    "visit-consent",
    "patient-info",
    "visit-intro",
    "visit-consultation",
    "product-detail",
    "shipping",
    "id-upload",
    "selfie-upload",
    "shipping-confirmation",
    "order-verification",
  ];

  const progressBarRegex =
    /visit-consent|patient-info|product-selection|visit-intro|visit-consultation|product-detail|shipping|id-upload|selfie-upload|order-verification|/gi;

  if (pathName.match(progressBarRegex)) {
    const splitedPathName = pathName.split("/");

    if (splitedPathName.includes("checkout")) {
      const stepIndex = progressBarSteps.indexOf(splitedPathName[2]);
      const stepLength = progressBarSteps.length;
      let totalSize: any = ((stepIndex / stepLength) * 100).toFixed();

      if (splitedPathName[2].includes("order-verification")) {
        totalSize = stepLength * 100;
      }
      return totalSize;
    }
  }
};

export { calculateProgressBar };
