import React, { useEffect, useState, useContext } from "react";
import { ApplyDiscountRequest, GetProductVariantsRequest } from "apis";
import { isEmpty, map, compact, find, first, capitalize } from "lodash";
import { CartContext } from "Contexts/CartContext";
import { OrderContext } from "Contexts/OrderContext";
import CartItem from "../Checkout/OrderVerification/CartItem";
import discountTagIcon from "assets/images/icons/discount-tag-icon.svg";
import crossIcon from "assets/images/icons/cross-icon.svg";
import uspsSvg from "assets/images/checkout/usps.svg";
import visaBadge from "assets/images/checkout/visa_badge.svg";
import amexBadge from "assets/images/checkout/amex_badge.svg";
import mastercardBadge from "assets/images/checkout/mastercard_badge.svg";
import discoverBadge from "assets/images/checkout/discover_badge.svg";
import hsaBadge from "assets/images/checkout/hsa_badge.svg";
import fsaBadge from "assets/images/checkout/fsa_badge.svg";
import dinerBadge from "assets/images/checkout/diner_badge.svg";
import jcbBadge from "assets/images/checkout/jcb_badge.svg";
import lockIcon from "assets/images/icons/lock-icon.svg";
import CreditCardPicker from "../Checkout/OrderVerification/CreditCardPicker";
import { currencyFormatter } from "helpers/Formatter";

type OrderEditorProps = {
  order?: any;
  user?: any;
};

const OrderEditor = ({ order, user }: OrderEditorProps) => {
  const [errors, setErrors] = useState<object>({});
  const [couponCode, setCouponCode] = useState("");
  const [variants, setVariants] = useState<any>([]);
  const [products, setProducts] = useState<any>([]);
  const [packages, setPackages] = useState<any>([]);
  const [selectedProduct, setSelectedProduct] = useState<any>({});
  const [selectedVariant, setSelectedVariant] = useState<any>({});
  const [selectedQuantity, setSelectedQuantity] = useState<any>({});
  const [customInput, setCustomInput] = useState<boolean>(false);
  const [maxQuantityExceeded, setMaxQuantityExceeded] = useState(false);
  const [minQuantityExceeded, setMinQuantityExceeded] = useState(false);
  const [showCustomField, setShowCustomField] = useState(false);
  const [maxQtyAllowed, setMaxQtyAllowed] = useState(false); // TODO: Handle properly
  const [customInputHasValidValue, setCustomInputHasValidValue] =
    useState(false);
  const [showCouponInputDisplay, setShowCouponInputDisplay] = useState(false);
  const [allowCouponInsert, setAllowCouponInsert] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const discountCodes = compact(map(order.discounts, (d) => d.code));
  const discountPercentage = compact(map(order.discounts, (d) => d.percent));
  const [discountError, setDiscountError] = useState<any>(
    order.errors && order.errors.discount
  );

  const totalPrice = parseFloat(order.total_price);

  const { UpdateCart, ApplyDiscount, RemoveDiscount } = useContext(CartContext);
  const { ReloadOrder } = useContext(OrderContext);
  const discountCart: any = order.discount_cart_id
    ? find(order.carts, ["id", order.discount_cart_id])
    : first(order.carts);

  useEffect(() => {
    if (isEmpty(order)) return;

    // Fetch data for dropdowns from the API
    GetProductVariantsRequest(discountCart?.id)
      .then((res) => {
        const { products } = res.data;

        setProducts(products);
        setAllowCouponInsert(!order.discounts.length);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  const handleProductChange = (cart: any, name: any) => {
    const product = find(products, { name });
    const variants = product.product_variants;
    const newVariant: any = first(variants); // TODO: Define a shared product variant type

    setSelectedProduct(product);
    setVariants(variants);
    setSelectedVariant(newVariant);
    setSelectedQuantity(newVariant.min_order_quantity);
    setShowCustomField(false);

    const newCart = cart;
    newCart.product_variant_id = newVariant.id;
    newCart.quantity = newVariant.min_order_quantity;

    UpdateCart(newCart);
  };

  const handleDosageChange = (cart: any, dosage: any) => {
    const variant = find(variants, { dosage_value: dosage });

    setSelectedVariant(variant);
    setPackages(variant.packages);
    setSelectedQuantity(variant.min_order_quantity);
    setShowCustomField(false);

    const newCart = cart;
    newCart.product_variant_id = variant.id;
    newCart.quantity = variant.min_order_quantity;

    UpdateCart(newCart);
  };

  const handlePackageSelect = (cart: any, quantity: any) => {
    const currentVariant = find(
      findCurrentProduct(cart.product_id)?.product_variants,
      { id: cart.product_variant_id }
    );
    const quantityTooSmall = quantity < currentVariant.min_order_quantity;
    const quantityTooLarge = quantity > cart.max_quantity;

    setSelectedQuantity(quantity);

    if ([null, currentVariant.id].includes(cart.product_variant_id)) {
      // Handle errors
      if (quantityTooSmall) {
        setErrors({
          errors,
          quantity: [
            `The minimum quantity allowed by your prescription is ${currentVariant.min_order_quantity} tablets`,
          ],
        });
      } else if (quantityTooLarge) {
        setErrors({
          ...errors,
          quantity: [
            `The maximum quantity allowed by your prescription is ${cart.max_quantity} tablets`,
          ],
        });
      } else {
        setErrors({
          ...errors,
          quantity: [],
        });
      }

      if (
        !quantityTooSmall &&
        !quantityTooLarge &&
        quantity !== "Custom Quantity"
      ) {
        setCustomInput(false);

        const newCart = cart;
        newCart.product_variant_id = currentVariant.id;
        newCart.quantity = quantity;

        UpdateCart(newCart).then(() => {
          ReloadOrder();
        });
      } else {
        handleCustomInput(quantity);
      }
    } else {
      // TODO: Add flash messages
      console.log(
        `You have already added another product to your cart. Please finish latest order or clear cart to add the new one.`
      );
    }
  };

  const handleCustomInput = (quantity: any) => {
    if (quantity === "Custom Quantity") {
      setShowCustomField(true);
    }
  };

  const handleApplyDiscount = () => {
    if (couponCode.length) {
      ApplyDiscountRequest({ cart_id: discountCart.id, code: couponCode })
        .then((res) => {
          ReloadOrder();
          setAllowCouponInsert(false);
          setShowCouponInputDisplay(false);
          setDiscountError("");
        })
        .catch((err) => {
          setDiscountError(err?.response?.data?.details);
          console.log(err);
        });
    }

    setCouponCode("");
  };

  const handleRemoveCoupon = () => {
    RemoveDiscount(discountCart.id).then(() => {
      setAllowCouponInsert(true);
      setCouponCode("");
      ReloadOrder();
    });
  };

  const handleCouponInput = () => {
    setDiscountError("");
    setShowCouponInputDisplay(!showCouponInputDisplay);
  };

  const handleCouponCodeChange = (e: any) => {
    setCouponCode(e.target.value);
  };

  const handleCouponFieldKeydown = (e: any) => {
    if (e.key === "Enter") {
      handleApplyDiscount();
    }
  };

  const findCurrentProduct = (product_id: number) => {
    return find(products, { id: product_id });
  };

  if (isEmpty(order) || isEmpty(products)) return <></>;

  const orderDefaultCart: any = first(order.carts);
  const orderCartsTotalPrice = Number(
    order.carts
      .map((cart: any) => parseFloat(cart.non_discounted_price))
      .reduce((a: any, b: any) => a + b, 0)
  ).toFixed(2);

  const discountPrice = () => {
    const cartsPriceTotal = order.carts
      .map((cart: any) => parseFloat(cart.price))
      .reduce((a: any, b: any) => a + b, 0);

    const cartsFinalPriceTotal = order.carts
      .map((cart: any) => parseFloat(cart.final_price))
      .reduce((a: any, b: any) => a + b, 0);

    return cartsPriceTotal - cartsFinalPriceTotal;
  };

  const orderDiscountPercentage: number =
    (1.0 - parseFloat(order.total_price) / parseFloat(orderCartsTotalPrice)) *
    100;

  return (
    <>
      <div className="current-cart__row">
        {order.carts.map((cart: any, index: number) => {
          const currentVariant = find(
            findCurrentProduct(cart.product_id)?.product_variants,
            { id: cart.product_variant_id }
          );

          return (
            <React.Fragment key={index}>
              <CartItem
                key={index}
                cart={cart}
                products={products}
                quantities={currentVariant?.packages}
                current_variant={currentVariant}
                variantsPerProduct={
                  findCurrentProduct(cart.product_id)?.product_variants
                }
                handleProductChange={(product: any) =>
                  handleProductChange(cart, product)
                }
                handleDosageChange={(dosage: any) =>
                  handleDosageChange(cart, dosage)
                }
                handleQuantityChange={(qty: any) =>
                  handlePackageSelect(cart, qty)
                }
                disabledOptions={["product", "dosage"]}
                discountPercentage={discountPercentage}
                errors={errors}
                setErrors={setErrors}
              />
              {cart.additionalQuantity > 0 && (
                <div className="current-cart__row">
                  <div className="left">
                    <strong>{cart.product_variant.fullname}</strong>
                    <p>
                      {cart.additionalQuantity}{" "}
                      {capitalize(cart.product_variant.unit)}
                      {cart.additionalQuantity > 1 && "s"}
                    </p>
                  </div>
                  <div className="right">
                    <strong>{`$${Number(0).toFixed(2)}`}</strong>
                  </div>
                </div>
              )}
            </React.Fragment>
          );
        })}
        <div
          className={`discount mx-0 px-0 w-100 ${
            discountError ? "field_with_errors" : ""
          }`}
        >
          {allowCouponInsert && (
            <label className="coupon-wrapper">
              I have a coupon
              <input
                type="checkbox"
                id="showCouponInputDisplay"
                value="false"
                name="coupon"
                onClick={() => handleCouponInput()}
              />
              <span
                className="checkmark"
                onClick={() => setDiscountError("")}
              />
              <img className="ml-3" src={discountTagIcon} alt="" />
            </label>
          )}

          {showCouponInputDisplay && (
            <div className="d-flex justify-content-between discount--input">
              <input
                className="discount--input-field"
                onChange={(e: any) => handleCouponCodeChange(e)}
                onKeyDown={(e: any) => handleCouponFieldKeydown(e)}
                value={couponCode}
              />
              <button
                className="discount--button"
                disabled={!orderDefaultCart?.quantity}
                onClick={() => handleApplyDiscount()}
              >
                Apply now
              </button>
            </div>
          )}
          {discountError && (
            <p className="error-message">
              {discountCart.errors?.discount
                ? `${discountCart.errors?.discount?.join(", ")}`
                : discountError}
            </p>
          )}
          {discountCart.discounts?.length > 0 && (
            <div className="current-cart__row d-flex justify-content-between">
              <div className="discount-pill">
                <span className="cart-product__details">
                  {discountCodes.length ? discountCodes.join(", ") : "Discount"}
                </span>
                <span
                  className="remove-discount-code"
                  onClick={handleRemoveCoupon}
                >
                  <img src={crossIcon} alt="" />
                </span>
              </div>
              <div className="discounted-price">{`- ${currencyFormatter(
                discountPrice(),
                2,
                true
              )}`}</div>
            </div>
          )}
        </div>
        <hr />
        <div className="current-cart__row">
          <div className="current-shipping d-flex py-0">
            <span className="cart-product__details d-flex">
              <img src={uspsSvg} alt="" className="mr-3 delivery-image" />
              <span className="delivery-option">
                {`${orderDefaultCart.delivery_label}`}{" "}
                <span>
                  {orderDefaultCart.delivery_option.delivery_days_label}
                </span>
              </span>
            </span>
            {Number(order.final_delivery_price) === 0 ? (
              <span className="free-box">Free</span>
            ) : (
              <span className="cart-product__price cart-product__shipping">
                {`${currencyFormatter(order.final_delivery_price)}`}
              </span>
            )}
          </div>
        </div>
        <hr />
      </div>
      <div className="current-cart__row py-0">
        <div className="current-shipping d-flex pb-1">
          <span className="total-price-text">Total</span>
          {orderDefaultCart.non_discounted_price !==
            orderDefaultCart.total_price && (
            <span className="slashed-price">
              {currencyFormatter(orderCartsTotalPrice)}
            </span>
          )}
          <span className="price-text ml-2">
            {currencyFormatter(totalPrice)}
          </span>
        </div>
        {Math.round(orderDiscountPercentage) > 0 && (
          <span className="saved-amount">
            You save {Math.round(orderDiscountPercentage)}%
          </span>
        )}
        <div className="no-subscription">
          <div className="text">No Subscription. One time charge only.</div>
        </div>
      </div>
      <hr className="mb-3" />
      <div className="payment-method-selector">
        <h5 className="my-4">Payment Method</h5>
        <span className="d-flex">
          <img src={amexBadge} alt="" className="payment-badge" />
          <img src={mastercardBadge} alt="" className="payment-badge" />
          <img src={visaBadge} alt="" className="payment-badge" />
          <img src={discoverBadge} alt="" className="payment-badge" />
          <img src={dinerBadge} alt="" className="payment-badge" />
          <img src={jcbBadge} alt="" className="payment-badge" />
          <img src={fsaBadge} alt="" className="payment-badge" />
          <img src={hsaBadge} alt="" className="payment-badge" />
        </span>
        <CreditCardPicker buttonText="Complete My Order" orderMode={true} />
        <div className="security-text-wrapper">
          <img src={lockIcon} alt="Security" />
          <span>128-Bit TLS Security</span>
        </div>
      </div>
    </>
  );
};

export default OrderEditor;
