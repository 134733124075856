import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
} from "react";
import { isEmpty, isNull } from "lodash";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { useRollbar } from "@rollbar/react";

import {
  LoginRequest,
  SignupRequest,
  GetUserRequest,
  UpdateUserRequest,
  UpdateUserPasswordRequest,
  GetPocketmedStatusesRequest,
  ForgotPasswordRequest,
  SetNewPasswordRequest,
  ValidateResetPasswordTokenRequest,
  SelectCardRequest,
  DeleteCardRequest,
  CreateFacebookUserRequest,
  FinishOauthSetupRequest,
  MarkMessagesAsRead,
  UserEmailConfirmationRequest,
  LoginViaMobileRequest,
  GetProductVariantRequest,
  LogoutRequest,
} from "../apis";
import Rollbar from "rollbar";
import ReactPixel from "react-facebook-pixel";

export type AuthContextValue = {
  authenticated: Boolean;
  loading: Boolean;
  currentUser?: UserType;
  loginError: string;
  otpLoginError: string;
  showRetakeModal: boolean;
  retakePending: boolean;
  showChatReminderModal: boolean;
  showChatGreenDot: boolean;
  showResumeVisitModal: boolean;
  showUnderReviewVisitModal: boolean;
  showRefillAvailableModal: boolean;
  showResumeRefillModal: boolean;
  showRefillInProgress: boolean;
  showChatModal: boolean;
  showDuplicateCartModal: boolean;
  resetPasswordMessage: string;
  resetPasswordError: string;
  invalidPasswordTokenError: string;
  loginCheck: boolean;
  defaultProductVariant: {
    id?: any;
  };
  setDefaultProductVariant: Dispatch<SetStateAction<{}>>;
  setLoginCheck: Dispatch<SetStateAction<boolean>>;
  setAuthenticated: Dispatch<SetStateAction<boolean>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setCurrentUser: Dispatch<SetStateAction<any>>;
  setShowRetakeModal: Dispatch<SetStateAction<boolean>>;
  setRetakePending: Dispatch<SetStateAction<boolean>>;
  setShowChatReminderModal: Dispatch<SetStateAction<boolean>>;
  setShowChatGreenDot: Dispatch<SetStateAction<boolean>>;
  setShowResumeVisitModal: Dispatch<SetStateAction<boolean>>;
  setShowUnderReviewVisitModal: Dispatch<SetStateAction<boolean>>;
  setShowRefillAvailableModal: Dispatch<SetStateAction<boolean>>;
  setShowResumeRefillModal: Dispatch<SetStateAction<boolean>>;
  setShowRefillInProgress: Dispatch<SetStateAction<boolean>>;
  setShowChatModal: Dispatch<SetStateAction<boolean>>;
  setShowDuplicateCartModal: Dispatch<SetStateAction<boolean>>;
  setLoginError: Dispatch<SetStateAction<string | "">>;
  setOtpLoginError: Dispatch<SetStateAction<string | "">>;
  setResetPasswordMessage: Dispatch<SetStateAction<string | "">>;
  setResetPasswordError: Dispatch<SetStateAction<string | "">>;
  setInvalidPasswordTokenError: Dispatch<SetStateAction<string | "">>;
  reloadUser: () => any;
  LoginUser: (values: {
    email: string;
    password: string;
    becomeToken: string;
    phone: string;
    otpCode?: string;
  }) => any;
  Logout: (isSignInRedirect?: boolean) => any;
  Signup: (values: {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    landing_page_id: number;
  }) => void;
  UpdateUser: (values: UserType) => any;
  UpdatePassword: (values: UserPasswordType) => any;
  ForgotPasswordCall: (values: { email: string }) => any;
  ValidateResetPasswordToken: (token: string) => any;
  SetNewPassword: (values: {
    password: string;
    passwordConfirmation: string;
    resetToken: string;
  }) => any;
  SelectCreditCard: (paymentProfileId: string) => any;
  DeleteCreditCard: (paymentProfileId: string) => any;
  CreateFacebookUser: (values: { oauth_res: any; cart_token: string }) => any;
  FinishOauthSetup: (values: UserType) => any;
  ReadChatMessages: () => void;
  UserEmailConfirmation: (token: string) => any;
};

export type UserType = {
  id?: number;
  first_name?: string;
  last_name?: string;
  fullname?: string;
  date_of_birth?: string;
  date_of_birth_formatted?: string;
  gender?: string;
  mobile_phone?: string;
  phone_contact_allowed?: boolean;
  drugs_names_included?: boolean;
  email?: string;
  created_at?: string;
  updated_at?: string;
  landing_page_id?: number;
  terms_of_service?: boolean;
  info_provided?: boolean;
  home_phone?: string;
  email_verified?: boolean;
  mobile_phone_verified?: boolean;
  species?: string;
  time_zone?: string;
  payment_profile_id?: string;
  authorize_customer_profile_id?: string;
  pioneer_id?: string;
  default_payment_profile_id?: string;
  provider?: string;
  uid?: string;
  pocketmed_uuid?: string;
  referrer?: any;
  source?: any;
  jti?: string;
  tag_list?: [];
  shipping_addresses?: [];
  confirmed?: boolean;
  has_in_progress_refill?: boolean;
  has_refill?: boolean;
  has_under_review_visit?: boolean;
  has_cart_in_progress?: boolean;
  credit_cards?: [];
  prescriptions?: [];
  confirmation_sent_at?: string;
  is_brand_new_patient?: boolean;
  is_order_being_processed?: boolean;
  otp_verified?: boolean;
  can_show_photos?: boolean;
  can_generate_ssn?: boolean;
};

export type UserPasswordType = {
  current_password: string;
  password: string;
  password_confirmation: string;
};

export const AuthContext = createContext<AuthContextValue>({
  authenticated: false,
  loading: false,
  currentUser: undefined,
  loginError: "",
  otpLoginError: "",
  showRetakeModal: false,
  retakePending: false,
  showChatReminderModal: false,
  showChatGreenDot: false,
  showResumeVisitModal: false,
  showUnderReviewVisitModal: false,
  showRefillAvailableModal: false,
  showResumeRefillModal: false,
  showRefillInProgress: false,
  showChatModal: false,
  showDuplicateCartModal: false,
  loginCheck: false,
  resetPasswordMessage: "",
  resetPasswordError: "",
  invalidPasswordTokenError: "",
  defaultProductVariant: {},
  setDefaultProductVariant: () => {},
  setShowRetakeModal: () => {},
  setRetakePending: () => {},
  setShowChatReminderModal: () => {},
  setShowChatGreenDot: () => {},
  setShowResumeVisitModal: () => {},
  setShowUnderReviewVisitModal: () => {},
  setShowRefillAvailableModal: () => {},
  setShowResumeRefillModal: () => {},
  setShowRefillInProgress: () => {},
  setShowChatModal: () => {},
  setShowDuplicateCartModal: () => {},
  setAuthenticated: () => {},
  setLoading: () => {},
  reloadUser: () => {},
  setCurrentUser: () => {},
  setLoginError: () => "",
  setOtpLoginError: () => "",
  setResetPasswordMessage: () => {},
  setResetPasswordError: () => {},
  setInvalidPasswordTokenError: () => {},
  LoginUser: () => {},
  Logout: () => {},
  Signup: () => {},
  UpdateUser: () => {},
  UpdatePassword: () => {},
  ForgotPasswordCall: () => {},
  ValidateResetPasswordToken: () => {},
  SetNewPassword: () => {},
  SelectCreditCard: () => {},
  DeleteCreditCard: () => {},
  CreateFacebookUser: () => {},
  FinishOauthSetup: () => {},
  ReadChatMessages: () => {},
  UserEmailConfirmation: () => {},
  setLoginCheck: () => {},
});

const AuthContextProvider = ({ children }: any) => {
  const navigate = useNavigate();
  const rollbar = useRollbar();
  const location = useLocation();

  let [searchParams] = useSearchParams();
  const [authenticated, setAuthenticated] = useState<any>(null);
  const [currentUser, setCurrentUser] = useState<any>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [loginError, setLoginError] = useState("");
  const [otpLoginError, setOtpLoginError] = useState<string>("");
  const [resetPasswordMessage, setResetPasswordMessage] = useState("");
  const [resetPasswordError, setResetPasswordError] = useState("");
  const [invalidPasswordTokenError, setInvalidPasswordTokenError] =
    useState("");
  const authToken = localStorage.getItem("authToken");
  const [showRetakeModal, setShowRetakeModal] = useState<boolean>(false);
  const [showChatReminderModal, setShowChatReminderModal] =
    useState<boolean>(false);
  const [showChatGreenDot, setShowChatGreenDot] = useState<boolean>(false);
  const [showResumeVisitModal, setShowResumeVisitModal] = useState(false);
  const [showUnderReviewVisitModal, setShowUnderReviewVisitModal] =
    useState(false);
  const [showRefillAvailableModal, setShowRefillAvailableModal] =
    useState(false);
  const [showResumeRefillModal, setShowResumeRefillModal] = useState(false);
  const [showRefillInProgress, setShowRefillInProgress] = useState(false);
  const [showChatModal, setShowChatModal] = useState<boolean>(false);
  const [showDuplicateCartModal, setShowDuplicateCartModal] =
    useState<boolean>(false);
  const [retakePending, setRetakePending] = useState<boolean>(false);
  const [loginCheck, setLoginCheck] = useState<boolean>(false);
  const [defaultProductVariant, setDefaultProductVariant] = useState<{
    id?: any;
  }>({});

  useEffect(() => {
    if (
      authToken &&
      !authenticated &&
      isNull(localStorage.getItem("logInThroughCrm"))
    ) {
      reLogin();
    } else if (
      isNull(localStorage.getItem("logInThroughCrm")) &&
      isNull(authToken)
    ) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  useEffect(() => {
    GetProductVariantRequest().then((res: any) => {
      setDefaultProductVariant(res.data.default_product_variant);
    });
  }, []);

  const getUser = () => {
    const controller = new AbortController();
    const { signal } = controller;

    return GetUserRequest(signal)
      .then((res) => {
        const resource = res.data.user;
        if (res.data.status === 401) {
          localStorage.removeItem("authToken");
          setAuthenticated(false);
          return;
        }
        setCurrentUser(resource);
        setAuthenticated(true);
      })
      .catch((err) => {
        if (err.request.status === 401) {
          localStorage.removeItem("authToken");
          localStorage.removeItem("PmJti");
        }

        console.log(err);
      });
  };

  useEffect(() => {
    if (!isEmpty(currentUser) && currentUser.pocketmed_uuid) {
      GetPocketmedStatusesRequest(currentUser.pocketmed_uuid)
        .then((res) => {
          const statuses = res.data.status;
          setRetakePending(statuses.retake);

          if (
            !showRetakeModal &&
            !searchParams.get("show_chat") &&
            !retakePending
          ) {
            setShowRetakeModal(statuses.retake);
          }

          setShowChatGreenDot(statuses.chat_message);

          if (
            !statuses.retake &&
            !showChatReminderModal &&
            !searchParams.get("show_chat") &&
            !location?.search?.includes("chat=true")
          ) {
            setShowChatReminderModal(statuses.chat_message);
          }

          if (location?.search?.includes("chat=true")) {
            setShowChatReminderModal(false);
            setShowChatModal(true);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const reloadUser = async () => {
    const controller = new AbortController();
    const { signal } = controller;

    if (authenticated) {
      return GetUserRequest(signal).then((res) => {
        const resource = res.data.user;
        if (res.data.status === 401) {
          localStorage.removeItem("authToken");
          setAuthenticated(false);
          return;
        }
        setCurrentUser(resource);
      });
    }
  };

  const reLogin = () => {
    getUser();
  };

  const LoginUser = async (values: {
    email: string;
    password: string;
    becomeToken: string;
    phone: string;
    otpCode?: string;
    is_otp_request?: boolean;
  }) => {
    const CartToken = localStorage.getItem("CartToken") ?? "";
    const introQuestions = localStorage.getItem("intro-answers");

    return LoginRequest({
      email: values.email,
      password: values.password,
      cart_token: CartToken,
      become_token: values.becomeToken,
      mobile_phone: values.phone,
      otp_code: values.otpCode,
      is_otp_request: values.is_otp_request || false,
    })
      .then((res) => {
        const resource = res.data.user;
        const token = res.data.token;

        if (res.data.status === 401 && !resource) {
          if (!isEmpty(res.data?.otp_error)) {
            setOtpLoginError(res.data.otp_error);
          }
          if (!isEmpty(res.data.message)) {
            setLoginError(res.data.message);
          }
          return;
        }
        localStorage.setItem("authToken", token);
        if (res.data.pm_user_jti) {
          localStorage.setItem("PmJti", res.data.pm_user_jti);
        }
        localStorage.removeItem("CartToken");
        localStorage.removeItem("referrer");
        localStorage.removeItem("sourceUrl");
        setCurrentUser(resource);
        setAuthenticated(true);
        setLoading(true);
        setLoginCheck(true);

        const redirectUri = searchParams.get("redirect_uri") ?? "";

        if (!isEmpty(redirectUri) && redirectUri.includes("account")) {
          navigate(redirectUri);
        } else if (
          isEmpty(introQuestions) &&
          res.data.redirect_path.includes("visit-consent")
        ) {
          navigate("/intro-questions", { replace: true });
        } else {
          navigate(res.data.redirect_path, { replace: true });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        localStorage.removeItem("logInThroughCrm");
      });
  };

  const Logout = (isSignInRedirect?: boolean) => {
    LogoutRequest()
      .then((_res) => {
        localStorage.removeItem("logInThroughCrm");
        localStorage.removeItem("authToken");
        localStorage.removeItem("CartToken");
        localStorage.removeItem("referrer");
        localStorage.removeItem("sourceUrl")
        localStorage.removeItem("PmJti");
        setLoading(true);
        setCurrentUser(null);
        setAuthenticated(false);
        setShowChatReminderModal(false);
        setShowChatGreenDot(false);
        setShowRefillAvailableModal(false);
        setShowRetakeModal(false);
        setRetakePending(false);

        if (!isSignInRedirect) {
          navigate("/");
        }
      })
      .catch((err) => {
        rollbar.error(err);
      });
  };

  const Signup = async (values: {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    landing_page_id: number;
  }) => {
    setLoading(true);
    const CartToken = localStorage.getItem("CartToken") ?? "";
    const referrer = localStorage.getItem("referrer") ?? "";
    const sourceUrl = localStorage.getItem("sourceUrl") ?? "";
    const priceVersion = localStorage.getItem('isMarketing') === 'marketing' ? 'v4' : "";

    return SignupRequest({
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      password: values.password,
      landing_page_id: values.landing_page_id,
      referrer: referrer,
      source: sourceUrl,
      cart_token: CartToken,
      price_version: priceVersion,
    })
      .then((res) => {
        const token = res.data.token;

        localStorage.setItem("authToken", token);
        localStorage.removeItem("CartToken");
        localStorage.setItem("PmJti", res.data?.pm_jti);
        localStorage.removeItem("referrer");
        localStorage.removeItem("sourceUrl");
        setAuthenticated(true);
        setCurrentUser(res.data.resource);

        ReactPixel.fbq("track", "Lead", {}, { eventID: "fb_lead" });

        navigate(res.data.redirect_path);

        // reloadUser();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const CreateFacebookUser = async (values: {
    oauth_res: any;
    cart_token: string;
  }) => {
    return CreateFacebookUserRequest({
      oauth_res: values.oauth_res,
      cart_token: values.cart_token,
    })
      .then((res) => {
        const { redirect_path, user, token } = res.data;

        localStorage.setItem("authToken", token);
        localStorage.removeItem("CartToken");
        setAuthenticated(true);
        setCurrentUser(user);
        setLoading(true);

        navigate(redirect_path);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const FinishOauthSetup = async (values: UserType) => {
    return FinishOauthSetupRequest(values)
      .then((res) => {
        setCurrentUser(res.data.user);
        navigate(res.data.redirect_path);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const UpdateUser = async (values: UserType) => {
    return UpdateUserRequest(values)
      .then((res) => {
        setCurrentUser(res.data.user);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const UpdatePassword = async (values: UserPasswordType) => {
    return UpdateUserPasswordRequest(values).catch((err) => {
      console.log(err);
    });
  };

  const ForgotPasswordCall = async (values: { email: string }) => {
    return ForgotPasswordRequest(values.email)
      .then((res) => {
        if (res.data.status === 404) {
          setResetPasswordError(res.data.message);
        } else {
          setResetPasswordMessage(res.data.message);
          navigate("/sign-in");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ValidateResetPasswordToken = async (token: string) => {
    ValidateResetPasswordTokenRequest(token)
      .then((res) => {
        if (res.data.status === 404) {
          setInvalidPasswordTokenError(res.data.message);
          navigate("/sign-in");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const SetNewPassword = async (values: {
    password: string;
    passwordConfirmation: string;
    resetToken: string;
  }) => {
    setLoading(true);
    return SetNewPasswordRequest(values)
      .then((res) => {
        const resource = res.data.user;
        const token = res.data.token;

        if (res.data.status === 401) {
          setInvalidPasswordTokenError(res.data.message);
        } else {
          localStorage.setItem("authToken", token);
          localStorage.removeItem("CartToken");
          setCurrentUser(resource);
          setAuthenticated(true);

          navigate(res.data.redirect_path);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ReadChatMessages = async () => {
    return MarkMessagesAsRead(currentUser.pocketmed_uuid)
      .then(() => {
        setShowChatGreenDot(false);
        setShowChatReminderModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const SelectCreditCard = async (paymentProfileId: string) => {
    return SelectCardRequest(paymentProfileId)
      .then(() => {
        reloadUser();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DeleteCreditCard = async (paymentProfileId: string) => {
    return DeleteCardRequest(paymentProfileId)
      .then((res: any) => {
        setCurrentUser({ ...currentUser, credit_cards: res.data.credit_cards });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // User Email Confirmation
  const UserEmailConfirmation = async (token: string) => {
    return UserEmailConfirmationRequest(token)
      .then((res) => {
        // reLogin(); // Unneeded since we now have a proper redirect system on no auth
        navigate("/account/profile");
      })
      .catch((err) => {
        navigate("/*", {
          state: {
            invalid_token: true,
          },
        });
        console.log(err);
        rollbar.error(err);
      });
  };

  const context = {
    authenticated,
    currentUser,
    loading,
    loginError,
    showRetakeModal,
    retakePending,
    showChatReminderModal,
    showChatGreenDot,
    showResumeVisitModal,
    showUnderReviewVisitModal,
    showRefillAvailableModal,
    showResumeRefillModal,
    showRefillInProgress,
    setShowRefillInProgress,
    showChatModal,
    resetPasswordMessage,
    resetPasswordError,
    invalidPasswordTokenError,
    loginCheck,
    setLoginCheck,
    setLoading,
    setAuthenticated,
    setCurrentUser,
    setLoginError,
    setResetPasswordMessage,
    setResetPasswordError,
    setInvalidPasswordTokenError,
    reloadUser,
    LoginUser,
    Logout,
    Signup,
    UpdateUser,
    UpdatePassword,
    setShowRetakeModal,
    setRetakePending,
    setShowChatReminderModal,
    setShowChatGreenDot,
    setShowResumeVisitModal,
    setShowUnderReviewVisitModal,
    setShowRefillAvailableModal,
    setShowResumeRefillModal,
    setShowChatModal,
    showDuplicateCartModal,
    setShowDuplicateCartModal,
    ForgotPasswordCall,
    ValidateResetPasswordToken,
    SetNewPassword,
    SelectCreditCard,
    DeleteCreditCard,
    CreateFacebookUser,
    FinishOauthSetup,
    ReadChatMessages,
    UserEmailConfirmation,
    setOtpLoginError,
    otpLoginError,
    defaultProductVariant,
    setDefaultProductVariant,
  };

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

export default AuthContextProvider;
