import React from "react";

type NewShippingAddressItemProps = {
  address: {
    id: any;
    first_name: any;
    last_name: any;
    street_1: any;
    street_2: any;
    city: any;
    state: any;
    zip: any;
    phone: any;
    is_valid: any;
  };
  onSelect: any;
  selectedAddressId: any;
  index: any;
};

const NewShippingAddressItem = ({
  address: {
    id,
    first_name,
    last_name,
    street_1,
    street_2,
    city,
    state,
    zip,
    phone,
    is_valid,
  },
  onSelect,
  selectedAddressId,
  index,
}: NewShippingAddressItemProps) => {
  let itemClassName = "shipping-address-item";

  if (!is_valid) itemClassName += " shipping-address-item--invalid";
  if (id === selectedAddressId)
    itemClassName +=
      " shipping-address-item--selected no-mouseflow mosueflow-hidden";

  return (
    <div className={itemClassName}>
      <div
        className="shipping-address-item__street no-mouseflow mosueflow-hidden"
        onMouseDown={onSelect}
      >
        {`${street_1} ${street_2}, ${city}, ${state}, ${zip}`}
      </div>
    </div>
  );
};

export default NewShippingAddressItem;
