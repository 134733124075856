import React from "react";
import LabReportImage from "assets/images/lab_report.png";
import "./styles.scss";

const LabReportModal = () => {
  return (
    <div
      aria-labelledby="labReportModalTitle"
      className="modal zoom-in"
      id="labReportModal"
      role="dialog"
      tabIndex={-1}
      aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <img src={LabReportImage} alt="lab-report" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LabReportModal;
