import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import passwordlIcon from "../../assets/images/login/password.svg";
import eyeIcon from "../../assets/images/login/eye.svg";
import eyeOffIcon from "../../assets/images/login/eye-off.svg";
import { Field } from "formik";

type HOCPasswordFieldProps = {
  name?: string;
  classes?: string;
  placeholder?: string;
  onKeyUp?: () => void;
};

const HOCPasswordField = ({
  name,
  classes,
  placeholder,
  onKeyUp,
}: HOCPasswordFieldProps) => {
  const [showHidePassword, setShowHidePassword] = useState(false);
  return (
    <div className={`input-group row ${classes} mx-0`}>
      <ReactSVG className="input__icon password-icon" src={passwordlIcon} />
      <Field
        name={name}
        type={showHidePassword ? "text" : "password"}
        className="input no-mouseflow mouseflow-hidden password-field"
        placeholder={placeholder}
        onKeyUp={onKeyUp}
      />
      <ReactSVG
        className="show-hide-password eye-icon"
        src={showHidePassword ? eyeIcon : eyeOffIcon}
        onClick={() => setShowHidePassword(!showHidePassword)}
      />
    </div>
  );
};

export default HOCPasswordField;
