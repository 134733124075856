import React, { useState } from "react";
import Button from "components/Button";
import DnaLoader from "components/DnaLoader";
import * as Yup from "yup";
import useLatestCart from "hooks/useLatestCart";
import { useNavigate } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import { SsnVerificationRequest } from "apis";
import SsnInput from "react-otp-input";

import "./styles.scss";

const SsnSchema = Yup.object().shape({
  code1: Yup.string().required("Required"),
  code2: Yup.string().required("Required"),
  code3: Yup.string().required("Required"),
  code4: Yup.string().required("Required"),
});

const SsnVerification = () => {
  const [ssnErrorMessage, setSsnErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [ssnCode, setSsnCode] = useState<string>("");
  const latestCart = useLatestCart();
  const navigate = useNavigate();

  const handleInputChange = (value: any) => {
    if (value.length <= 1) {
      return value;
    } else {
      return value.substring(0, value.length - 1);
    }
  };

  const handleSsnCodeSubmit = () => {
    setIsLoading(true);
    SsnVerificationRequest(ssnCode, latestCart?.id)
      .then((res) => {
        navigate(`/${res.data.redirect_path}`);
      })
      .catch((err) => {
        setSsnErrorMessage(err?.response?.data?.error_message);

        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading) return <DnaLoader />;

  return (
    <div className="container checkout-page product-page identity-verification-page">
      <div className="content">
        <div className="row shipping-address-content pt-0 pt-md-3 justify-content-center product-overview">
          <div className="col col-lg-6">
            <div className="mb-2 mt-2">
              <p className="modal-text">
                Please enter the last 4 digits of your Social Security Number.
              </p>
            </div>
            <div className="ssn-content d-flex flex-column mt-1">
              <SsnInput
                value={ssnCode}
                onChange={setSsnCode}
                numInputs={4}
                containerStyle="ssn-fields"
                renderInput={(props) => <input {...props} />}
              />
              {ssnErrorMessage && (
                <div className="ssn-error">{ssnErrorMessage}</div>
              )}
              <div className="actions">
                <Button
                  type="button"
                  color="secondary"
                  fullWidth={true}
                  disabled={ssnCode.length !== 4}
                  onClick={() => handleSsnCodeSubmit()}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SsnVerification;
