/* eslint-disable jsx-a11y/heading-has-content */
import React from "react";
import { Trans } from "react-i18next";

type Props = {
  path: string;
  readonly components?: {
    [key: string]: JSX.Element;
  };
};

const baseComponents = {
  h1: <h1 />,
  h2: <h2 />,
  h3: <h3 />,
  h4: <h4 />,
  h5: <h5 />,
  h6: <h6 />,
  small: <small />,
  p: <p />,
  label: <label />,
  span: <span />,
};

const Translator = ({ path, components }: Props) => {
  return (
    <Trans i18nKey={path} components={{ ...components, ...baseComponents }} />
  );
};

export default Translator;
