import React from "react";
import { Dna } from "react-loader-spinner";
import { useLocation } from "react-router-dom";

const DnaLoader = () => {
  const location = useLocation();
  const isVerificationLoader = ['/checkout/shipping', '/checkout/ssn-verification'].includes(location.pathname);
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        top: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        zIndex: 99999,
        backgroundColor: "#f1f8fb",
      }}>
      <Dna
        visible={true}
        height="80"
        width="80"
        ariaLabel="dna-loading"
        wrapperStyle={{}}
        wrapperClass={"dna-wrapper"}
      />
      <div className={`${!isVerificationLoader && 'd-none'}`}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          zIndex: 99999,
          color: "#000000",
          fontSize: "16px",
          fontWeight: 700,
          marginTop: "170px",
        }}>
        <p>Thank you for your patience.</p>
        <p>We are analyzing your response…</p>
      </div>
    </div>
  );
};

export default DnaLoader;
