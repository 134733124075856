import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../components/Button";


const PatientRequest = () => {
  const navigate = useNavigate();

  return (
    <div className="account-card__status">
      <h3>Order Status</h3>
      <p>
        Please {" "}
        <Link
          to="/account/order-status"
        >
          Download
        </Link>
        {""} and print the document and provide it to your doctor.
      </p>
      <Button
        color="secondary"
        size="small"
        classes="d-flex justify-content-center"
        onClick={() => navigate("/account/order-status")}
      >
        DOWNLOAD
      </Button>
    </div>
  )
};

export default PatientRequest;
