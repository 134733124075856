import React, { useState } from "react";

import sildenafilBottle from "assets/images/home/sildenafil_bottle_new_with_shadow.png";
import tadalafilBottle from "assets/images/home/tadalafil_bottle_new_with_shadow.png";
import { capitalize, upperFirst } from "lodash";
import EditModal from "./Modals/EditModal";
import { currencyFormatter } from "helpers/Formatter";

type CartItemProps = {
  cart: any;
  products: any;
  quantities: any;
  current_variant: any;
  variantsPerProduct: any;
  allowEdit?: any;
  updateCart?: any;
  handleProductChange: any;
  handleDosageChange: any;
  handleQuantityChange: any;
  disabledOptions: any;
  discountPercentage: any;
  handleCustomInputChange?: any;
  errors?: any;
  setErrors?: any;
};

const CartItem = ({
  cart,
  products,
  quantities,
  current_variant,
  variantsPerProduct,
  allowEdit,
  updateCart,
  handleProductChange,
  handleDosageChange,
  handleQuantityChange,
  disabledOptions,
  discountPercentage,
  handleCustomInputChange,
  errors,
  setErrors,
}: CartItemProps) => {
  const [showEditOptions, setShowEditOptions] = useState(false);
  const [show, setShow] = useState(false);
  const maxQtyError = cart.errors && cart.errors.quantity;

  const toggleEditOptions = (
    cart: any,
    updateCart: any,
    showEditOptions: any,
    setShowEditOptions: any
  ) => {
    if (cart.errors?.quantity) {
      updateCart({ cart: cart });
    }

    setShowEditOptions(!showEditOptions);
  };

  return (
    <>
      <div className="current-product">
        <div className="d-flex flex-row">
          <div className="med-bottles-box">
            {cart.product_id === 7 ? (
              <img
                className="med-bottles"
                src={sildenafilBottle}
                alt="Sildenafil"
              />
            ) : (
              cart.product_id === 8 && (
                <img
                  className="med-bottles"
                  src={tadalafilBottle}
                  alt="Tadalafil"
                />
              )
            )}
          </div>
          <div className="cart-item-header w-100">
            <p className="current-product__name mb-0">
              <span className="variant-fullname">
                {cart.product_simple_name}{" "}
                {`${cart.product_variant.dosage_value}mg`}
                &nbsp;
                {cart.product_simple_name.includes("Sildenafil") ? (
                  <span>(Generic Viagra)</span>
                ) : (
                  <span>(Generic Cialis)</span>
                )}
              </span>
              <span className="variant-price">
                {currencyFormatter(cart.price)}
              </span>
            </p>
            <p className="current-product__info">
              <span className="variant__quantity">
                {`${cart.quantity} ${capitalize(cart.product_variant.unit)}${
                  cart.quantity > 1 && "s"
                }`}
                <br />
                <a
                  href="#"
                  onClick={(e: any) => {
                    e.preventDefault();
                    setShowEditOptions(true);
                    setShow(true);
                  }}>
                  Edit
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>
      <EditModal
        show={show}
        setShow={setShow}
        cart={cart}
        products={products}
        quantities={quantities}
        current_variant={current_variant}
        showEditOptions={showEditOptions}
        variantsPerProduct={variantsPerProduct}
        handleProductChange={(product: any) => handleProductChange(product)}
        handleDosageChange={(dosage: any) => handleDosageChange(dosage)}
        handleQuantityChange={(quantity: any) => handleQuantityChange(quantity)}
        toggleEditOptions={() =>
          toggleEditOptions(
            cart,
            updateCart,
            showEditOptions,
            setShowEditOptions
          )
        }
        disabledOptions={disabledOptions}
        discountPercentage={discountPercentage}
        errors={errors}
        setErrors={setErrors}
      />
      <div className="pl-3 pr-3 alert alert-helper">
        {cart.max_quantity !== null && !maxQtyError && (
          <p className="mb-0 max-quantity-info">
            Your prescription has <strong>{cart.max_quantity} tablets</strong>{" "}
            available to order
          </p>
        )}
      </div>
      {maxQtyError && (
        <p className="error__message font-weight-normal">
          {upperFirst(maxQtyError)}
        </p>
      )}
      <hr />
    </>
  );
};

export default CartItem;
