import React, { Dispatch, SetStateAction, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { AuthContext } from "Contexts/AuthContext";
import CreditCardForm from "containers/Checkout/OrderVerification/CreditCardForm";
import useCurrentUser from "hooks/useCurrentUser";
import { isEmpty } from "lodash";

import "./styles.scss";

type Props = {
  show: boolean;
  setShowFormModal: Dispatch<SetStateAction<boolean>>;
};

const NewCreditCardModal = ({ show, setShowFormModal }: Props) => {
  const { reloadUser, setLoading } = useContext(AuthContext);
  const onSuccessSubmit = () => {
    reloadUser();
    setShowFormModal(false);
    setLoading(false);
  };
  const user = useCurrentUser();

  return (
    <Modal
      show={show}
      onHide={() => setShowFormModal(false)}
      centered
      animation={false}
      className="new-credit-card-modal"
    >
      <Modal.Body>
        {!isEmpty(user?.shipping_addresses) ? ( 
        <CreditCardForm
          onSuccessSubmit={onSuccessSubmit}
          buttonText={"Save Card"}
        />
        ): (
          <div className= "content">
          <p className="no-payment-options"> To add a Payment Method, there must be at least one Shipping Address added to your account. Please add one before proceeding.</p>
          <button
              className="btn-aum small d-flex justify-content-center secondary get-started-btn"
              onClick={() => setShowFormModal(false)}
            >
              Close
            </button></div>
        )}
      
      </Modal.Body>
    </Modal>
  );
};

export default NewCreditCardModal;
