import React, { useContext, useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { isEmpty } from "lodash";
import HOCPasswordField from "../../components/HOCPasswordField";
import Translator from "../../components/Translator";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../Contexts/AuthContext";

const PasswordEditSchema = Yup.object().shape({
  password: Yup.string()
    .required("Required")
    .min(8, "Password must be 8 characters long")
    .matches(
      /^((?=.*\d)|(?=.*[A-Z])|(?=.*\W)).*$/,
      "Include at least one uppercase letter or number or symbol"
    ),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required"),
});

const PasswordEdit = () => {
  const location = useLocation();
  const token = location.search.split("_token=")[1];
  const {
    SetNewPassword,
    ValidateResetPasswordToken,
    invalidPasswordTokenError,
    setInvalidPasswordTokenError,
  } = useContext(AuthContext);
  const [passwordMinLength, setPasswordMinLength] = useState<boolean>(false);
  const [passwordVariation, setPasswordVariation] = useState<boolean>(false);
  const [characterValidation, setCharacterValidation] =
    useState<boolean>(false);

  useEffect(() => {
    if (!isEmpty(token)) {
      ValidateResetPasswordToken(token);
    }
  }, [token]);

  const validatePasswordChecks = (value: string) => {
    const regex = /^((?=.*\d)|(?=.*[A-Z])|(?=.*\W)).*$/;

    if (value.length > 7 && passwordMinLength === false) {
      setPasswordMinLength(true);
    }

    if (regex.test(value) && passwordVariation === false) {
      setPasswordVariation(true);
    }

    if (value.length < 8) {
      setPasswordMinLength(false);
    }

    if (!regex.test(value)) {
      setPasswordVariation(false);
    }
  };

  const shouldButtonDisabled = (values: any) => {
    const { password, passwordConfirmation } = values;

    return !(
      !isEmpty(password) &&
      passwordMinLength &&
      passwordVariation &&
      password === passwordConfirmation
    );
  };

  return (
    <main className="main">
      <div className="session-page">
        <div className="content">
          <h3>Create your password</h3>
          {!isEmpty(invalidPasswordTokenError) && (
            <span className="input-error">{`${invalidPasswordTokenError}`}</span>
          )}
          <Formik
            initialValues={{
              password: "",
              passwordConfirmation: "",
              resetToken: token,
            }}
            validationSchema={PasswordEditSchema}
            onSubmit={(values) => {
              setInvalidPasswordTokenError("");
              SetNewPassword(values);
            }}
          >
            {({ errors, values, touched }) => (
              <Form className="new_user">
                <div className="field">
                  <label className="input-label" htmlFor="password">
                    <Translator path="signIn.fields.passwordTitle" />
                  </label>
                  <HOCPasswordField
                    placeholder="Password"
                    name="password"
                    onKeyUp={() => validatePasswordChecks(values.password)}
                  />
                  {errors.password && touched.password ? (
                    <div className="input-errors">{errors.password}</div>
                  ) : null}
                </div>
                <div className="field">
                  <label className="input-label" htmlFor="passwordConfirmation">
                    <Translator path="signIn.fields.passwordConfirmationTitle" />
                  </label>
                  <HOCPasswordField
                    placeholder="Confirm password"
                    name="passwordConfirmation"
                    onKeyUp={() => validatePasswordChecks(values.password)}
                  />
                  {errors.passwordConfirmation &&
                  touched.passwordConfirmation ? (
                    <div className="input-errors">
                      {errors.passwordConfirmation}
                    </div>
                  ) : null}
                </div>
                <div className="password-checks mb-0">
                  <div className="check-wrapper length">
                    <label
                      className={`password-length-check check-icon ${
                        passwordMinLength && "checked"
                      }`}
                    ></label>
                    <span className="check-text">
                      <Translator path="registration.passwordMustBe" />
                    </span>
                  </div>
                  <div className="check-wrapper case">
                    <label
                      className={`password-regx-check check-icon ${
                        passwordVariation && "checked"
                      }`}
                    ></label>
                    <span className="check-text">
                      <Translator path="registration.includeAtleast" />
                    </span>
                  </div>
                </div>
                <button
                  className={`btn submit-btn text-uppercase ${
                    shouldButtonDisabled(values) ? "disabled" : ""
                  }`}
                  type="submit"
                  disabled={shouldButtonDisabled(values) || undefined}
                >
                  Continue
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </main>
  );
};

export default PasswordEdit;
