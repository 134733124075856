import React, { useContext, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import DayJS from "dayjs";
import { AuthContext, UserPasswordType } from "Contexts/AuthContext";
import useCurrentUser from "hooks/useCurrentUser";
import Translator from "components/Translator";
import Button from "components/Button";
import HOCDateMask from "components/HOCDateMask";
import HOCPasswordField from "components/HOCPasswordField";
import HOCPhoneNumberMask from "components/HOCPhoneNumberMask";
import OtpModal from "./Modals/OtpModal";
import {
  ResendEmailConfirmationRequest,
  UpdateUserPasswordRequest,
  UpdateUserRequest,
  GenerateOtpRequest,
} from "apis";
import { useRollbar } from "@rollbar/react";
import { isEmpty } from "lodash";
import { NAME_REGEX } from "helpers/Constants";


const PatientProfileSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("Required")
    .matches(NAME_REGEX, "Must enter a proper name"),
  last_name: Yup.string()
    .required("Required")
    .matches(NAME_REGEX, "Must enter a proper name"),
  date_of_birth: Yup.string()
    .min(10, "Must enter a proper date")
    .required("Required"),
  gender: Yup.string().required("Required"),
  mobile_phone: Yup.string()
    .min(14, "Must enter proper number")
    .required("Required"),
  home_phone: Yup.string().min(14, "Must enter proper number").notRequired(),
  email: Yup.string().email("Invalid email").required("Required"),
});

const PatientPasswordSchema = Yup.object({
  current_password: Yup.string()
    .required("Required")
    .min(8, "Password must be 8 characters long")
    .matches(
      /^((?=.*\d)|(?=.*[A-Z])|(?=.*\W)).*$/,
      "Include at least one uppercase letter or number or symbol"
    ),
  password: Yup.string()
    .required("Required")
    .min(8, "Password must be 8 characters long")
    .matches(
      /^((?=.*\d)|(?=.*[A-Z])|(?=.*\W)).*$/,
      "Include at least one uppercase letter or number or symbol"
    ),
  password_confirmation: Yup.string()
    .required("Required")
    .min(8, "Password must be 8 characters long")
    .matches(
      /^((?=.*\d)|(?=.*[A-Z])|(?=.*\W)).*$/,
      "Include at least one uppercase letter or number or symbol"
    )
    .oneOf([Yup.ref("password")], "Your passwords do not match."),
});

const Profile = () => {
  const { UpdateUser, reloadUser, setCurrentUser } = useContext(AuthContext);
  const currentUser = useCurrentUser();
  const [ageLimitError, setAgeLimitError] = useState<string>("");
  const [genderError, setGenderError] = useState<string>("");
  const [passwordUpdateErrors, setPasswordUpdateErrors] = useState<{
    current_password: string;
  }>();
  const [updatePasswordMessage, setUpdatePasswordMessage] =
    useState<string>("");
  const [pocketmedError, setPocketmedError] = useState<string>("");
  const [openOtpModal, setOpenOtpModal] = useState<boolean>(false);
  const [limitExceededError, setLimitExceededError] = useState<boolean>(false);
  const [mobileError, setMobileError] = useState<string>("");

  const rollbar = useRollbar();

  const handleResendEmailConfirmation = () => {
    ResendEmailConfirmationRequest()
      .then((res) => {
        reloadUser();
      })
      .catch((err) => {
        console.log(err);
        rollbar.error(err);
      });
  };

  const handleUpdatePassword = (values: UserPasswordType, resetForm: any) => {
    return UpdateUserPasswordRequest(values)
      .then((res) => {
        if (res.status === 200) {
          resetForm();
          setUpdatePasswordMessage("Password successfully Updated.");
        }
      })
      .catch((err) => {
        setPasswordUpdateErrors(err.response.data.errors);
        console.log(err);
      });
  };

  const shouldShowResendEmail =
    !currentUser?.confirmed && !!isEmpty(currentUser?.confirmation_sent_at);

  const validGender = (gender: string) => {
    if (gender === "male") {
      return true;
    } else {
      setGenderError("Service only available for male gender.");
      return false;
    }
  };

  const validDateOfBirth = (dob: any) => {
    const age = DayJS().diff(DayJS(dob), "years", true);

    if (age >= 25 && age <= 75) {
      return true;
    } else {
      setAgeLimitError(
        `${age < 25
          ? "Min age is 25 to continue"
          : "Please call (844) 745-3362 for assistance"
        }`
      );

      return false;
    }
  };

  const handleSubmit = (values: any) => {
    const newUserData = {
      ...values,
      date_of_birth: DayJS(values.date_of_birth).format("YYYY-MM-DD"),
      id: currentUser?.id,
    };

    UpdateUserRequest(newUserData)
      .then((res) => {
        if (res.data.status === 400) {
          setPocketmedError(res.data.message);
          return false;
        }
        setCurrentUser(res.data.user);
        reloadUser();
      })
      .catch((err) => {
        console.log(err);
        setMobileError(err.response.data.errors.mobile_phone);
      });
  };

  const handleVerifyNow = () => {
    GenerateOtpRequest()
      .then((res) => {
        setOpenOtpModal(true);
        setLimitExceededError(false);
      })
      .catch((err) => {
        if (err?.response?.data?.limit_exceeded) {
          setOpenOtpModal(true);
          setLimitExceededError(true);
        }

        console.log(err);
        rollbar.error(err);
      });
  };

  return (
    <div className="account-card padded">
      <Formik
        initialValues={{
          first_name: currentUser?.first_name,
          last_name: currentUser?.last_name,
          date_of_birth: currentUser?.date_of_birth_formatted || "",
          gender: currentUser?.gender || "male",
          mobile_phone: currentUser?.mobile_phone || "",
          email: currentUser?.email,
          home_phone: currentUser?.home_phone || "",
        }}
        validationSchema={PatientProfileSchema}
        onSubmit={(values) => {
          validDateOfBirth(values.date_of_birth);
          setAgeLimitError("");
          if (
            validDateOfBirth(values.date_of_birth) &&
            validGender(values.gender)
          ) {
            handleSubmit(values);
            setPocketmedError("");
          }
        }}
      >
        {({ errors, touched, resetForm, setFieldValue }) => (
          <Form className="user-profile-form ">
            <div className="row">
              <div className="form-group field">
                <label className="input-label" htmlFor="firstName">
                  <Translator path="account.profile.userFirstName" />
                </label>
                <div className="input-group row flex-row mx-0">
                  <div className="input__icon user email-icon user-icon"></div>
                  <Field
                    name="first_name"
                    id="first_name"
                    className="lg no-mouseflow mouseflow-hidden"
                    disabled={currentUser?.info_provided ? "disabled" : ""}
                  />
                </div>
                {errors.first_name && touched.first_name ? (
                  <div className="input-error">{errors.first_name}</div>
                ) : null}
              </div>

              <div className="form-group field">
                <label className="input-label" htmlFor="_n">
                  <Translator path="account.profile.userLastName" />
                </label>
                <div className="input-group row flex-row mx-0">
                  <div className="input__icon user email-icon user-icon"></div>
                  <Field
                    name="last_name"
                    id="last_name"
                    className="lg no-mouseflow mouseflow-hidden"
                    disabled={currentUser?.info_provided ? "disabled" : ""}
                  />
                </div>
                {errors.last_name && touched.last_name ? (
                  <div className="input-error">{errors.last_name}</div>
                ) : null}
              </div>
            </div>
            <div className="row">
              <div className="form-group field">
                <label className="input-label" htmlFor="dateOfBirth">
                  <Translator path="account.profile.dateOfBirth" />
                </label>
                <div className="input-group">
                  <div className="input__icon date email-icon user-icon"></div>
                  <Field name="date_of_birth">
                    {({ field }: any) => (
                      <HOCDateMask
                        field={field}
                        onChange={() => {
                          setAgeLimitError("");
                          setPocketmedError("");
                        }}
                        disabled={currentUser?.info_provided}
                      />
                    )}
                  </Field>
                </div>
                {errors.date_of_birth && touched.date_of_birth ? (
                  <div className="input-error">{errors.date_of_birth}</div>
                ) : null}
                {!errors.date_of_birth && ageLimitError && (
                  <div className="input-error mt-2">{ageLimitError}</div>
                )}
              </div>
              <div className="form-group field">
                <label className="input-label" htmlFor="gender">
                  <Translator path="account.profile.gender" />
                </label>
                <div className="input-group">
                  <div className="input__icon gender email-icon user-icon"></div>
                  <Field
                    name="gender"
                    as="select"
                    className="input lg with-icon input-select gender no-mouseflow mouseflow-hidden"
                    disabled={currentUser?.info_provided ? "disabled" : ""}
                    onChange={(e: any) => {
                      setFieldValue("gender", e.target.value);
                      setGenderError("");
                      setPocketmedError("");
                    }}
                  >
                    <option value="male">
                      <Translator path="account.profile.male" />
                    </option>
                    <option value="female">
                      <Translator path="account.profile.female" />
                    </option>
                  </Field>
                </div>
                {errors.gender && touched.gender ? (
                  <div className="input-error">{errors.gender}</div>
                ) : null}
                {genderError && (
                  <div className="input-error mt-2">{genderError}</div>
                )}
              </div>
            </div>
            {currentUser?.info_provided && (
              <p className="mb-4">
                <Translator path="account.profile.pleaseEmail" />
                <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>
                  {process.env.REACT_APP_SUPPORT_EMAIL}
                </a>
                <Translator path="account.profile.callUs" />
                <a href="tel:(000) 001-0001">
                  {process.env.REACT_APP_PHONE_NUMBER}
                </a>
                <Translator path="account.profile.changeAnyInfo" />
              </p>
            )}
            <div className="row">
              <div className="form-group field">
                <label className="input-label">
                  <Translator path="account.profile.mobileNumber" />
                </label>
                <HOCPhoneNumberMask
                  name="mobile_phone"
                  size="lg"
                  setError={setMobileError}
                  setField={setFieldValue}
                />
                {errors.mobile_phone && touched.mobile_phone ? (
                  <div className="input-error">{errors.mobile_phone}</div>
                ) : null}
                {mobileError && (
                  <div className="input-error">Phone {mobileError}</div>
                )}
                <div className="otp-verification-content">
                  {currentUser?.otp_verified ? (
                    <>
                      <span className="verified-otp-text">OTP is verified</span>
                    </>
                  ) : (
                    <>
                      {currentUser?.mobile_phone && (
                        <>
                          <span className="unverified-otp-text">
                            OTP is not verified please
                          </span>
                          <button
                            className="verify-phone-otp"
                            type="button"
                            onClick={() => handleVerifyNow()}
                          >
                            Verify Now
                          </button>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="form-group field">
                <label className="input-label">
                  <Translator path="account.profile.homeNumber" />
                </label>
                <HOCPhoneNumberMask name="home_phone" size="lg" />
                {errors.home_phone && touched.home_phone ? (
                  <div className="input-error">{errors.home_phone}</div>
                ) : null}
              </div>
            </div>
            <div className="row">
              <div className="form-group field">
                <label className="input-label" htmlFor="email">
                  <Translator path="account.profile.email" />
                </label>
                <div className="input-group row flex-row">
                  <div className="input__icon email user-icon"></div>
                  <Field
                    name="email"
                    className="input lg"
                    type="email"
                    onChange={(e: any) => {
                      setFieldValue("email", e.target.value);
                      setPocketmedError("");
                    }}
                  />
                </div>
                {errors.email && touched.email ? (
                  <div className="input-error">{errors.email}</div>
                ) : null}
              </div>
            </div>
            {shouldShowResendEmail && (
              <>
                <p className="email_verification_info">
                  <Translator path="account.profile.emailVerificationInfo" />
                </p>
                <p className="email_verification_link">
                  <Button
                    type="button"
                    onClick={() => handleResendEmailConfirmation()}
                  >
                    <Translator path="account.profile.resendConfirmationLink" />
                  </Button>
                </p>
              </>
            )}
            {!currentUser?.confirmed && currentUser?.confirmation_sent_at && (
              <>
                <p className="email_verification_sent">
                  Confimation email has already been sent.
                </p>
              </>
            )}
            <div className="actions">
              {!isEmpty(pocketmedError) && (
                <div className="input-error text-center mb-2">
                  {pocketmedError}
                </div>
              )}
              <Button
                type="reset"
                size="small"
                color="ghost"
                fullWidth={false}
                classes="btn-ghost dark"
                onClick={() => resetForm}
              >
                <Translator path="account.profile.cancelButton" />
              </Button>
              <Button
                type="submit"
                size="small"
                color="secondary"
                fullWidth={false}
              >
                <Translator path="account.profile.saveButton" />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <hr />
      <h3>
        <Translator path="account.profile.password" />
      </h3>
      <Formik
        initialValues={{
          current_password: "",
          password: "",
          password_confirmation: "",
        }}
        validationSchema={PatientPasswordSchema}
        onSubmit={(values, { resetForm }) => {
          handleUpdatePassword(values, resetForm);
        }}
      >
        {({ errors, touched, resetForm }) => (
          <Form className="user-profile-form" id="user-passsword-form">
            <div className="row">
              <div className="form-group field">
                <label className="input-label">
                  <Translator path="account.profile.oldPassword" />
                </label>
                <HOCPasswordField
                  name="current_password"
                  classes="flex-row"
                  onKeyUp={() => {
                    setPasswordUpdateErrors(undefined);
                    setUpdatePasswordMessage("");
                  }}
                />
                {errors.current_password && touched.current_password ? (
                  <div className="input-error">{errors.current_password}</div>
                ) : null}
                {passwordUpdateErrors && (
                  <div className="input-error">
                    {`Current password ${passwordUpdateErrors.current_password}`}
                  </div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="form-group field">
                <label className="input-label">
                  <Translator path="account.profile.newPassword" />
                </label>
                <HOCPasswordField name="password" classes="flex-row" />
                {errors.password && touched.password ? (
                  <div className="input-error">{errors.password}</div>
                ) : null}
              </div>

              <div className="form-group field">
                <label className="input-label">
                  <Translator path="account.profile.repeatNewPassword" />
                </label>
                <HOCPasswordField
                  name="password_confirmation"
                  classes="flex-row"
                />
                {errors.password_confirmation &&
                  touched.password_confirmation ? (
                  <div className="input-error">
                    {errors.password_confirmation}
                  </div>
                ) : null}
              </div>
            </div>
            {updatePasswordMessage && (
              <p className="email_verification_sent">{updatePasswordMessage}</p>
            )}
            <div className="actions">
              <Button
                type="reset"
                size="small"
                color="ghost"
                fullWidth={false}
                classes="btn-ghost dark"
                onClick={() => resetForm}
              >
                <Translator path="account.profile.cancelButton" />
              </Button>
              <Button
                type="submit"
                size="small"
                color="secondary"
                fullWidth={false}
              >
                <Translator path="account.profile.updateButton" />
              </Button>
            </div>
          </Form>
        )}
      </Formik>

      <OtpModal
        openOtpModal={openOtpModal}
        setOpenOtpModal={setOpenOtpModal}
        limitExceededError={limitExceededError}
        setLimitExceededError={setLimitExceededError}
        isProfileModal={true}
        phoneCodeLimitCounter={0}
        emailCodeLimitCounter={0}
      />
    </div>
  );
};

export default Profile;
