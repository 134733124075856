import React, { useEffect, useState, useContext } from "react";
import { isEmpty, find, first } from "lodash";
import { useNavigate } from "react-router-dom";
import {
  GetProductVariantsRequest,
  ProductDetailConversionRequest,
} from "apis";
import { CartContext } from "Contexts/CartContext";
import { ReactSVG } from "react-svg";
import arrowLeftIcon from "assets/images/home/arrow-left.svg";
import useCurrentUser from "hooks/useCurrentUser";
import { OrderContext } from "Contexts/OrderContext";
import { AuthContext } from "Contexts/AuthContext";
import Translator from "components/Translator";
import DosageSelector from "./DosageSelector";
import ProductSelector from "./ProductSelector";
import QuantitySelector from "./QuantitySelector";
import PackagesList from "./PackagesList";
import Button from "components/Button";
import DnaLoader from "components/DnaLoader";
import { handleShowModals } from "../../Home/helpers";

import "./styles.scss";
import MarketingProductDetail from "../MarketingProductDetail";
import useLatestCart from "hooks/useLatestCart";
import BottleSection from "containers/MarketingPage/BottleSection";

type ProductDetailProps = {
  cart?: any;
  products?: any;
  marketingPage?: boolean;
};

const ProductDetail = ({ cart, marketingPage }: ProductDetailProps) => {
  const latestCart = useLatestCart();

  return (
    <div className="main cart-product-detail">
      <div className="row flex-column flex-md-row">
        <div className="col-lg-6 col-12" id="bottle-section">
          <BottleSection latestCart={latestCart} />
        </div>
        <div className="col-lg-6 col-12" id="product-detail-section">
          <MarketingProductDetail cart={cart} />
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
