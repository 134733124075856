import React, { useState, useEffect } from "react";
import { DebounceInput } from "react-debounce-input";
import Button from "../../../../../components/Button";

// import http from '@/utils/http';
import AllergyModal from "./AllergyModal";
import AddedAllergies from "./AddedAllergies";
import AllergySearchResults from "./AllergySearchResults";
import { FetchDosespotDataRequest } from "../../../../../apis";

type Props = {
  selectedAnswers: any;
  isProcessing: any;
  setIsProcessing: any;
  queriedAllergies: any;
  setQueriedAllergies: any;
  setErrors: any;
  addedAllergies: any;
  setAddedAllergies: any;
  setContinueButtonDisable: any;
};

const AllergySearch = ({
  selectedAnswers,
  isProcessing,
  setIsProcessing,
  queriedAllergies,
  setQueriedAllergies,
  setErrors,
  addedAllergies,
  setAddedAllergies,
  setContinueButtonDisable,
}: Props) => {
  const [showResults, setShowResults] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [showAllergyModal, setShowAllergyModal] = useState(false);
  const [activeAllergy, setActiveAllergy] = useState(null);

  useEffect(() => {
    if (isProcessing) {
      setShowResults(false);
    }
  }, [isProcessing]);

  const handleAllergySearch = (name: any) => {
    setErrors([]);
    setIsProcessing(true);
    setSearchText(name);

    FetchDosespotDataRequest({ name, api: "allergy" })
      .then((response: any) => {
        setQueriedAllergies(response.data);
        setShowResults(true);
        setErrors([]);
      })
      .catch((error: any) => {
        setErrors([error]);
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  useEffect(() => {
    setContinueButtonDisable(!Boolean(addedAllergies.length));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedAllergies]);

  const addCustomAllergy = () => {
    if (searchText.length) {
      const allergies = addedAllergies?.filter(
        (a: any) => a.name !== searchText
      );
      const newLocalId = addedAllergies?.length
        ? addedAllergies[addedAllergies.length - 1].local_id + 1
        : addedAllergies.length + 1;
      const newAllergy = { name: searchText, local_id: newLocalId };
      setAddedAllergies([...allergies, newAllergy]);
      setErrors([]);
      setSearchText("");
      setQueriedAllergies([]);
      setShowResults(false);
    } else {
      setErrors([
        "You need to search for a allergy before trying to add something.",
      ]);
    }
  };

  return (
    <>
      <div className="answer-option-wrapper">
        <h5 className="question-heading extra-label mt-4">
          {selectedAnswers[0]?.extra_label}
        </h5>
        <div className="text-search-wrapper">
          <DebounceInput
            type="text"
            className="answer-option-text-field"
            placeholder="Add allergy"
            value={searchText}
            debounceTimeout={300}
            onChange={(e: any) => handleAllergySearch(e.target.value)}
            onFocus={() => setShowResults(true)}
            onBlur={() => setShowResults(false)}
          />
          <Button
            classes="inline-add-btn"
            color="secondary"
            onClick={() => addCustomAllergy()}>
            Add
          </Button>
        </div>
        {showResults && (
          <AllergySearchResults
            queriedAllergies={queriedAllergies}
            setQueriedAllergies={setQueriedAllergies}
            searchText={searchText}
            addedAllergies={addedAllergies}
            setAddedAllergies={setAddedAllergies}
            setShowResults={setShowResults}
            setSearchText={setSearchText}
          />
        )}
        <AddedAllergies
          addedAllergies={addedAllergies}
          setActiveAllergy={setActiveAllergy}
          setShowAllergyModal={setShowAllergyModal}
        />
        <AllergyModal
          allergy={activeAllergy}
          showAllergyModal={showAllergyModal}
          setShowAllergyModal={setShowAllergyModal}
          addedAllergies={addedAllergies}
          setAddedAllergies={setAddedAllergies}
        />
      </div>
    </>
  );
};

export default AllergySearch;
