import React, { FC } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider as RollbarProvider } from "@rollbar/react";
import { useTranslation } from "react-i18next";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import ReactPixel from "react-facebook-pixel";

// Views/Containers
import MainRouter from "./mainRouter";

// Stylesheets
import "./App.scss";

const App: FC = () => {
  const { i18n } = useTranslation();
  const gtmParams = { id: process.env.REACT_APP_GTM_KEY || "" };

  ReactPixel.init(`${process.env.REACT_APP_FB_PIXEL_ID}` || "");
  ReactPixel.pageView();

  const rollbarConfig = {
    accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
    environment: process.env.REACT_APP_ENVIRONMENT,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      client: {
        javascript: {
          code_version: "1.0.0",
          source_map_enabled: true,
        },
      },
    },
  };

  return (
    <div className={`parent-wrapper ${i18n.language}`}>
      <RollbarProvider config={rollbarConfig}>
        <GTMProvider state={gtmParams}>
          <Router>
            <MainRouter />
          </Router>
        </GTMProvider>
      </RollbarProvider>
    </div>
  );
};

export default App;
