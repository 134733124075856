import React, { useContext } from "react";
import { isUndefined } from "lodash";
import Modal from "react-bootstrap/Modal";
import crossIcon from "assets/images/icons/entypo-cross.svg";

import { CartContext } from "Contexts/CartContext";
import Button from "components/Button";

import "./styles.scss";

type SkipAndSubmitModalProps = {
  show: boolean;
  setShow: any;
  view: string;
  cartId: number | undefined;
};

const SkipAndSubmitModal = ({
  show,
  setShow,
  view,
  cartId,
}: SkipAndSubmitModalProps) => {
  const { IdUploadNextStep, SelfieNextStep } = useContext(CartContext);

  const handleSkipPhoto = () => {
    if (!isUndefined(cartId)) {
      switch (view) {
        case "idUpload":
          IdUploadNextStep(cartId, true);
          break;
        case "selfieUpload":
          SelfieNextStep(cartId, true);
          break;
      }
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      className="drawer"
      dialogClassName="skip-and-submit-modal"
      contentClassName="px-3"
    >
      <Modal.Header>
        <h4 className="pt-2">
          Ok, you can skip for now and submit your photos later.
        </h4>
        <button
          onClick={() => setShow(false)}
          className="close-button"
          style={{ width: "70px" }}
        >
          <img src={crossIcon} alt="Close" style={{ width: "100%" }} />
        </button>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <p>
          Remember: your provider will have to verify your identity in order to
          prescribe treatment. After you complete your visit, you can upload
          your ID and selfie in your account.
        </p>
        <div className="d-flex flex-column mt-4">
          <Button
            color="secondary"
            classes=""
            size="large"
            onClick={() => handleSkipPhoto()}
            fullWidth
          >
            SKIP AND SUBMIT LATER
          </Button>
          <button
            className="cancel-btn mt-4 w-100"
            onClick={() => setShow(false)}
          >
            Cancel Skip
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SkipAndSubmitModal;
