// Can't call useContext in here and can't really convert this into a hook
export const handleShowModals = ({
  showRetake,
  showUnderReview,
  showResumeVisit,
  showRefillAvailableModal,
  setShowRetakeModal,
  setShowUnderReviewVisitModal,
  setShowResumeVisitModal,
  setShowRefillAvailableModal,
  showRefillInProgress,
  setShowRefillInProgress,
  is_order_being_processed,
  setShowDuplicateCartModal,
}: any) => {
  if (showRetake) {
    setShowRetakeModal(true);
    return false;
  }

  if (showUnderReview) {
    setShowUnderReviewVisitModal(true);
    return false;
  }

  if (is_order_being_processed) {
    setShowDuplicateCartModal(true);
    return false;
  }

  if (showRefillAvailableModal) {
    setShowRefillAvailableModal(true);
    return false;
  }

  if (showRefillInProgress) {
    setShowRefillInProgress(true);
    return false;
  }

  if (showResumeVisit) {
    setShowResumeVisitModal(true);
    return false;
  }

  return true;
};
