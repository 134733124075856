import React, { useState, useEffect, useContext } from "react";
import {
  Link,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { isEmpty } from "lodash";
import { ReactSVG } from "react-svg";
import { FetchDiscountTemplate, GetChatModalIframUrl } from "apis";
import { AuthContext } from "Contexts/AuthContext";
import { CartContext } from "Contexts/CartContext";
import { OrderContext } from "Contexts/OrderContext";
import CartDropdown from "./CartDropdown";
import LanguageSelect from "../LanguageSelect";
import Translator from "components/Translator";
import useLatestCart from "hooks/useLatestCart";
import useCurrentUser from "hooks/useCurrentUser";
import useCurrentOrder from "hooks/useCurrentOrder";
import ChatModal from "./Modals/ChatModal";
import logoIcon from "assets/images/home/logo.svg";
import phoneIcon from "assets/images/home/phone.svg";
import chatIcon from "assets/images/home/chat.svg";
import shoppingCartSolidIcon from "assets/images/home/shopping-cart-solid.svg";
import signInIcon from "assets/images/home/sign-in.png";
import userIcon from "assets/images/home/sign-in.svg";
import howItWorksIcon from "assets/images/home/how-it-works.png";
import helpIcon from "assets/images/home/help.png";
import profileIcon from "assets/images/home/profile.png";
import signOutIcon from "assets/images/home/sign-out.png";
import homeIcon from "assets/images/home/home.png";
import crossIcon from "assets/images/icons/entypo-cross.svg";
import menuIcon from "assets/images/icons/entypo-menu.svg";
import {
  DeleteRefillOrderCartRequest,
  FetchRefillContactStageCartRequest,
  ResumeCartRequest,
} from "apis";

import "./styles.scss";
import Iframe from "react-iframe";
import { scrollToPageTop } from "helpers/ScrollToTop";

const MainNavbar = () => {
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [url, setUrl] = useState<any>(undefined);
  const [bannerText, setBannerText] = useState<string>('navbar.newPatientCoupon');
  let [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const {
    Logout,
    authenticated,
    currentUser,
    showChatGreenDot,
    showChatModal,
    setShowChatModal,
    ReadChatMessages,
    setShowChatGreenDot,
    setShowResumeVisitModal,
    setShowRefillInProgress,
    reloadUser,
  } = useContext(AuthContext);
  const { DestroyCart } = useContext(CartContext);
  const navigate = useNavigate();
  const [refillCart, setRefillCart] = useState<any>(undefined);
  const [prescription, setPrescription] = useState<any>(undefined);
  const latestCart = useLatestCart();
  const user = useCurrentUser();
  const order = useCurrentOrder();
  const { setCurrentOrder } = useContext(OrderContext);
  const { setLatestCart } = useContext(CartContext);

  const validLatestCartSteps = [
    "shipping_address_info",
    "visit_id_upload",
    "visit_selfie_upload",
    "order_verification",
  ];

  useEffect(() => {
    if (searchParams.get("show_chat")) {
      setShowChatModal(true);
    }
  }, []);

  useEffect(() => {
    if (authenticated) {
      FetchRefillContactStageCartRequest()
        .then((res) => {
          const cart = res.data.cart;

          if (!isEmpty(cart)) {
            setLatestCart(res.data.cart);
          }
          setPrescription(res.data.prescription);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  useEffect(() => {
    user?.pocketmed_uuid &&
      GetChatModalIframUrl()
        .then((res) => {
          setUrl(res.data.url);
        })
        .catch((err) => console.log(err));
  }, [user?.pocketmed_uuid]);

  window.addEventListener("message", function (e) {
    const data = e.data;

    if (data && data.read === false) {
      setShowChatGreenDot(true);
    }
  });

  const handleContinueProcess = () => {
    if (!isEmpty(refillCart)) {
      navigate("/refill-product-detail");
    } else if (!isEmpty(latestCart)) {
      ResumeCartRequest()
        .then((res) => {
          navigate(res.data.redirect_path);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleDeleteCart = () => {
    if (!isEmpty(order?.prescription)) {
      DeleteRefillOrderCartRequest(order?.prescription.id)
        .then((res) => {
          // setRefillCart(undefined);
          setLatestCart(undefined);
          setCurrentOrder(undefined);
          setShowResumeVisitModal(false);
          setShowRefillInProgress(false);
          navigate("/account/order-refill");
          reloadUser();
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (!isEmpty(latestCart)) {
      DestroyCart(latestCart?.id);
    }
  };

  const showBlueBanner = () => {
    const routesToNotShow = /account|order-shipping|refill-product-detail/gi;
    const orderRoute = location.pathname.includes("/order/");

    return !(location.pathname.match(routesToNotShow) || orderRoute);
  };

  document.addEventListener("scroll", () => {
    const navbar = document.querySelector("nav.discount-spacing");

    navbar?.classList.add("slide-down");

    // Only do this on mobile/tablets
    if (window.innerWidth <= 800) {
      if (location.pathname.includes("product-selection")) {
        window.scrollY >= 360
          ? navbar?.classList.add("slide-top")
          : navbar?.classList.remove("slide-top");
      }
    }
  });

  useEffect(() => {
    const code = searchParams.get("discount")
    FetchDiscountTemplate(code || '')
    .then((res: any) => {
      if (res.data.banner_text !== null) {
        setBannerText(res.data.banner_text);
      }    
    })
    .catch((err: any) => {
      console.log(err);
    });
    
  },[searchParams.get("discount")])

  return (
    <nav className={`nav ${showBlueBanner() && "discount-spacing"}`}>
      {showBlueBanner() && (
        <div className="alert alert-primary brand-new-patient-coupon font-weight-bold w-100 text-center">
          <p className="mb-0">
            <Translator path={bannerText} />
          </p>
        </div>
      )}
      <div className="content">
        <button
          className="toggle-nav mobile mr-2 menu-icon"
          onClick={() => setShowDrawer(!showDrawer)}
        >
          {showDrawer ? (
            <img src={crossIcon} alt="Close" />
          ) : (
            <img src={menuIcon} alt="Menu" />
          )}
        </button>
        <Link
          className="nav-logo nav-link py-0 px-0 mr-md-3"
          to="/"
          onClick={() => {
            scrollToPageTop();
            reloadUser();
          }}
        >
          <ReactSVG src={logoIcon} wrapper="span" />
        </Link>
        <ul className={`nav-left mr-md-auto ${showDrawer && "show"}`}>
          <li className="logo mobile">
            <ReactSVG src={logoIcon} />
          </li>
          <li>
            <Link
              className="nav-link"
              to="/"
              onClick={() => {
                scrollToPageTop();
                setShowDrawer(false);
                reloadUser();
              }}
            >
              <img src={homeIcon} alt="" />
              <Translator path="navbar.links.toHome" />
            </Link>
          </li>
          <li>
            <a
              className="nav-link"
              href="/#process"
              onClick={() => setShowDrawer(false)}
            >
              <img src={howItWorksIcon} alt="" />
              <Translator path="navbar.links.toHowItWorks" />
            </a>
          </li>
          <li>
            <a
              className="nav-link"
              href="/#help"
              onClick={() => setShowDrawer(false)}
            >
              <img src={helpIcon} alt="" />
              <Translator path="navbar.links.toFaq" />
            </a>
          </li>
          {authenticated ? (
            <>
              <li className="mobile">
                <a className="nav-link" href="/account/profile">
                  <img src={profileIcon} alt="" />
                  <Translator path="navbar.links.yourProfile" />
                </a>
              </li>
              <li className="mobile">
                <button className="nav-link" onClick={() => Logout(false)}>
                  <img src={signOutIcon} alt="" />
                  <Translator path="navbar.links.signOut" />
                </button>
              </li>
            </>
          ) : (
            <li>
              <Link to={"/sign-in"} className="nav-link mobile">
                <img src={signInIcon} alt="" />
                <Translator path="navbar.links.signIn" />
              </Link>
            </li>
          )}
        </ul>
        <ul className="nav-right">
          <li
            className={`${
              authenticated
                ? "call-us authenticated"
                : "call-us un-authenticated"
            }`}
          >
            <a href="tel:(844) 745-3362" className="text-for-call">
              <span>
                <Translator path="navbar.right.needHelp" />
              </span>
              <div>
                <Translator path="navbar.right.needCallUs" />
              </div>
            </a>
            <span className="field-for-call">
              <Translator path="navbar.right.needHelp" />
            </span>
            <span className="field-for-call">
              <Translator path="navbar.right.needCallUs" />
            </span>
          </li>
          <li className="phone-number">
            <a className="btn-aum btn-ghost small" href="tel:(844) 745-3362">
              <ReactSVG src={phoneIcon} wrapper="span" />
              (844) 745-3362
            </a>
          </li>
          {!authenticated && (
            <li className={"phone-number-svg un-authenticated mr-1"}>
              <a href="tel:(844) 745-3362">
                <ReactSVG src={phoneIcon} wrapper="span" />
              </a>
            </li>
          )}
          <li
            className={`language-selector d-none ${
              !authenticated && "un-authenticated"
            }`}
          >
            <LanguageSelect />
          </li>
          {authenticated && (
            <>
              {user?.pocketmed_uuid && (
                <li className="chat">
                  <div
                    className={`new-message-notification ${
                      !showChatGreenDot ? "d-none" : ""
                    }`}
                  ></div>
                  <button
                    className="nav-link cart chat-button"
                    onClick={() => {
                      setShowChatModal(!showChatModal);
                      ReadChatMessages();
                    }}
                  >
                    <ReactSVG src={chatIcon} />
                  </button>
                </li>
              )}
              {user?.pocketmed_uuid && (
                <Iframe
                  url={url}
                  width="100%"
                  height="100%"
                  className="d-none"
                />
              )}
              <ChatModal
                show={showChatModal}
                setShow={setShowChatModal}
                url={url}
              />
              <li className="phone-number-svg">
                <a className="nav-link" href="tel:(844) 745-3362">
                  <ReactSVG src={phoneIcon} wrapper="span" />
                </a>
              </li>
              <li className="nav-cart ml-0">
                <button
                  className="nav-link cart cart-dropdown-btn"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <ReactSVG src={shoppingCartSolidIcon} />
                </button>
                <CartDropdown
                  validLatestCartSteps={validLatestCartSteps}
                  handleContinueProcess={handleContinueProcess}
                  handleDeleteCart={handleDeleteCart}
                />
              </li>
            </>
          )}
          <li className="nav-right-signin">
            {authenticated ? (
              <div className="user-menu">
                <button
                  className="nav-link btn text-color"
                  type="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <ReactSVG src={userIcon} wrapper="span" />
                  <span className="username no-mouseflow mouseflow-hidden">
                    {currentUser?.first_name} {currentUser?.last_name}
                  </span>
                </button>
                <div className="dropdown dropdown-menu aum-dropdown-menu">
                  <Link
                    to={"/account/order-refill"}
                    className="dropdown__row nav-link"
                  >
                    <Translator path="navbar.links.orderRefill" />
                  </Link>
                  <Link
                    to="/account/current-order"
                    className="dropdown__row nav-link"
                  >
                    <Translator path="navbar.links.currentOrder" />
                  </Link>
                  <Link
                    to="/account/profile"
                    className="dropdown__row nav-link"
                  >
                    <Translator path="navbar.links.yourProfile" />
                  </Link>
                  <button
                    onClick={() => Logout(false)}
                    className="dropdown__row nav-link"
                  >
                    <Translator path="navbar.links.logOut" />
                  </button>
                </div>
              </div>
            ) : (
              <button
                className="btn-aum primary small signIn-nav-btn "
                onClick={() => navigate("/sign-in")}
              >
                <Translator path="navbar.right.signIn" />
              </button>
            )}
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default MainNavbar;
