import React from "react";

type Props = {
  queriedAllergies: any;
  setQueriedAllergies: any;
  searchText: any;
  addedAllergies: any;
  setAddedAllergies: any;
  setShowResults: any;
  setSearchText: any;
};

const AllergySearchResults = ({
  queriedAllergies,
  setQueriedAllergies,
  searchText,
  addedAllergies,
  setAddedAllergies,
  setShowResults,
  setSearchText,
}: Props) => {
  return (
    <ul className="allergy-search-results">
      {queriedAllergies?.length === 0 && !!searchText?.length && (
        <li>
          <a href="#" onClick={(e: any) => e.preventDefault()}>
            No allergies found. Click the 'Add' button to add a custom allergy.
          </a>
        </li>
      )}
      {queriedAllergies &&
        queriedAllergies?.map((qa: any, idx: number) => (
          <li key={idx}>
            <a
              href="#"
              onMouseDown={(e: any) => {
                e.preventDefault();
                setQueriedAllergies([]);
                setAddedAllergies([
                  ...addedAllergies,
                  {
                    name: qa["Name"],
                    description: "",
                    local_id: addedAllergies?.length + 1,
                  },
                ]);
                setShowResults(false);
                setSearchText("");
              }}>
              {qa["Name"]}
            </a>
          </li>
        ))}
    </ul>
  );
};

export default AllergySearchResults;
