import React, { useEffect, useState, useContext } from "react";
import { isEmpty, find, first } from "lodash";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import {
  GetProductVariantsRequest,
  ProductDetailConversionRequest,
} from "apis";
import useCurrentUser from "hooks/useCurrentUser";
import { CartContext } from "Contexts/CartContext";
import { OrderContext } from "Contexts/OrderContext";
import { AuthContext } from "Contexts/AuthContext";
import Translator from "components/Translator";
import DosageSelector from "./DosageSelector";
import ProductSelector from "./ProductSelector";
import PackagesList from "./PackagesList";
import Button from "components/Button";
import DnaLoader from "components/DnaLoader";
import { handleShowModals } from "../../Home/helpers";
import GreenVanIcon from "assets/images/home/green_van.svg";
import arrowLeftIcon from "assets/images/home/arrow-left.svg";
import DrugInformation from "components/Modals/DrugInforamtionModal";

import "./styles.scss";

type ProductDetailProps = {
  cart?: any;
  products?: any;
  marketingPage?: boolean;
};

const MarketingProductDetail = ({
  cart,
  marketingPage,
}: ProductDetailProps) => {
  const [errors, setErrors] = useState<object>({});
  const [variants, setVariants] = useState<any>([]);
  const [products, setProducts] = useState<any>([]);
  const [packages, setPackages] = useState<any>([]);
  const [selectedProduct, setSelectedProduct] = useState<any>({});
  const [selectedVariant, setSelectedVariant] = useState<any>({});
  const [selectedQuantity, setSelectedQuantity] = useState<any>({});
  const [customInput, setCustomInput] = useState<boolean>(false);
  const [showDrugInformation, setShowDrugInformation] =
    useState<boolean>(false);
  const [maxQuantityExceeded, setMaxQuantityExceeded] =
    useState<boolean>(false);
  const [minQuantityExceeded, setMinQuantityExceeded] =
    useState<boolean>(false);
  const [showCustomField, setShowCustomField] = useState<boolean>(false);
  const [customInputHasValidValue, setCustomInputHasValidValue] =
    useState<boolean>(false);
  useState<boolean>(false); // TODO: Handle properly
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activePrescription, setActivePrescription] = useState<any>(undefined);
  const [refillCart, setRefillCart] = useState<any>({});

  const user = useCurrentUser();
  const navigate = useNavigate();
  const { UpdateCart, ProductDetailNextStep, setLatestCart } =
    useContext(CartContext);
  const { setCurrentOrder } = useContext(OrderContext);
  const {
    reloadUser,
    setShowResumeVisitModal,
    setShowUnderReviewVisitModal,
    retakePending,
    setShowRetakeModal,
    setShowRefillAvailableModal,
    setShowRefillInProgress,
    setShowDuplicateCartModal,
  } = useContext(AuthContext);

  const { MarketingNextStep } = useContext(CartContext);

  useEffect(() => {
    setRefillCart(
      find(user?.prescriptions, {
        product_variant_id: selectedVariant.id,
      })
    );
  }, [selectedVariant]);

  useEffect(() => {
    if (isEmpty(cart)) {
      return;
    }

    // Fetch data for dropdowns from the API
    GetProductVariantsRequest(cart.id, true)
      .then((res) => {
        const { products } = res.data;
        const currentProduct = find(products, { id: cart.product_id });
        const currentVariant = find(currentProduct.product_variants, {
          id: cart.product_variant_id,
        });
        const currentPrescription = find(user?.prescriptions, {
          product_variant_id: cart.product_variant_id,
        });

        setProducts(products);
        setVariants(currentProduct.product_variants);
        // setThresholds(currentVariant.price_thresholds);
        setPackages(currentVariant.packages);

        // TODO: Set selectedProduct based on whether a value is present in cart or not
        setSelectedProduct(currentProduct);
        setSelectedVariant(currentVariant);
        setSelectedQuantity(cart.quantity ?? currentVariant.min_order_quantity);
        setIsLoading(false);

        if (!cart.marketing && !isEmpty(currentPrescription)) {
          setActivePrescription(currentPrescription);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  const handleContinue = () => {
    if (marketingPage) {
      const allowCartCreation = handleShowModals({
        showRetake: retakePending,
        showUnderReview: user?.has_under_review_visit,
        showResumeVisit: user?.has_cart_in_progress,
        showRefillAvailableModal: user?.has_refill,
        showRefillInProgress: user?.has_in_progress_refill,
        is_order_being_processed: user?.is_order_being_processed,
        setShowRefillAvailableModal,
        setShowRetakeModal,
        setShowUnderReviewVisitModal,
        setShowResumeVisitModal,
        setShowRefillInProgress,
        setShowDuplicateCartModal,
      });

      if (allowCartCreation) {
        MarketingNextStep(cart.id);
      }

      return;
    }

    let prescription: any;

    if (!cart.marketing) {
      prescription = find(user?.prescriptions, {
        product_variant_id: selectedVariant.id,
        has_an_active_cart: false,
      });
    }

    if (
      !isEmpty(prescription) &&
      cart.quantity <= prescription.rx_total_quantity_remaining
    ) {
      ProductDetailConversionRequest(cart.id)
        .then((res) => {
          setCurrentOrder(res.data.order);
          setLatestCart(undefined);
          navigate(res.data.redirect_path);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          reloadUser();
        });
    } else {
      ProductDetailNextStep(cart.id);
    }
  };

  const handleProductChange = (id: any) => {
    const product = find(products, { id });
    const variants = product.product_variants;
    const newVariant: any = first(variants); // TODO: Define a shared product variant type

    setSelectedProduct(product);
    setVariants(variants);
    setSelectedVariant(newVariant);
    setSelectedQuantity(newVariant.min_order_quantity);
    setShowCustomField(false);

    const newCart = cart;
    newCart.product_variant_id = newVariant.id;
    newCart.quantity = newVariant.min_order_quantity;

    UpdateCart(newCart);
  };

  const handleDosageChange = (dosage: any) => {
    const variant = find(variants, { dosage_value: dosage });

    setPackages(variant.packages);
    setSelectedQuantity(variant.min_order_quantity);
    setShowCustomField(false);

    const newCart = cart;
    newCart.product_variant_id = variant.id;
    newCart.quantity = variant.min_order_quantity;

    UpdateCart(newCart);
  };

  const handlePackageSelect = (quantity: any) => {
    const quantityTooSmall = quantity < selectedVariant.min_order_quantity;
    const quantityTooLarge = quantity > 1000000;

    if ([null, selectedVariant.id].includes(cart.product_variant_id)) {
      if (quantityTooSmall) {
        setErrors(["Quantity is too small"]);
        setMaxQuantityExceeded(false);
        setMinQuantityExceeded(true);
      } else if (quantityTooLarge) {
        setErrors(["Quantity is too large"]);
        setMinQuantityExceeded(false);
        setMaxQuantityExceeded(true);
      }

      if (
        !quantityTooSmall &&
        !quantityTooLarge &&
        quantity !== "Custom Quantity"
      ) {
        setSelectedQuantity(quantity);
        setMaxQuantityExceeded(false);
        setMinQuantityExceeded(false);
        setCustomInput(false);

        const newCart = cart;
        newCart.product_variant_id = selectedVariant.id;
        newCart.quantity = quantity;

        if (!isEmpty(refillCart) && !quantityTooLarge) {
          newCart.max_quantity = refillCart.rx_total_quantity_remaining;
        }

        UpdateCart(newCart);
      } else {
        handleCustomInput(quantity);
      }
    } else {
      // TODO: Add flash messages
      console.log(
        `You have already added another product to your cart. Please finish latest order or clear cart to add the new one.`
      );
    }
  };

  const handleCustomInput = (quantity: any) => {
    if (quantity === "Custom Quantity") {
      setShowCustomField(true);
    }
  };

  const handleCloseCustomInput = (value: any) => {
    setShowCustomField(value);
  };

  const handleOpen = () => {
    setShowDrugInformation(true);
  };

  const handleCustomInputChange = (newQuantity: any) => {
    const min_quantity = selectedVariant.min_order_quantity;
    const max_quantity = 1000000;

    setSelectedQuantity(newQuantity);

    // Handle maximum quantity scenario
    if (max_quantity && max_quantity < newQuantity) {
      setCustomInputHasValidValue(false);
      setMinQuantityExceeded(false);
      setMaxQuantityExceeded(true);
      return;
    } else {
      setCustomInputHasValidValue(true);
      setMaxQuantityExceeded(false);
    }

    // Handle minimum quantity scenario
    if (min_quantity && newQuantity.length > 0 && min_quantity > newQuantity) {
      setCustomInputHasValidValue(false);
      setMinQuantityExceeded(true);
    } else {
      setCustomInputHasValidValue(true);
      setMinQuantityExceeded(false);
    }

    handlePackageSelect(newQuantity);
  };

  if (isLoading) return <DnaLoader />;

  return (
    <div className="main marketing-product-detail">
      <div className="container checkout-page product-page product-detail-page pt-0">
        <div className="content">
          <div className="row">
            <div className="col-xl-9 col-lg-10 col-md-8 col-11 mx-auto">
              <div className="product-picker product-overview pb-5">
                <div className="sticky-price">
                  {!isEmpty(cart) && (
                    <>
                      <strong className="cart-product__price total-value">
                        ${Number(cart.final_price).toFixed(2)}
                        <span className="per-tab ml-2">
                          {`
                            ($${Number(cart.per_tablet_cost).toFixed(2)}
                          `}
                          <Translator path="checkout.productDetail.perTablet" />
                          )
                        </span>
                      </strong>
                    </>
                  )}

                  <div
                    className={`shipping-info delivery-text ${
                      Number(cart.final_delivery_price) === 0 && "free"
                    }`}
                  >
                    <ReactSVG src={GreenVanIcon} className="mr-2" />
                    {cart.final_delivery_price > 0
                      ? "Shipping NOT Included With This Quantity"
                      : "FREE 1 to 3 Day Priority Shipping"}
                  </div>
                </div>

                <div className="drug-section">
                  <h6 className="step-label">
                    <span className="marketing-index">1</span>
                    Drug
                    <button className="view-detail-button" onClick={handleOpen}>
                      {" "}
                      View drug details
                    </button>
                    <DrugInformation
                      show={showDrugInformation}
                      setShow={setShowDrugInformation}
                      productName={selectedProduct.name}
                    />
                  </h6>
                  <ProductSelector
                    label="Drug name"
                    selected={selectedProduct}
                    options={products}
                    onSelect={(id: any) => handleProductChange(id)}
                    prescriptions={user?.prescriptions}
                    disabled={cart?.branch === "refill_request"}
                    cart={cart}
                  />
                </div>

                <div className="strength-section">
                  <h6 className="step-label">
                    <span className="marketing-index">2</span>
                    Strength
                  </h6>

                  <DosageSelector
                    label="Dosage"
                    value={selectedVariant}
                    options={variants}
                    onSelect={(dosage) => handleDosageChange(dosage)}
                    prescriptions={user?.prescriptions}
                    disabled={cart?.branch === "refill_request"}
                    cart={cart}
                  />
                </div>

                <div className="quantity-section">
                  <h6 className="step-label">
                    <span className="marketing-index">3</span>
                    Quantity
                  </h6>
                  <div className="alert alert-helper warning">
                    {!cart.marketing &&
                      refillCart &&
                      // !maxQuantityExceeded &&
                      refillCart?.rx_total_quantity_remaining !== null && (
                        <p className="max-quantity-info mb-0">
                          <Translator path="checkout.productDetail.lastQuantity" />{" "}
                          <strong>
                            {refillCart?.quantity_dispensed || 0}{" "}
                            <Translator path="checkout.productDetail.tablets" />{" "}
                          </strong>
                        </p>
                      )}
                  </div>
                  {minQuantityExceeded && (
                    <div className="max-quantity-error">
                      <p>
                        <Translator path="checkout.productDetail.minimumQuantity" />{" "}
                        {cart?.product_variant?.min_order_quantity}{" "}
                        <Translator path="checkout.productDetail.tablets" />
                      </p>
                    </div>
                  )}

                  {!isEmpty(packages) && (
                    <PackagesList
                      packages={packages}
                      currentQuantity={cart?.quantity}
                      variant={selectedVariant}
                      onSelect={(quantity: any) => {
                        handlePackageSelect(quantity);
                      }}
                      errors={{ ...errors, ...cart?.errors }}
                      hideCustomInput={(value: any) =>
                        handleCloseCustomInput(value)
                      }
                      isCustomInput={customInputHasValidValue}
                      isInputOpen={showCustomField}
                      cartDiscounts={cart?.discounts}
                      setShowCustomField={setShowCustomField}
                    />
                  )}
                </div>

                {showCustomField && (
                  <label
                    className={`custom-input-label w-100 mb-4 mt-3 ${
                      maxQuantityExceeded && "error-label"
                    }`}
                  >
                    <input
                      className={"input custom_input"}
                      name="custom-input"
                      id="custom_input"
                      type="number"
                      min="0"
                      placeholder="Type in the amount"
                      onChange={(e: any) =>
                        handleCustomInputChange(e.target.value)
                      }
                    />
                  </label>
                )}
              </div>

              <Button
                fullWidth
                color="primary"
                onClick={handleContinue}
                classes="ga-add-to-cart-event"
              >
                {isProcessing ? (
                  <Translator path="checkout.productDetail.processingButtonText" />
                ) : (
                  <>
                    <span className="total-price">
                      <Translator path="checkout.productDetail.totalText" />{" "}
                      {`$${Number(cart.final_price).toFixed(2)}`} {" - "}
                      <Translator path="checkout.productDetail.addToCart" />
                      <ReactSVG className="ml-3" src={arrowLeftIcon} />
                    </span>
                  </>
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketingProductDetail;
