import React from "react";
import "./styles.scss";
import Button from "../../../components/Button";

const LearnMoreModal = () => {
  return (
    <div
      aria-labelledby="learnMoreModalTitle"
      className="modal zoom-in"
      id="learnMoreModal"
      role="dialog"
      tabIndex={-1}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="p-md-4">
              <h4 className="mb-4">
                *Viagra is a registered trademark of Pfizer, Inc.
              </h4>
              <h4 className="mb-4">
                Cialis is a registered trademark of Eli Lilly and Company.
              </h4>
              <p className="mb-4">
                Roman is a registered trademark of Roman Health Ventures, Inc.
              </p>
              <h5 className="mb-2">
                ** Price per dose/tablet based on publicly available information
              </h5>
              <div className="row justify-content-end mt-5">
                <div className="col-12 col-md-6">
                  <Button
                    classes=""
                    fullWidth={true}
                    color="secondary"
                    modalDismiss="modal"
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearnMoreModal;
