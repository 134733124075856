import React from "react";
import Translator from "components/Translator";
import { ReactSVG } from "react-svg";
import clock from "assets/images/home/clock.svg";
import calendar from "assets/images/home/calendar.svg";
import mobile from "assets/images/home/mobile.svg";
import NewVisitButton from "./Shared/NewVisitButton";

type Props = {
  defaultProductVariant:
    | {
        id?: any;
      }
    | undefined;
  slug?: boolean;
};

const Process = ({ defaultProductVariant, slug = false }: Props) => {
  return (
    <>
      <div className="ab-wrapper ab-1">
        <div className="homepage-container">
          <div className="process section" id="process">
            <div className="content flex-column">
              <h2 className="h2">
                <Translator path="homepage.process.headings.howProcessWork" />
              </h2>
              <div className="row">
                <div className="col-12 col-lg-4">
                  <div className="card-block mb-3">
                    <div className="row no-gutters flex-row">
                      <div className="col-2">
                        <div className="h5 highlighted-number">
                          <Translator path="homepage.process.cards.one.index" />
                        </div>
                      </div>
                      <div className="col-10">
                        <h3 className="h3">
                          <Translator path="homepage.process.cards.one.answerSomeQuestions" />
                        </h3>
                        <p className="mt-3">
                          <Translator path="homepage.process.cards.one.privatelyInform" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="card-block mb-3">
                    <div className="row no-gutters flex-row">
                      <div className="col-2">
                        <div className="h5 highlighted-number">
                          <Translator path="homepage.process.cards.two.index" />
                        </div>
                      </div>
                      <div className="col-10">
                        <h3 className="h3">
                          <Translator path="homepage.process.cards.two.receivePrescription" />
                        </h3>
                        <p className="mt-3">
                          <Translator path="homepage.process.cards.two.treatmentPrescribed" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="card-block">
                    <div className="row no-gutters flex-row">
                      <div className="col-2">
                        <div className="h5 highlighted-number">
                          <Translator path="homepage.process.cards.three.index" />
                        </div>
                      </div>
                      <div className="col-10">
                        <h3 className="h3">
                          <Translator path="homepage.process.cards.three.freeShipping" />
                        </h3>
                        <p className="mt-3">
                          <Translator path="homepage.process.cards.three.shippedOut" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-11 mx-auto mt-5">
                <p className="mb-auto text-center marketing-text">
                  <Translator path="homepage.process.currentTechnologies" />
                </p>
              </div>
              <div className="row mt-5 justify-content-around mb-3">
                <div className="col-12 col-lg-3">
                  <div className="process-card">
                    <ReactSVG src={clock} />
                    <p className="ml-4 mb-0">
                      <Translator path="homepage.process.steps.onlineConsultation" />
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-3">
                  <div className="process-card">
                    <ReactSVG src={calendar} />
                    <p className="ml-4 mb-0">
                      <Translator path="homepage.process.steps.noWaiting" />
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-3">
                  <div className="process-card">
                    <ReactSVG src={mobile} />
                    <p className="ml-5 ml-md-4 mb-0">
                      <Translator path="homepage.process.steps.medicalVisit" />
                    </p>
                  </div>
                </div>
              </div>
              <div className="get-started-section d-flex w-100 justify-content-center my-3 mt-lg-5 mb-lg-0">
                {(defaultProductVariant?.id || slug) && (
                  <NewVisitButton
                    defaultProductVariant={defaultProductVariant}
                    position="center"
                    slug={slug}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Process;
