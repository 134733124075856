import { AuthContext } from "Contexts/AuthContext";
import { isEmpty } from "lodash";
import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

const EmailConfirmation = () => {
  const location = useLocation();
  const token = location.search.split("_token=")[1];
  const { UserEmailConfirmation } = useContext(AuthContext);

  useEffect(() => {
    if (!isEmpty(token)) {
      UserEmailConfirmation(token);
    }
  }, [token]);
  return <></>;
};
export default EmailConfirmation;
