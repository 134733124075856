export function getCameraPermissions(setPermModal: any) {
  let localStream = null;

  setPermModal(true);

  navigator.mediaDevices
    .getUserMedia({ video: true })
    .then((response) => {
      localStream = response;
    })
    .catch((error) => console.log(error))
    .finally(() => {
      setPermModal(false);
    });
}
