import Translator from "components/Translator";
import React from "react";

const PermissionsRequiredModal = () => (
  <div className="permissions-required-modal">
    <h1>
      <Translator path="checkout.permissionRequiredWebCam" />
    </h1>
  </div>
);

export default PermissionsRequiredModal;
