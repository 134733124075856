import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useContext,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "lodash";

import { AuthContext } from "./AuthContext";
import { FetchOrderRequest } from "../apis";

export type OrderContextValue = {
  currentOrder?: OrderType;
  setCurrentOrder: Dispatch<SetStateAction<OrderType | undefined>>;
  ReloadOrder: () => void;
};

export type OrderType = {
  id: number;
  invoice_number: string;
  state: string;
  shipping_address_id: number;
  delivery_type: string;
  delivery_price: number | string;
  final_delivery_price: number | string;
  total_price: number | string;
  final_price: number | string;
  terms_of_service: boolean;
  standard_delivery_price: number | string;
  express_delivery_price: number | string;
  errors: [];
  carts: [];
  delivery_label: string;
  admin_id: number;
  shipping_address?: any;
  delivery_option?: any;
  prescription?: any;
  discount_cart_id?: any;
};

export const OrderContext = createContext<OrderContextValue>({
  currentOrder: undefined,
  setCurrentOrder: () => {},
  ReloadOrder: () => {},
});

const OrderContextProvider = ({ children }: any) => {
  const { authenticated } = useContext(AuthContext);
  const [currentOrder, setCurrentOrder] = useState<OrderType | undefined>(
    undefined
  );
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (!authenticated) {
      setCurrentOrder(undefined);
      return;
    }

    if (!isEmpty(currentOrder)) return;

    FetchOrderRequest()
      .then((res) => {
        setCurrentOrder(res.data.order);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  const ReloadOrder = async (id?: number) => {
    FetchOrderRequest(id)
      .then((res) => {
        setCurrentOrder(res.data.order);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const context = {
    currentOrder,
    setCurrentOrder,
    ReloadOrder,
  };

  return (
    <OrderContext.Provider value={context}>{children}</OrderContext.Provider>
  );
};

export default OrderContextProvider;
