import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import Button from "../../../components/Button";
import { Link } from "react-router-dom";
import { AuthContext } from "Contexts/AuthContext";

import "./styles.scss";

type ChatReminderModalProps = {
  setChatReminderModal: any;
  show: boolean;
};

const ChatReminderModal = ({
  setChatReminderModal,
  show,
}: ChatReminderModalProps) => {
  const { setShowChatModal, ReadChatMessages } = useContext(AuthContext);

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="chat-remainder-modal"
      animation={false}
      onHide={() => setChatReminderModal(false)}
    >
      <Modal.Header>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => setChatReminderModal(false)}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <h5 className="modal-title px-2">There is a chat message.</h5>
        <p className="modal-text mt-4 mb-0">
          If you have any questions please contact us by phone at (714) 276-2040
          or start a chat by clicking{" "}
          <Link
            id="showChatPanel"
            to="#"
            onClick={() => {
              setShowChatModal(true);
              setChatReminderModal(false);
              ReadChatMessages();
            }}
          >
            here
          </Link>
        </p>
        <div className="modal-actions d-flex flex-md-row flex-column mt-4 mb-4">
          <Button
            classes="btn-outline mr-md-2 mb-2 mb-md-0"
            fullWidth={true}
            onClick={() => setChatReminderModal(false)}
          >
            Cancel
          </Button>
          <Button
            classes="ml-md-2"
            fullWidth={true}
            color="secondary"
            onClick={() => {
              setShowChatModal(true);
              setChatReminderModal(false);
              ReadChatMessages();
            }}
          >
            OPEN CHAT MESSAGES
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ChatReminderModal;
