import React from "react";
import { ReactSVG } from "react-svg";
import Slider from "react-slick";
import { isEmpty } from "lodash";
import Modal from "react-bootstrap/Modal";
import crossIcon from "assets/images/icons/entypo-cross.svg";
import RatingStarsIcon from "assets/images/home/rating-stars.svg";

import "./styles.scss";

type PatientTestimonialModalProps = {
  show: boolean;
  setShow: any;
  testimonial?: any;
};

const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const PatientTestimonialsModal = ({
  show,
  setShow,
  testimonial,
}: PatientTestimonialModalProps) => {
  return (
    <Modal
      id="patient-testimonials-modal"
      centered
      size={"lg"}
      show={show}
      animation={false}
      onHide={() => setShow(false)}>
      <Modal.Header>
        <button
          onClick={() => setShow(false)}
          className="close-button"
          style={{ width: "40px" }}>
          <img src={crossIcon} alt="Close" style={{ width: "100%" }} />
        </button>
      </Modal.Header>
      <Modal.Body>
        {!isEmpty(testimonial) ? (
          <div className="carousel-item happy-patient">
            <div className="header">
              <ReactSVG src={RatingStarsIcon} className="mb-1" />
              <h5>{testimonial.name}</h5>
            </div>
            <p>{testimonial.content}</p>
          </div>
        ) : (
          <Slider {...settings}>
            <div className="carousel-item">
              <div className="header">
                <ReactSVG src={RatingStarsIcon} className="mb-1" />
                <h5>Sildenafil Patient, CA</h5>
              </div>
              <p>
                I had a few doubts that it would help me--but the only other
                alternative was to purchase the brand name expensive VIAGRA--and
                if I could get the expected results with this Sildenafil, then I
                would benefit two-fold: in the bedroom--and my out of pocket
                expense!
              </p>
            </div>
            <div className="carousel-item">
              <div className="header">
                <ReactSVG src={RatingStarsIcon} className="mb-1" />
                <h5>Sildenafil Patient, NY</h5>
              </div>
              <p>
                I've issues with ED due to blood flow issues. I had a previous
                Sildenfil prescription at $50.00 a dose. After paying too high
                of prices, I was doing some research to find a cheaper price. I
                had heard / read positive things about Sildenafil.com. I was a
                little hesitant to try an online pharmacy, but thought I'd give
                it a try. The folks at Sildenafil.com were very professional and
                easy to work with. I would describe this service as being
                trustworthy, professional and great prices. Thanks for making it
                easy!
              </p>
            </div>
            <div className="carousel-item">
              <div className="header">
                <ReactSVG src={RatingStarsIcon} className="mb-1" />
                <h5>Sildenafil Patient, NY</h5>
              </div>
              <p>
                Didn't know if it would work... But it did! Found the generic
                form, Sildenafil, worked just as well as the big brand names. I
                am fully satisfied! All my doubts were dispelled! Sildenafil
                worked just fine. Ask my fiancé!
              </p>
            </div>
            <div className="carousel-item">
              <div className="header">
                <ReactSVG src={RatingStarsIcon} className="mb-1" />
                <h5>Sildenafil Patient, CA</h5>
              </div>
              <p>
                Two words: THE BOMB! - (that's what the young people say when
                they think something is really cool or real radical) - at least
                that's what one of my grandkids says all the time!
              </p>
            </div>
            <div className="carousel-item">
              <div className="header">
                <ReactSVG src={RatingStarsIcon} className="mb-1" />
                <h5>Sildenafil Patient, CA</h5>
              </div>
              <p>
                The pharmacist and staff were very helpful and friendly.
                Answered all my questions and I received very prompt and
                professional service.
              </p>
            </div>
            <div className="carousel-item">
              <div className="header">
                <ReactSVG src={RatingStarsIcon} className="mb-1" />
                <h5>Sildenafil Patient, TX</h5>
              </div>
              <p>
                I never heard of generic viagra before and I was kind of
                hesitant about the product, but once I tried it I liked it. They
                provide me with a quick service. I was pleased. I would also say
                that they are very knowledgeable about their products, friendly
                service, and very quick.
              </p>
            </div>
            <div className="carousel-item">
              <div className="header">
                <ReactSVG src={RatingStarsIcon} className="mb-1" />
                <h5>Sildenafil Patient, FL</h5>
              </div>
              <p>
                I was referred by a friend to this site and was pleased with
                shipping in a timely manner and so I didn't have to wait too
                long when I ordered it. I am able to go ahead and last a little
                longer because of the sildenafils and then also be able to have
                a firmer package. Having my other half be happy also with the
                sildenafils was another benefit.
              </p>
            </div>
            <div className="carousel-item active">
              <div className="header">
                <ReactSVG src={RatingStarsIcon} className="mb-1" />
                <h5>Sildenafil Patient, AZ</h5>
              </div>
              <p>
                Honestly, I have to say I am happy with the price and the
                product itself. I have done some research and everything is
                positive. It didn't affect my health in any way. It doesn't have
                any conflict with the other medications I am on, my blood
                pressure and a few other things. Being only 20mg and I was
                thinking I would need to take more and viagra is 100mg, but no I
                don't, I can take 3 or even 4. 4 is a big plus but 3 is
                satisfactory, so 60 mgs is good. The service has always been
                excellent and I have talked to one other person about it and I
                don't know if they went through with it or not. I told him to go
                online to sildenafil.com See, another thing I want you to know
                is that I never have to worry about my refills being expired.
                You guys take care of them.
              </p>
            </div>
          </Slider>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PatientTestimonialsModal;
