import React, { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import ScrollIntoView from "react-scroll-into-view";
import { calculateDiscount } from "helpers/DiscountCalculator";
import { currencyFormatter } from "helpers/Formatter";
import entypoChevronDown from "assets/images/icons/entypo-chevron-down.svg";
import chevronDown from "assets/images/home/chevron-down.svg";

type CartItemDropdownProps = {
  options?: any;
  suffix?: any;
  name?: any;
  onOptionSelect?: any;
  className?: any;
  selectedValue?: any;
  current_variant?: any;
  allowCustomInput?: any;
  showEditOptions?: any;
  disabled?: any;
  discountPercentage?: any;
  onChange?: any;
  errors?: any;
  setErrors?: any;
};
const CartItemDropdown = ({
  options,
  suffix,
  name,
  onOptionSelect,
  className,
  selectedValue,
  current_variant,
  allowCustomInput,
  showEditOptions,
  disabled,
  discountPercentage,
  onChange,
  errors,
  setErrors,
}: CartItemDropdownProps) => {
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [cartDropDown, setCartDropDown] = useState<boolean>(false);
  const [isScrollDown, setIsScrollDown] = useState<boolean>(false);
  const [isMenuHeader, setIsMenuHeader] = useState<boolean>(true);
  const [text, setText] = useState("");
  const [value, setValue] = useState("");
  const valueToShow = selectedValue || value || options[0];
  const textToShow = selectedValue || text || options[0];
  const basePrice = current_variant?.price_thresholds[0]?.price;
  const handleOptionSelect = (value: any, text: any) => {
    setCartDropDown(false);
    setShowCustomInput(false);
    setText(text);
    setValue(value);
    onOptionSelect(value);
  };
  const formatText = (opt: any) => {
    return `${opt} ${suffix?.length ? suffix : ""}`;
  };
  const getDiscountPercentage = (calculatedDiscount: any) => {
    return discountPercentage > calculatedDiscount
      ? discountPercentage
      : calculatedDiscount;
  };
  const evaluateDiscount = (current_variant: any, opt: any) => {
    const defaultDiscount = getDiscountPercentage(
      calculateDiscount(current_variant, opt).discount
    );
    let percentage = null;
    if (defaultDiscount > 0) {
      percentage = defaultDiscount;
    }
    return percentage;
  };
  const handleScroll = (key: string, e: any) => {
    e.preventDefault();
    setIsScrollDown(!isScrollDown);
    setIsMenuHeader(!isMenuHeader);
  };

  useEffect(() => {
    if (!showEditOptions) {
      setShowCustomInput(false);
    }
  }, [showEditOptions]);

  return (
    <>
      <div
        className={`cart__dropdown-wrapper ${className} ${
          disabled && "deactivated"
        }`}>
        <input
          type="hidden"
          className="cart__dropdown-input hidden"
          value={formatText(valueToShow)}
          name={name}
        />
        <div className="position-relative">
          <input
            type="text"
            className="cart__dropdown-input input"
            readOnly={true}
            onFocus={() => setCartDropDown(true)}
            onBlur={() => {
              setCartDropDown(false);
              setIsMenuHeader(true);
              setIsScrollDown(false);
            }}
            value={showCustomInput ? "Custom" : formatText(textToShow)}
            tabIndex={0}
          />
          <span className="cart-dropdown-chevron-down">
            <img src={entypoChevronDown} alt="Open" />
          </span>
        </div>
        <div
          className={`cart-dropdown ${
            allowCustomInput ? "scroll-main-div" : ""
          }`}>
          {cartDropDown && allowCustomInput && (
            <div className="scroll-actions">
              <ScrollIntoView
                selector={`${isMenuHeader ? "#header" : "#footer"} `}>
                <button
                  className={`${isScrollDown ? "scroll-up-btn" : ""} `}
                  onMouseDown={(e) =>
                    handleScroll(isScrollDown ? "up" : "down", e)
                  }>
                  <ReactSVG wrapper="span" src={chevronDown}></ReactSVG>
                </button>
              </ScrollIntoView>
            </div>
          )}
          {cartDropDown && (
            <ul className=" cart-dropdown-border">
              {options.map((opt: number, index: number) => {
                let optText = formatText(opt);
                return (
                  <React.Fragment key={index}>
                    <button
                      className="cart-item__row d-flex"
                      onMouseDown={() => handleOptionSelect(opt, optText)}
                      id={`${allowCustomInput && index === 0 ? "header" : ""}`}>
                      <div
                        className={`cart-item__radio ${
                          opt === selectedValue && "selected"
                        } `}></div>
                      <p className="cart-item__quantity">
                        {optText}
                        {name === "quantity" && opt === current_variant.popular_qty && (
                          <span className="popular-text">Popular</span>
                        )}
                      </p>
                      {name === "quantity" && (
                        <p className="cart-item__price">
                          <span
                            className={`${
                              index !== 0 ? "base-price" : "original-price"
                            }`}>
                            <span className="price">
                              {currencyFormatter(Number(opt * basePrice))}
                            </span>{" "}
                            {index !== 0 && (
                              <span className="discounted-price">
                                $
                                {Number(
                                  opt *
                                    current_variant?.price_thresholds[index]
                                      ?.price
                                ).toFixed(2)}
                              </span>
                            )}
                          </span>
                          {evaluateDiscount(current_variant, opt) && (
                            <span className="cart-item__save-percent mt-1">
                              You save{" "}
                              {Math.round(
                                evaluateDiscount(current_variant, opt)
                              )}
                              %
                            </span>
                          )}
                        </p>
                      )}
                    </button>
                  </React.Fragment>
                );
              })}
              {allowCustomInput && (
                <>
                  <button
                    key={options.length + 1}
                    className="cart-item__row d-flex"
                    id="footer"
                    onMouseDown={() => {
                      setShowCustomInput(true);
                      setCartDropDown(false);
                      setErrors &&
                        setErrors({
                          ...errors,
                          quantity: {},
                        });
                    }}>
                    <div
                      className={`cart-item__radio ${
                        !options.includes(selectedValue) && "selected"
                      } `}></div>
                    <p className="cart-item__quantity">Custom Quantity</p>
                  </button>
                </>
              )}
            </ul>
          )}
        </div>
      </div>
      {showCustomInput && (
        <label className="w-100 mt-3 px-3">
          <span> Enter Custom Quantity</span>
          <input
            type="number"
            className="cart__dropdown-input input"
            onChange={(e: any) => onOptionSelect(e.target.value)}
          />
        </label>
      )}
    </>
  );
};

export default CartItemDropdown;
