import React, { useEffect } from "react";
import { isEmpty, sortBy } from "lodash";
import EditCartItemDropdown from "./CartItemDropdown";
import Button from "components/Button";

type EditCartOptionsProps = {
  cart?: any;
  products?: any;
  quantities?: any;
  current_variant?: any;
  showEditOptions?: any;
  variantsPerProduct?: any;
  handleUpdateShippingPrice?: any;
  handleProductChange?: any;
  handleDosageChange?: any;
  handleQuantityChange?: any;
  toggleEditOptions?: any;
  disabledOptions?: any;
  discountPercentage?: any;
  setShow: any;
  errors?: any;
  setErrors?: any;
};

const EditCartOptions = ({
  cart,
  products,
  quantities,
  current_variant,
  showEditOptions,
  variantsPerProduct,
  handleUpdateShippingPrice,
  handleProductChange,
  handleDosageChange,
  handleQuantityChange,
  toggleEditOptions,
  disabledOptions,
  discountPercentage,
  setShow,
  errors,
  setErrors,
}: EditCartOptionsProps) => {
  return (
    <div
      className={`current-product__edit-options ${
        !showEditOptions && "d-none"
      }`}>
      <EditCartItemDropdown
        className="mt-2"
        options={products.map((product: any) => product.name)}
        name="product"
        onOptionSelect={(option: any) => handleProductChange(option)}
        selectedValue={cart.product_simple_name}
        disabled={disabledOptions.includes("product")}
      />
      <EditCartItemDropdown
        className="mt-3"
        options={sortBy(variantsPerProduct?.map((v: any) => v.dosage_value))}
        suffix="mg"
        name="dosage"
        onOptionSelect={(option: any) => handleDosageChange(option)}
        selectedValue={cart.product_variant.dosage_value}
        disabled={disabledOptions.includes("dosage")}
      />
      <EditCartItemDropdown
        className="mt-3"
        options={quantities}
        suffix="tablets"
        name="quantity"
        onOptionSelect={(option: any) => handleQuantityChange(option)}
        selectedValue={cart.quantity}
        allowCustomInput={true}
        current_variant={current_variant}
        showEditOptions={showEditOptions}
        disabled={disabledOptions.includes("quantity")}
        discountPercentage={discountPercentage}
        errors={errors}
        setErrors={setErrors}
      />
      {!isEmpty(errors?.quantity) && (
        <div className="max-quantity-error px-3 py-2">{errors?.quantity}</div>
      )}
      <Button
        color="secondary"
        size="medium"
        onClick={() => {
          setShow(false);
        }}
        classes="edit-cart-options-button">
        Save now
      </Button>
    </div>
  );
};

export default EditCartOptions;
