import React from 'react';
import { ReactSVG } from 'react-svg';
import Translator from '../../components/Translator';
import six from '../../assets/images/home/6.svg';
import usFlag from '../../assets/images/home/us-flag.png';
import one from '../../assets/images/home/1.svg';
import five from '../../assets/images/home/5.svg';
import three from '../../assets/images/home/3.svg';
import four from '../../assets/images/home/4.svg';

const WhatIsIncluded = () => {
  return (
    <>
      <div className="ab-wrapper ab-1">
        <div className="homepage-container">
          <div className="what-is-included">
            <div className="content">
              <div className="row justify-content-between w-100">
                <div className="col-12 col-md-5">
                  <h2 className="h2">
                    <Translator
                      path="homepage.whatIsIncluded.headings.whatIsIcluded"
                    />
                  </h2>
                </div>
                <div className="col-12 col-md-6">
                  <div className="d-flex flex-column align-items-center">
                    <ul className="w-100 flex-column align-items-start">
                      <li>
                        <ReactSVG src={six} wrapper="svg" />
                        <h3 className="h3">
                          <Translator
                            path="homepage.whatIsIncluded.listItems.licensedProvider"
                          />
                        </h3>
                      </li>
                      <li>
                        <img src={usFlag} alt="us flag" />
                        <h3 className="h3">
                          <Translator
                            path="homepage.whatIsIncluded.listItems.dispensedFrom"
                          />
                        </h3>
                      </li>
                      <li>
                        <ReactSVG src={one} wrapper="svg" />
                        <h3 className="h3">
                          <Translator
                            path="homepage.whatIsIncluded.listItems.fdaApproved"
                          />
                        </h3>
                      </li>
                      <li>
                        <ReactSVG src={five} wrapper="svg" />
                        <h3 className="h3">
                          <Translator
                            path="homepage.whatIsIncluded.listItems.professionalEd"
                          />
                        </h3>
                      </li>
                      <li>
                        <ReactSVG src={three} wrapper="svg" />
                        <h3 className="h3">
                          <Translator
                            path="homepage.whatIsIncluded.listItems.urgentShipping"
                          />
                        </h3>
                      </li>
                      <li>
                        <ReactSVG src={four} wrapper="svg" />
                        <h3 className="h3">
                          <Translator
                            path="homepage.whatIsIncluded.listItems.noWaiting"
                          />
                        </h3>
                      </li>
                    </ul>
                    <small className="mt-4">
                      <Translator
                        path="homepage.whatIsIncluded.prescriptionNote"
                      />
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WhatIsIncluded;
