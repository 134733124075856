import React, { useContext, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { GetCurrentOrders } from "apis";
import OrderCard from "./OrderCard";
import { ReactSVG } from "react-svg";
import currentOrder from "assets/images/dashboard/current-order-card.svg";
import Default from "./CurrentOrderDefaults";
import Spinner from "components/Spinner";
import { AuthContext } from "Contexts/AuthContext";
import NewVisitButton from "../Home/Shared/NewVisitButton";

const CurrentOrder = () => {
  const { setLoading, defaultProductVariant } = useContext(AuthContext);
  const [orders, setOrders] = useState<[] | undefined>(undefined);
  const [carts, setCarts] = useState<[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    GetCurrentOrders()
      .then((res) => {
        setOrders(res.data.orders);
        setCarts(res.data.carts);
        setIsLoading(false);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className="account-content">
      {isLoading && <Spinner />}
      {isEmpty(orders) && isEmpty(carts) && !isLoading ? (
        <div className="empty-screen">
          <div className="empty-illustration-ellipse">
            <ReactSVG className="dashboard-svg" src={currentOrder} />
          </div>
          <span className="title-box">Current Order</span>
          <span className="detail-box">
            It's blank for now, but your current order and shipping info can be found here.
          </span>
          <NewVisitButton
            defaultProductVariant={defaultProductVariant}
            position="center"
            isDashboardBtn={true}
          />
        </div>
      ) : (
        <>
          {orders?.map((order: any) => (
            <OrderCard key={order.id} order={order} />
          ))}
          {carts?.map((cart: any) => {
            return <Default key={cart.id} cart={cart} />;
          })}
        </>
      )}
    </div>
  );
};

export default CurrentOrder;
