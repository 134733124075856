import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import Slider from "react-slick";
import { isEmpty } from "lodash";
import Translator from "components/Translator";
import { useTranslation } from "react-i18next";
import PatientTestimonialsModal from "./PatientTestimonialsModal";
import NewVisitButton from "../Shared/NewVisitButton";

import RatingStarsIcon from "assets/images/home/rating-stars.svg";
import caretRightIcon from "assets/images/home/caret-right.svg";

import "./styles.scss";

const MAX_CHARACTER_LIMIT = 280;

const settings = {
  dots: true,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  // useTransform: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        dots: true,
      },
    },
  ],
};

const formatContent = (content: string) => {
  if (content.length > MAX_CHARACTER_LIMIT) {
    return `${content.substring(0, MAX_CHARACTER_LIMIT)}...`;
  } else {
    return content;
  }
};

interface HappyPatientsTestimonialsProps {
  defaultProductVariant:
    | {
        id?: any;
      }
    | undefined;
  slug?: boolean;
}

const HappyPatientsTestimonials = ({
  defaultProductVariant,
  slug = false,
}: HappyPatientsTestimonialsProps) => {
  const { t } = useTranslation();
  const [activeTestimonial, setActiveTestimonial] = useState({});
  const [show, setShow] = useState<boolean>(false);
  const userTestimonials = [
    {
      name: <Translator path="homepage.testimonials.cards.one.name" />,
      content: t("homepage.testimonials.cards.one.content"),
    },
    {
      name: <Translator path="homepage.testimonials.cards.two.name" />,
      content: t("homepage.testimonials.cards.two.content"),
    },
    {
      name: <Translator path="homepage.testimonials.cards.three.name" />,
      content: t("homepage.testimonials.cards.three.content"),
    },
    {
      name: <Translator path="homepage.testimonials.cards.four.name" />,
      content: t("homepage.testimonials.cards.four.content"),
    },
    {
      name: <Translator path="homepage.testimonials.cards.five.name" />,
      content: t("homepage.testimonials.cards.five.content"),
    },
    {
      name: <Translator path="homepage.testimonials.cards.six.name" />,
      content: t("homepage.testimonials.cards.six.content"),
    },
    {
      name: <Translator path="homepage.testimonials.cards.seven.name" />,
      content: t("homepage.testimonials.cards.seven.content"),
    },
    {
      name: <Translator path="homepage.testimonials.cards.eight.name" />,
      content: t("homepage.testimonials.cards.eight.content"),
    },
  ];

  return (
    <div className="testimonials-wrapper">
      <Slider {...settings}>
        {userTestimonials.map((testimonial, index) => (
          <div className="happyPatientsCardMobile" key={index}>
            <div className="testimonial-card card testimonial-item">
              <div className="testimonial-content">
                <ReactSVG src={RatingStarsIcon} className="mb-1" />
                <div className="card-body">
                  <h6>{testimonial.name}</h6>
                  <p className="card-text">
                    {formatContent(testimonial.content)}
                  </p>
                  {testimonial.content.length > MAX_CHARACTER_LIMIT && (
                    <button
                      onClick={() => {
                        setActiveTestimonial(testimonial);
                        setShow(true);
                      }}
                      className="read-more mt-1">
                      <Translator path="homepage.testimonials.readMore" />
                      <ReactSVG
                        src={caretRightIcon}
                        wrapper="span"
                        className="readMoreCaretRight"
                      />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <div className="d-flex justify-content-center">
        {(!isEmpty(defaultProductVariant) || slug) && (
          <NewVisitButton
            defaultProductVariant={defaultProductVariant}
            position={"center"}
            slug={slug}
          />
        )}
      </div>
      <PatientTestimonialsModal
        show={show}
        setShow={setShow}
        testimonial={activeTestimonial}
      />
    </div>
  );
};

export default HappyPatientsTestimonials;
