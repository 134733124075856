import React, { useState, useContext } from "react";
import { AuthContext } from "Contexts/AuthContext";
import useCurrentUser from "hooks/useCurrentUser";
import NewCreditCardModal from "./Modals/NewCreditCardModal";
import Button from "components/Button";
import { first, isEmpty } from "lodash";
import { ReactSVG } from "react-svg";
import paymentOptions from "assets/images/dashboard/payment-options.svg";

const PaymentOptions = () => {
  const user = useCurrentUser();
  const { SelectCreditCard, DeleteCreditCard } = useContext(AuthContext);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [showFormModal, setShowFormModal] = useState<boolean>(false);

  const defaultCreditCard: any = first(
    user?.credit_cards?.filter(
      (card: any) => card.payment_profile_id === user.default_payment_profile_id
    )
  );

  const creditCards: any = user?.credit_cards?.filter(
    (card: any) => card.payment_profile_id !== user.default_payment_profile_id
  );
  const isCreditCardDetails: boolean =
    !isEmpty(creditCards) || !isEmpty(defaultCreditCard);

  if (!user) return <></>;

  return (
    <div className={`${isCreditCardDetails && "account-card padded"}`}>
      {isCreditCardDetails ? (
        <>
          <div className="checkout-option credit-card-item">
            {!isEmpty(defaultCreditCard) && (
              <div className="payment-option">
                <div className="payment-option__header default">
                  <div className="payment-option__cardnumber no-mouseflow mouseflow-hidden">
                    xxxx - xxxx - xxxx -{" "}
                    {defaultCreditCard?.card_number?.substring(4)}
                  </div>
                  <div className="payment-option__owner no-mouseflow mouseflow-hidden">{`${defaultCreditCard.first_name} ${defaultCreditCard.last_name}`}</div>
                  <div className="payment-option__date no-mouseflow mouseflow-hidden">
                    {defaultCreditCard?.expiration_date}
                  </div>
                  <div className="payment-option_cardtype default">
                    <span
                      className={`card-img ${defaultCreditCard.card_type.toLowerCase()}`}
                    ></span>
                  </div>
                </div>
                <div className="payment-option__body">
                  <p className="default-card-text">Default card</p>
                  <div className="actions d-flex">
                    <div>
                      <Button
                        color="ghost"
                        classes="btn-ghost dark small"
                        disabled={isProcessing}
                        onClick={() => {
                          setIsProcessing(true);
                          DeleteCreditCard(
                            defaultCreditCard.payment_profile_id
                          ).then(() => setIsProcessing(false));
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!isEmpty(creditCards) &&
              creditCards.map((cc: any, i: number) => (
                <div className="payment-option" key={i}>
                  <div className="payment-option__header">
                    <div className="payment-option__cardnumber no-mouseflow mouseflow-hidden">
                      xxxx - xxxx - xxxx - {cc.card_number.substring(4)}
                    </div>
                    <div className="payment-option__owner no-mouseflow mouseflow-hidden">{`${cc.first_name} ${cc.last_name}`}</div>
                    <div className="payment-option__date no-mouseflow mouseflow-hidden">
                      {cc.expiration_date}
                    </div>
                    <div className="payment-option_cardtype">
                      <span
                        className={`card-img ${cc.card_type.toLowerCase()}`}
                      ></span>
                    </div>
                  </div>
                  <div className="payment-option__body">
                    <div className="actions d-flex">
                      <div className="mr-2">
                        <Button
                          color="ghost"
                          classes="btn-ghost dark small"
                          disabled={isProcessing}
                          onClick={() => {
                            setIsProcessing(true);
                            SelectCreditCard(cc.payment_profile_id).then(() =>
                              setIsProcessing(false)
                            );
                          }}
                        >
                          Set as Default
                        </Button>
                      </div>
                      <div>
                        <Button
                          color="ghost"
                          classes="btn-ghost dark small"
                          disabled={isProcessing}
                          onClick={() => {
                            setIsProcessing(true);
                            DeleteCreditCard(cc.payment_profile_id).then(() =>
                              setIsProcessing(false)
                            );
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="picker-actions">
            <Button
              color="secondary"
              classes="small"
              onClick={() => setShowFormModal(true)}
            >
              Add card
            </Button>
          </div>
          <NewCreditCardModal
            show={showFormModal}
            setShowFormModal={setShowFormModal}
          />
        </>
      ) : (
        <div className="no-payment-options">
          <div className="empty-screen">
            <div className="empty-illustration-ellipse">
              <ReactSVG className="dashboard-svg" src={paymentOptions} />
            </div>
            <span className="title-box">Payment Options</span>
              <>
                <span className="detail-box">
                  Your secure payment hub. Update and manage your payment methods in this section.
                </span>
                <div className="button-here">
                  <button
                    className="btn-aum text-capitalize medium secondary get-started-btn"
                    onClick={() => setShowFormModal(true)}
                  >
                    Add Card
                  </button>
                  <NewCreditCardModal
                    show={showFormModal}
                    setShowFormModal={setShowFormModal}
                  />
                </div>
              </>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentOptions;
