import Translator from "components/Translator";
import React from "react";
import PackageItem from "./PackageItem";
import OtherPackageItem from "./OtherPackageItem";

type PackagesListProps = {
  currentQuantity: any;
  variant: any;
  onSelect: any;
  errors: any;
  isCustomInput: any;
  isInputOpen: any;
  cartDiscounts: any;
  hideCustomInput: any;
  packages: any;
  setShowCustomField?: any;
};

const PackagesList = ({
  currentQuantity,
  variant,
  onSelect,
  errors,
  isCustomInput,
  isInputOpen,
  cartDiscounts,
  hideCustomInput,
  packages,
  setShowCustomField,
}: PackagesListProps) => {
  if (!packages.includes(currentQuantity) && !isInputOpen) {
    packages.push(currentQuantity);
  }

  return (
    <div className="packages-list">
      <div className="packages-list__body">
        {packages.map((quantity: any, index: number) => (
          <React.Fragment key={index}>
            <PackageItem
              key={quantity}
              index={index}
              quantity={quantity}
              variant={variant}
              currentQuantity={currentQuantity}
              onQuantitySelect={onSelect}
              onCloseInput={hideCustomInput}
              validCustomInput={isCustomInput}
              customInputFieldOpen={isInputOpen}
              cartDiscounts={cartDiscounts}
            />
          </React.Fragment>
        ))}
        <OtherPackageItem
          onQuantitySelect={() => onSelect("Custom Quantity")}
          onCloseInput={hideCustomInput}
          validCustomInput={isCustomInput}
          customInputFieldOpen={isInputOpen}
          setShowCustomField={setShowCustomField}
        />
      </div>
    </div>
  );
};

export default PackagesList;
