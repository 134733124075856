import React, { useEffect, useState } from "react";

import AnswerOption from "./AnswerOption";
import DropdownOptions from "./DropdownOptions";
import TextOption from "./TextOption";
import MedSearch from "./med/MedSearch";
import AllergySearch from "./allergy/AllergySearch";
import ReactTable from "react-table";
import { find, isEmpty } from "lodash";

type Props = {
  index: any;
  selectedAnswers: any;
  setSelectedAnswers: any;
  question: any;
  questions: any;
  setAllowText: any;
  extraText: any;
  setExtraText: any;
  setAllowAllergy: any;
  showSideEffectText: any;
  setShowSideEffectText: any;
  sideEffectsQuestionLabel: any;
  setSideEffectsQuestionLabel: any;
  overrideMultiDisplay: any;
  setUserAction: any;
  setShowContinueButton: any;
  setContinueButtonDisable: any;
  textAreaBorder: any;
  allowText: any;
  allowAllergy: any;
  allowMed: any;
  setAllowMed: any;
  isProcessing: any;
  setIsProcessing: any;
  queriedMeds: any;
  setQueriedMeds: any;
  addedMeds: any;
  setAddedMeds: any;
  errors: any;
  setErrors: any;
  subQuestions: any;
  setSubQuestions: any;
  questionRenderType: any;
  setOverrideMultiDisplay: any;
  showSideEffects?: any;
  setShowSideEffects?: any;
  activeSubQuestions?: any;
};

const MutlipleQuestionItem = ({
  index,
  selectedAnswers,
  setSelectedAnswers,
  question,
  questions,
  setAllowText,
  extraText,
  setExtraText,
  setAllowAllergy,
  showSideEffectText,
  setShowSideEffectText,
  sideEffectsQuestionLabel,
  setSideEffectsQuestionLabel,
  overrideMultiDisplay,
  setUserAction,
  setShowContinueButton,
  setContinueButtonDisable,
  textAreaBorder,
  allowText,
  allowAllergy,
  allowMed,
  setAllowMed,
  isProcessing,
  setIsProcessing,
  queriedMeds,
  setQueriedMeds,
  addedMeds,
  setAddedMeds,
  errors,
  setErrors,
  subQuestions,
  setSubQuestions,
  questionRenderType,
  setOverrideMultiDisplay,
  showSideEffects,
  setShowSideEffects,
  activeSubQuestions,
}: Props) => {
  const findOptionByQuestionLabel = () =>
    selectedAnswers.filter(
      (sa: any) => sa.question_label === sideEffectsQuestionLabel
    )[0];
  const badges = ["8.01.01", "8.01.02"].includes(question?.label)
    ? ["low", "normal", "elevated", "high", ""]
    : [];

  const [showDoctorUsageInfo, setShowDoctorUsageInfo] = useState(false);

  useEffect(() => {
    if (
      !isEmpty(question) &&
      question.data_type === "side_effects" &&
      question.question_type === "radio"
    ) {
      setShowSideEffects(
        find(selectedAnswers, ["question_id", question.id])?.value === "1"
      );
    }
  }, []);

  return (
    <>
      <h4
        className="question-heading mb-3"
        data-question-label={`Q${question?.label?.split(".")[0]}`}
      >
        {question.text}
      </h4>
      {question.subtitle && (
        <p className="question-sub-heading mb-4">{question.subtitle}</p>
      )}
      {overrideMultiDisplay && index === 0 && (
        <div className="doctor-usage-wrapper mb-4">
          <a
            href="#"
            className="show-doctor-usage-link"
            onClick={(e: any) => {
              e.preventDefault();
              setShowDoctorUsageInfo(!showDoctorUsageInfo);
            }}
          >
            + Learn how this information is used by your doctor
          </a>
          {showDoctorUsageInfo && (
            <p className="sub-questions-helper-text">
              This information helps your health care provider better understand
              your particular case of ED and how your body has responded to past
              treatments. If you have responded well to a specific medication,
              or poorly to another one, it may impact your recommended course of
              treatment.
            </p>
          )}
        </div>
      )}
      <div className="answer-options-container">
        {question.question_type === "dropdown" && (
          <DropdownOptions
            selectedAnswers={selectedAnswers}
            setSelectedAnswers={setSelectedAnswers}
            question={question}
            questionType={question.question_type}
            setContinueButtonDisable={setContinueButtonDisable}
          />
        )}
        {question?.answer_options?.map((option: any, idx: number) => (
          <React.Fragment key={idx}>
            <AnswerOption
              selectedAnswers={selectedAnswers}
              setSelectedAnswers={setSelectedAnswers}
              question={question}
              option={option}
              questionType={question.question_type}
              setAllowText={setAllowText}
              questionsLength={questions.length}
              extraText={extraText}
              setExtraText={setExtraText}
              setAllowAllergy={setAllowAllergy}
              setShowSideEffectText={setShowSideEffectText}
              setSideEffectsQuestionLabel={setSideEffectsQuestionLabel}
              badge={badges[idx]}
              setUserAction={setUserAction}
              setShowContinueButton={setShowContinueButton}
              setContinueButtonDisable={setContinueButtonDisable}
              textAreaBorder={textAreaBorder}
              userResponses={null}
              activeQuestion={null}
              handleContinue={null}
              questionId={null}
              showSideEffects={showSideEffects}
              setShowSideEffects={setShowSideEffects}
              activeSubQuestions={activeSubQuestions}
            />
          </React.Fragment>
        ))}
      </div>
      {showSideEffectText &&
        findOptionByQuestionLabel()?.question_label === question.label && (
          <TextOption
            selectedAnswers={selectedAnswers}
            setSelectedAnswers={setSelectedAnswers}
            option={findOptionByQuestionLabel()}
            questionType="text"
            userResponses={question.responses}
            extraText={extraText}
            extraLabel={findOptionByQuestionLabel()?.extra_label}
            setExtraText={setExtraText}
            setContinueButtonDisable={setContinueButtonDisable}
            textAreaBorder={textAreaBorder}
            questionId={question.id}
          />
        )}
    </>
  );
};

export default MutlipleQuestionItem;
