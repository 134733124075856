import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import AddressNotFound from "./AddressNotFound";
import AddressSuggestion from "./AddressSuggestion";
import crossIcon from "assets/images/icons/entypo-cross.svg";
import { useFormikContext } from "formik";

import "./modal_styles.scss";
import { AddressType } from "types";
type DeliveryInfoModalProps = {
  show: boolean;
  setShow: any;
  selectedAddress: any;
  setAddress: any;
  suggestedAddress: any;
  deliveryInfoModalType: any;
  handleSubmitAddress: (values: AddressType) => void;
};

const DeliveryInfoModal = ({
  show,
  setShow,
  selectedAddress,
  setAddress,
  suggestedAddress,
  deliveryInfoModalType,
  handleSubmitAddress,
}: DeliveryInfoModalProps) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const formik = useFormikContext();

  const getHeaderText = () => {
    let text = "";

    switch (deliveryInfoModalType) {
      case "suggested":
        text =
          "We show the address may have an apartment or a suit number. Is this correct?";
        break;
      case "not found":
        text = "Sorry, we can't find the address.";
        break;
    }

    return text;
  };

  const handleResetFormikValues = (values: any) => {
    formik.setFieldValue("street_1", values.street_1);
    formik.setFieldValue("street_2", values.street_2);
    formik.setFieldValue("city", values.city);
    formik.setFieldValue("state", values.state);
    formik.setFieldValue("zip", values.zip);
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      dialogClassName="visit-consent-modals"
      className="drawer"
    >
      <button onClick={() => setShow(false)} className="close-button">
        <img src={crossIcon} alt="Close" />
      </button>
      <Modal.Header>
        <h3 className="header">{getHeaderText()}</h3>
      </Modal.Header>
      <Modal.Body>
        {deliveryInfoModalType === "not found" && (
          <AddressNotFound
            selectedAddress={selectedAddress}
            setDeliveryInfoModal={setShow}
            handleSubmitAddress={(values) => handleSubmitAddress(values)}
          />
        )}
        {deliveryInfoModalType === "suggested" && (
          <AddressSuggestion
            selectedAddress={selectedAddress}
            setAddress={setAddress}
            suggestedAddress={suggestedAddress}
            setDeliveryInfoModal={setShow}
            handleSubmitAddress={(values) => handleSubmitAddress(values)}
            handleResetFormikValues={(values) =>
              handleResetFormikValues(values)
            }
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default DeliveryInfoModal;
