import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import { RetakePhotosStatusRequest, FetchRecentMostCartRequest } from "apis";
import { AuthContext } from "Contexts/AuthContext";
import { CartContext } from "Contexts/CartContext";
import useLatestCart from "hooks/useLatestCart";
import IdRetake from "./IdRetake";
import SelfieRetake from "./SelfieRetake";

const RetakePhotos = () => {
  const cart = useLatestCart();
  const [showIdRetake, setShowIdRetake] = useState<boolean>(false);
  const [showSelfieRetake, setShowSelfieRetake] = useState<boolean>(false);
  const { setShowRetakeModal } = useContext(AuthContext);
  const { setLatestCart } = useContext(CartContext);
  const navigate = useNavigate();

  useEffect(() => {
    FetchRecentMostCartRequest().then((res) => {
      setLatestCart(res.data.cart);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(cart)) {
      RetakePhotosStatusRequest(cart.id)
        .then((res) => {
          const data = res.data;

          if (
            (data.id_retake == null && data.selfie_retake == null) ||
            (data.id_retake === false && data.selfie_retake === false)
          ) {
            navigate("/account/current-order");
          }

          setShowIdRetake(data.id_retake);
          setShowSelfieRetake(data.selfie_retake);
        })
        .catch((err) => {
          console.log(err);
        });
      setShowRetakeModal(false);
    }
  }, [cart, showIdRetake, showSelfieRetake]);

  return (
    <>
      {showSelfieRetake && !isEmpty(cart) && (
        <SelfieRetake
          showIdRetake={showIdRetake}
          setShowSelfieRetake={setShowSelfieRetake}
          cart={cart}
        />
      )}
      {showIdRetake && !showSelfieRetake && !isEmpty(cart) && (
        <IdRetake setShowIdRetake={setShowIdRetake} cart={cart} />
      )}
    </>
  );
};

export default RetakePhotos;
