import React from "react";
import Modal from "react-bootstrap/Modal";
import crossIcon from "assets/images/icons/entypo-cross.svg";

type PrivacyPolicyModalProps = {
  show: boolean;
  setShow: any;
};

const PrivacyPolicyModal = ({ show, setShow }: PrivacyPolicyModalProps) => {
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      className="drawer"
      dialogClassName="visit-consent-modals">
      <Modal.Header>
        <h3>Privacy Policy</h3>
        <button
          onClick={() => setShow(false)}
          className="close-button"
          style={{ width: "40px" }}>
          <img src={crossIcon} alt="Close" style={{ width: "100%" }} />
        </button>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong className="subheading">1. Introduction</strong>
        </p>
        <p>
          This Privacy Policy describes how PocketMed collects and uses Personal
          Data about you through the use of our Website, and through email,
          text, and other electronic communications between you and PocketMed.
        </p>
        <p>
          Tura Health LLC d/b/a PocketMed (“
          <strong>PocketMed,” “ we,” “ our,” or “ us</strong>
          ”) respects your privacy, and we are committed to protecting it
          through our compliance with this policy.
        </p>
        <p>
          This Privacy Policy (our “<strong>Privacy Policy</strong>
          ”) describes the types of information we may collect from you or that
          you may provide when you visit the{" "}
          <a href="https://pocketmed.com/">website</a> and its subdomains
          (collectively, our “<strong>Website</strong>
          ”) and our practices for collecting, using, maintaining, protecting,
          and disclosing that information.
        </p>
        <p>This policy applies to information we collect:</p>
        <ul>
          <li>on our Website;</li>
          <li>
            by telephone, email, text, and other electronic messages between you
            and us; and
          </li>
          <li>
            when you interact with our advertising and applications on third
            party websites and services, if those applications or advertising
            include links to this policy.
          </li>
        </ul>
        <p></p>
        <p>It does not apply to information collected by:</p>
        <ul>
          <li>
            us offline or through any other means, including on any other
            website operated by PocketMed or any third party; and
          </li>
          <li>
            any third party, including through any application or content
            (including advertising) that may link to or be accessible from or on
            the Website.
          </li>
        </ul>
        <p></p>
        <p>
          Please read this policy carefully to understand our policies and
          practices regarding your information and how we will treat it. If you
          do not agree with our policies and practices, your choice is not to
          use our Websites, and you must promptly exit our Website. By accessing
          or using our Website, you agree to this Privacy Policy. This Privacy
          Policy may change from time to time (see{" "}
          <a href="#">Changes to Our Privacy Policy</a>). Your continued use of
          our Website after we make changes is deemed to be acceptance of those
          changes, so please check this Privacy Policy periodically for updates.
        </p>
        <p>
          <strong>
            Note, PocketMed is not a medical group. Any telemedicine consults
            obtained through our Website are provided by independent medical
            practitioners including, but not limited to, Enjoy Health Medical
            P.A. and Enjoy Health Medical Group of California P.C.
            (collectively, “Enjoy Health Medical”), independent medical groups
            with a network of United States based health care providers (each, a
            “Provider”). Enjoy Health Medical (or your own medical provider if
            you do not use an Enjoy Health Medical provider) is responsible for
            providing you with a Notice of Privacy Practices describing its
            collection and use of your health information, not PocketMed. If you
            do not agree to be bound by those terms, you are not authorized to
            access or use our Website, and you must promptly exit our Website.
          </strong>
        </p>
        <p>
          <strong className="subheading">
            2. Children Under the Age of 18
          </strong>
        </p>
        <p>
          Our Website and App are not intended for children under the age of 18
          and children under the age of 18 are not permitted to use our Website.
          We will remove any information about a child under the age of 18 if we
          become aware of it.
        </p>
        <p>
          Our Website is not intended for children under 18 years of age. No one
          under age 18 may provide any information to or through the Website. We
          do not knowingly collect Personal Data from children under 18. If you
          are under 18, do not use or provide any information on our Website or
          on or through any of its features, including your name, address,
          telephone number, email address, or any screen name or user name you
          may use. If we learn we have collected or received Personal Data from
          a child under 18 without verification of parental consent, we will
          delete that information. If you believe we might have any information
          from a child under 18, please contact us at (714) 464-7557 /
          care@pocketmed.com.
        </p>
        <p>
          <strong className="subheading">
            3. Information We Collect About You and How We Collect It
          </strong>
        </p>
        <p className="text-center">
          <strong>Generally</strong>
        </p>
        <p>
          We collect several types of information from and about users of our
          Website (collectively, “Personal Data”), specifically information:
        </p>
        <ul>
          <li>
            by which you may be personally identified, such as name, address,
            e-mail address, telephone numbers, date of birth, credit or debit
            card number, bank or financial account number (for payment purposes
            only), salary or other financial information, social security
            number, driver’s license or state identification card number, images
            and video of you, your medical history, medical condition, physical
            or mental disability, gender, age (40 years or older), race or
            ethnicity, color, ancestry, religion or creed, marital status, sex
            (including gender, gender identity, gender expression, pregnancy or
            childbirth and related medical conditions), data concerning your sex
            life or sexual orientation;
          </li>
          <li>
            that is about you but individually does not identify you, such as
            traffic data, logs, referring/exit pages, date and time of your
            visit to our Website, error information, clickstream data, and other
            communication data and the resources that you access and use on the
            Website; or
          </li>
          <li>
            about your Internet connection, the equipment you use to access our
            Website and usage details, such as traffic data, logs,
            referring/exit pages, date and time of your visit to our Website,
            error information, clickstream data, and other communication data
            and the resources that you access and use on the Website.
          </li>
        </ul>
        <p></p>
        <p>
          <strong>We collect this information</strong>
        </p>
        <ul>
          <li>directly from you when you provide it to us;</li>
          <li>
            automatically as you navigate through the Website. Information
            collected automatically may include usage details, IP addresses,
            your Internet activity while on our Site and others (e.g., pages
            viewed, clicks, scrolling and mouse-overs) and other information
            collected through cookies, web beacons, session replay software and
            other tracking technologies; and Information about your computer and
            internet connection, i.e. your IP address, unique device identifier,
            unique advertising identifier, operating system, and browser type.
            From third parties, for example, our business partners; and
          </li>
          <li>
            automatically if you use your social media accounts (Facebook,
            Google, etc.) as the means to login to our Site and use our
            Services.
          </li>
        </ul>
        <p></p>
        <p className="text-center">
          <strong>
            <u>Information You Provide to Us</u>
          </strong>
        </p>
        <p>The information we collect on or through our Website is:</p>
        <ul>
          <li>Personal data such as the data identified above;</li>
          <li>
            information that you provide by filling in forms on our Website.
            This includes information provided at the time of registering to use
            our Website, posting material, purchasing products, or requesting
            further services. We may also ask you for information when you
            report a problem with our Website;
          </li>
          <li>
            records and copies of your correspondence (including email
            addresses), if you contact us;
          </li>
          <li>
            your responses to surveys that we might ask you to complete for
            research purposes; and
          </li>
          <li>
            details of transactions you carry out through our Website and of the
            fulfillment of your orders. You may be required to provide financial
            information before placing an order through our Website.
          </li>
        </ul>
        <p></p>
        <p>
          You also may provide information during medical screenings and other
          uses of our Website (collectively, “
          <strong>User Contributions</strong>
          ”). User Contributions are voluntary and provided at your own risk.
          Although we limit access to certain pages, please be aware that no
          security measures are perfect or impenetrable. Additionally, we cannot
          control the actions of other users of the Website with whom you may
          choose to share your User Contributions. Therefore, we cannot and do
          not guarantee that your User Contributions will not be viewed by
          unauthorized persons. You are prohibited from engaging in
          inappropriate conduct during a group therapy session, including but
          not limited to using unlawful, offensive, harassing or inappropriate
          language, or engage in any other unlawful, offensive, harassing or
          inappropriate conduct. We reserve the right to remove you from a group
          therapy session or other event, and cancel your membership, if you
          engage in behavior that would violate the restrictions set forth in
          this section.
        </p>
        <p className="text-center">
          <strong>
            <u>
              Information We Collect Through Automatic Data Collection
              Technologies
            </u>
          </strong>
        </p>
        <p>
          As you navigate through and interact with our Website, we may use
          automatic data collection technologies to collect certain information
          about your equipment, browsing actions, and patterns, specifically:
        </p>
        <ul>
          <li>
            Usage Details. Details of your visits to our Website, such as
            traffic data, location, logs, referring/exit pages, date and time of
            your visit to our Website, error information, clickstream data, and
            other communication data and the resources that you access and use
            on the Website;
          </li>
          <li>
            Device Information. Information about your computer, mobile device,
            and Internet connection, specifically your IP address, operating
            system, browser type, and browser version information; and
          </li>
          <li>
            Location Data. The Website, depending on the version, may collect
            information about the location of your device. If you do not want us
            to collect this information, you can choose not to enable or you can
            turn-off collection of location information using the privacy
            settings in the Website or your device.
          </li>
        </ul>
        <p></p>
        <p>
          The information we collect automatically may include Personal Data or
          we may maintain it or associate it with Personal Data we collect in
          other ways or receive from third parties. It helps us to improve our
          Website and to deliver a better and more personalized service by
          enabling us to:
        </p>
        <ul>
          <li>estimate our audience size and usage patterns;</li>
          <li>improve our product and services offering;</li>
          <li>
            store information about your preferences, allowing us to customize
            our Website according to your individual interests;
          </li>
          <li>recognize you when you return to our Website.</li>
        </ul>
        <p></p>
        <p>
          The technologies we use for this automatic data collection may
          include:
        </p>
        <ul>
          <li>
            <strong>Cookies (or browser cookies).</strong>
            We and our service providers may use cookies, web beacons, and other
            technologies to receive and store certain types of information
            whenever you interact with our Website through your computer or
            mobile device. A “cookie” is a small piece of data sent from a
            website and stored on the user's computer by the user's web browser
            while the user is browsing. On your computer, you may refuse to
            accept browser cookies by activating the appropriate setting on your
            browser, and you may have similar capabilities on your mobile device
            in the preferences for your operating system or browser. However, if
            you select this setting you may be unable to access certain parts of
            our Website. Unless you have adjusted your browser or operating
            system setting so that it will refuse cookies, our system will issue
            cookies when you direct your browser to our Website.
          </li>
          <li>
            <strong>Web Beacons.</strong>
            Pages of a Website, or our e-mails, may contain small electronic
            files known as web beacons (also referred to as clear gifs. pixel
            tags and single-pixel gifs) that permit us, for example, to count
            users who have visited those pages or opened an e-mail and for other
            related website statistics (for example, recording the popularity of
            certain website content and verifying system and server integrity).
          </li>
          <li>
            <strong>Google Analytics.</strong>
            We use Google Analytics, a web analytics service provided by Google,
            Inc. (“
            <strong>Google</strong>
            ”) to collect certain information relating to your use of the
            Website. Google Analytics uses “<strong>cookies</strong>
            ”, which are text files placed on your computer, to help the Website
            analyze how users use the site. You can find out more about how
            Google uses data when you visit our Website by visiting “How Google
            uses data when you use our partners' sites or apps”, (located at{" "}
            <a href="https://policies.google.com/technologies/partner-sites">
              www.google.com/policies/privacy/partners/
            </a>
            ). We may also use Google Analytics Advertising Features or other
            advertising networks to provide you with interest-based advertising
            based on your online activity. For more information regarding Google
            Analytics please visit Google's website, and pages that describe
            Google Analytics, such as{" "}
            <a href="https://www.google.com/analytics/learn/privacy.html">
              www.google.com/analytics/learn/privacy.html
            </a>
            .
          </li>
        </ul>
        <p></p>
        <p>
          <strong className="subheading">4. How We Use Your Information</strong>
        </p>
        <p>
          We use your Personal Data for various purposes described below,
          including to:
        </p>
        <ul>
          <li>provide our Website to you;</li>
          <li>provide products and services to you;</li>
          <li>provide you with information you request from us;</li>
          <li>enforce our rights arising from contracts;</li>
          <li>notify you about changes; and</li>
          <li>provide you with notices about your account.</li>
        </ul>
        <p></p>
        <p>
          We use information that we collect about you or that you provide to
          us, including any Personal Data:
        </p>
        <ul>
          <li>to present our Website and its contents to you;</li>
          <li>to provide our products and services to you;</li>
          <li>
            to provide you with information, products, or services that you
            request from us or that may be of interest to you;
          </li>
          <li>
            to process, fulfill, support, and administer transactions and orders
            for products and services ordered by you;
          </li>
          <li>to provide you with notices about your PocketMed account;</li>
          <li>to contact you in response to a request;</li>
          <li>to administer quizzes and surveys;</li>
          <li>to fulfill any other purpose for which you provide it;</li>
          <li>
            to carry out our obligations and enforce our rights arising from any
            contracts entered into between you and us, including for billing and
            collection
          </li>
          <li>
            tto notify you about changes to our Website, or any products or
            services we offer or provide though them;
          </li>
          <li>
            in any other way we may describe when you provide the information;
            and
          </li>
          <li>for any other purpose with your consent.</li>
        </ul>
        <p></p>
        <p>
          We may also use your information to contact you about goods and
          services that may be of interest to you, including through
          newsletters. If you wish to opt-out of receiving such communications,
          you may do so at any time by clicking unsubscribe at the bottom of
          these communications. For more information, see{" "}
          <a href="">Choices About How We Use and Disclose Your Information</a>.
        </p>
        <p>
          We may de-identify your Personal Data so that it no longer reasonably
          identifies you. In this case, we may use this de-identified data
          without restriction and for any purpose, including to improve our
          Website, products and services.
        </p>
        <p>
          Some information PocketMed collects constitutes protected health
          information (“PHI”) under the U.S. Health Insurance Portability and
          Accountability Act (“HIPAA”). As set forth above, Enjoy Health Medical
          (or your own medical provider if you do not use an Enjoy Health
          Medical Provider) will provide you with a Notice of Privacy Practices
          describing its collection, use, and disclosure of your health
          information, not PocketMed. PocketMed will use and disclose PHI only
          as permitted in PocketMed’s agreements with Enjoy Health Medical (or
          your own medical provider if you do not use a PocketMed Provider) and
          we only collect the PHI we need to fully perform our services and to
          respond to you or your Provider. We may use your PHI to contact you to
          the extent permitted by law, to provide requested services, to provide
          information to your Providers and insurers, to obtain payment for our
          services, to respond to your inquiries and requests, and to respond to
          inquiries and requests from your Providers and benefits program. We
          may combine your information with other information about you that is
          available to us, including information from other sources, such as
          from your Providers, insurers or benefits program, in order to
          maintain an accurate record of our participants. PHI will not be used
          for any other purpose, including marketing, without your consent.
        </p>
        <p>
          <strong className="subheading">
            5. Disclosure of Your Information
          </strong>
        </p>
        <p>
          We do not share, sell, or otherwise disclose your Personal Data for
          purposes other than those outlined in this Privacy Policy. We disclose
          your Personal Data to a few third parties, including:
        </p>
        <ul>
          <li>
            our affiliates and third party service providers that we use to
            support our business;
          </li>
          <li>
            to a company we merge, acquire, or that buys us, or in the event of
            change in structure of our company of any form;
          </li>
          <li>to comply with our legal obligations;</li>
          <li>
            to affiliates and third parties for their own commercial purposes;
          </li>
          <li>to enforce our rights; and</li>
          <li>with your consent.</li>
        </ul>
        <p></p>
        <p>
          We do not share, sell, or otherwise disclose your Personal Data for
          purposes other than those outlined in this Privacy Policy. However, we
          may disclose aggregated information about our users, and information
          that does not identify any individual, without restriction.
        </p>
        <p>
          We may disclose Personal Data that we collect or you provide as
          described in this Privacy Policy:
        </p>
        <ul>
          <li>
            to affiliates, contractors, service providers, and other third
            parties we use to support our business. The services provided by
            these organizations include providing IT and infrastructure support
            services, advertising, marketing, and payment processing services.
          </li>
          <li>
            to a buyer or other successor in the event of a merger, divestiture,
            restructuring, reorganization, dissolution, or other sale or
            transfer of some or all of our assets, whether as a going concern or
            as part of bankruptcy, liquidation, or similar proceeding, in which
            Personal Data held by PocketMed about our Website users are among
            the assets transferred;
          </li>
          <li>to fulfill the purpose for which you provide it;</li>
          <li>
            for any other purpose disclosed by us when you provide the
            information;
          </li>
          <li>
            with your consent. For example, where you share an experience with
            us for the purposes of discussion during a group therapy session.
            Submissions are always voluntary.
          </li>
        </ul>
        <p></p>
        <p>We may also disclose your Personal Data:</p>
        <ul>
          <li>
            to comply with any court order, law, or legal process, including to
            respond to any government or regulatory request;
          </li>
          <li>
            to affiliates and third parties to market their products or services
            to you if you have not opted out of these disclosures. For more
            information, see{" "}
            <a href="">
              Choices About How We Use and Disclose Your Information
            </a>
            ;
          </li>
          <li>
            to enforce or apply our <a href="/#terms_of_use">Terms of Use</a>{" "}
            and other agreements, including for billing and collection purposes;
            and
          </li>
          <li>
            if we believe disclosure is necessary or appropriate to protect the
            rights, property, or safety of PocketMed, our customers, or others.
            This includes exchanging information with other companies and
            organizations for the purposes of fraud protection and credit risk
            reduction.
          </li>
        </ul>
        <p></p>
        <p>
          <strong className="subheading">
            6. Choices About How We Use and Disclose Your Information
          </strong>
        </p>
        <p>
          We offer you choices on how you can opt out of our advertising to you.
          We do not control the collection and use of your information collected
          by third parties.
        </p>
        <p>
          We do not control the collection and use of your information collected
          by third parties described above in{" "}
          <a href="">Disclosure of Your Information</a>. These third parties may
          aggregate the information they collect with information from their
          other customers for their own purposes. In addition, we strive to
          provide you with choices regarding the Personal Data you provide to
          us. We have created mechanisms to provide you with control over your
          Personal Data:
        </p>
        <p>
          In addition, we strive to provide you with choices regarding the
          Personal Data you provide to us. We have created mechanisms to provide
          you with control over your Personal Data:
        </p>
        <ul>
          <li>
            <strong>Tracking Technologies and Advertising.</strong>
            You can set your browser or operating to refuse all or some cookies,
            or to alert you when cookies are being sent. If you disable or
            refuse cookies, please note that some parts of our Website or App
            may then be inaccessible or not function properly.
          </li>
          <li>
            <strong>Promotional Offers from PocketMed.</strong>
            If you do not wish to have your email address used by PocketMed to
            promote our own products and services, you can opt-out at any time
            by clicking the unsubscribe link at the bottom of any email or other
            marketing communications you receive from us. This opt out does not
            apply to information provided to PocketMed as a result of a product
            purchase, or your use of our services.
          </li>
          <li>
            <strong>
              Disclosure of Your Information to Affiliates and Third Parties.
            </strong>
            By using our Website, you consent to our sharing of your Personal
            Data (excluding personal health information) with our affiliates and
            third parties for their promotional purposes. If you wish to
            unsubscribe from such affiliate and third parties’ promotions, you
            can do so by clicking the unsubscribe link at the bottom of any
            email or other marketing communications you receive from them. If
            you wish to opt-out of such sharing, please email us
            care@pocketmed.com
          </li>
          <li>
            <strong>Targeted Advertising.</strong>
            To learn more about interest-based advertisements and your opt-out
            rights and options, visit the{" "}
            <a href="https://youradchoices.com/">
              Digital Advertising Alliance
            </a>{" "}
            and the{" "}
            <a href="https://www.networkadvertising.org/">
              Network Advertising Initiative
            </a>{" "}
            websites (<a href="https://www.aboutads.info">www.aboutads.info</a>{" "}
            and{" "}
            <a href="https://www.networkadvertising.org/">
              www.networkadvertising.org
            </a>
            ). Please note that if you choose to opt out, you will continue to
            see ads, but they will not be based on your online activity. We do
            not control third parties’ collection or use of your information to
            serve interest-based advertising. However, these third parties may
            provide you with ways to choose not to have your information
            collected or used in this way. You can also opt out of receiving
            targeted ads from members of the NAI on its website.
          </li>
        </ul>
        <p></p>
        <p>
          <strong className="subheading">
            7. Your Rights Regarding Your Information and Accessing and
            Correcting Your Information
          </strong>
        </p>
        <p>You may review and change your Personal Data by contacting us.</p>
        <p>
          You can notify us through one of the ways listed in the Contact
          Information section below of any changes or errors in any Personal
          Data we have about you to ensure that it is complete, accurate, and as
          current as possible or to delete your account. We cannot delete your
          Personal Data except by also deleting your account with us. We may
          also not be able to accommodate your request if we believe it would
          violate any law or legal requirement or cause the information to be
          incorrect.
        </p>
        <p>
          With respect to any PHI PocketMed may obtain, you have certain rights
          under HIPAA to access your data, to restrict use and disclosure of it,
          to request communication methods, to request corrections to your data,
          to receive an accounting of disclosures and to receive notice of any
          breach. See the Notice of Privacy Practices provided to you by your
          Provider for more information.
        </p>
        <p>
          <strong className="subheading">8. Do Not Track Signals</strong>
        </p>
        <p>
          We currently do not honor do-not-track signals that may be sent by
          some browsers.
        </p>
        <p>
          Some web browsers permit you to broadcast a signal to websites and
          online services indicating a preference that they “do not track” your
          online activities. Because there is no standard for these signals at
          this time, we currently do not honor such signals and we do not modify
          what information we collect or how we use that information based upon
          whether such a signal is broadcast or received by us.
        </p>
        <p>
          <strong className="subheading">9. Data security</strong>
        </p>
        <p>
          Information transmitted over the Internet is not completely secure,
          but we do our best to protect your Personal Data. You can help protect
          your Personal Data and other information by keeping your password to
          our Website confidential.
        </p>
        <p>
          We have implemented measures designed to secure your Personal Data
          from accidental loss and from unauthorized access, use, alteration,
          and disclosure.
        </p>
        <p>
          The safety and security of your information also depends on you. Where
          you have chosen a password for the use of our Website, you are
          responsible for keeping this password confidential. We ask you not to
          share your password with anyone.
        </p>
        <p>
          Unfortunately, the transmission of information via the Internet is not
          completely secure. Although we work diligently to try and protect your
          Personal Data, we cannot guarantee the security of your Personal Data
          transmitted to our Website. Any transmission of Personal Data is at
          your own risk. We are not responsible for circumvention of any privacy
          settings or security measures contained on the Website or in your
          operating system.
        </p>
        <p>
          <strong className="subheading">10. California Privacy Rights</strong>
        </p>
        <p>
          Under some circumstances, California law may provide you with the
          right to obtain a list of third parties (if any) that we provide your
          Personal Data to for their own direct marketing purposes.
        </p>
        <p>
          California Civil Code Section 1798.83 (California’s “
          <strong>Shine the Light</strong>” law) permits users of our Website
          that are California residents and who provide Personal Data in
          obtaining products and services for personal, family, or household use
          to request certain information regarding our disclosure of Personal
          Data to third parties for their own direct marketing purposes. If
          applicable, this information would include the categories of Personal
          Data and the names and addresses of those businesses with which we
          shared your Personal Data with for the immediately prior calendar year
          (e.g. requests made in 2020 will receive information regarding such
          activities in 2019). You may request this information once per
          calendar year. To make such a request, please contact us using the{" "}
          <a href="">We may</a> change this Privacy Policy at any time. It is
          our policy to post any changes we make to our Privacy Policy on this
          page with a notice that the Privacy Policy has been updated on the
          Website’s home page or the App’s home screen. If we make material
          changes to how we treat our users’ Personal Data, we will notify you
          by email to the email address specified in your account and/or through
          a notice on the Website’s home page or the App’s home screen. The date
          this Privacy Policy was last revised is identified at the top of the
          page. You are responsible for ensuring we have an up-to-date active
          and deliverable email address for you, and for periodically accessing
          the App or visiting our Website and reviewing this Privacy Policy to
          check for any changes. Contact Information below.
        </p>
        <p>Contact Information below.</p>
        <p>
          <strong className="subheading">
            11. Changes to Our Privacy Policy
          </strong>
        </p>
        <p>
          We will post any changes to our Privacy Policy on our Website. If we
          make material changes to our Privacy Policy, we may notify you of such
          changes through your contact information and invite you to review (and
          accept, if necessary) the changes.
        </p>
        <p>
          We may change this Privacy Policy at any time. It is our policy to
          post any changes we make to our Privacy Policy on this page with a
          notice that the Privacy Policy has been updated on the Website’s home
          page or the App’s home screen. If we make material changes to how we
          treat our users’ Personal Data, we will notify you by email to the
          email address specified in your account and/or through a notice on the
          Website’s home page or the App’s home screen. The date this Privacy
          Policy was last revised is identified at the top of the page. You are
          responsible for ensuring we have an up-to-date active and deliverable
          email address for you, and for periodically accessing the App or
          visiting our Website and reviewing this Privacy Policy to check for
          any changes.
        </p>
        <p>
          <strong className="subheading">12. Contact Information</strong>
        </p>
        <p>You may contact us through the contact information below.</p>
        <p>
          If you have any questions, concerns, complaints or suggestions
          regarding our Privacy Policy or otherwise need to contact us, you may
          contact us at the contact information below or through the “Contact
          Us” page on our Website.
        </p>
        <p className="text-center">
          <strong>How to Contact Us:</strong>
        </p>
        <p className="text-center">Tura Health LLC</p>
        <p className="text-center">1100 Town &amp; Country Road, Suite 1250</p>
        <p className="text-center">Orange, CA 92868</p>
        <p className="text-center">Telephone: (714) 464-7557</p>
        <p className="text-center">
          Email: <a href="care@pocketmed.com">care@pocketmed.com</a>
        </p>
        <p className="text-center"></p>
      </Modal.Body>
    </Modal>
  );
};

export default PrivacyPolicyModal;
