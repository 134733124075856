const currencyFormatter = (
  price: number | string | undefined,
  decimalPoints = 2,
  truncate = false
) => {
  const priceToUse = price ?? 0;
  const priceToDisplay = truncate
    ? Math.trunc(Number(priceToUse) * 100) / 100
    : price;
  return `$${Number(priceToDisplay).toFixed(decimalPoints)}`;
};

export { currencyFormatter };
