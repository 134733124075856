import React, { useEffect, useState } from "react";
import { first, isEmpty, filter, find as lodashFind } from "lodash";
import { Modal } from "react-bootstrap";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { GetProductDetailsRequest } from "apis";

const MedicationDetailsModal = ({
  medication,
  showMedicationDetailsModal,
  setShowMedicationDetailsModal,
}: any) => {
  const [activeProduct, setActiveProduct] = useState<any>("");
  const isMarketing = localStorage.getItem("isMarketing") || "landing";

  useEffect(() => {
    GetProductDetailsRequest(isMarketing)
      .then((res: any) => {
        const drugName = first(medication.split(" "));

        if (isEmpty(filter(res.data.products, { name: drugName }))) return;
        setActiveProduct(lodashFind(res.data.products, { name: drugName }));
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, [medication]);

  if (isEmpty(activeProduct)) return <></>;

  return (
    <Modal
      show={showMedicationDetailsModal}
      onHide={() => setShowMedicationDetailsModal(false)}
      className="medication-info-dialog"
      size="xl"
      id="drugInfoModal"
      animation={false}>
      <Modal.Header>
        <Modal.Title>{medication} oral tablet</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="plan-details-wrapper">
          <h6 className="treatment-plans-heading">Overview</h6>
          <p>{activeProduct.overview}</p>
          <h6 className="treatment-plans-heading">Description</h6>
          <p className="markdown-container">{activeProduct.description}</p>
          <h6 className="treatment-plans-heading">Usage</h6>
          <div className="markdown-container">
            <p style={{ whiteSpace: "pre-line" }}>{activeProduct.usage}</p>
          </div>
          <h6 className="treatment-plans-heading">Side Effects</h6>
          <div className="markdown-container">
            <ReactMarkdown
              children={activeProduct.side_effects}
              remarkPlugins={[remarkGfm]}
            />
          </div>
          <h6 className="treatment-plans-heading">Drug Interactions</h6>
          <div className="markdown-container">
            <ReactMarkdown
              children={activeProduct.drug_interactions}
              remarkPlugins={[remarkGfm]}
            />
          </div>
          <h6 className="treatment-plans-heading">Storage</h6>
          <div className="markdown-container">
            <ReactMarkdown
              children={activeProduct.storage}
              remarkPlugins={[remarkGfm]}
            />
          </div>
          <h6 className="treatment-plans-heading">Other Info</h6>
          <div className="markdown-container">
            <ReactMarkdown
              children={activeProduct.other_info}
              remarkPlugins={[remarkGfm]}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MedicationDetailsModal;
