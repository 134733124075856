import React, { useContext, useState } from "react";
import { ReactSVG } from "react-svg";
import { Formik, Form } from "formik";
import { isEmpty } from "lodash";

import { AuthContext } from "Contexts/AuthContext";
import { CartContext } from "Contexts/CartContext";

import useCurrentUser from "hooks/useCurrentUser";

import Translator from "components/Translator";
import Button from "components/Button";

import PatientTestimonialsModal from "./Modals/PatientTestimonialsModal";
import { handleShowModals } from "./helpers";

import ratingStars from "assets/images/home/rating-stars.svg";
import sildenafilBottle from "assets/images/home/sildenafil_bottle.png";
import arrowLeft from "assets/images/home/arrow-left.svg";
import discountTag from "assets/images/home/discount_tag.svg";
import one from "assets/images/home/1.svg";
import hipaaCompliance from "assets/images/home/hipaa_compliance.svg";
import heroImage from "assets/images/home/hero_image.png";

type Props = {
  defaultProductVariant: {
    id?: any;
  };
};

const Hero = ({ defaultProductVariant }: Props) => {
  const user = useCurrentUser();
  const { CreateCart } = useContext(CartContext);
  const {
    setShowResumeVisitModal,
    setShowUnderReviewVisitModal,
    retakePending,
    setShowRetakeModal,
    setShowRefillAvailableModal,
    setShowRefillInProgress,
    setShowDuplicateCartModal,
  } = useContext(AuthContext);
  const [showTestimonialModal, setShowTestimonialModal] =
    useState<boolean>(false);

  return (
    <>
      <div className="ab-wrapper ab-1">
        <div className="homepage-container">
          <header className="landing-page-header mt-1">
            <div className="header-bg d-flex">
              <div className="content d-flex">
                <div className="header-content">
                  <h5 className="quote-text text-brand-primary">
                    <Translator path="homepage.hero.headings.officialLabTested" />
                  </h5>
                  <h1 className="h1 mb-2 mt-3 text-brand-primary">
                    <Translator path="homepage.hero.headings.genericSildenafil" />
                  </h1>
                  <div className="row no-gutters">
                    <div className="col-12 col-md-6">
                      <p className="my-2 mb-3">
                        <Translator path="homepage.hero.loveLifeOnHold" />
                      </p>
                      <button
                        className="sublink testimonial-link testimonail-modal-btn mt-0"
                        type="button"
                        onClick={() =>
                          setShowTestimonialModal(!showTestimonialModal)
                        }
                      >
                        <Translator path="homepage.hero.testimonialLink" />
                      </button>
                      <ReactSVG
                        src={ratingStars}
                        wrapper={"span"}
                        className="ml-2"
                      />
                      <PatientTestimonialsModal
                        show={showTestimonialModal}
                        setShow={setShowTestimonialModal}
                      />
                      <div className="d-flex">
                        <img
                          src={sildenafilBottle}
                          alt="sildenafil bottle logo"
                          className="d-block d-md-none mx-auto mt-2 medicine-bottle-image"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="details-wrapper">
                    {!isEmpty(defaultProductVariant) && (
                      <Formik
                        initialValues={{
                          variant: defaultProductVariant?.id ?? null,
                        }}
                        onSubmit={(values) => {
                          const allowCartCreation = handleShowModals({
                            showRetake: retakePending,
                            showUnderReview: user?.has_under_review_visit,
                            showResumeVisit: user?.has_cart_in_progress,
                            showRefillAvailableModal: user?.has_refill,
                            showRefillInProgress: user?.has_in_progress_refill,
                            is_order_being_processed:
                              user?.is_order_being_processed,
                            setShowRetakeModal,
                            setShowUnderReviewVisitModal,
                            setShowResumeVisitModal,
                            setShowRefillAvailableModal,
                            setShowRefillInProgress,
                            setShowDuplicateCartModal,
                          });

                          const newValues = {
                            ...values,
                            variant: defaultProductVariant.id,
                          };
                          if (allowCartCreation) CreateCart(newValues);
                        }}
                      >
                        {() => (
                          <Form className="refillChecks">
                            <div className="get-started-section">
                              <div className="button-wrapper mr-0 mr-md-4">
                                <Button
                                  type={"submit"}
                                  id={"getStartedBtn"}
                                  size={"large"}
                                  color={"primary"}
                                  dataAttribute={defaultProductVariant.id}
                                  disabled={false}
                                  fullWidth={false}
                                  classes={"get-started-btn"}
                                >
                                  <Translator path="homepage.hero.startVisit" />
                                  <ReactSVG src={arrowLeft} className="ml-2" />
                                </Button>
                                <div className="discount-icon mb-3">
                                  <ReactSVG
                                    src={discountTag}
                                    wrapper={"span"}
                                  />
                                  <small className="font-semi-bold ml-1">
                                    <Translator path="homepage.hero.discountText" />
                                  </small>
                                </div>
                              </div>
                              <div className="get-started-icons d-flex justify-content-center justify-content-md-start mr-2 mt-4 mt-md-0">
                                <div className="licensed-pharmacy mr-4">
                                  <ReactSVG src={one} />
                                  <p>
                                    <Translator path="homepage.hero.licensedPharmacy" />
                                  </p>
                                </div>
                                <div className="hipaa-compliant">
                                  <ReactSVG src={hipaaCompliance} />
                                  <p>
                                    <Translator path="homepage.hero.hipaaCompliant" />
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </div>
                </div>
                <img src={heroImage} alt="hero icon" className="hero-image" />
              </div>
            </div>
          </header>
        </div>
      </div>
    </>
  );
};

export default Hero;
