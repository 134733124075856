import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../Contexts/AuthContext";
import useCurrentUser from "../../hooks/useCurrentUser";

const Dashboard = () => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const { Logout } = useContext(AuthContext);
  if (!currentUser) {
    navigate("/");
    return <></>;
  }
  return (
    <>
      <span className="no-mouseflow">
        Hello this is the dashboard. Welcome {currentUser.first_name}.
      </span>
      <button onClick={() => Logout(false)}>Logout</button>
    </>
  );
};

export default Dashboard;
