import React, { useContext } from "react";
import { Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import Translator from "../../components/Translator";
import emailIcon from "../../assets/images/login/mail.svg";
import * as Yup from "yup";
import { AuthContext } from "../../Contexts/AuthContext";
import { isEmpty } from "lodash";

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});

const ForgotPassword = () => {
  const { ForgotPasswordCall } = useContext(AuthContext);
  const { resetPasswordError, setResetPasswordError } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSignInClick = () => {
    navigate("/sign-in", { state: { loginWithEmail: true }, replace: true });
  }

  return (
    <main className="main">
      <div className="session-page">
        <div className="content forget-password">
          <h3>Password assistance</h3>
          <p>
            Please enter your email address and we'll send you a link to reset
            your password.
          </p>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={ForgotPasswordSchema}
            onSubmit={(values) => {
              setResetPasswordError("");
              ForgotPasswordCall(values);
            }}
          >
            {({ errors, touched }) => (
              <Form className="new_user">
                <div className="field">
                  <label className="input-label" htmlFor="email">
                    <Translator path="signIn.fields.emailTitle" />
                  </label>
                  <div className="input-group row mx-0">
                    <ReactSVG className="email-icon" src={emailIcon} />
                    <Field
                      className="no-mouseflow input with-icon"
                      name="email"
                      type="email"
                      placeHolder="Enter your email"
                    />
                  </div>
                  {errors.email && touched.email ? (
                    <div className="input-errors">{errors.email}</div>
                  ) : null}
                  {!isEmpty(resetPasswordError) && (
                    <span className="input-error">{`${resetPasswordError}`}</span>
                  )}
                </div>
                <button className="btn submit-btn text-uppercase" type="submit">
                  Continue
                </button>
              </Form>
            )}
          </Formik>
          <div className="d-flex justify-content-center mt-1">
            <button className="sign-in-btn" onClick={handleSignInClick} >
              Back to Sign In
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ForgotPassword;
