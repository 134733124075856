import React from "react";

import { IMaskInput } from "react-imask";

const CvvNumberInput = (props) => {
  const { label, icon, customClass, onChange, inputClasses, ...attrs } = props;

  const haveIcon = icon ? "input with-icon" : "input";

  const onAccept = (val) =>
    onChange({
      target: {
        value: val,
        name: attrs.name,
      },
    });

  return (
    <div className={`form-group text-field-wrapper ${customClass}`}>
      {label && (
        <label className="input-label" htmlFor={attrs.id}>
          {label}
        </label>
      )}

      <div className={`input-group row mx-0`}>
        <IMaskInput
          className={`no-mouseflow mouseflow-hidden ${haveIcon} ${inputClasses}`}
          mask={/^\d+$/}
          onAccept={onAccept}
          {...attrs}
        />
        {icon && <span className={`input__icon ${icon}`} />}
      </div>
    </div>
  );
};

export default CvvNumberInput;
