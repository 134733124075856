import React, { useContext } from "react";
import { ReactSVG } from "react-svg";
import Translator from "../../components/Translator";
import Button from "../../components/Button";
import welcomeOne from "../../assets/images/welcome/welcome-1.svg";
import welcomeTwo from "../../assets/images/welcome/welcome-2.svg";
import welcomeThree from "../../assets/images/welcome/welcome-3.svg";
import { CartContext } from "../../Contexts/CartContext";
import "./styles.scss";
import ReactPixel from "react-facebook-pixel";

const Welcome = () => {
  const { WelcomePage } = useContext(CartContext);

  const handleContinue = () => {
    ReactPixel.fbq("track", "ViewContent", {}, { eventID: "fb_view_content" });
    WelcomePage();
  };

  return (
    <>
      <div className="blue-banner d-flex justify-content-center align-items-center">
        <p className="mb-0">
          <Translator path="welcome.freeConsultationAndShipping" />
        </p>
      </div>
      <main className="main">
        <div className="container checkout-page product-page">
          <div className="content wide">
            <div className="welcome-container row justify-content-center pt-0 pt-md-3">
              <div className="col">
                <section>
                  <div className="row justify-content-center mt-lg-0 mt-5">
                    <div className="col-lg-8 col-12 text-center">
                      <h3>
                        <Translator
                          path={"welcome.welcomeToFreeConsultation"}
                        />
                      </h3>
                      <p>
                        <Translator path={"welcome.letsGetStarted"} />
                      </p>
                    </div>
                  </div>
                  <div className="welcome-info-cards-container">
                    <div className="row">
                      <div className="col-lg-4 col-12 card-column">
                        <div className="card">
                          <div className="card-body">
                            <div className="icon-wrapper">
                              <ReactSVG src={welcomeOne} />
                            </div>
                            <h5>
                              <Translator
                                path={"welcome.cards.one.answerQuick"}
                              />
                            </h5>
                            <p>
                              <Translator
                                path={"welcome.cards.one.answerQuickAnswer"}
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-12 card-column">
                        <div className="card">
                          <div className="card-body">
                            <div className="icon-wrapper">
                              <ReactSVG src={welcomeTwo} />
                            </div>
                            <h5>
                              <Translator
                                path={"welcome.cards.two.chooseTreatment"}
                              />
                            </h5>
                            <p>
                              <Translator
                                path={"welcome.cards.two.chooseTreatmentAnswer"}
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-12 card-column">
                        <div className="card">
                          <div className="card-body">
                            <div className="icon-wrapper">
                              <ReactSVG src={welcomeThree} />
                            </div>
                            <h5>
                              <Translator
                                path={"welcome.cards.three.expressShipping"}
                              />
                            </h5>
                            <p>
                              <Translator
                                path={
                                  "welcome.cards.three.expressShippingAnswer"
                                }
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-lg-4 col-12">
                      <Button
                        classes="continue-btn ga-view-content-event"
                        color="secondary"
                        fullWidth
                        onClick={() => handleContinue()}
                      >
                        <Translator path="welcome.continueButtonText" />
                      </Button>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Welcome;
