import React, { useState, useEffect } from "react";
import { DebounceInput } from "react-debounce-input";
import { FetchDosespotDataRequest } from "../../../../../apis";
import Button from "../../../../../components/Button";
import MedModal from "./MedModal";
import AddedMeds from "./AddedMeds";
import MedSearchResults from "./MedSearchResults";

type Props = {
  selectedAnswers: any;
  isProcessing: any;
  setIsProcessing: any;
  queriedMeds: any;
  setQueriedMeds: any;
  errors: any;
  setErrors: any;
  addedMeds: any;
  setAddedMeds: any;
  condition: any;
  questionId: any;
  activeQuestion: any;
  option: any;
  setTextAreaBorder: any;
  setContinueButtonDisable: any;
  setSelectedAnswers?: any;
};

const MedSearch = ({
  selectedAnswers,
  isProcessing,
  setIsProcessing,
  queriedMeds,
  setQueriedMeds,
  errors,
  setErrors,
  addedMeds,
  setAddedMeds,
  condition,
  questionId,
  activeQuestion,
  option,
  setTextAreaBorder,
  setContinueButtonDisable,
  setSelectedAnswers,
}: Props) => {
  const [showResults, setShowResults] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [showMedModal, setShowMedModal] = useState(false);
  const [activeMed, setActiveMed] = useState(null);
  const [filteredMeds, setFilteredMeds] = useState([]);

  useEffect(() => {
    if (isProcessing) {
      setShowResults(false);
    }
  }, [isProcessing]);

  useEffect(() => {
    setContinueButtonDisable(!Boolean(addedMeds.length));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedMeds]);

  useEffect(() => {
    if (addedMeds) {
      // Filter the added meds to display
      let meds = [];

      meds =
        activeQuestion?.label !== "16.01"
          ? addedMeds.filter((m: any) => m.question_id === questionId)
          : addedMeds;

      setFilteredMeds(meds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuestion, addedMeds]);

  useEffect(() => {
    if (
      activeQuestion?.question_type === "medication_search" &&
      activeQuestion?.label !== "16.01"
    ) {
      const ansOpt = activeQuestion?.answer_options[0];
      setSelectedAnswers([...selectedAnswers, ansOpt]);
    }
  }, [addedMeds]);

  const handleMedSearch = (name: any) => {
    setIsProcessing(true);
    setSearchText(name);
    setErrors([]);
    if (errors?.length) {
      setErrors([]);
    }

    const values = { name: name, api: "med" };

    FetchDosespotDataRequest(values)
      .then((response: any) => {
        setQueriedMeds(response.data);
        setShowResults(true);
        setErrors([]);
      })
      .catch((error: any) => {
        setErrors([error]);
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const addCustomMed = () => {
    if (searchText.length) {
      const meds = addedMeds?.filter((a: any) => a.name !== searchText);
      const newLocalId = addedMeds?.length
        ? addedMeds[addedMeds.length - 1].local_id + 1
        : addedMeds.length + 1;
      const newMed = {
        name: searchText,
        local_id: newLocalId,
        question_id: questionId,
      };
      setAddedMeds([...meds, newMed]);
      setSearchText("");
      setQueriedMeds([]);
      setShowResults(false);
      setErrors([]);
    } else {
      setErrors([
        "You need to search for a medication before trying to add something.",
      ]);
    }
  };

  return (
    <>
      <div className="answer-option-wrapper">
        <h5 className="question-heading extra-label mt-4">
          {option?.extra_label}
        </h5>
        <div className="text-search-wrapper">
          <DebounceInput
            type="text"
            className="answer-option-text-field"
            placeholder="Add medication"
            value={searchText}
            debounceTimeout={500}
            onChange={(e: any) => handleMedSearch(e.target.value)}
            onFocus={() => setShowResults(true)}
            onBlur={() => setShowResults(false)}
          />
          <Button
            classes="inline-add-btn"
            color="secondary"
            onClick={() => {
              addCustomMed();
              setTextAreaBorder(false);
            }}
          >
            Add
          </Button>
        </div>
        {showResults && (
          <MedSearchResults
            queriedMeds={queriedMeds}
            setQueriedMeds={setQueriedMeds}
            searchText={searchText}
            addedMeds={addedMeds}
            setAddedMeds={setAddedMeds}
            setShowResults={setShowResults}
            setSearchText={setSearchText}
            questionId={questionId}
          />
        )}
        <AddedMeds
          addedMeds={filteredMeds}
          setActiveMed={setActiveMed}
          setShowMedModal={setShowMedModal}
          setContinueButtonDisable={setContinueButtonDisable}
        />
        <MedModal
          med={activeMed}
          showMedModal={showMedModal}
          setShowMedModal={setShowMedModal}
          addedMeds={addedMeds}
          setAddedMeds={setAddedMeds}
          condition={condition}
          questionId={questionId}
        />
      </div>
    </>
  );
};

export default MedSearch;
