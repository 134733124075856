import React from "react";
import { Field } from "formik";
import InputMask from "react-text-mask";

type HOCPhoneNumberMaskProp = {
  name?: string;
  size?: string;
  setError?: any;
  setField?: any;
};

const HOCPhoneNumberMask = ({
  name,
  size,
  setError,
  setField,
}: HOCPhoneNumberMaskProp) => {
  const phoneMask = [
    "(",
    /[1-9]/,
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  return (
    <div className="input-group row flex-row mx-0">
      <div className="input__icon phone email-icon user-icon"></div>
      <Field name={name} className="input" type="tel">
        {({ field }: any) => (
          <InputMask
            {...field}
            mask={phoneMask}
            guide={false}
            type="tel"
            className={`input ${
              size ?? ""
            } with-icon no-mouseflow mouseflow-hidden`}
            onKeyUp={(e: any) => {
              setError("");
              setField("mobile_phone", e.target.value);
            }}
          />
        )}
      </Field>
    </div>
  );
};

export default HOCPhoneNumberMask;
