import React, { useEffect, useState, useContext } from "react";
import { ApplyDiscountRequest, GetProductVariantsRequest } from "apis";
import { isEmpty, map, compact, filter, find, first, capitalize } from "lodash";
import { CartContext } from "Contexts/CartContext";
import CartItem from "./CartItem";
import CreditCardPicker from "./CreditCardPicker";
import { currencyFormatter } from "helpers/Formatter";
import { evaluateDiscount } from "helpers/PriceCalculator";

import discountTagIcon from "assets/images/icons/discount-tag-icon.svg";
import crossIcon from "assets/images/icons/cross-icon.svg";
import uspsSvg from "assets/images/checkout/usps.svg";
import visaBadge from "assets/images/checkout/visa_badge.svg";
import amexBadge from "assets/images/checkout/amex_badge.svg";
import mastercardBadge from "assets/images/checkout/mastercard_badge.svg";
import discoverBadge from "assets/images/checkout/discover_badge.svg";
import hsaBadge from "assets/images/checkout/hsa_badge.svg";
import fsaBadge from "assets/images/checkout/fsa_badge.svg";
import dinerBadge from "assets/images/checkout/diner_badge.svg";
import jcbBadge from "assets/images/checkout/jcb_badge.svg";
import lockIcon from "assets/images/icons/lock-icon.svg";

type CartEditorProps = {
  cart?: any;
  user?: any;
};

const CartEditor = ({ cart, user }: CartEditorProps) => {
  const [errors, setErrors] = useState<object>({});
  const [couponCode, setCouponCode] = useState("");
  const [variants, setVariants] = useState<any>([]);
  const [products, setProducts] = useState<any>([]);
  const [thresholds, setThresholds] = useState<any>([]); // TODO: Remove if not needed
  const [packages, setPackages] = useState<any>([]);
  const [selectedProduct, setSelectedProduct] = useState<any>({});
  const [selectedVariant, setSelectedVariant] = useState<any>({});
  const [selectedQuantity, setSelectedQuantity] = useState<any>({});
  const [customInput, setCustomInput] = useState<boolean>(false);
  const [maxQuantityExceeded, setMaxQuantityExceeded] = useState(false);
  const [minQuantityExceeded, setMinQuantityExceeded] = useState(false);
  const [showCustomField, setShowCustomField] = useState(false);
  const [maxQtyAllowed, setMaxQtyAllowed] = useState(false); // TODO: Handle properly
  const [customInputHasValidValue, setCustomInputHasValidValue] =
    useState(false);
  const [showCouponInputDisplay, setShowCouponInputDisplay] = useState(false);
  const [allowCouponInsert, setAllowCouponInsert] = useState(true);
  const [processingCoupon, setProcessingCoupon] = useState(false);
  const [discountError, setDiscountError] = useState<any>(
    cart.errors && cart.errors.discount
  );

  const discountCodes = compact(map(cart.discounts, (d) => d.code));
  const discountPercentage = compact(map(cart.discounts, (d) => d.percent));
  const totalPrice =
    parseFloat(cart.total_price) + parseFloat(cart.provider_fee);

  const { UpdateCart, ApplyDiscount, RemoveDiscount, setLatestCart } =
    useContext(CartContext);

  useEffect(() => {
    if (isEmpty(cart)) {
      return;
    }

    // Fetch data for dropdowns from the API
    GetProductVariantsRequest(cart.id)
      .then((res) => {
        const { products } = res.data;
        const currentProduct = find(products, { id: cart.product_id });
        const currentVariant = find(currentProduct.product_variants, {
          id: cart.product_variant_id,
        });

        setProducts(products);
        setVariants(currentProduct.product_variants);
        setThresholds(currentVariant.price_thresholds);
        setPackages(currentVariant.packages);

        // TODO: Set selectedProduct based on whether a value is present in cart or not
        setSelectedProduct(currentProduct);
        setSelectedVariant(currentVariant);
        setSelectedQuantity(cart.quantity ?? currentVariant.min_order_quantity);
        setAllowCouponInsert(!cart.discounts.length);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  const handleProductChange = (name: any) => {
    const product = find(products, { name });
    const variants = product.product_variants;
    const newVariant: any = first(variants); // TODO: Define a shared product variant type

    // setShowUnderReviewError(false);

    setSelectedProduct(product);
    setVariants(variants);
    setSelectedVariant(newVariant);
    setSelectedQuantity(newVariant.min_order_quantity);
    setShowCustomField(false);

    const newCart = cart;
    newCart.product_variant_id = newVariant.id;
    newCart.quantity = newVariant.min_order_quantity;

    UpdateCart(newCart);
  };

  const handleDosageChange = (dosage: any) => {
    const variant = find(variants, { dosage_value: dosage });

    // setShowUnderReviewError(false);
    setSelectedVariant(variant);
    setPackages(variant.packages);
    setSelectedQuantity(variant.min_order_quantity);
    setShowCustomField(false);

    const newCart = cart;
    newCart.product_variant_id = variant.id;
    newCart.quantity = variant.min_order_quantity;

    UpdateCart(newCart);
  };

  const handlePackageSelect = (quantity: any) => {
    const quantityTooSmall = quantity < selectedVariant.min_order_quantity;
    const quantityTooLarge = quantity > 1000000;

    // setShowUnderReviewError(false);
    setSelectedQuantity(quantity);

    if ([null, selectedVariant.id].includes(cart.product_variant_id)) {
      if (
        !quantityTooSmall &&
        !quantityTooLarge &&
        quantity !== "Custom Quantity"
      ) {
        setCustomInput(false);

        const newCart = cart;
        newCart.product_variant_id = selectedVariant.id;
        newCart.quantity = quantity;

        UpdateCart(newCart);
      } else {
        handleCustomInput(quantity);
      }
    } else {
      // TODO: Add flash messages
      console.log(
        `You have already added another product to your cart. Please finish latest order or clear cart to add the new one.`
      );
    }
  };

  const handleCustomInput = (quantity: any) => {
    if (quantity === "Custom Quantity") {
      setShowCustomField(true);
    }
  };

  const handleCloseCustomInput = (value: any) => {
    setShowCustomField(value);
    setMaxQuantityExceeded(value);
    setMinQuantityExceeded(value);
    setCustomInputHasValidValue(true);
  };

  const handleCustomInputChange = (newQuantity: any) => {
    const min_quantity = selectedVariant.min_order_quantity;
    const max_quantity = cart.max_quantity;

    setSelectedQuantity(newQuantity);

    // Handle maximum quantity scenario
    if (max_quantity && max_quantity < newQuantity) {
      setMaxQuantityExceeded(true);
    } else {
      setMaxQuantityExceeded(false);
    }

    // // Handle minimum quantity scenario
    if (min_quantity && newQuantity.length > 0 && min_quantity > newQuantity) {
      setMinQuantityExceeded(true);
    } else {
      setMinQuantityExceeded(false);
    }
    handlePackageSelect(newQuantity);
  };

  const calculateDiscount = (variant_id: any) => {
    return filter(variants, { id: variant_id });
  };

  const handleApplyDiscount = () => {
    if (couponCode.length) {
      setDiscountError("");
      setProcessingCoupon(true);
      ApplyDiscountRequest({ cart_id: cart.id, code: couponCode })
        .then((res) => {
          setLatestCart(res.data.cart);
          setAllowCouponInsert(false);
          setShowCouponInputDisplay(false);
        })
        .catch((err) => {
          setDiscountError(err?.response?.data?.details);
          console.log(err);
        })
        .finally(() => {
          setProcessingCoupon(false);
        });
    }
    setCouponCode("");
  };

  const handleRemoveCoupon = () => {
    RemoveDiscount(cart.id).then(() => {
      setAllowCouponInsert(true);
      setCouponCode("");
    });
  };

  const handleCouponInput = () => {
    setDiscountError("");
    setShowCouponInputDisplay(!showCouponInputDisplay);
  };

  const handleCouponCodeChange = (e: any) => {
    setCouponCode(e.target.value);
  };

  const handleCouponFieldKeydown = (e: any) => {
    if (e.key === "Enter") {
      handleApplyDiscount();
    }
  };

  const cartDiscountPercentage: number =
    (1.0 -
      parseFloat(cart?.total_price) / parseFloat(cart?.non_discounted_price)) *
    100;
  return (
    <>
      <div className="current-cart__row">
        <CartItem
          cart={cart}
          products={products}
          quantities={packages}
          current_variant={selectedVariant}
          variantsPerProduct={variants}
          handleProductChange={(product: any) => handleProductChange(product)}
          handleDosageChange={(dosage: any) => handleDosageChange(dosage)}
          handleQuantityChange={(qty: any) => handlePackageSelect(qty)}
          disabledOptions={[]}
          discountPercentage={discountPercentage}
          handleCustomInputChange={handleCustomInputChange}
        />
      </div>
      {cart.additionalQuantity > 0 && (
        <div className="current-cart__row">
          <div className="left">
            <strong>{cart.product_variant.fullname}</strong>
            <p>
              {cart.additionalQuantity} {capitalize(cart.product_variant.unit)}
              {cart.additionalQuantity > 1 && "s"}
            </p>
          </div>
          <div className="right">
            <strong>{currencyFormatter(0)}</strong>
          </div>
        </div>
      )}
      <div
        className={`discount mx-0 px-0 w-100 ${
          discountError ? "field_with_errors" : ""
        }`}
      >
        {allowCouponInsert && (
          <label className="coupon-wrapper">
            I have a coupon
            <input
              type="checkbox"
              id="showCouponInputDisplay"
              value="false"
              name="coupon"
              onClick={() => handleCouponInput()}
            />
            <span className="checkmark" onClick={() => setDiscountError("")} />
            <img className="ml-3" src={discountTagIcon} alt="" />
          </label>
        )}

        {showCouponInputDisplay && (
          <div className="d-flex justify-content-between discount--input">
            <input
              className="discount--input-field"
              onChange={(e: any) => handleCouponCodeChange(e)}
              onKeyDown={(e: any) => handleCouponFieldKeydown(e)}
              value={couponCode}
            />
            <button
              className="discount--button"
              disabled={!cart.quantity || processingCoupon}
              onClick={() => handleApplyDiscount()}
            >
              {processingCoupon ? "Processing..." : "Apply now"}
            </button>
          </div>
        )}
        {discountError && (
          <p className="error-message">
            {cart.errors?.discount
              ? `${cart.errors?.discount?.join(", ")}`
              : discountError}
          </p>
        )}
        {cart.discounts.length > 0 && (
          <div className="current-cart__row d-flex justify-content-between">
            <div className="discount-pill">
              <span className="cart-product__details">
                {discountCodes.length ? discountCodes.join(", ") : "Discount"}
              </span>
              <span
                className="remove-discount-code"
                onClick={handleRemoveCoupon}
              >
                <img src={crossIcon} alt="" />
              </span>
            </div>
            <div className="discounted-price">
              {`- ${currencyFormatter(cart.price - cart.final_price)}`}
            </div>
          </div>
        )}
      </div>
      <hr className="mb-0" />
      <div className="current-cart__row d-flex justify-content-between align-items-center p-0">
        <div className="current-shipping d-flex flex-column">
          <span className="cart-product__details provider-text">
            Medical Provider Vist Fee
          </span>
        </div>
        {Number(cart.provider_fee) === 0 ? (
          <span className="free-box">Free</span>
        ) : (
          <span className="cart-product__price">
            {`${currencyFormatter(cart.provider_fee)}`}
          </span>
        )}
      </div>
      <hr />
      <div className="current-cart__row">
        <div className="current-shipping d-flex">
          <span className="cart-product__details d-flex">
            <img src={uspsSvg} alt="" className="mr-3 delivery-image" />
            <span className="delivery-option">
              {`${cart.delivery_label}`}{" "}
              <span>{cart.delivery_option.delivery_days_label}</span>
            </span>
          </span>
          {Number(cart.final_delivery_price) === 0 ? (
            <span className="free-box">Free</span>
          ) : (
            <span className="cart-product__price cart-product__shipping">
              {`${currencyFormatter(cart.final_delivery_price)}`}
            </span>
          )}
        </div>
      </div>
      <hr />
      <div className="current-cart__row py-0">
        <div className="current-shipping d-flex pb-1">
          <span className="total-price-text">Total</span>
          {cart.non_discounted_price !== cart.total_price && (
            <span className="slashed-price">
              {currencyFormatter(cart.non_discounted_price)}
            </span>
          )}
          <span className="price-text ml-2">
            {currencyFormatter(totalPrice)}
          </span>
        </div>
        {Math.round(
          evaluateDiscount(
            discountPercentage,
            cart.product_variant,
            cart.quantity
          )
        ) > 0 && (
          <span className="saved-amount">
            You save {Math.round(cartDiscountPercentage)}%
          </span>
        )}
        <div className="no-subscription">
          <div className="text">No Subscription. One time charge only.</div>
        </div>
      </div>
      <hr className="my-3" />
      <div className="payment-method-selector">
        <button className="d-none ga-initiate-checkout-event ga-confirm-and-pay-event fb-initiate-checkout-event fb-confirm-and-pay-event"></button>
        <h5 className="my-4">Payment Method</h5>
        <span className="d-flex">
          <img src={amexBadge} alt="" className="payment-badge" />
          <img src={mastercardBadge} alt="" className="payment-badge" />
          <img src={visaBadge} alt="" className="payment-badge" />
          <img src={discoverBadge} alt="" className="payment-badge" />
          <img src={dinerBadge} alt="" className="payment-badge" />
          <img src={jcbBadge} alt="" className="payment-badge" />
          <img src={fsaBadge} alt="" className="payment-badge" />
          <img src={hsaBadge} alt="" className="payment-badge" />
        </span>
        <CreditCardPicker />
        <div className="security-text-wrapper">
          <img src={lockIcon} alt="Security" />
          <span>128-Bit TLS Security</span>
        </div>
      </div>
    </>
  );
};

export default CartEditor;
