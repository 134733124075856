import React, { useState } from "react";
import { isEmpty } from "lodash";

import "./styles.scss";
type SiteAuthProps = {
  handleSiteLogIn: any;
};

const SiteAuth = ({ handleSiteLogIn }: SiteAuthProps) => {
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [key, setKey] = useState<string>("");

  const handleOnChange = (e: any) => {
    setError(false);

    if (!isEmpty(e.target.value)) {
      setBtnDisabled(false);
      setKey(e.target.value);
    } else {
      setBtnDisabled(true);
      setKey("");
    }

    if (!isEmpty(e.target.value) && e.key === "Enter") {
      handleContinue();
    }
  };

  const handleContinue = () => {
    if (
      !isEmpty(key) &&
      (key === process.env.REACT_APP_SITE_TOKEN ||
        key === process.env.REACT_APP_SITE_GUEST_TOKEN)
    ) {
      console.log("1");
      handleSiteLogIn(key);
    } else {
      setError(true);
    }
  };
  return (
    <>
      <div className="site-auth">
        <div className="row h-100 justify-content-center align-items-center">
          <div className="col-8">
            <div className="d-flex justify-content-center flex-column">
              <h1>Please enter the code word to continue…</h1>
              {error && (
                <>
                  <span className="error-text">
                    Hmm… that doesn’t seem right. Try again?
                  </span>
                </>
              )}
              <div className="key">
                <input
                  type="password"
                  name="key"
                  id="key"
                  onKeyUp={(e) => handleOnChange(e)}
                />
                <button
                  className={`${btnDisabled ? "disabled" : ""}`}
                  disabled={btnDisabled}
                  onClick={() => handleContinue()}
                >
                  Go
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SiteAuth;
