import React, { useState, useEffect, useContext } from "react";
import { handleShowModals } from "containers/Home/helpers";
import { AuthContext } from "Contexts/AuthContext";
import { CartContext } from "Contexts/CartContext";
import useCurrentUser from "hooks/useCurrentUser";
import Submitted from "./Submitted";
import Completed from "./Completed";
import CompletedRejected from "./CompletedRejected";
import Rejected from "./Rejected";
import Cancelled from "./Cancelled";
import Abandoned from "./Abandoned";
import Refunded from "./Refunded";
import { GetProductVariantRequest } from "../../../../apis";

const Statuses = ({ visit, removeActiveVisit }: any) => {
  const {
    setShowResumeVisitModal,
    setShowUnderReviewVisitModal,
    retakePending,
    setShowRetakeModal,
    setShowRefillAvailableModal,
    setShowRefillInProgress,
    setShowDuplicateCartModal,
  } = useContext(AuthContext);

  const [defaultProductVariant, setDefaultProductVariant] = useState<any>({});

  useEffect(() => {
    GetProductVariantRequest().then((res: any) => {
      setDefaultProductVariant(res.data.default_product_variant);
    });
  }, []);
  const user = useCurrentUser();
  const { CreateCart } = useContext(CartContext);

  const visitDetailStartNewVisit = () => {
    const allowCartCreation = handleShowModals({
      showRetake: retakePending,
      showUnderReview: user?.has_under_review_visit,
      showResumeVisit: user?.has_cart_in_progress,
      showRefillAvailableModal: user?.has_refill,
      showRefillInProgress: user?.has_in_progress_refill,
      is_order_being_processed: user?.is_order_being_processed,
      setShowRetakeModal,
      setShowUnderReviewVisitModal,
      setShowResumeVisitModal,
      setShowRefillAvailableModal,
      setShowRefillInProgress,
      setShowDuplicateCartModal,
    });

    if (allowCartCreation) {
      CreateCart({ variant: defaultProductVariant?.id });
    }
  };

  if (visit.status === "rejected") {
    if (visit.clinical_review_stage === "clinical_completed_rejected") {
      return (
        <CompletedRejected
          removeActiveVisit={removeActiveVisit}
          defaultProductVariant={defaultProductVariant}
        />
      );
    } else if (visit.id_review_stage === "completed_rejected") {
      return (
        <Rejected
          removeActiveVisit={removeActiveVisit}
          defaultProductVariant={defaultProductVariant}
          startNewVisit={visitDetailStartNewVisit}
        />
      );
    } else {
      return (
        <Cancelled
          removeActiveVisit={removeActiveVisit}
          defaultProductVariant={defaultProductVariant}
          startNewVisit={visitDetailStartNewVisit}
        />
      );
    }
  }

  switch (visit.status) {
    case "submitted":
      return (
        <Submitted
          removeActiveVisit={removeActiveVisit}
          defaultProductVariant={defaultProductVariant}
        />
      );
    case "completed":
      return (
        <Completed
          removeActiveVisit={removeActiveVisit}
          defaultProductVariant={defaultProductVariant}
        />
      );
    case "cancelled":
      return (
        <Cancelled
          removeActiveVisit={removeActiveVisit}
          defaultProductVariant={defaultProductVariant}
          startNewVisit={visitDetailStartNewVisit}
        />
      );
    case "abandoned":
      return (
        <Abandoned
          removeActiveVisit={removeActiveVisit}
          defaultProductVariant={defaultProductVariant}
          startNewVisit={visitDetailStartNewVisit}
        />
      );
    case "refunded":
      return (
        <Refunded
          removeActiveVisit={removeActiveVisit}
          defaultProductVariant={defaultProductVariant}
          startNewVisit={visitDetailStartNewVisit}
        />
      );
  }

  return <></>;
};

export default Statuses;
