import React from "react";
import Modal from "react-bootstrap/Modal";
import crossIcon from "assets/images/icons/entypo-cross.svg";

type TelehealthModalProps = {
  show: boolean;
  setShow: any;
};

const TelehealthModal = ({ show, setShow }: TelehealthModalProps) => {
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      className="drawer"
      dialogClassName="visit-consent-modals">
      <Modal.Header>
        <h3>Informed Consent</h3>
        <button onClick={() => setShow(false)} className="close-button">
          <img src={crossIcon} alt="Close" />
        </button>
      </Modal.Header>
      <Modal.Body>
        <p className="text-align-justify">
          Telehealth involves the use of secure electronic communications,
          information technology, or other means to enable a healthcare provider
          and a patient at different locations to enable a healthcare provider
          and a patient at different locations to communicate and share
          individual patient health information for the purpose of rendering
          clinical care. This “<strong>“Telehealth Informed Consent”</strong>
          informs the patient (<strong>“patient,”</strong>
          <strong>“you,”</strong>
          or
          <strong>“your”</strong>) concerning the treatment methods, risks, and
          limitations of using a telehealth platform.
        </p>
        <p>
          <strong className="subheading">Services Provided:</strong>
        </p>
        <p>
          Telehealth services offered by Enjoy Health Medical P.A. (
          <strong>“Group”</strong>
          ), and the Group’s engaged providers (our
          <strong>“Providers”</strong>
          or your
          <strong>“Provider”</strong>) may include a patient consultation,
          diagnosis, treatment recommendation, prescription, and/or a referral
          to in-person care, as determined clinically appropriate (the
          <strong>“Services”</strong>
          ).
        </p>
        <p>
          Tura Health LLC does not provide the Services; it performs
          administrative, payment, and other supportive activities for Group and
          our Providers.
        </p>
        <p>
          <strong className="subheading">Electronic Transmissions:</strong>
        </p>
        <p>
          The types of electronic transmissions that may occur using the
          telehealth platform include, but are not limited to:
        </p>
        <ul>
          <li>Appointment scheduling;</li>
          <li>
            Completion, exchange, and review of medical intake forms and other
            clinically relevant information (for example: health records;
            images; output data from medical devices; sound and video files;
            diagnostic and/or lab test results) between you and your Provider
            via:
            <ul className="inner-list">
              <li>asynchronous communications;</li>
              <li>
                two-way interactive audio in combination with store-and-forward
                communications; and/or
              </li>
              <li>two-way interactive audio and video interaction;</li>
            </ul>
          </li>
          <li>
            Treatment recommendations by your Provider based upon such review
            and exchange of clinical information;
          </li>
          <li>
            Delivery of a consultation report with a diagnosis, treatment and/or
            prescription recommendations, as deemed clinically relevant;
          </li>
          <li>Prescription refill reminders (if applicable); and/or</li>
          <li>
            Other electronic transmissions for the purpose of rendering clinical
            care to you.
          </li>
        </ul>
        <p></p>
        <p>
          <strong className="subheading">Expected Benefits:</strong>
        </p>
        <p></p>
        <ul>
          <li>
            Improved access to care by enabling you to remain in your preferred
            location while your Provider consults with you. Our telehealth
            services are available 9am to 6pm hours a day, 5 days a week.
          </li>
          <li>
            Convenient access to follow-up care. If you need to receive
            non-emergent follow-up care related to your treatment, please
            contact your Provider by by directly sending a message through the
            patient’s user account.
          </li>
          <li>
            More efficient care evaluation and management. Email: 9 hours during
            the week or 0 hours over weekends and holidays. Electronic messages
            via telehealth platform: 9 hours during the week or 0 hours over
            weekends and holidays. Phone calls: 9 hours during the week or 0
            hours over weekends and holidays.
          </li>
        </ul>
        <p></p>
        <p>
          <strong className="subheading">Service Limitations:</strong>
        </p>
        <p></p>
        <ul>
          <li>
            The primary difference between telehealth and direct in-person
            service delivery is the inability to have direct, physical contact
            with the patient. Accordingly, some clinical needs may not be
            appropriate for a telehealth visit and your Provider will make that
            determination.
          </li>
          <li>
            <strong>
              OUR PROVIDERS DO NOT ADDRESS MEDICAL EMERGENCIES. IF YOU BELIEVE
              YOU ARE EXPERIENCING A MEDICAL EMERGENCY, YOU SHOULD DIAL 9-1-1
              AND/OR GO TO THE NEAREST EMERGENCY ROOM. PLEASE DO NOT ATTEMPT TO
              CONTACT TURA HEALTH LLC, GROUP, OR YOUR PROVIDER. AFTER RECEIVING
              EMERGENCY HEALTHCARE TREATMENT, YOU SHOULD VISIT YOUR LOCAL
              PRIMARY CARE PROVIDER.
            </strong>
          </li>
          <li>
            Our Providers are an addition to, and not a replacement for, your
            local primary care provider. Responsibility for your overall medical
            care should remain with your local primary care provider, if you
            have one, and we strongly encourage you to locate one if you do not.
          </li>
          <li>Group does not have any in-person clinic locations.</li>
        </ul>
        <p></p>
        <p>
          <strong className="subheading">Security Measures:</strong>
        </p>
        <p>
          The electronic communication systems we use will incorporate network
          and software security protocols to protect the confidentiality of
          patient identification and imaging data and will include measures to
          safeguard the data and to ensure its integrity against intentional or
          unintentional corruption. All the Services delivered to the patient
          through telehealth will be delivered over a secure connection that
          complies with the requirements of the Health Insurance Portability and
          Accountability Act of 1996 (“HIPAA”).
        </p>
        <p>
          <strong className="subheading">Possible Risks:</strong>
        </p>
        <p></p>
        <ul>
          <li>
            Delays in evaluation and treatment could occur due to deficiencies
            or failures of the equipment and technologies, or provider
            availability.
          </li>
          <li>
            In the event of a inability to communicate as a result of a
            technological or equipment failure, please contact the Group at
            (714) 464-7557 or care@pocketmed.com.
          </li>
          <li>
            In rare events, your Provider may determine that the transmitted
            information is of inadequate quality, thus necessitating a
            rescheduled telehealth consult or an in-person meeting with your
            local primary care doctor.
          </li>
          <li>
            In very rare events, security protocols could fail, causing a breach
            of privacy of personal medical information.
          </li>
        </ul>
        <p></p>
        <p>
          <strong className="subheading">Patient Acknowledgments:</strong>
        </p>
        <p>I further acknowledge and understand the following:</p>
        <ol>
          <li>
            Prior to the telehealth visit, will be given an opportunity to
            select a provider as appropriate, including a review of the
            provider’s credentials, or I have elected to visit with the next
            available provider from Group, and have been given my Provider’s
            credentials.
          </li>
          <li>
            If I am experiencing a medical emergency, I will be directed to dial
            9-1-1 immediately and my Provider is not able to connect me directly
            to any local emergency services.
          </li>
          <li>
            I may elect to seek services from a medical group with in-person
            clinics as an alternative to receiving telehealth services.
          </li>
          <li>
            I have the right to withhold or withdraw my consent to the use of
            telehealth in the course of my care at any time without affecting my
            right to future care or treatment.
          </li>
          <li>
            Federal and state law requireshealth care providers to protect the
            privacy and the security of health information. I am entitled to all
            confidentiality protections under applicable federal and state laws.
            I understand all medical reports resulting from the telehealth visit
            are part of my medical record.
          </li>
          <li>
            Group will take steps to make sure that my health information is not
            seen by anyone who should not see it. Telehealth may involve
            electronic communication of my personal health information to other
            health practitioners who may be located in other areas, including
            out of state.
          </li>
          <li>
            Dissemination of any patient identifiable images or information from
            the telehealth visit to researchers or other educational entities
            will not occur without my affirmative consent.
          </li>
          <li>
            There is a risk of technical failures during the telehealth visit
            beyond the control of Group. I AGREE TO HOLD HARMLESS GROUP AND ITS
            EMPLOYEES, CONTRACTORS, AGENTS, DIRECTORS, MEMBERS, MANAGERS,
            SHAREHOLDERS, OFFICERS, REPRESENTATIVES, ASSIGNS, PARENTS,
            PREDECESSORS, AND SUCCESSORS FOR DELAYS IN EVALUATION OR FOR
            INFORMATION LOST DUE TO SUCH TECHNICAL FAILURES.
          </li>
          <li>
            In choosing to participate in a telehealth visit, I understand that
            some parts of the Services involving tests (e.g., labs or bloodwork)
            may be conducted at another location such as a testing facility, at
            the direction of my Provider.
          </li>
          <li>
            Persons may be present during the telehealth visit other than my
            Provider in order to operate the telehealth technologies. If another
            person is present during the telehealth visit, I will be informed of
            the individual’s presence and his/her role.
          </li>
          <li>
            My Provider will explain my diagnosis and its evidentiary basis, and
            the risks and benefits of various treatment options.
          </li>
          <li>
            I have the right to request a copy of my medical records. I can
            request to obtain or send a copy of my medical records to my primary
            care or other designated health care provider by contacting Group
            at: (714) 464-7557 or care@pocketmed.com. A copy will be provided to
            me at reasonable cost of preparation, shipping and delivery.
          </li>
          <li>
            It is necessary to provide my Provider a complete, accurate, and
            current medical history. I understand that I can log into my
            “Portal” www.pocketmed.com/dashboard at any time to access, amend,
            or review my health information.
          </li>
          <li>
            There is no guarantee that I will be issued a prescription and that
            the decision of whether a prescription is appropriate will be made
            in the professional judgement of my Provider. If my Provider issues
            a prescription, I have the right to select the pharmacy of my
            choice.
          </li>
          <li>
            There is no guarantee that I will be treated by a Group provider. My
            Provider reserves the right to deny care for potential misuse of the
            Services or for any other reason if, in the professional judgment of
            my Provider, the provision of the Services is not medically or
            ethically appropriate.
          </li>
        </ol>
        <p></p>
        <p>
          <strong>
            <u>Additional State-Specific Consents:</u>
          </strong>
          The following consents apply to patients accessing Group’s website for
          the purposes of participating in a telehealth consultation as required
          by the states listed below:
        </p>
        <p>
          <strong>
            <u>Iowa:</u>
          </strong>
          I have been informed that if I want to register a formal complaint
          about a provider, I should visit the medical board’s website,{" "}
          <a href="https://medicalboard.iowa.gov/consumers/filing-complaint">
            here
          </a>
          .
        </p>
        <p>
          <strong>
            <u>Idaho:</u>
          </strong>
          I have been informed that if I want to register a formal complaint
          about a provider, I should visit the medical board’s website,{" "}
          <a href="https://bom.idaho.gov/BOMPortal/AgencyAdditional.aspx?Agency=425&amp;AgencyLinkID=650">
            here
          </a>
          .
        </p>
        <p>
          <strong>
            <u>Indiana:</u>
          </strong>
          I have been informed that if I want to register a formal complaint
          about a provider, I should visit the medical board’s website,{" "}
          <a href="https://www.in.gov/attorneygeneral/consumer-protection-division/file-a-complaint/consumer-complaint/">
            here
          </a>
          .
        </p>
        <p>
          <strong>
            <u>Kentucky:</u>
          </strong>
          I have been informed that if I want to register a formal complaint
          about a provider, I should visit the medical board’s website,{" "}
          <a href="https://kbml.ky.gov/grievances/Pages/default.aspx">here</a>.
        </p>
        <p>
          <strong>
            <u>Maine:</u>
          </strong>
          I have been informed that if I want to register a formal complaint
          about a provider, I should visit the medical board’s website,{" "}
          <a href="https://www.maine.gov/md/discipline/file-complaint.html">
            here
          </a>
          .
        </p>
        <p>
          <strong>
            <u>Oklahoma:</u>
          </strong>
          I have been informed that if I want to register a formal complaint
          about a provider, I should visit the medical board’s website,{" "}
          <a href="https://www.okmedicalboard.org/complaint">here</a>; Or, the
          Oklahoma Board of Osteopathic Examiners’ website,{" "}
          <a href="https://www.ok.gov/osboe/faqs.html">here</a>.
        </p>
        <p>
          <strong>
            <u>Texas:</u>
          </strong>
          I have been informed of the following notice:
        </p>
        <p>
          NOTICE CONCERNING COMPLAINTS -Complaints about physicians, as well
          asother licensees and registrants of the Texas Medical Board,
          including physician assistants, acupuncturists, and surgical
          assistants may be reported for investigation at the following address:
          Texas Medical Board, Attention: Investigations, 333 Guadalupe, Tower
          3, Suite 610, P.O. Box 2018, MC-263, Austin, Texas 78768-2018,
          Assistance in filing a complaint is available by calling the following
          telephone number: 1-800-201-9353, For more information, please visit
          our website at{" "}
          <a href="https://www.tmb.state.tx.us">www.tmb.state.tx.us</a>.
        </p>
        <p>
          AVISO SOBRE LAS QUEJAS- Las quejas sobre médicos, asi como sobre otros
          profesionales acreditados e inscritos del Consejo Médico de Tejas,
          incluyendo asistentes de médicos, practicantes de acupuntura y
          asistentes de cirugia, se pueden presentar en la siguiente dirección
          para ser investigadas: Texas Medical Board, Attention: Investigations,
          333 Guadalupe, Tower3, Suite 610, P.O. Box 2018, MC-263, Austin, Texas
          78768-2018, Si necesita ayuda para presentar una queja, llame al:
          1-800-201-9353, Para obtener más información, visite nuestro sitio web
          en <a href="https://www.tmb.state.tx.us">www.tmb.state.tx.us</a>
        </p>
        <p>
          <strong>
            <u>Vermont:</u>
          </strong>
          I have been informed thatif I want to register a formal complaint
          about a provider, I should visit the medical board’s website,{" "}
          <a href="https://www.healthvermont.gov/health-professionals-systems/board-medical-practice/file-complaint">
            here
          </a>
          ; Or, the Vermont Board of Osteopathic Examiners’ website,{" "}
          <a href="https://www.sec.state.vt.us/professional-regulation/file-a-complaint-employer-mandatory-reporting.aspx">
            here
          </a>
          .
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default TelehealthModal;
