import React from "react";
import Footer from "components/Footer";

const Hipaa = () => {
  return (
    <>
      <div className="content mt-5 py-5">
        <p className="text-center">
          <strong>
            <u>NOTICE of HIPAA PRIVACY POLICY</u>
          </strong>
        </p>
        <p className="text-center">&nbsp;</p>
        <p>
          <strong>NOTICE OF PRIVACY PRACTICES</strong>
        </p>
        <p>
          <strong>
            THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED
            AND DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE
            REVIEW IT CAREFULLY.
          </strong>
        </p>
        <p>
          Sildenafil.com, LLC (known and hereinafter referred to as
          “Sildenafil.com”) are “affiliated entities” for purposes of HIPAA
          compliance and administration. We are committed to protecting your
          privacy and understand the importance of safeguarding your personal
          health information. We are required by federal law to maintain the
          privacy of health information that identifies you or that could be
          used to identify you (known as “Protected Health Information” or
          “PHI”). We also are required to provide you with this Notice, which
          explains our legal duties and privacy practices with respect to PHI
          that we collect and maintain. This Notice describes your rights under
          federal law and state law, where applicable, relating to your PHI.
          Albertsons Companies is required by federal law to abide by this
          Notice. However, we reserve the right to change the privacy practices
          outlined in this Notice and make the new practices effective for all
          PHI that we maintain. Should we make such a change, we will display
          the revised Notice at our pharmacies and make it available to you upon
          request.
        </p>
        <p>
          <strong>
            I. USES AND DISCLOSURES OF PROTECTED HEALTH INFORMATION
          </strong>
        </p>
        <p>
          <strong>
            <u>
              A. Routine Uses and Disclosures of Protected Health Information
              for Treatment, Payment, or Health Care Operations.
            </u>
          </strong>
        </p>
        <p>
          Sildenafil.com is permitted under federal law to use and disclose PHI
          without your specific permission for three types of routine purposes:
          treatment, payment, and health care operations.
        </p>
        <p>
          Your pharmacist will use or disclose your PHI as described below. Your
          PHI may be used and disclosed by your pharmacist, pharmacy staff, and
          others outside of the pharmacy involved in your care and treatment.
          Set out below are examples of the uses and disclosures of your PHI we
          are permitted to make for these routine purposes. While this list is
          not meant to be exhaustive, it should give you an idea of the everyday
          uses and disclosures “behind the scenes” that are essential to the
          care you receive.
        </p>
        <ol>
          <li>
            <strong>Treatment.&nbsp;</strong>Your PHI can be used and disclosed
            by Sildenafil.com for treatment purposes. For example, your PHI will
            be used by our pharmacists to fill your prescription and to counsel
            you about the appropriate use of your medication.
            <br />
            We also may use and disclose your PHI to provide you with
            information about our health-related products and services. We may
            also send you compliance communications, such as reminders to refill
            or renew your prescription, information about generic alternatives
            for your prescription, or information about ways to enhance or
            improve your treatment outcomes.
          </li>
          <li>
            <strong>Payment.&nbsp;</strong>Your PHI can be used and disclosed
            for payment purposes. For example, we may communicate your PHI to
            your insurance company so that it can process payment for your
            prescription.
          </li>
          <li>
            <strong>Health Care Operations.&nbsp;</strong>Your PHI can be used
            and disclosed to allow us to conduct health care operations, which
            generally are the administrative activities that we undertake in
            order to operate our pharmacies. For example, we may use your PHI to
            evaluate the performance of our pharmacists and to engage in other
            quality assurance activities.
          </li>
        </ol>
        <p>
          <strong>
            <u>
              B. Other Uses and Disclosures of Protected Health Information
              Sildenafil.com is Permitted or Required to Make Without Your
              Authorization.&nbsp;
            </u>
          </strong>
        </p>
        <p>
          <strong>
            In general, we are required to obtain your specific written
            authorization to use or disclose your PHI for purposes unrelated to
            treatment, payment, or health care operations. However, there are
            exceptions to this general rule under which we are permitted or
            required to make certain uses and disclosures of your PHI without
            your authorization.
            <br />
            These
          </strong>
        </p>
        <ol>
          <li>
            <strong>
              Required by the Secretary of Health and Human Services.&nbsp;
            </strong>
            We may be required to disclose your PHI to the Secretary of Health
            and Human Services to investigate or determine our compliance with
            the federal privacy law.
          </li>
          <li>
            <strong>Required by Law.&nbsp;</strong>We may use or disclose your
            PHI to the extent that the use or disclosure is otherwise required
            by state or federal law.
          </li>
          <li>
            <strong>Public Health.&nbsp;</strong>We may disclose your PHI for
            public health activities, such as disclosures to a public health
            authority or other government agency that is permitted by law to
            collect or receive the information (e.g., the Food and Drug
            Administration).
          </li>
          <li>
            <strong>Abuse or Neglect.&nbsp;</strong>If you have been a victim of
            abuse, neglect, or domestic violence, we may disclose your PHI to
            the government agency authorized to receive such information.
          </li>
          <li>
            <strong>Health Oversight.&nbsp;</strong>We may disclose PHI to a
            health oversight agency for activities authorized by law, such as:
            civil or criminal investigations; inspections; licensure or
            disciplinary actions; or other activities necessary for appropriate
            oversight of retail pharmacies, governmental health benefit
            programs, or compliance with laws.
          </li>
          <li>
            <strong>Judicial and Administrative Proceedings.&nbsp;</strong>We
            may disclose PHI in response to a court or agency order, and in some
            cases, in response to a subpoena or other lawful process not
            accompanied by a court order.
          </li>
          <li>
            <strong>Law Enforcement.&nbsp;</strong>We may disclose PHI for law
            enforcement purposes, such as providing information to the police
            about the victim of a crime.
          </li>
          <li>
            <strong>
              Coroners, Medical Examiners, and Funeral Directors.&nbsp;
            </strong>
            We may disclose PHI to a coroner, medical examiner, or funeral
            director if it is needed to carry out their duties.
          </li>
          <li>
            <strong>Research.&nbsp;</strong>We may disclose your PHI to
            researchers when the research is being conducted under established
            protocols to ensure the privacy of your information.
          </li>
          <li>
            <strong>Serious Threat to Health or Safety.&nbsp;</strong>Your PHI
            may be disclosed if we believe it is necessary to prevent a serious
            and imminent threat to the public health or safety and it is to
            someone we reasonably believe is able to prevent or lessen the
            threat.
          </li>
          <li>
            <strong>Specialized Government Functions.&nbsp;</strong>We may
            disclose PHI for purposes related to military or national security
            concerns, such as for the purpose of a determination by the
            Department of Veterans Affairs of your eligibility for benefits.
          </li>
          <li>
            <strong>Domestic Armed Forces Personnel.&nbsp;</strong>We may use
            and disclose your PHI for activities deemed necessary by appropriate
            military command authorities to assure the proper execution of the
            military mission, if the authority has published proper notice in
            the Federal Register stating the purposes for which such information
            may be used or disclosed.
          </li>
          <li>
            <strong>Inmates.&nbsp;</strong>Under certain circumstances, we may
            disclose the PHI of inmates of a correctional institution.
          </li>
          <li>
            <strong>Workers’ Compensation.&nbsp;</strong>Your PHI may be
            disclosed to comply with workers’ compensation laws and other
            similar programs.
          </li>
        </ol>
        <p>
          <strong>
            <u>
              C. Other Restrictions on Uses and Disclosures of Protected Health
              Information.&nbsp;
            </u>
          </strong>
          The uses and disclosures of your PHI described above are permitted or
          required by federal law. Some states have laws that require additional
          privacy safeguards above and beyond the federal requirements. Thus, if
          a state law is more restrictive regarding uses and disclosures of your
          PHI or provides you with greater rights with respect to your PHI,
          Sildenafil.com will comply with the state law. If your state has
          enacted a more stringent law, we have attached as an addendum to this
          Notice our privacy practices regarding your PHI in that state.
        </p>
        <p>
          <strong>
            <u>D. Notice to Minors:&nbsp;</u>
          </strong>
          If you are a minor who has lawfully provided consent for treatment and
          you wish that we treat you as an adult for purposes of access to, and
          disclosure of, records related to such treatment, please notify a
          pharmacist or our Privacy Office.
        </p>
        <p>
          <strong>
            <u>
              E. Disclosures to Business Associates for Conducting Permitted
              Activities.&nbsp;
            </u>
          </strong>
          Sildenafil.com may conduct the above-described activities ourselves,
          or we may use non-Sildenafil.com Business Associates to perform those
          operations. In those instances where we disclose your PHI to a third
          party acting on our behalf, we will protect your PHI through an
          appropriate privacy agreement, referred to as a Business Associate
          Agreement. In addition to these contractual obligations, as of
          February 17, 2010, Business Associates have independent HIPAA
          compliance obligations.
        </p>
        <p>
          <strong>
            <u>
              F. Other Uses and Disclosures of Protected Health Information
              Based Upon Your Written Authorization.&nbsp;
            </u>
          </strong>
          Other uses and disclosures of your PHI, not described above, will be
          made only with your written authorization. We are specifically
          prohibited from selling your PHI without your authorization. You may
          revoke this authorization at any time, in writing, except to the
          extent that we have taken action in reliance on the authorization.
        </p>
        <p>
          <strong>II. YOUR RIGHTS</strong>
        </p>
        <p>
          As a patient, you have certain rights regarding your PHI. We require
          that you submit a written request to exercise a patient right,
          addressed to our HIPAA Privacy Office and delivered during regular
          business hours sufficiently in advance to allow us to administer your
          request as required. These rights include:
        </p>
        <p>
          <strong>
            <u>
              A. You have the right to request a restriction on certain uses and
              disclosures of your Protected Health Information.
            </u>
          </strong>
          This means that you may ask us not to use or disclose any part of your
          PHI for purposes of treatment, payment, or health care operations. You
          may request that we not disclose PHI to your health plan if the
          disclosure is for purposes of payment or health care operations and is
          not otherwise required by law. Sildenafil.com is obligated to honor
          this request when you have both submitted the request as required
          herein and you, or someone other than your health plan, have paid in
          full for the product or service.
        </p>
        <p>
          You may also request that any part of your PHI not be disclosed to
          family members or friends who may be involved in your care. Your
          request must state the specific restriction requested and state to
          whom you want the restriction to apply.
        </p>
        <p>
          Sildenafil.com is not required to agree to such a restriction. If we
          do agree, we will abide by your restriction unless we need to use your
          PHI to provide emergency treatment. In addition, we may elect to
          terminate the restriction at any time.
        </p>
        <p>
          <strong>
            <u>
              B. You have the right to request to receive information from us by
              an alternative means or at an alternative location if you believe
              it would enhance your privacy.&nbsp;
            </u>
          </strong>
          For example, you may request that we send written communications to an
          alternative address. We will attempt to accommodate all reasonable
          requests and will not request an explanation from you as to the basis
          for your request.
        </p>
        <p>
          <strong>
            <u>
              C. You have the right to inspect and copy your Protected Health
              Information.&nbsp;
            </u>
          </strong>
          If you would like to see or obtain copies of your PHI that we maintain
          in a designated record set, we are required to provide you access to
          your PHI for inspection and copying within 30 days after receipt of
          your request (60 days if the information is stored off-site).
          Alternatively, you have the right to request an electronic copy of
          your PHI, and we are required to provide it to you in a readable
          electronic form and format. We may charge you a reasonable, cost-based
          fee to cover duplicating and mailing costs or the costs of preparation
          and transmission of PHI in electronic form. In addition, there may be
          situations where we may decide to deny your request for access. For
          example, we may deny your request if we believe the disclosure will
          endanger your life or health or that of another person. Depending on
          the circumstances of the denial, you may have a right to have this
          decision reviewed.
        </p>
        <p>
          <strong>
            <u>
              D. You have the right to amend your Protected Health
              Information.&nbsp;
            </u>
          </strong>
          This means you may request an amendment of your PHI in our records for
          as long as we maintain this information. We will respond to your
          request within 60 days (with up to a 30-day extension, if needed). We
          may deny your request if, for example, we determine that your PHI is
          accurate and complete. If we deny your request, we will send you a
          written explanation and allow you to submit a written statement of
          disagreement.
        </p>
        <p>
          <strong>
            <u>
              E. You have the right to receive an accounting of certain
              disclosures we have made of your Protected Health
              Information.&nbsp;
            </u>
          </strong>
          An accounting is a record of the disclosures that have been made of
          PHI. This right generally applies to non-routine disclosures, i.e.,
          for purposes other than treatment, payment, or health care operations,
          as described in this Notice, made in the six-year period prior to your
          request (although you are free to request an accounting for a shorter
          period). We are required to provide the accounting within 60 days
          (with one 30-day extension, if needed) and to provide one accounting
          free of charge in any 12-month period. (For more frequent requests, a
          reasonable fee may be charged.)
        </p>
        <p>
          <strong>
            <u>
              F. You have a right to receive notification in the event of a
              breach that involves your PHI.&nbsp;
            </u>
          </strong>
          We may use your PHI to provide the required notifications in the event
          of a breach.
        </p>
        <p>
          <strong>
            <u>
              G. You have the right to obtain a paper copy of this notice from
              Sildenafil.com.&nbsp;
            </u>
          </strong>
          You may request a copy from your pharmacy or from our HIPAA Privacy
          Office.
        </p>
        <p>
          <strong>III. COMPLAINTS</strong>
        </p>
        <p>
          If you believe your privacy rights have been violated, you have the
          right to report such alleged violations to Sildenafil.com, and we will
          promptly investigate the matter. You may file a complaint with
          Sildenafil.com by contacting our HIPAA Privacy Office. Rest assured,
          we will not retaliate against you in any way for filing a complaint
          about our privacy practices. You may also contact the Secretary of
          Health and Human Services.
        </p>
        <p>
          You may contact our HIPAA Privacy Office at (844) 745-3362 (toll
          free), at patientcare@sildenafil.com, or at 2372 Morse Avenue, Suite
          #377, Irvine, CA 92614 for further information about the complaint
          process or any other information covered by this Notice.
        </p>
        <p>This notice is effective as of October 1, 2020</p>
      </div>
      <Footer />
    </>
  );
};

export default Hipaa;
