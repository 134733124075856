import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import DayJS from "dayjs";
import { filter, first, last, isEmpty } from "lodash";
import useCurrentUser from "hooks/useCurrentUser";
import Button from "components/Button";
import closeIcon from "assets/images/home/close.svg";
import ChevronDown from "assets/images/home/chevron-down.svg";
import { OrderContext } from "Contexts/OrderContext";
import { currencyFormatter } from "helpers/Formatter";

const OrderCard = ({ order }: any) => {
  const [expand, setExpand] = useState(true);
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const lastOrderPayment: any = last(order.payments);
  const lastOrderPaymentCard: any = filter(currentUser?.credit_cards, [
    "payment_profile_id",
    lastOrderPayment.payment_profile_id,
  ]);
  const { setCurrentOrder } = useContext(OrderContext);

  const handleContinueProcess = () => {
    new Promise((resolve) => {
      resolve(setCurrentOrder(order));
    }).then(() => navigate("/order-shipping-confirmation"));
  };

  const discountedCart = first(
    filter(order.carts, (cart: any) => !isEmpty(cart.discounts))
  );

  const discountedPrice = Number(
    discountedCart?.price - discountedCart?.final_price
  ).toFixed(2);

  return (
    <div className={`account-card ${expand && "is-open"}`}>
      <div className="account-card__header">
        <div className="account-card__header-box">
          <strong> Date Ordered</strong>
          <p>{DayJS(order.created_at).format("MM/DD/YYYY")}</p>
        </div>
        <div className="account-card__header-box">
          <strong>Order ID</strong>
          <p>{order.invoice_number}</p>
        </div>
        <div className="account-card__header-box">
          <strong>Amount</strong>
          <p>{`${currencyFormatter(order?.total_price)}`}</p>
        </div>
        <button
          className="account-card__toggle"
          onClick={() => setExpand(!expand)}>
          <ReactSVG
            src={expand ? closeIcon : ChevronDown}
            className={`account-card__toggle-control account-card__toggle-${
              !expand ? "close" : ""
            }`}
          />
        </button>
      </div>
      <div className="account-card__body">
        {order.quantity_changed && !order.paid && (
          <div className="account-card__status">
            <h3>Order Status: </h3>
            <p>Your Prescription is ready to Order.</p>
            <p className="info">
              {" "}
              Your doctor has approved a different strength and/or quantity for
              the medication. Please reselect the proper amount to complete the
              order.
            </p>
            <Button
              color="secondary"
              size="small"
              onClick={() => handleContinueProcess()}>
              PROCEED TO CHECKOUT
            </Button>
          </div>
        )}
        {order.state === "pending" && (
          <div className="account-card__status">
            <h3>Order Status: </h3>
            <p>Your Prescription is ready to Order.</p>
            <Button
              color="secondary"
              size="small"
              onClick={() => handleContinueProcess()}>
              PROCEED TO CHECKOUT
            </Button>
          </div>
        )}
        {(order.state === "awaiting_shipment" ||
          order.last_cart?.stage === "fulfillment") && (
          <div className="account-card__status">
            <h3>Order Status </h3>
            <p>Your Order is being fulfilled by AUM Pharmacy.</p>
            <p className="info">
              Please call {process.env.REACT_APP_PHONE_NUMBER} for any
              questions.
            </p>
          </div>
        )}
        {order.state === "shipped" && (
          <div className="account-card__status">
            <h3>Order Status </h3>
            <p>Your medication has shipped.</p>
          </div>
        )}
        {order.carts?.map((cart: any) => (
          <div className="account-card__container" key={cart.id}>
            <p>
              <strong>Drug: </strong>
              {cart?.product_variant?.fullname}
            </p>
            <p>
              <strong>Quantity: {cart.quatity}</strong>
              {cart?.quantity} {cart?.product_variant?.unit}
              {"s"}
            </p>
            <p>
              <strong>Price: </strong>
              {`$${Number(cart?.price).toFixed(2)}`}
            </p>
          </div>
        ))}
        {!isEmpty(order?.shipping_address) && (
          <React.Fragment>
            <div className="account-card__shipping">
              {order?.delivery_type && (
                <>
                  <strong>Shipping type: </strong>
                  {order?.delivery_label}
                </>
              )}
              <p>
                <strong>Ship To:</strong>
                {order?.shipping_address && (
                  <span className="no-mouseflow mouseflow-hidden">
                    {" "}
                    {order.shipping_address.street_1},{" "}
                    {order.shipping_address.city},
                    <span> {order?.shipping_address?.state}</span>
                    <span className="no-mouseflow mouseflow-hidden">
                      {", "}
                      {order?.shipping_address?.zip}
                    </span>
                  </span>
                )}
              </p>
            </div>
            {order.state === "shipped" && (
              <Button
                color="secondary"
                size="small"
                onClick={() => navigate(order.tracking_url)}>
                TRACK PACKAGE
              </Button>
            )}
          </React.Fragment>
        )}
        <div className="account-card__payment">
          <div className="info">
            {!isEmpty(lastOrderPaymentCard) &&
              lastOrderPaymentCard[0]?.payment_profile_id && (
                <React.Fragment>
                  <p>
                    <strong>Payment Method:</strong>{" "}
                    {lastOrderPaymentCard[0]?.card_type} ending in{" "}
                    {lastOrderPayment?.credit_card_ending}
                  </p>
                </React.Fragment>
              )}
          </div>
          <div className="summary">
            <p>
              Sub Total:{" "}
              {`${currencyFormatter(
                order.carts
                  .map((cart: any) => parseFloat(cart.price))
                  .reduce((a: any, b: any) => a + b, 0)
              )}`}
            </p>
            {order?.carts.map((cart: any) => (
              <React.Fragment key={cart.id}>
                {cart.applied_drug_price_discount && (
                  <p>
                    <>
                      Discount:{" "}
                      {!isEmpty(discountedPrice) ? `$${discountedPrice}` : ""}
                    </>
                  </p>
                )}
              </React.Fragment>
            ))}
            <p>
              Shipping:{" "}
              {order.final_delivery_price > 0
                ? `${currencyFormatter(order?.final_delivery_price)}`
                : "Free"}
            </p>
            <p>Sales tax: {currencyFormatter(0)}</p>
            <hr />
            <p>
              Grand Total:{" "}
              <strong>{`$${Number(order?.total_price).toFixed(2)}`}</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderCard;
