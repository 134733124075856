import React, { useState, useContext, useEffect } from "react";
import { Formik, Form, Field, useFormikContext } from "formik";
import * as Yup from "yup";
import InputMask from "react-text-mask";
import { isEmpty } from "lodash";
import DayJS from "dayjs";

import { AuthContext } from "Contexts/AuthContext";
import { CartContext } from "Contexts/CartContext";
import useLatestCart from "hooks/useLatestCart";
import useCurrentUser from "hooks/useCurrentUser";
import Button from "components/Button";
import Translator from "components/Translator";
import OtpModal from "../Account/Modals/OtpModal";

import "./styles.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { UpdateUserRequest, GenerateOtpRequest } from "apis";
import { useRollbar } from "@rollbar/react";
import { NAME_REGEX } from "helpers/Constants";

const PatientInfoSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("Required")
    .matches(NAME_REGEX, "Must enter a proper name"),
  last_name: Yup.string()
    .required("Required")
    .matches(NAME_REGEX, "Must enter a proper name"),
  date_of_birth: Yup.string()
    .min(10, "Must enter a proper date")
    .required("Required"),
  gender: Yup.string().required("Required"),
  mobile_phone: Yup.string()
    .min(14, "Must enter proper number")
    .required("Required"),
});

type Props = {
  editMode?: boolean;
};

export const ScrollIntoView = () => {
  const { submitCount, isValid, errors } = useFormikContext();

  useEffect(() => {
    if (!isValid) {
      window.scrollTo(0, 191);
    }
  }, [isValid, submitCount]);

  return <></>;
};

const PatientInfo = ({ editMode }: Props) => {
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const { setCurrentUser, Logout } = useContext(AuthContext);
  const { PatientInfoNextStep } = useContext(CartContext);
  const currentUser = useCurrentUser();
  const latestCart = useLatestCart();
  const disableFields = currentUser?.info_provided;
  const [ageLimitError, setAgeLimitError] = useState<string>("");
  const [genderError, setGenderError] = useState<string>("");
  const [mobileError, setMobileError] = useState<string>("");
  const [pocketmedError, setPocketmedError] = useState<string>("");
  const [openOtpModal, setOpenOtpModal] = useState<boolean>(false);
  const [limitExceededError, setLimitExceededError] = useState<boolean>(false);
  const [phoneType, setPhoneType] = useState<string>("mobile_phone");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const location = useLocation();
  const rollbar = useRollbar();

  const phoneMask = [
    "(",
    /[1-9]/,
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  const handleSubmit = (values: any) => {
    const newUserData = {
      ...values,
      date_of_birth: DayJS(values.date_of_birth).format("YYYY-MM-DD"),
      id: currentUser?.id,
    };

    setIsProcessing(true);

    // If the user tries to save a home phone number, we unverify them
    if (phoneType === "home_phone") {
      newUserData["otp_verified"] = false;
    }

    if (!isEmpty(latestCart) && !isEmpty(currentUser)) {
      UpdateUserRequest(newUserData)
        .then((res) => {
          if (!currentUser?.otp_verified && phoneType === "mobile_phone") {
            setOpenOtpModal(true);
          }

          if (res.data.status === 400) {
            setPocketmedError(res.data.message);
            setIsProcessing(false);
            return false;
          }

          setCurrentUser(res.data.user);
          if (editMode) {
            const locationState = location?.state;

            navigate(
              `${locationState?.order_view
                ? "/order-shipping-confirmation"
                : "/checkout/shipping-confirmation"
              }`
            );
          } else {
            if (res.data.otp_verified || phoneType === "home_phone") {
              PatientInfoNextStep(latestCart.id);
            } else {
              GenerateOtpRequest()
                .then((res) => {
                  setLimitExceededError(false);
                })
                .catch((err) => {
                  if (err?.response?.data?.limit_exceeded) {
                    setOpenOtpModal(true);
                    setLimitExceededError(true);
                  }

                  console.log(err);
                  rollbar.error(err);
                });
            }
          }
          setIsProcessing(false);
        })
        .catch((err) => {
          const errorsResponseData = err.response?.data?.errors;

          if (!isEmpty(errorsResponseData?.mobile_phone)) {
            if (
              errorsResponseData.mobile_phone[0] === "has already been taken"
            ) {
              setMobileError(
                "Your number is already registered with us. Want to sign in? Just click"
              );
            } else {
              setMobileError(`${errorsResponseData.mobile_phone[0]}`);
            }
          }
          if (!isEmpty(errorsResponseData?.gender)) {
            setGenderError(errorsResponseData.gender[0]);
          }

          console.log(err);
        });
    }
  };

  const validDateOfBirth = (dob: any) => {
    const age = DayJS().diff(DayJS(dob), "years");

    if (age >= 25 && age <= 75) {
      return true;
    } else {
      setAgeLimitError(
        `${age < 25
          ? "Min age is 25 to continue"
          : "Please call (844) 745-3362 for assistance"
        }`
      );

      return false;
    }
  };

  const validGender = (gender: string) => {
    if (gender === "male") {
      return true;
    } else {
      setGenderError("Service only available for male gender.");
      return false;
    }
  };

  useEffect(() => {
    scrollToInvalidField();
  }, [genderError, ageLimitError, mobileError]);

  const monthsInAYear = () => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const options = months.map((month, index) => (
      <option value={index + 1} key={index + 1}>
        {month}
      </option>
    ));
    return options;
  };

  const generateDaysInMonth = (selectedMonth: any, selectedYear: any) => {
    const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();
    const days = [];

    for (let i = 1; i <= daysInMonth; i++) {
      days.push(<option value={i}>{i}</option>);
    }

    return days;
  };

  const formatDobFields = (
    year: number | string,
    month: number | string,
    day: number | string
  ) => {
    return year === '' || month === '' || day === '' ? 'invalid' :
      `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
  };

  useEffect(() => {
    const localPhoneType =
      localStorage.getItem(`phone_type_${currentUser?.id}`) || "mobile_phone";
    setPhoneType(localPhoneType);
  }, []);

  const scrollToInvalidField = () => {
    const firstError = document.querySelector(".input-error");
    if (firstError) {
      firstError.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        window.scrollBy({ behavior: "smooth", top: -200, left: 0 });
      }, 250);
    }
  };

  const handleDuplicateAttempt = () => {
    const dupAttemptNumber = phoneNumber.replace(/\D/g, "");

    Logout(true);

    if (isEmpty(localStorage.getItem("authToken"))) {
      navigate(`/sign-in?phone=${dupAttemptNumber}`, {
        state: { loginWithPhone: true },
      });
    }
  };

  return (
    <div className="aum container checkout-page product-page patient-info-page">
      <OtpModal
        openOtpModal={openOtpModal}
        setOpenOtpModal={setOpenOtpModal}
        cart={latestCart}
        limitExceededError={limitExceededError}
        setLimitExceededError={setLimitExceededError}
        phoneCodeLimitCounter={0}
        emailCodeLimitCounter={0}
      />

      <div className="row pt-0 pt-md-3 justify-content-center">
        <div className="col col-lg-6">
          <h3 className="patient-info-heading">
            <Translator path="patientInfo.heading.patientInfo" />
          </h3>
          <p className="subtext">
            <Translator path="patientInfo.heading.processHelp" />
          </p>
          <Formik
            initialValues={{
              first_name: currentUser?.first_name || "",
              last_name: currentUser?.last_name || "",
              date_of_birth: currentUser?.date_of_birth || "",
              gender: currentUser?.gender || "",
              mobile_phone: currentUser?.mobile_phone || "",
              home_phone: currentUser?.home_phone || "",
              dob_month:
                Number(currentUser?.date_of_birth?.split("-")?.at(1)) || "",
              dob_day:
                Number(currentUser?.date_of_birth?.split("-")?.at(2)) || "",
              dob_year:
                Number(currentUser?.date_of_birth?.split("-")?.at(0)) || "",
              phone_contact_allowed: currentUser?.phone_contact_allowed || true,
              drugs_names_included: currentUser?.drugs_names_included || true,
            }}
            validationSchema={PatientInfoSchema}
            onSubmit={(values) => {
              setPhoneNumber(values?.mobile_phone);
              setLimitExceededError(false);
              validDateOfBirth(values?.date_of_birth);
              setAgeLimitError("");
              if (
                validDateOfBirth(values?.date_of_birth) &&
                validGender(values?.gender)
              ) {
                handleSubmit(values);
              }
            }}
          >
            {({ errors, touched, values, handleChange, setFieldValue }) => (
              <Form>
                <ScrollIntoView />

                <div className="form-group">
                  <label className="input-label">
                    <Translator path="patientInfo.labels.gender.label" />
                  </label>
                  <div className="input-group row mx-0">
                    <div className="gender-radio-wrapper no-mouseflow mouseflow-hidden">
                      <Field
                        id="gender-radio-male"
                        type="radio"
                        name="gender"
                        className="gender-radio no-mouseflow"
                        disabled={disableFields}
                        value="male"
                        onChange={(e: any) => {
                          setFieldValue("gender", e.target.value);
                          setGenderError("");
                          setPocketmedError("");
                          setIsProcessing(false);
                        }}
                      />
                      <label
                        className="input lg male"
                        htmlFor="gender-radio-male"
                      >
                        <Translator path="patientInfo.labels.gender.male" />
                      </label>
                    </div>
                    <div className="gender-radio-wrapper no-mouseflow mouseflow-hidden">
                      <Field
                        id="gender-radio-female"
                        type="radio"
                        name="gender"
                        className="gender-radio no-mouseflow"
                        disabled={disableFields}
                        value="female"
                        onChange={(e: any) => {
                          setFieldValue("gender", e.target.value);
                          setGenderError("");
                          setPocketmedError("");
                          setIsProcessing(false);
                        }}
                      />
                      <label
                        className="input lg female"
                        htmlFor="gender-radio-female"
                      >
                        <Translator path="patientInfo.labels.gender.female" />
                      </label>
                    </div>
                  </div>
                  {errors.gender && touched.gender ? (
                    <div className="input-error">{errors.gender}</div>
                  ) : null}
                  {genderError && (
                    <div className="input-error mt-2">{genderError}</div>
                  )}
                </div>

                <div className="form-group">
                  <label className="input-label">
                    <Translator path="patientInfo.labels.firstName" />
                  </label>
                  <div className="input-group row mx-0">
                    <div className="input__icon user"></div>
                    <Field
                      name="first_name"
                      className="input lg no-mouseflow"
                      disabled={disableFields}
                    />
                  </div>
                  {errors.first_name && touched.first_name ? (
                    <div className="input-error">{errors.first_name}</div>
                  ) : null}
                </div>

                <div className="form-group">
                  <label className="input-label">
                    <Translator path="patientInfo.labels.lastName" />
                  </label>
                  <div className="input-group row mx-0">
                    <div className="input__icon user"></div>
                    <Field
                      name="last_name"
                      className="input lg no-mouseflow"
                      disabled={disableFields}
                    />
                  </div>
                  {errors.last_name && touched.last_name ? (
                    <div className="input-error">{errors.last_name}</div>
                  ) : null}
                </div>

                <div className="form-group">
                  <label className="input-label">
                    <Translator path="patientInfo.labels.dateOfBirth" />
                  </label>
                  <div className="input-group row mx-0">
                    <div className="dob-picker-wrapper no-mouseflow mouseflow-hidden">
                      <label htmlFor="dob_month">
                        <Field
                          as="select"
                          className={`picker-dropdown no-mouseflow mouseflow-hidden ${!isEmpty(values.dob_month) ? "picker-text" : ""
                            }`}
                          id="dob_month"
                          value={values.dob_month}
                          onChange={(e: any) => {
                            setFieldValue("dob_month", e.target.value);
                            setFieldValue("date_of_birth", formatDobFields(values.dob_year, e.target.value, ''));
                            setGenderError("");
                            setPocketmedError("");
                            setIsProcessing(false);
                            setFieldValue("dob_day", '');
                          }}
                        >
                          <option disabled value="">Month</option>
                          {monthsInAYear().map((month, index) => (
                            <option key={index + 1} value={index + 1}>{month}</option>
                          ))}
                        </Field>
                      </label>
                    </div>
                    <div className="dob-picker-wrapper no-mouseflow mouseflow-hidden">
                      <label htmlFor="dob_day">
                        <Field
                          as="select"
                          className={`picker-dropdown no-mouseflow mouseflow-hidden ${!isEmpty(values.dob_day) ? "picker-text" : ""
                            }`}
                          id="dob_day"
                          value={values.dob_day}
                          onChange={(e: any) => {
                            setFieldValue("dob_day", e.target.value);
                            setFieldValue(
                              "date_of_birth",
                              formatDobFields(
                                values.dob_year,
                                values.dob_month,
                                e.target.value
                              )
                            );
                            setGenderError("");
                            setPocketmedError("");
                            setIsProcessing(false);
                          }}
                        >
                          <option disabled value="">
                            Day
                          </option>
                          {generateDaysInMonth(values.dob_month, values.dob_year).map((day, index) => (
                            <option key={index + 1} value={index + 1}>{day}</option>
                          ))}
                        </Field>
                      </label>
                    </div>
                    <div className="dob-picker-wrapper no-mouseflow mouseflow-hidden">
                      <label htmlFor="dob_year">
                        <Field
                          as="select"
                          className={`picker-dropdown no-mouseflow mouseflow-hidden ${!isEmpty(values.dob_year) ? "picker-text" : ""
                            }`}
                          id="dob_year"
                          value={values.dob_year}
                          onChange={(e: any) => {
                            const selectedYear = e.target.value;
                            const isLeapYear = (year: number) =>
                              (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

                            if (
                              values.dob_year !== "" &&
                              isLeapYear(Number(values.dob_year)) &&
                              !isLeapYear(Number(selectedYear))
                            ) {
                              setFieldValue("dob_month", "");
                              setFieldValue("dob_day", "");
                              setFieldValue(
                                "date_of_birth",
                                formatDobFields(selectedYear, "", ""))
                            } else {
                              setFieldValue(
                                "date_of_birth",
                                formatDobFields(selectedYear, values.dob_month, values.dob_day)
                              );
                            }

                            setFieldValue("dob_year", selectedYear);
                            setGenderError("");
                            setPocketmedError("");
                            setIsProcessing(false);
                          }}
                        >
                          <option disabled value="">
                            Year
                          </option>
                          {Array(new Date().getFullYear() - 1900 + 1)
                            .fill(1900)
                            .map((year, index) => year + index)
                            .reverse()
                            .map((year) => (
                              <option value={year}>{year}</option>
                            ))}
                        </Field>
                      </label>
                    </div>
                  </div>
                  {errors.date_of_birth && touched.date_of_birth ? (
                    <div className="input-error">{errors.date_of_birth}</div>
                  ) : null}
                  {!errors.date_of_birth && ageLimitError && (
                    <div className="input-error mt-2">{ageLimitError}</div>
                  )}
                </div>

                <div className="form-group">
                  <label className="input-label">
                    <Translator path="patientInfo.labels.mobileNumber" />
                  </label>
                  <div className="input-group row mx-0">
                    <div className="phone-picker-wrapper">
                      <label htmlFor="phone_type">
                        <Field
                          as="select"
                          className="picker-dropdown"
                          id="phone_type"
                          value={phoneType}
                          onChange={(e: any) => {
                            localStorage.setItem(
                              `phone_type_${currentUser?.id}`,
                              e.target.value
                            );
                            setPhoneType(e.target.value);
                          }}
                        >
                          <option value="mobile_phone">Mobile</option>
                          <option value="home_phone">Home</option>
                        </Field>
                      </label>
                    </div>

                    <Field
                      name={phoneType}
                      className="input lg phone-number-input"
                      type="tel"
                    >
                      {({ field }: any) => (
                        <InputMask
                          {...field}
                          mask={phoneMask}
                          guide={false}
                          type="tel"
                          value={values.mobile_phone}
                          className={"input lg phone-input"}
                          onChange={(e: any) => {
                            setFieldValue("mobile_phone", e.target.value);
                            setMobileError("");
                            setPocketmedError("");
                            setIsProcessing(false);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  {errors.mobile_phone && touched.mobile_phone ? (
                    <div className="input-error">{errors.mobile_phone}</div>
                  ) : null}
                  {!isEmpty(mobileError) && (
                    <>
                      <div className="input-error">
                        {mobileError}
                        {mobileError !== "is invalid number" && (
                          <button
                            className="sign-in-link"
                            onClick={handleDuplicateAttempt}
                          >
                            HERE.
                          </button>
                        )}
                      </div>
                    </>
                  )}
                </div>

                <p className="legal-text">
                  As part of our efforts to ensure patient safety, we need to
                  verify your phone number. By giving us your phone number and
                  continuing, you agree that we may send text messages to you to
                  verify your phone number and for any other lawful purpose
                  related to your patient account and your use of our services,
                  including order confirmations, shipments notifications,
                  messages from your provider, and our partner pharmacy.
                </p>

                <div className="terms-checkboxes-wrapper">
                  <div className="field custom-checkbox">
                    <Field type="checkbox" name="phone_contact_allowed">
                      {({ field }: any) => {
                        return (
                          <>
                            <input
                              type="checkbox"
                              id="phone-contacts"
                              {...field}
                            />
                            <label
                              className="input-label checkbox-label"
                              htmlFor="phone-contacts"
                            >
                              <Translator path="patientInfo.checkBoxes.receiveSms" />
                            </label>
                          </>
                        );
                      }}
                    </Field>
                  </div>

                  <div className="field custom-checkbox">
                    <Field type="checkbox" name="drugs_names_included">
                      {({ field }: any) => {
                        return (
                          <>
                            <input
                              type="checkbox"
                              id="include-drugs"
                              {...field}
                            />
                            <label
                              className="input-label checkbox-label"
                              htmlFor="include-drugs"
                            >
                              <Translator path="patientInfo.checkBoxes.includeMedicationNames" />
                            </label>
                          </>
                        );
                      }}
                    </Field>
                  </div>
                </div>

                <div className="actions">
                  {!isEmpty(pocketmedError) && (
                    <div className="input-error ml-2 mb-2">
                      {pocketmedError}
                    </div>
                  )}
                  <Button
                    classes="ga-contact-event"
                    type="submit"
                    color="secondary"
                    fullWidth={true}
                    disabled={isProcessing}
                  >
                    <Translator path="patientInfo.saveButton" />
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
export default PatientInfo;
