import React, { useState, useEffect, useContext } from "react";
import { isEmpty, find } from "lodash";
import useCurrentUser from "hooks/useCurrentUser";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import DayJS from "dayjs";
import Button from "components/Button";
import { FetchMedicalVisitsRequest, ResumeCartRequest } from "apis";
import VisitDetail from "./VisitDetails";
import { AuthContext } from "Contexts/AuthContext";
import { ReactSVG } from "react-svg";
import medicalVisits from "assets/images/dashboard/medical_visit.svg";
import NewVisitButton from "../Home/Shared/NewVisitButton";

const MedicalVisits = () => {
  const navigate = useNavigate();
  const user = useCurrentUser();
  const [isLoading, setIsLoading] = useState(true);
  const [visits, setVisits] = useState<any[]>([]);
  const [activeVisit, setActiveVisit] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { setLoading, defaultProductVariant } = useContext(AuthContext);

  const FetchMedicalVisits = () => {
    const visitUuid = searchParams.get("visit_uuid");

    FetchMedicalVisitsRequest(user?.pocketmed_uuid)
      .then((res) => {
        const { visits } = res.data;
        setVisits(visits);
        if (!isEmpty(visitUuid)) {
          setActiveVisit(find(visits, { uuid: visitUuid })); // importing only 'find' is returning false for some reason
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    FetchMedicalVisits();
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!location.state?.isActiveVisit && activeVisit) setActiveVisit(null);

    if (location.search && isEmpty(activeVisit)) {
      FetchMedicalVisits();
    }
  }, [location]);

  const formatVisitType = (visitType: any) => {
    switch (visitType) {
      case "erectile_dysfunction":
        return "Erectile Dysfunction (ED) diagnosis and treatment";
    }
  };

  const handleMedicalVisitBack = () => {
    if (location.search?.includes("visit_uuid")) {
      setActiveVisit(null);
      navigate("/account/medical-visits");
    } else navigate(-1);
  };

  const formatStatus = (visit: any) => {
    // Visit has status 'rejected' when Provider rejects visit or when user ends up in Questionnaire rejection flow
    if (visit.status === "rejected") {
      if (visit.clinical_review_stage === "clinical_completed_rejected") {
        return "Visit Completed";
      } else {
        return "Cancelled";
      }
    }

    switch (visit.status) {
      case "draft":
        return "Pre-Visit Intake";
      case "submitted":
        return "Ready for Provider";
      case "cancelled":
        return "Cancelled";
      case "completed":
        return "Visit Completed";
      case "rejected":
        return "Rejected";
      case "abandoned":
        return "Abandoned";
      case "refunded":
        return "Refunded";
      default:
        return visit.status;
    }
  };

  return (
    <>
      {visits.length ? (
        <div className="account-nav-content order-refills medical-visits-container">
          <div className={activeVisit ? "d-none" : ""}>
            <div className="main-heading">
              <h3 className="mb-3">Medical Visits</h3>
            </div>
          </div>
          {visits &&
            !activeVisit &&
            visits.map((visit, idx) => (
              <div className="account-card" key={idx}>
                <div className="row accountCardMobile">
                  <div className="col">
                    <p className="visit-status mb-1">
                      {formatVisitType(visit.visit_type)}
                    </p>
                    <div className="meta-wrapper d-flex align-items-center">
                      <span
                        className={`badge badge-primary ${visit.status} ${visit.clinical_review_stage} mr-auto mr-md-3`}
                      >
                        {formatStatus(visit)}
                      </span>
                      <small>
                        {DayJS(visit.created_at).format("MM/DD/YY")}
                      </small>
                    </div>
                  </div>
                  <div className="col col-md-4 d-flex flex-column justify-content-center align-items-end actions-wrapper">
                    {visit.status === "draft" ? (
                      <Button
                        classes="visit-detail-btn"
                        size="medium"
                        fullWidth={true}
                        color="secondary"
                        onClick={() => {
                          ResumeCartRequest()
                            .then((res) => {
                              navigate(res.data.redirect_path);
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }}
                      >
                        Resume visit
                      </Button>
                    ) : (
                      <Button
                        classes="visit-detail-btn"
                        fullWidth={true}
                        color="secondary"
                        size="medium"
                        onClick={() => {
                          setActiveVisit(visit);
                          navigate("/account/medical-visits", {
                            state: { isActiveVisit: true },
                          });
                        }}
                      >
                        View details
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            ))}

          {activeVisit && (
            <VisitDetail
              user={user}
              visit={activeVisit}
              removeActiveVisit={() => handleMedicalVisitBack()}
            />
          )}
        </div>
      ) : (
        <div className="empty-screen">
          <div className="empty-illustration-ellipse">
            <ReactSVG className="dashboard-svg p-0" src={medicalVisits} />
          </div>
          <span className="title-box">Medical Visits</span>
          <span className="detail-box">Access your current and past ED medical visits details right here. Click "Get Started" to initiate an online ED visit.</span>
          <NewVisitButton
            defaultProductVariant={defaultProductVariant}
            position="center"
            isDashboardBtn={true}
          />
        </div>
      )}
    </>
  );
};

export default MedicalVisits;
