import React from "react";
import Translator from "components/Translator";

type NewAddressTileProps = {
  onNewAddress: any;
};

const NewAddressTile = ({ onNewAddress }: NewAddressTileProps) => (
  <div
    onMouseDown={onNewAddress}
    className="shipping-address-item shipping-address__add-new"
  >
    <span className="shipping-address-item__text">
      + {<Translator path="checkout.shipping.addNew" />}
    </span>
  </div>
);

export default NewAddressTile;
