import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { GetOrderStatusRequest } from "../../../apis";

const Mailed = () => {
  const [orderStatus, setOrderStatus] = useState<any>(undefined);

  useEffect(() => {
    GetOrderStatusRequest().then((res) => {
      setOrderStatus(res.data.subAddress);
    }).catch((err) => console.log(err))
  }, [])

  return (
    <div className="account-card__status">
      {!isEmpty(orderStatus) && (
        <React.Fragment>
          <h3>Order Status</h3>
          <p>No Prescription on file as of yet</p>
          <p className="info">If you are mailing in the prescription, please mail to:</p>
          <address>
            {orderStatus.name}
            <br />
            {orderStatus.street}
            <br />
            {orderStatus.city},{" "}{orderStatus.zip}
            <br />
            Phone:{" "}{orderStatus.phone}
          </address>
        </React.Fragment>
      )
      }
    </div>
  )
};

export default Mailed;
