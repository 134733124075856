import React from "react";
import PhoneIcon from "assets/images/home/phone.svg";
import SamplePhotoIcon from "assets/images/icons/sample-photo-icon.svg";
import { ReactSVG } from "react-svg";

const TroubleModal = () => (
  <>
    <div
      className="modal"
      id="troubleModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="troubleModalTitle"
      aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div className="header-image">
              <ReactSVG src={SamplePhotoIcon} />
            </div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="body-text">
              <h5>Having trouble saving your picture</h5>
              <span>
                Give us a call we are available to assist you Monday - Friday
                9:00am to 6:00pm
              </span>
            </div>
          </div>
          <div className="modal-footer">
            <a className="btn" href="tel:(844) 754-3362">
              <ReactSVG src={PhoneIcon} />
              <span>(844) 754-3362</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default TroubleModal;
