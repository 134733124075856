import React, { useContext, useEffect } from "react";
import { isEmpty } from "lodash";
import useCurrentUser from "hooks/useCurrentUser";
import useLatestCart from "hooks/useLatestCart";
import PatientInfoCard from "../../Shared/PatientInfoCard";
import Button from "components/Button";
import { ShippingConfirmationNextStep } from "apis";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "Contexts/AuthContext";

const ShippingConfirmation = () => {
  const cart = useLatestCart();
  const user = useCurrentUser();
  const navigate = useNavigate();
  const { setLoading } = useContext(AuthContext);

  useEffect(() => {
    setLoading(false);
  }, []);

  if (isEmpty(cart) || isEmpty(user)) return <></>;

  const handleContinue = () => {
    ShippingConfirmationNextStep(cart.id)
      .then((res) => {
        setLoading(true);
        navigate(res.data.redirect_path);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="container checkout-page order-page cart-page product-page pt-5 px-3 px-md-5">
      <div className="content px-0 px-md-2">
        <div className="row justify-content-center pt-0 pt-md-3 flex-md-row">
          <div className="col-lg-7 mt-5 shipping-confirmation">
            <h4 className="mb-5">Let's confirm your Delivery Info</h4>
            <>
              {/* TODO: this is not Permanent solution we need to fix this */}
              {cart.user_id && (
                <PatientInfoCard
                  cart={cart}
                  user={user}
                  showChangeLinks={true}
                />
              )}
              <Button
                fullWidth={true}
                color="secondary"
                classes="mt-5"
                onClick={() => handleContinue()}
              >
                Continue
              </Button>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingConfirmation;
