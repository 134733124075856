/* eslint-disable array-callback-return */
import React, { useState, useEffect, useContext } from "react";
import { isEmpty, flatMap, first, last } from "lodash";
import { FetchIntroQuestionsRequest } from "../../../apis";
import { CartContext } from "../../../Contexts/CartContext";
import Loader from "../Shared/Loader";
import AnswerOption from "../Shared/Questionnaire/AnswerOption";
import "../Shared/Questionnaire/styles.scss";
import { useLocation, useNavigate } from "react-router-dom";
import useLatestCart from "hooks/useLatestCart";

const IntroQuestions = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errors, setErrors] = useState([]);
  const [questions, setQuestions] = useState<any>(undefined);
  const [activeQuestion, setActiveQuestion] = useState<any>(null);
  const [selectedAnswers, setSelectedAnswers] = useState<any>(undefined);
  const [allowText, setAllowText] = useState(false);
  const [allowAllergy, setAllowAllergy] = useState(false);
  const [queriedAllergies, setQueriedAllergies] = useState(null);
  const [addedAllergies, setAddedAllergies] = useState([]);
  const [allowMed, setAllowMed] = useState(true);
  const [queriedMeds, setQueriedMeds] = useState(null);
  const [addedMeds, setAddedMeds] = useState<any>([]);
  const [extraText, setExtraText] = useState(null);
  const [userAction, setUserAction] = useState<any>(null);
  const [showContinueButton, setShowContinueButton] = useState(false);
  const [continueButtonDisable, setContinueButtonDisable] = useState(true);
  const { SendIntroQuesToPocketmed } = useContext(CartContext);
  const navigate = useNavigate();
  const location = useLocation();
  const cart = useLatestCart();

  useEffect(() => {
    FetchIntroQuestionsRequest(cart?.visit_uuid)
      .then((res) => {
        setQuestions(res.data.questions);
      })
      .catch((err) => {
        setErrors(err);
      })
      .finally(() => setIsLoading(false));
  }, [cart]);

  // Handles auto-proceeding
  useEffect(() => {
    if (!isEmpty(selectedAnswers)) {
      const userSelectedAnOption =
        activeQuestion?.question_type === "radio" &&
        userAction === "optionSelect";
      const hasNoTextOption = !selectedAnswers.filter(
        (sa: any) => sa?.allow_text
      ).length;

      if (userAction !== "optionSelect") {
        return;
      }

      if (userSelectedAnOption && hasNoTextOption) {
        handleContinue();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAnswers, userAction]);

  // Snap to the first available question when 'questions' change and set default selected answer(s)
  useEffect(() => {
    if (!isEmpty(questions)) {
      let selectedAnswerIds: any,
        defaultSelectedAnswer,
        currentQuestionResponse: any,
        currentQuestion: any,
        extraTextValue: any;
      let allergies,
        meds = [];
      const allResponses = questions.map(
        (question: any) => question?.responses
      );

      const localStorageAns = localStorage.getItem("intro-answers") || "";

      // Handle moving back and forth in continuous questions
      if (
        userAction === "back" ||
        (!isEmpty(localStorageAns) && questions[0]?.question_type !== null)
      ) {
        currentQuestion = last(questions);
      } else {
        currentQuestion = first(questions);
        if (isEmpty(location.state)) {
          navigate("/intro-questions", {
            state: { intro_question_id: currentQuestion.label },
            replace: true,
          });
        }
      }

      selectedAnswerIds = [];

      questions.map((question: any) => {
        const responsees = question.responses;

        if (!isEmpty(responsees)) {
          selectedAnswerIds.push(responsees[0].answer_option_ids);
        }
      });

      const defaultAnswers = currentQuestion?.answer_options?.filter(
        (answer: any) => selectedAnswerIds.includes(String(answer.id))
      );

      defaultSelectedAnswer =
        defaultAnswers?.length > 0
          ? defaultAnswers
          : (!isEmpty(localStorageAns) && JSON.parse(localStorageAns)) || [];

      currentQuestionResponse = currentQuestion?.responses?.filter(
        (response: any) => response.question_id === currentQuestion.id
      )[0];
      extraTextValue = currentQuestionResponse?.text;
      allergies =
        defaultSelectedAnswer[defaultSelectedAnswer.length - 1]?.allergies ||
        [];
      meds =
        defaultSelectedAnswer[defaultSelectedAnswer.length - 1]?.medications ||
        [];

      // Map extra text value to answer option(s) to enforce textarea value to match stored answer
      // eslint-disable-next-line array-callback-return
      !isEmpty(defaultSelectedAnswer) &&
        defaultSelectedAnswer.map((dsa: any) => {
          if (questions[0].question_type === null) {
            currentQuestionResponse = flatMap(allResponses).filter(
              (res: any) => res.question_id === dsa.question_id
            )[0];
            dsa.text_value = currentQuestionResponse?.text;
          } else {
            dsa.text_value = extraTextValue;
          }
        });

      setExtraText(extraTextValue);
      setActiveQuestion(currentQuestion);
      setSelectedAnswers(defaultSelectedAnswer);
      setAllowAllergy(
        defaultSelectedAnswer[defaultSelectedAnswer.length - 1]?.allow_allergy
      );
      setAddedAllergies(allergies);
      setAllowMed(
        defaultSelectedAnswer[defaultSelectedAnswer.length - 1]?.allow_med ||
          currentQuestion.med_api
      );
      setAddedMeds(meds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions]);

  const sendDataToPocketMed = () => {
    // Store the answers locally to send later
    localStorage.setItem("intro-answers", JSON.stringify(selectedAnswers));

    SendIntroQuesToPocketmed();
  };

  const handleContinue = () => {
    const indexOfNextQuestion =
      questions.findIndex((item: any) => item.id === activeQuestion.id) + 1;
    const nextQuestion = questions[indexOfNextQuestion];
    let meds: any = [];

    setUserAction("next");

    if (
      activeQuestion.question_type !== null &&
      questions[questions.length - 1].id !== activeQuestion.id
    ) {
      const selectedAnswerIds =
        nextQuestion?.responses?.answer_option_ids || [];
      const defaultSelectedAnswer = nextQuestion.answer_options.filter(
        (answer: any) => selectedAnswerIds.includes(String(answer.id))
      );
      const extraTextValue =
        nextQuestion.responses?.filter(
          (response: any) => response.question_id === nextQuestion.id
        )[0]?.text || "";

      // Map extra text value to answer option(s) to enforce textarea value to match stored answer
      defaultSelectedAnswer.map((dsa: any) => {
        dsa.text_value = extraTextValue;
      });

      // Map the local_id for ordering the meds
      meds?.map((med: any, idx: number) => {
        med.local_id = idx;
        med.question_id = nextQuestion.id;
        med.condition = nextQuestion.condition;
      });

      setActiveQuestion(nextQuestion);
      setSelectedAnswers([...selectedAnswers, ...defaultSelectedAnswer]);
      setAllowText(false);
      setAllowMed(
        defaultSelectedAnswer[defaultSelectedAnswer.length - 1]?.allow_med ||
          nextQuestion.med_api
      );
      setAddedMeds([...addedMeds, ...meds]);
      navigate("/intro-questions", {
        state: { intro_question_id: nextQuestion.label },
      });
    } else {
      sendDataToPocketMed();
    }
  };

  const shouldDisableButton = () => {
    return (
      !selectedAnswers.filter(
        (sa: any) => sa?.question_id === activeQuestion?.id
      ).length || isProcessing
    );
  };

  useEffect(() => {
    if (!isEmpty(questions)) {
      const localStorageAns = localStorage.getItem("intro-answers") || "";

      const currentQuestion = questions.find(
        (question: any) => question.label === location.state?.intro_question_id
      );

      let selectedAnswerIds = !isEmpty(currentQuestion?.responses)
        ? currentQuestion?.responses[0]?.answer_option_ids
        : [];

      const defaultAnswers =
        !isEmpty(selectedAnswerIds) &&
        currentQuestion?.answer_options?.filter((answer: any) =>
          selectedAnswerIds.includes(String(answer.id))
        );

      let defaultSelectedAnswer =
        defaultAnswers?.length > 0
          ? defaultAnswers
          : (!isEmpty(localStorageAns) && JSON.parse(localStorageAns)) || [];

      setActiveQuestion(currentQuestion ? currentQuestion : first(questions));
    }
  }, [questions, location.state?.intro_question_id]);

  return (
    <>
      <main className="main">
        <div className="container checkout-page product-page">
          <div className="content">
            <div className="row justify-content-center pt-0 pt-md-3">
              <div className="col col-lg-6">
                <section>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    !isEmpty(activeQuestion) && (
                      <>
                        <h4 className="question-heading mb-3">
                          {activeQuestion?.text}
                        </h4>
                        {activeQuestion?.subtitle && (
                          <h6 className="question-sub-heading mb-4">
                            {activeQuestion?.subtitle}
                          </h6>
                        )}
                        <div className={`answer-options-container`}>
                          {activeQuestion.answer_options.map(
                            (option: any, idx: number) => (
                              <div key={idx}>
                                <AnswerOption
                                  question={null}
                                  selectedAnswers={selectedAnswers}
                                  setSelectedAnswers={setSelectedAnswers}
                                  option={option}
                                  questionType={activeQuestion?.question_type}
                                  setAllowText={setAllowText}
                                  userResponses={activeQuestion?.responses}
                                  questionsLength={questions?.length}
                                  extraText={extraText}
                                  setExtraText={setExtraText}
                                  setAllowAllergy={setAllowAllergy}
                                  activeQuestion={activeQuestion}
                                  setUserAction={setUserAction}
                                  setShowContinueButton={setShowContinueButton}
                                  setContinueButtonDisable={
                                    setContinueButtonDisable
                                  }
                                  setShowSideEffectText={null}
                                  setSideEffectsQuestionLabel={null}
                                  badge={null}
                                  handleContinue={null}
                                  textAreaBorder={null}
                                  questionId={null}
                                />
                              </div>
                            )
                          )}
                        </div>
                        {errors?.map((err, idx) => (
                          <div className="error-label" key={idx}>
                            {err}
                          </div>
                        ))}
                        {showContinueButton && (
                          <button
                            className="btn btn-secondary w-100 mt-4"
                            onClick={handleContinue}
                            disabled={shouldDisableButton()}>
                            {isProcessing ? "Processing..." : "Continue"}
                          </button>
                        )}
                      </>
                    )
                  )}
                </section>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default IntroQuestions;
