import React from "react";
import trashIcon from "assets/images/home/trash.png";
import Button from "components/Button";

interface CartDropdownProps {
  cart?: any;
  handleDeleteCart: () => void;
  showDeleteIcon?: boolean;
}

const CartDropdownItem = ({
  cart,
  handleDeleteCart,
  showDeleteIcon = true,
}: CartDropdownProps) => {
  if (!cart?.cart_in_showing_steps) return <></>;

  return (
    <div className="dropdown__row d-flex justify-content-between">
      <p className="dropdown-cart__info d-flex flex-column">
        <strong>{cart.product_variant.fullname}</strong>
        <span className="wrapped-mid-size">
          Quantity: {cart.quantity} Tablets
        </span>
      </p>
      <div className="dropdown-cart__actions">
        <p className="dropdown-cart__price">${Number(cart.price).toFixed(2)}</p>
        {showDeleteIcon && (
          <Button
            classes="dropdown-cart__remove d-flex justify-content-end delete-cart"
            onClick={() => handleDeleteCart()}>
            <img
              src={trashIcon}
              alt="delete cart"
              className="delete-cart-icon"
            />
            <span className="sr-only">Remove from cart</span>
          </Button>
        )}
      </div>
    </div>
  );
};

export default CartDropdownItem;
