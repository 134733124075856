import React from "react";
import { Dna } from "react-loader-spinner";

type Props = {
  isLoading: boolean;
  setIsLoading: any;
};  
const ModalLoader = ({
  isLoading,
  setIsLoading,
  }: Props) => {
  return (
    <>
      {isLoading && (
        <div
          style={{
            height: "100%",
            width: "100%",
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            zIndex: 99999,
            opacity: 0.9,
            backgroundColor: "#f1f8fb",
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Dna
              visible={true}
              height={90}
              width={90}
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass={"dna-wrapper"}
            />
            <div
              style={{
                fontWeight:700
              }}>
              <p className="text-center">Please wait while we verify your SSN <br /> Number</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalLoader;
