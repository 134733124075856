import React from "react";
import InputMask from "react-text-mask";
import { date } from "yup/lib/locale";

type HOCDateMaskProps = {
  field?: any;
  disabled?: boolean;
  onChange?: any;
};

const HOCDateMask = ({ field, disabled, onChange }: HOCDateMaskProps) => {
  const dobMask = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];

  return (
    <InputMask
      {...field}
      mask={dobMask}
      pipe={(conformedValue: string) => {
        const monthDateYear = conformedValue.split("/");
        const dob = monthDateYear.reduce(function (acc: any, cur, i) {
          acc[i] = cur;
          return acc;
        }, {});
        const formattedValue =
          parseInt(dob[0]) > 12 ||
          parseInt(dob[1]) > 31 ||
          (parseInt(dob[2]) > 2100 && parseInt(dob[2]) < 1900)
            ? false
            : conformedValue;
        onChange(formattedValue);
        return formattedValue;
      }}
      guide={false}
      placeholder="MM/DD/YYYY"
      type={date}
      disabled={disabled}
      className={"input lg with-icon no-mouseflow mouseflow-hidden"}
    />
  );
};

export default HOCDateMask;
