import React, { useEffect, useState, useContext } from "react";
import { GetOrdersHistory } from "apis";
import { isEmpty } from "lodash";
import OrderCard from "./OrderCard";
import { ReactSVG } from "react-svg";
import orderHistory from "assets/images/dashboard/order-history-card.svg";
import NewVisitButton from "../Home/Shared/NewVisitButton";
import { AuthContext } from "Contexts/AuthContext";

const OrdersHistory = () => {
  const [orders, setOrders] = useState<[] | undefined>(undefined);
  const { defaultProductVariant, showRetakeModal, setShowRetakeModal } =
    useContext(AuthContext);

  useEffect(() => {
    GetOrdersHistory()
      .then((res) => {
        setOrders(res.data.orders);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="account-content">
      {isEmpty(orders) ? (
        <div className="empty-screen">
          <div className="empty-illustration-ellipse">
            <ReactSVG className="dashboard-svg" src={orderHistory} />
          </div>
          <span className="title-box">Order History</span>
          <span className="detail-box">
            Nothing to see now, but your past orders are seen here.
          </span>
          <NewVisitButton
            defaultProductVariant={defaultProductVariant}
            position="center"
            isDashboardBtn={true}
          />
        </div>
      ) : (
        <div>
          {orders?.map((order: any) => (
            <OrderCard key={order?.id} order={order} />
          ))}
        </div>
      )}
    </div>
  );
};

export default OrdersHistory;
