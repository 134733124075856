import React, { useState, useEffect, useContext } from "react";
import Button from "components/Button";
import DnaLoader from "components/DnaLoader";
import useLatestCart from "hooks/useLatestCart";
import useCurrentUser from "hooks/useCurrentUser";
import { useNavigate } from "react-router-dom";
import { IdentityVerificationNextStep } from "apis";
import SsnModal from "./SsnModal";

import "./styles.scss";

const IdentityVerification = () => {
  const currentUser = useCurrentUser();
  const latestCart = useLatestCart();
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openSsnModal, setOpenSsnModal] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [limitExceededError, setLimitExceededError]= useState<boolean>(!currentUser?.can_generate_ssn);

  const handleOnChange = (value: string) => {
    setSelectedOption(value);
  };

  const handleContinue = () => {
    setIsProcessing(true)

    if(selectedOption != "last_4_ssn") {
      IdentityVerificationNextStep(latestCart?.id, selectedOption)
      .then((res) => {
        navigate(`/${res.data.redirect_path}`);
        setIsProcessing(false)
      })
      .catch((err) => {
        console.log(err);
      });
    }
    else {
        setOpenSsnModal(true);
        setIsProcessing(false);
    }
  };

  if (isLoading) return <DnaLoader />;

  return (
    <div className="container checkout-page product-page identity-verification-page">
      <SsnModal
        openSsnModal={openSsnModal}
        setOpenSsnModal={setOpenSsnModal}
        cart={latestCart}
        limitExceededError={limitExceededError}
        setLimitExceededError={setLimitExceededError}
        setSelectedOption={setSelectedOption}
      />
      <div className="content">
        <div className="row shipping-address-content pt-0 pt-md-3 justify-content-center product-overview">
          <div className="col col-lg-6">
            <div className="content-heading">Let's verify your Identity</div>
            <section>
              <p className="content-text">
                Verifying your identity is crucial to ensuring the right person
                receives the right medical advice and treatment. We apologize
                for any inconvenience and appreciate your cooperation.
              </p>
              <h6 className="my-4">
                Provide the best option that works for you:
              </h6>
              <div className="answer-option-wrapper">
                <input
                  type="radio"
                  id="last_4_ssn"
                  name={`option-select`}
                  checked={selectedOption === 'last_4_ssn'}
                  disabled={limitExceededError}
                  onChange={() => handleOnChange('last_4_ssn')}
                />
                <label
                  className={`answer-option align-items-start d-flex flex-column ${limitExceededError ? 'limit-exceeded-field' : ''}`}
                  htmlFor="last_4_ssn"
                >
                  Last 4 of Social Security Number
                  <span className="subtext">
                    For your privacy, the full number is not required. We don't
                    save this information. It will be deleted immediately after
                    verification.
                  </span>
                </label>
              </div>
              {limitExceededError &&
                <p className="limit-exceeded">You’ve exceeded the number of attempts. Please try the option below.</p>
              }
              <div className="answer-option-wrapper">
                <input
                  type="radio"
                  id="id_review"
                  name={`option-select`}
                  checked={selectedOption === 'id_review'}
                  onChange={() => handleOnChange('id_review')}
                />
                <label
                  className={`answer-option align-items-start d-flex flex-column`}
                  htmlFor="id_review"
                >
                  Picture of Government Issued ID & Selfie
                  <span className="subtext">
                    This option is great if you are comfortable uploading
                    pictures on your phone
                  </span>
                </label>
              </div>
              <Button
                color="secondary"
                fullWidth
                classes="mt-5"
                onClick={handleContinue}
                disabled={!selectedOption || isLoading}
              >
                Continue
              </Button>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IdentityVerification;
