import React, { useEffect } from "react";
import { orderBy } from "lodash";

type Props = {
  addedMeds: any;
  setActiveMed: any;
  setShowMedModal: any;
  setContinueButtonDisable?: any;
};
const AddedMeds = ({
  addedMeds,
  setActiveMed,
  setShowMedModal,
  setContinueButtonDisable,
}: Props) => {
  if (addedMeds.length) {
    setContinueButtonDisable(false);
  } else {
    setContinueButtonDisable(true);
  }

  return (
    <div className="added-meds">
      <ul>
        {orderBy(addedMeds, "local_id")?.map((med, idx) => (
          <li key={idx}>
            <button
              className="edit-allergy-modal"
              onClick={() => {
                setActiveMed(med);
                setShowMedModal(true);
              }}
            >
              <div className="d-flex flex-column">
                <span className="d-flex justify-content-between text-left font-weight-bold">
                  {med.name}
                  <span className="edit-text">Edit</span>
                </span>
                <p className="font-weight-light details mb-0">
                  {med?.strength || med?.duration
                    ? `${med?.strength || ""} - ${med?.duration || ""}`
                    : "Add dosage and duration"}
                </p>
              </div>
              {med?.description && <p className="mb-0">{med.description}</p>}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AddedMeds;
