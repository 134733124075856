import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import Webcam from "react-webcam";
import { detect } from "detect-browser";
import { isMobile } from "react-device-detect";
import { ReactSVG } from "react-svg";

import { AuthContext } from "Contexts/AuthContext";
import useCurrentUser from "hooks/useCurrentUser";

import TroubleModal from "../../Checkout/Shared/Modals/TroubleModal";
import CameraInstructions from "../../Checkout/Shared/Modals/CameraInstructions";
import { getCameraPermissions } from "../../Checkout/Shared/Helpers/PhotoUploadHelpers";
import PermissionsRequiredModal from "../../Checkout/Shared/Modals/PermissionsRequiredModal";
import WhyNeedPhoto from "./WhyNeedPhoto";
import CameraModal from "./CameraModal";

import Translator from "components/Translator";
import Button from "components/Button";
import SelfiePhotoPath from "assets/images/home/selfie-upload.png";
import LockIcon from "assets/images/icons/lock-icon.svg";
import CameraIcon from "assets/images/icons/camera-icon.svg";
import {
  UploadVisitSelfieRequest,
  ResetPhotosRetakeRequest,
  RetakePhotosNextStepRequest,
} from "apis";

type SelfieRetakeProps = {
  showIdRetake: boolean;
  setShowSelfieRetake: any;
  cart: any;
};

const SelfieRetake = ({
  showIdRetake,
  setShowSelfieRetake,
  cart,
}: SelfieRetakeProps) => {
  const user = useCurrentUser();
  const { setRetakePending } = useContext(AuthContext);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [errors, setErrors] = useState<[]>([]);
  const [openCamera, setOpenCamera] = useState<boolean>(false);
  const [cameraInstructions, setCameraInstructions] = useState<boolean>(false);
  const [imgSrc, setImgSrc] = useState<any>(undefined);
  const [file, setFile] = useState<any>(undefined);
  const [nextStep, setNextStep] = useState<boolean>(false);
  const [mobileCam, setMobileCam] = useState<boolean>(false);
  const [permModal, setPermModal] = useState<boolean>(false);
  const [fbWebViewDetected, setFbWebViewDetected] = useState<boolean>(false);

  let videoRef = useRef(null);
  const webcamRef: any = React.useRef(null);
  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "environment",
  };

  useEffect(() => {
    if (!isEmpty(user) && !isEmpty(cart)) {
      handleMobileCam();
      detectFbWebView();
    }
  }, [user, cart, fbWebViewDetected]);

  const detectFbWebView = () => {
    const browser = detect();

    if (browser?.name === "chromium-webview") {
      setFbWebViewDetected(true);
    }
  };

  const handleMobileCam = () => {
    if (window.innerWidth <= 800) {
      setMobileCam(true);
    }
  };

  const getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: true,
      })
      .then((stream) => {
        setOpenCamera(true);
        let video: any = videoRef.current;
        video.srcObject = stream;
        video.play();
      })
      .catch((err) => {
        if (err.name === "NotAllowedError") {
          handleCloseCameraModal();
          setCameraInstructions(true);
        }
        setErrors(err.response?.data?.error);
        console.log(err.message);
      })
      .finally(() => {
        setPermModal(false);
      });
  };

  useEffect(() => {
    if (permModal) {
      getVideo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permModal]);

  const handleCloseCameraModal = () => {
    setOpenCamera(false);
  };

  const handleOpenCameraModal = () => {
    // Need to check for perms here
    getCameraPermissions(setPermModal);
  };

  const handleCloseCameraInstructions = () => {
    setCameraInstructions(false);
  };

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    setFile(imageSrc);
    handleCloseCameraModal();
    setNextStep(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webcamRef, nextStep]);

  const handleIdUploadFile = (e: any) => {
    const temp_path = URL.createObjectURL(e.target.files[0]);
    setFile(e.target.files[0]);
    setImgSrc(temp_path);
    setNextStep(false);
  };

  const handleImageUploadRequest = () => {
    const formData = new FormData();
    formData.append("photo", file);

    // Disable the button
    setIsProcessing(true);

    if (!isEmpty(cart)) {
      RetakePhotosNextStepRequest(cart.id)
        .then((res) => {
          UploadVisitSelfieRequest(cart?.id, user?.pocketmed_uuid, formData)
            .then((response) => {
              if (!showIdRetake) {
                ResetPhotosRetakeRequest(user?.pocketmed_uuid, cart.visit_uuid)
                  .then((res) => {
                    setShowSelfieRetake(false);
                    setRetakePending(false);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              } else {
                setShowSelfieRetake(false);
              }
            })
            .catch((err) => {
              setErrors(err.response?.data?.error);
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className="main">
      <div className="container checkout-page product-page px-0">
        <div className="content false">
          <div className="row shipping-address-content pt-0 pt-md-3 justify-content-center">
            <div className="col-lg-6 col-12">
              <div className="photos-content">
                {permModal && <PermissionsRequiredModal />}
                {!imgSrc && (
                  <>
                    <div className="photo-headings">
                      <h4>Upload a photo of your face</h4>
                      <p>
                        <Translator path="checkout.selfieUpload.telemedicineRegulations" />
                      </p>
                    </div>
                    <div className="selfie-image no-mouseflow">
                      <img
                        src={SelfiePhotoPath}
                        alt=""
                        className="no-mouseflow"
                      />
                    </div>
                    <div className="action-buttons">
                      <div className="upload-wrapper mb-2 w-100">
                        <Button
                          classes="select-photo-btn ml-0 mr-5"
                          fullWidth
                          color="secondary">
                          SELECT PHOTO
                        </Button>
                        <input
                          type="file"
                          id="upload-id-image"
                          name="upload-id-image"
                          accept="image/png, image/jpeg"
                          onChange={(e: any) => handleIdUploadFile(e)}
                        />
                      </div>
                      {!fbWebViewDetected && (
                        <>
                          <div className="take-wrapper w-100 ml-md-2">
                            {isMobile ? (
                              <>
                                <input
                                  className="mobile-image-input"
                                  accept="image/*"
                                  id="mobile-image"
                                  type="file"
                                  capture="environment"
                                  onChange={(e: any) => handleIdUploadFile(e)}
                                />
                                <label
                                  className="btn btn-secondary mr-0"
                                  htmlFor="mobile-image">
                                  {" "}
                                  TAKE PHOTO{" "}
                                </label>
                              </>
                            ) : (
                              <>
                                <Button
                                  classes="take-photo-btn mr-0"
                                  fullWidth
                                  color="secondary"
                                  data-toggle="modal"
                                  data-target="#cameraModal"
                                  onClick={() => handleOpenCameraModal()}>
                                  TAKE PHOTO
                                </Button>
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}

                {imgSrc && (
                  <div className="photo-review">
                    <h4>Review and confirm photo of your Selfie</h4>
                    <p>
                      <Translator path="checkout.selfieUpload.telemedicineRegulations" />
                      <strong>
                        {" "}
                        Your selfie that included a picture of you.{" "}
                      </strong>
                    </p>
                    <Link
                      to={""}
                      className="mb-5"
                      data-toggle="modal"
                      data-target="#whyNeedPhotoModal">
                      Why do you need this?
                    </Link>
                    <div className="uploaded-image">
                      <img src={imgSrc} alt="" className="no-mouseflow" />
                      <button
                        className="btn btn-ghost retake-btn"
                        onClick={() => setImgSrc(null)}>
                        <ReactSVG src={CameraIcon} />
                        <span>Re-take</span>
                      </button>
                    </div>
                    <div className="ensure-tex">
                      <strong>Ensure that:</strong>
                      <ul>
                        <li>The photo is not blurry or dark</li>
                        <li>You are the only person in your photo</li>
                        <li>The photo has not been edited/filtered</li>
                        <li>The photo was taken within the past 30 days</li>
                        <li>You are not covering your face</li>
                      </ul>
                    </div>
                    <Button
                      fullWidth
                      color="secondary"
                      onClick={() => handleImageUploadRequest()}
                      disabled={isProcessing}>
                      {!isProcessing
                        ? nextStep
                          ? "Continue"
                          : "Upload"
                        : "Processing..."}
                    </Button>
                  </div>
                )}
                <div className="trouble-link">
                  <Link to={""} data-toggle="modal" data-target="#troubleModal">
                    Having Trouble?
                  </Link>
                  <span>
                    <ReactSVG src={LockIcon} />
                    128-BIT TLS SECURITY
                  </span>
                </div>

                <CameraModal
                  isOpen={openCamera}
                  onClose={() => handleCloseCameraModal()}>
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={() => handleCloseCameraModal()}>
                    <span aria-hidden="true">&times;</span>
                  </button>

                  <div className="render-camera no-mouseflow">
                    <Webcam
                      audio={false}
                      height={500}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      width={550}
                      videoConstraints={videoConstraints}
                    />
                    <Button
                      classes="btn-secondary medium capture-button"
                      fullWidth
                      color="secondary"
                      onClick={capture}>
                      Capture photo
                    </Button>
                  </div>
                </CameraModal>

                <CameraInstructions
                  isOpen={cameraInstructions}
                  onClose={() => handleCloseCameraInstructions()}
                  data-id="cameraInstructionsModal"
                />
                <TroubleModal />
                <WhyNeedPhoto />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelfieRetake;
