import React from "react";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";
import globe from "assets/images/home/globe.svg";
import chevronDown from "assets/images/home/chevron-down.svg";
import greenTick from "assets/images/home/green-tick.svg";
import "./styles.scss";

const languageMapper = (lang: string) => {
  switch (lang) {
    case "es":
      return "Spanish";
    default:
      return "English";
  }
};

const LanguageSelect = () => {
  const { i18n } = useTranslation();

  return (
    <div className="dropdown-language d-flex align-items-center">
      <button
        className="btn text-color text-capitalize mt-0"
        type="button"
        data-toggle="dropdown"
        aria-expanded="false"
      >
        {languageMapper(i18n.language)}
        <ReactSVG
          src={chevronDown}
          wrapper="span"
          className="chevron-icon ml-1"
        />
      </button>
      <div className="dropdown-menu">
        <button
          className="dropdown-item"
          type="button"
          value="en"
          onClick={(e: any) => i18n.changeLanguage(e.target.value)}
        >
          English
          {(i18n.language === "en" || i18n.resolvedLanguage === "en") && (
            <ReactSVG src={greenTick} wrapper="span" className="green-tick" />
          )}
        </button>
        <button
          className="dropdown-item"
          type="button"
          value="es"
          onClick={(e: any) => i18n.changeLanguage(e.target.value)}
        >
          Spanish
          {i18n.language === "es" && (
            <ReactSVG src={greenTick} wrapper="span" className="green-tick" />
          )}
        </button>
      </div>
      <ReactSVG src={globe} wrapper="span" className="globe-svg" />
    </div>
  );
};

export default LanguageSelect;
