import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { isEmpty, find, first } from "lodash";
import { AuthContext } from "Contexts/AuthContext";
import DosageSelector from "../Checkout/MarketingProductDetail/DosageSelector";
import ProductSelector from "../Checkout/MarketingProductDetail/ProductSelector";
import PackagesList from "../Checkout/MarketingProductDetail/PackagesList";
import { CartContext } from "Contexts/CartContext";
import { OrderContext } from "Contexts/OrderContext";
import useCurrentUser from "hooks/useCurrentUser";
import Button from "components/Button";
import DnaLoader from "components/DnaLoader";
import { UpdateRefillOrderRequest, FetchRefillCartRequest, DeleteRefillOrderCartRequest } from "apis";
import { UpdateCartRequest } from "apis";
import GreenVanIcon from "assets/images/home/green_van.svg";
import caretRightIcon from "assets/images/controls/caret-right.svg";
import Translator from "components/Translator";
import { CartType } from "Contexts/CartContext";
import DrugInformation from "components/Modals/DrugInforamtionModal";

import "./styles.scss";

const RefillProductDetail = () => {
  const { reloadUser, setLoading, defaultProductVariant } =
    useContext(AuthContext);
  const { setLatestCart, CreateCart, DestroyCart } = useContext(CartContext);
  const { setCurrentOrder } = useContext(OrderContext);
  const user = useCurrentUser();

  const [cart, setCart] = useState<any>(undefined);
  const [errors, setErrors] = useState<object>({});
  const [variants, setVariants] = useState<any>([]);
  const [products, setProducts] = useState<any>([]);
  // const [thresholds, setThresholds] = useState<any>([]); // TODO: Remove if not needed
  const [packages, setPackages] = useState<any>([]);
  const [selectedProduct, setSelectedProduct] = useState<any>({});
  const [selectedVariant, setSelectedVariant] = useState<any>({});
  const [selectedQuantity, setSelectedQuantity] = useState<any>({});
  const [customInput, setCustomInput] = useState<boolean>(false);
  const [maxQuantityExceeded, setMaxQuantityExceeded] =
    useState<boolean>(false);
  const [minQuantityExceeded, setMinQuantityExceeded] =
    useState<boolean>(false);
  const [showCustomField, setShowCustomField] = useState<boolean>(false);
  const [customInputHasValidValue, setCustomInputHasValidValue] =
    useState<boolean>(false);
  const [showDrugInformation, setShowDrugInformation] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [refillCart, setRefillCart] = useState<any>({});

  const navigate = useNavigate();

  useEffect(() => {
    if (isEmpty(cart)) {
      FetchRefillCartRequest()
        .then((res) => {
          const products = res.data.products;
          const dataCart = res.data.cart;
          const currentProduct = find(products, { id: dataCart.product_id });
          const currentVariant = res.data.product_variant;
          setCart(dataCart);
          setProducts(products);
          setVariants(res.data.product_variants);
          // setThresholds(currentVariant.price_thresholds);
          setPackages(currentVariant.packages);
          setSelectedProduct(currentProduct);
          setSelectedVariant(currentVariant);
          setSelectedQuantity(
            dataCart.quantity ?? currentVariant.min_order_quantity
          );
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [cart]);

  useEffect(() => {
    setRefillCart(
      find(user?.prescriptions, {
        product_variant_id: selectedVariant.id,
      })
    );
  }, [selectedVariant]);

  const updateRefillCart = (cart: CartType) => {
    UpdateCartRequest(cart)
      .then((res) => {
        setCart(res.data.cart);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleContinue = () => {
    if (cart?.max_quantity >= cart.quantity) {
      setLoading(true);
      UpdateRefillOrderRequest(cart?.id)
        .then((res) => {
          setLatestCart(undefined);
          setCurrentOrder(res.data.order);
          navigate(`/order-shipping-confirmation`);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        })
        .finally(() => {
          reloadUser();
        });
    } else {
      CreateCart({ variant: selectedVariant.id, qty: cart.quantity });
      DeleteRefillOrderCartRequest(cart.prescription.id)
      .then((res) => {
        setLatestCart(undefined);
        setCurrentOrder(undefined);
        reloadUser();
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };

  // const cartErrors = (cart.errors && cart.errors.base) || [];
  const maxAvailableQty =
    cart?.max_quantity ||
    cart?.product_variant?.packages[
      cart?.product_variant?.packages?.length - 1
    ];

  const handleProductChange = (id: any) => {
    const product = find(products, { id });
    const variants = product.product_variants;
    const newVariant: any = first(variants); // TODO: Define a shared product variant type

    setSelectedProduct(product);
    setVariants(variants);
    setSelectedVariant(newVariant);
    setSelectedQuantity(newVariant.min_order_quantity);
    setShowCustomField(false);

    const newCart = cart;
    newCart.product_variant_id = newVariant.id;
    newCart.quantity = newVariant.min_order_quantity;

    updateRefillCart(newCart);
  };

  const handleDosageChange = (dosage: any) => {
    const variant = find(variants, { dosage_value: dosage });

    setSelectedVariant(variant);
    setPackages(variant.packages);
    setSelectedQuantity(variant.min_order_quantity);
    setShowCustomField(false);

    const newCart = cart;
    newCart.product_variant_id = variant.id;
    newCart.quantity = variant.min_order_quantity;

    updateRefillCart(newCart);
  };

  const handlePackageSelect = (quantity: any) => {
    const quantityTooSmall = quantity < selectedVariant.min_order_quantity;
    const quantityTooLarge = quantity > 1000000;

    if ([null, selectedVariant.id].includes(cart?.product_variant_id)) {
      if (quantityTooSmall) {
        setErrors(["Quantity is too small"]);
        setMaxQuantityExceeded(false);
        setMinQuantityExceeded(true);
      } else if (quantityTooLarge) {
        setErrors(["Quantity is too large"]);
        setMinQuantityExceeded(false);
        setMaxQuantityExceeded(true);
      }

      if (
        !quantityTooSmall &&
        !quantityTooLarge &&
        quantity !== "Custom Quantity"
      ) {
        setSelectedQuantity(quantity);
        setMaxQuantityExceeded(false);
        setMinQuantityExceeded(false);
        setCustomInput(false);

        const newCart = cart;
        newCart.product_variant_id = selectedVariant.id;
        newCart.quantity = quantity;

        updateRefillCart(newCart);
      } else {
        handleCustomInput(quantity);
      }
    } else {
      // TODO: Add flash messages
      console.log(
        `You have already added another product to your cart. Please finish latest order or clear cart to add the new one.`
      );
    }
  };

  const handleCustomInput = (quantity: any) => {
    if (quantity === "Custom Quantity") {
      setShowCustomField(true);
    }
  };

  const handleCloseCustomInput = (value: any) => {
    setShowCustomField(value);
  };

  const handleCustomInputChange = (newQuantity: any) => {
    const min_quantity = selectedVariant.min_order_quantity;

    setSelectedQuantity(newQuantity);
    setCustomInputHasValidValue(true);

    // Handle minimum quantity scenario
    if (min_quantity && newQuantity.length > 0 && min_quantity > newQuantity) {
      setCustomInputHasValidValue(false);
      setMinQuantityExceeded(true);
    } else {
      setCustomInputHasValidValue(true);
      setMinQuantityExceeded(false);
    }

    handlePackageSelect(newQuantity);
  };

  const handleOpen = () => {
    setShowDrugInformation(true);
  };

  if (isLoading) return <DnaLoader />;

  return (
    <div className="main marketing-product-detail refill-page">
      {cart &&
        <div className="container checkout-page product-page product-detail-page pt-0">
          <div className="content">
            <div className="row">
              <div className="col-xl-9 col-lg-10 col-md-8 col-11 mx-auto">
                <div className="product-picker product-overview pb-5">
                  <div className="sticky-price">
                    {!isEmpty(cart) && (
                      <>
                        <strong className="cart-product__price total-value">
                          ${Number(cart.final_price).toFixed(2)}
                          <span className="per-tab ml-2">
                            {`
                              ($${Number(cart.per_tablet_cost).toFixed(2)}
                            `}
                            <Translator path="checkout.productDetail.perTablet" />
                            )
                          </span>
                        </strong>
                      </>
                    )}

                    <div
                      className={`shipping-info delivery-text ${
                        Number(cart.final_delivery_price) === 0 && "free"
                      }`}
                    >
                      <ReactSVG src={GreenVanIcon} className="mr-2" />
                      {cart.final_delivery_price > 0
                        ? "Shipping NOT Included With This Quantity"
                        : "FREE 1 to 3 Day Priority Shipping"}
                    </div>
                  </div>

                  <div className="drug-section">
                    <h6 className="step-label">
                      <span className="marketing-index">1</span>
                      Drug
                      <button className="view-detail-button" onClick={handleOpen}>
                        {" "}
                        View drug details
                      </button>
                      <DrugInformation
                        show={showDrugInformation}
                        setShow={setShowDrugInformation}
                        productName={selectedProduct.name}
                      />
                    </h6>
                    <ProductSelector
                      label="Drug name"
                      selected={selectedProduct}
                      options={products}
                      onSelect={(id: any) => handleProductChange(id)}
                      disabled={cart.branch === "refill_request"}
                      cart={cart}
                    />
                  </div>

                  <div className="strength-section">
                    <h6 className="step-label">
                      <span className="marketing-index">2</span>
                      Strength
                    </h6>
                    <DosageSelector
                      label="Dosage"
                      value={selectedVariant}
                      options={variants}
                      onSelect={(dosage) => handleDosageChange(dosage)}
                      disabled={cart.branch === "refill_request"}
                      cart={cart}
                    />
                  </div>

                  <div className="quantity-section">
                    <h6 className="step-label">
                      <span className="marketing-index">3</span>
                      Quantity
                    </h6>
                    <div className="alert alert-helper warning">
                      {!cart.marketing &&
                        refillCart &&
                        // !maxQuantityExceeded &&
                        refillCart?.rx_total_quantity_remaining !== null && (
                          <p className="max-quantity-info mb-0">
                            <Translator path="checkout.productDetail.lastQuantity" />{" "}
                            <strong>
                              {refillCart?.quantity_dispensed || 0}{" "}
                              <Translator path="checkout.productDetail.tablets" />{" "}
                            </strong>
                          </p>
                        )}
                    </div>
                    {minQuantityExceeded && (
                      <div className="max-quantity-error">
                        <p>
                          <Translator path="checkout.productDetail.minimumQuantity" />{" "}
                          {cart.product_variant?.min_order_quantity}{" "}
                          <Translator path="checkout.productDetail.tablets" />
                        </p>
                      </div>
                    )}

                    {!isEmpty(packages) && (
                      <PackagesList
                        packages={packages}
                        currentQuantity={cart.quantity}
                        variant={selectedVariant}
                        onSelect={(quantity: any) => {
                          handlePackageSelect(quantity);
                        }}
                        errors={{ ...errors, ...cart.errors }}
                        hideCustomInput={(value: any) =>
                          handleCloseCustomInput(value)
                        }
                        isCustomInput={customInputHasValidValue}
                        isInputOpen={showCustomField}
                        cartDiscounts={cart.discounts}
                        setShowCustomField={setShowCustomField}
                      />
                    )}
                  </div>

                  {showCustomField && (
                    <label
                      className={`custom-input-label w-100 mb-4 mt-3 ${
                        maxQuantityExceeded && "error-label"
                      }`}
                    >
                      <input
                        className={"input custom_input"}
                        name="custom-input"
                        id="custom_input"
                        type="number"
                        min="0"
                        placeholder="Type in the amount"
                        onChange={(e: any) =>
                          handleCustomInputChange(e.target.value)
                        }
                      />
                    </label>
                  )}
                </div>
                <Button
                  classes="refill-product-detail-btn"
                  fullWidth
                  color="secondary"
                  onClick={handleContinue}>
                  <span className="d-flex justify-content-center">
                    <Translator path="checkout.productDetail.totalText" />{" "}
                    {`$${Number(cart.final_price).toFixed(2)}`} {" - "}
                    <Translator path="checkout.productDetail.addToCart" />
                    <ReactSVG src={caretRightIcon} />
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      }  
    </div>
  );
};

export default RefillProductDetail;
