import React, { useEffect, useState } from "react";
import { first, find, sortBy, isEmpty } from "lodash";
import { ReactSVG } from "react-svg";
import starIcon from "assets/images/icons/star.svg";
import { CartType } from "Contexts/CartContext";

type DosageSelectorProps = {
  label: any;
  value: any;
  options: any;
  disabled: boolean;
  prescriptions?: [];
  onSelect: (value: any) => void;
  cart: CartType;
};

const DosageSelector = ({
  options,
  disabled,
  onSelect,
  prescriptions,
  cart,
}: DosageSelectorProps) => {
  let orderedOptions = sortBy(options, "dosage_value");
  const [selectedOption, setSelectedOption] = useState<any>(
    first(orderedOptions)
  );

  useEffect(() => {
    setSelectedOption(
      !isEmpty(cart) ? cart?.product_variant : first(orderedOptions)
    );
  }, [cart.product_id]);

  const handleOnMouseDown = (variant: any) => {
    if (!disabled) {
      onSelect(variant.dosage_value);
      setSelectedOption(variant);
    }
  };

  return (
    <div
      className={`dosage-selector marketing-strength-buttons ${
        disabled && "disabled"
      }`}
    >
      {orderedOptions.map((variant) => {
        const prescription: any = find(prescriptions, {
          product_variant_id: variant.id,
          has_an_active_cart: false,
        });

        return (
          <button
            className={`marketing-strength-button ${
              selectedOption.id === variant.id && "active"
            }`}
            onMouseDown={(e: any) => {
              handleOnMouseDown(variant)
            }}
            data-value={variant.dosage_value}
            key={variant.id}
          >
            {variant.dosage}
            {!cart?.marketing && !isEmpty(prescription) && (
              <ReactSVG src={starIcon} className="refill-star-icon ml-2" />
            )}
          </button>
        );
      })}
    </div>
  );
};

export default DosageSelector;
