import React, { useContext } from "react";
import { ReactSVG } from "react-svg";
import { Link, useLocation } from "react-router-dom";
import Translator from "../Translator";
import LanguageSelect from "../LanguageSelect";
import arrowLeftIcon from "assets/images/home/arrow-left.svg";
import sildenafilLogoIcon from "assets/images/home/logo.svg";
import phoneIcon from "assets/images/home/phone.svg";

import "./styles.scss";
import { CartContext } from "Contexts/CartContext";

type NavbarType = {
  progressPercent?: number | null;
  progressBarSize: any;
};

const SecondaryNavBar = ({
  progressPercent = null,
  progressBarSize,
}: NavbarType) => {
  const location = useLocation();
  const { PreviousCartStep } = useContext(CartContext);

  return (
    <nav className="nav-custom">
      <div className="d-flex px-md-4 justify-content-between align-items-center">
        <button
          className="nav-left back-button d-block"
          id="navBarBackButton"
          onClick={() => PreviousCartStep()}>
          <ReactSVG src={arrowLeftIcon} />
        </button>
        <div className="d-flex align-items-center sildenafil-position sildenafil-logo">
          <Link className="sildenafil-anchor" to={""}>
            <ReactSVG src={sildenafilLogoIcon} />
          </Link>
          {location?.pathname.includes("checkout") && (
            <div className="medical-visit-text">
              <div className="text-one">
                <Translator path="secondaryNavBar.linkTexts.ofMedicalVisit" />
              </div>
              <div className="text-two">
                <Translator path="secondaryNavBar.linkTexts.ofPocketMed" />
              </div>
            </div>
          )}
        </div>
        <div className="d-none">
          <LanguageSelect />
        </div>
        <div>
          <a
            className="btn small phone-anchorcolor ml-md-3"
            href="tel:(844) 745-3362">
            <ReactSVG src={phoneIcon} wrapper="svg" className="phone-svg" />
            <span className="phone-number-text">(844) 745-3362</span>
          </a>
        </div>
      </div>
      {!!progressBarSize && (
        <div className="progress-bar bar" id="progress-bar">
          <div className="bar" style={{ width: `${progressBarSize}%` }}></div>
        </div>
      )}
    </nav>
  );
};

export default SecondaryNavBar;
