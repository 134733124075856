import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import AccountSidebar from "./AccountSidebar";
import { AuthContext } from "Contexts/AuthContext";
import RetakeModal from "containers/Home/Modals/RetakeModal";
import UnderReviewVisitModal from "components/Modals/UnderReviewVisitModal";
import ResumeVisitModal from "components/Modals/ResumeVisitModal";
import DuplicateCartModal from "components/Modals/DuplicateCartModal";
import RefillAvailableModal from "components/Modals/RefillAvailableModal";
import { GetProductVariantRequest } from "apis";
import "./styles.scss";
import { isEmpty } from "lodash";

const Account = () => {
  const { currentUser, showRetakeModal, setShowRetakeModal } =
    useContext(AuthContext);
  const location = useLocation();
  const isOrderStatusPage = location.pathname.includes("order-status");
  const [defaultProductVariant, setDefaultProductVariant] = useState({});

  useEffect(() => {
    if (!isEmpty(currentUser)) {
      GetProductVariantRequest().then((res: any) => {
        setDefaultProductVariant(res.data.default_product_variant);
      });
    }
  }, [currentUser]);

  return (
    <div className="row content main-account">
      <div className={`account-sidebar ${isOrderStatusPage ? "d-none" : ""}`}>
        <AccountSidebar />
      </div>
      <div className="account-content">
        <Outlet />
        <RetakeModal setShow={setShowRetakeModal} show={showRetakeModal} />
        <ResumeVisitModal defaultProductVariant={defaultProductVariant} />
        <UnderReviewVisitModal />
        <DuplicateCartModal />
        <RefillAvailableModal defaultProductVariant={defaultProductVariant} />
      </div>
    </div>
  );
};

export default Account;
