import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import Button from "components/Button";
import { AuthContext } from "Contexts/AuthContext";
import modalCloseIcon from "assets/images/controls/modal-close.svg";
import "../styles.scss";

const DuplicateCartModal = () => {
  const { showDuplicateCartModal, setShowDuplicateCartModal } =
    useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <Modal
      centered
      className="new-modal"
      id="duplicate-cart-modal"
      show={showDuplicateCartModal}
      aria-labelledby="contained-modal-title-vcenter"
      animation={false}
      onHide={() => setShowDuplicateCartModal(false)}>
      <Modal.Dialog>
        <Modal.Header>
          <button
            className="ml-auto"
            onClick={() => setShowDuplicateCartModal(false)}>
            <img src={modalCloseIcon} alt="" />
          </button>
        </Modal.Header>

        <Modal.Body>
          <div className="refill-text mb-2 mt-2">
            <h5 className="modal-title">
              Your Prescription is Being Processed
            </h5>
            <p className="modal-text mb-4">
              Your healthcare provider has approved a medication for you and the
              AUM Pharmacy team is diligently processing your order. For any
              additional details, kindly give us a call at{" "}
              <a href="tel:(844) 745-3362">(714) 276-2040</a>.
            </p>
            <Button
              fullWidth
              color="secondary"
              classes="medium"
              onClick={() => {
                setShowDuplicateCartModal(false);
                navigate("/account/current-order");
              }}>
              Go to Your Dashboard for Status Updates
            </Button>
          </div>
        </Modal.Body>
      </Modal.Dialog>
    </Modal>
  );
};

export default DuplicateCartModal;
