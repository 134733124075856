import React from "react";
import Translator from "components/Translator";
import HappyPatientsTestimonials from "./Modals/HappyPatientsTestimonials";
import happyCustomerOne from "assets/images/home/happy_customer1.png";
import happyCustomerTwo from "assets/images/home/happy_customer2.png";
import happyCustomerThree from "assets/images/home/happy_customer3.png";

type Props = {
  defaultProductVariant:
    | {
        id?: any;
      }
    | undefined;
  slug?: boolean;
};

const HappyPatients = ({ defaultProductVariant, slug = false }: Props) => {
  return (
    <div className="ab-wrapper">
      <div className="testimonials-content homepage-container">
        <div className="content">
          <div className="headings">
            <h2>
              <Translator path="homepage.happyPatients.happyPatientsHeading" />
            </h2>
            <h6>
              <Translator path="homepage.happyPatients.findOut" />
            </h6>
            <div className="customer-images">
              <img
                src={happyCustomerOne}
                alt="customer-one"
                className="happy-customer1"
              />
              <img
                src={happyCustomerTwo}
                alt="customer-one"
                className="happy-customer2"
              />
              <img
                src={happyCustomerThree}
                alt="customer-one"
                className="happy-customer3"
              />
            </div>
          </div>
          <div className="testimonials-cards">
            <HappyPatientsTestimonials
              defaultProductVariant={defaultProductVariant}
              slug={slug}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HappyPatients;
