import React, { useContext, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ReactSVG } from "react-svg";
import { Formik, Field, Form } from "formik";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "Contexts/AuthContext";
import HOCPasswordField from "components/HOCPasswordField";
import { VerifyEmailRequest, ValidateSendGridEmailRequest } from "apis";
import PrivacyPolicyModal from "../Checkout/VisitConsent/Modals/PrivacyPolicyModal";
import TermsOfUseModal from "../Checkout/VisitConsent/Modals/TermsOfUseModal";
import emailIcon from "assets/images/login/mail.svg";
import personIcon from "assets/images/login/person.svg";
import Button from "components/Button";
import Translator from "components/Translator";
import { NAME_REGEX } from "helpers/Constants";

import "./styles.scss";
import { useRollbar } from "@rollbar/react";

const EmailVerifierSchema = Yup.object().shape({
  email_verifier: Yup.string().email("Invalid email").required("Required"),
});

const UserFormVerification = Yup.object().shape({
  first_name: Yup.string().required("Required")
    .matches(NAME_REGEX, "Must enter a proper name"),
  last_name: Yup.string().required("Required")
    .matches(NAME_REGEX, "Must enter a proper name"),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .required("Required")
    .min(8, "Password must be 8 characters long")
    .matches(
      /^((?=.*\d)|(?=.*[A-Z])|(?=.*\W)).*$/,
      "Include at least one uppercase letter or number or symbol"
    ),
  toggle: Yup.boolean().oneOf([true], "Please check the box to continue"),
});

const Registration = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [emailVerifierValue, setEmailVerifierValue] = useState("");
  const [showRegistrationForm, setShowRegistrationForm] = useState(false);
  const [emailVerfierError, setEmailVerfierError] = useState("");
  const [suggestedEmail, setSuggestedEmail] = useState("");
  const [emailValidationError, setEmailValidationError] = useState("");
  const [passwordMinLength, setPasswordMinLength] = useState<boolean>(false);
  const [passwordVariation, setPasswordVariation] = useState<boolean>(false);
  const [showTermsOfUseModal, setShowTermsOfUseModal] =
    useState<boolean>(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState<boolean>(false);
  const [characterValidation, setCharacterValidation] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const rollbar = useRollbar();
  const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;
  const { t } = useTranslation();

  const { Signup, CreateFacebookUser } = useContext(AuthContext);
  useEffect(() => {
    if (location?.state?.verifyEmail) {
      setShowRegistrationForm(true);
    } else {
      setShowRegistrationForm(false);
    }
  }, [location]);

  const onVerifyEmaill = (values: { email_verifier: string }) => {

    VerifyEmailRequest(values).then((res) => {
      const data = res.data;

      if (data.status.code === 200) {
        setShowRegistrationForm(false);
        setEmailVerfierError(data.status.message);
      } else if (data.status.code === 420) {
        validateEmail(values)
        setEmailVerfierError("");
      }
    });
  };

  const validateEmail = (values: any) => {
    ValidateSendGridEmailRequest(values)
    .then((res) => {
      if (res.data.suggested_email) {
        setShowRegistrationForm(false);
        setSuggestedEmail(res.data.suggested_email);
      } else if (res.data.error) {
        setShowRegistrationForm(false);
        setEmailValidationError(res.data.error);
      } else {
        setSuggestedEmail("");
        setEmailValidationError("");
        setShowRegistrationForm(true);
        navigate("/sign-up", {
          state: { verifyEmail: true },
        });
      }
    })
    .catch((err) => {
      console.log(err);
      rollbar.error(err);
    })
  }

  const validatePasswordChecks = (value: string) => {
    const regex = /^((?=.*\d)|(?=.*[A-Z])|(?=.*\W)).*$/;

    if (value.length > 7 && passwordMinLength === false) {
      setPasswordMinLength(true);
    }

    if (regex.test(value) && passwordVariation === false) {
      setPasswordVariation(true);
    }

    if (value.length < 8) {
      setPasswordMinLength(false);
    }

    if (!regex.test(value)) {
      setPasswordVariation(false);
    }
  };

  const shouldButtonDisabled = (values: any) => {
    const { first_name, last_name, password } = values;

    return !(
      !isEmpty(first_name) &&
      !isEmpty(last_name) &&
      !isEmpty(password) &&
      passwordMinLength &&
      passwordVariation
    );
  };

  const handleSignInClick = () => {
    const state = location.state;
    navigate("/sign-in", { state: { loginWithEmail: true } });
  };

  const handleSignInRedirect = () => {
    navigate(`/sign-in?email=${emailVerifierValue}`);
  };

  return (
    <div className="session-page">
      <div className="content">
        {!showRegistrationForm ? (
          <>
            {!isEmpty(localStorage.getItem("intro-answers")) ? (
              <h3>
                <Translator path="registration.continueWithYourEdVisit" />
              </h3>
            ) : (
              <h3> Let's get your account set up</h3>
            )}
            <div className="email-verifier-wrapper">
              <p className="mt-2">
                {!isEmpty(localStorage.getItem("intro-answers")) ? (
                  <Translator path="registration.provideSomeInformation" />
                ) : (
                  ""
                )}
              </p>
              <Formik
                initialValues={{
                  email_verifier: emailVerifierValue,
                }}
                validationSchema={EmailVerifierSchema}
                onSubmit={(values) => {
                  setPasswordMinLength(false);
                  setPasswordVariation(false);

                  onVerifyEmaill(values);
                  setEmailVerifierValue(values.email_verifier);
                }}
              >
                {({ errors, touched, values, setSubmitting, setFieldValue }) => (
                  <Form className="user-form">
                    <div className="field">
                      <label className="input-label" htmlFor="email_verifier">
                        <Translator path="formik.email" />
                      </label>
                      <div className="input-group">
                        <ReactSVG className="email-icon" src={emailIcon} />
                        <Field
                          className="email-verifier"
                          type="email"
                          name="email_verifier"
                          id="email_verifier"
                          placeholder={t("formik.enterYourEmail")}
                          onKeyDown={(e: any) => {
                            if (e.key === "Enter") {
                              setSubmitting(true);
                            } else {
                              setEmailVerfierError("");
                              setSuggestedEmail("");
                              setEmailValidationError("")
                            }
                          }}
                        />
                      </div>
                      {errors.email_verifier && touched.email_verifier ? (
                        <div className="input-errors">
                          {errors.email_verifier}
                        </div>
                      ) : null}
                      <div className="email-verifier-errors text-danger"></div>
                    </div>
                    <div
                      className={`email-verifier-errors d-none ${emailVerfierError.length && "d-block"
                        }`}
                    >
                      {emailVerfierError}
                      <button
                        className="sign-in-link"
                        onClick={handleSignInRedirect}
                        type="button"
                      >
                        <Translator path="registration.here" />
                      </button>
                    </div>
                    <div
                      className={`email-verifier-errors d-none ${
                        suggestedEmail.length && "d-block"
                      }`}
                    >
                      Did you mean
                      <button
                        className="email-suggesstion"
                        onClick={() => {
                          setFieldValue('email_verifier', suggestedEmail);
                          setSuggestedEmail("");
                        }}
                        type="button"
                      >
                        {suggestedEmail}
                      </button>
                      ?
                    </div>
                    <div
                      className={`email-verifier-errors d-none ${
                        emailValidationError.length && "d-block"
                      }`}
                    >
                      {emailValidationError}
                    </div>
                    <button
                      className="btn btn-secondary submit-btn text-uppercase"
                      type="submit"
                    >
                      <Translator path="registration.continueButtonText" />
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </>
        ) : (
          <>
            <div className=" mb-3">
              {!isEmpty(localStorage.getItem("intro-answers")) ? (
                <h3>
                  <Translator path="registration.continueWithYourEdVisit" />
                </h3>
              ) : (
                <h3> Last step for set up</h3>
              )}
            </div>
            <Formik
              initialValues={{
                first_name: "",
                last_name: "",
                email: emailVerifierValue,
                password: "",
                toggle: true,
                landing_page_id: 7,
              }}
              validationSchema={UserFormVerification}
              onSubmit={(values) => {
                Signup(values);
              }}
            >
              {({ errors, touched, values }) => (
                <Form className="user-form">
                  <div className="form-col">
                    <div className="field">
                      <div className="input-group flex-nowrap">
                        <ReactSVG
                          className="email-icon input__icon"
                          src={personIcon}
                        />
                        <Field
                          className="first_name input no-mouseflow mouseflow-hidden name_placeholder"
                          name="first_name"
                          id="first_name"
                          placeholder={t("formik.firstName")}
                        />
                      </div>
                      {errors.first_name && touched.first_name ? (
                        <div className="input-errors">{errors.first_name}</div>
                      ) : null}
                    </div>
                    <div className="field">
                      <div className="input-group flex-nowrap">
                        <ReactSVG
                          className="email-icon input__icon"
                          src={personIcon}
                        />
                        <Field
                          className="last_name input no-mouseflow mouseflow-hidden name_placeholder"
                          name="last_name"
                          id="last_name"
                          placeholder={t("formik.lastName")}
                        />
                      </div>
                      {errors.last_name && touched.last_name ? (
                        <div className="input-errors">{errors.last_name}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="field">
                    <HOCPasswordField
                      name="password"
                      placeholder={t("formik.password")}
                      onKeyUp={() => validatePasswordChecks(values.password)}
                    />
                    {errors.password && touched.password ? (
                      <div className="input-errors">{errors.password}</div>
                    ) : null}
                  </div>
                  <div className="password-checks">
                    <div className="check-wrapper length">
                      <label
                        className={`password-length-check check-icon ${passwordMinLength && "checked"
                          }`}
                      ></label>
                      <span className="check-text">
                        <Translator path="registration.passwordMustBe" />
                      </span>
                    </div>
                    <div className="check-wrapper case">
                      <label
                        className={`password-regx-check check-icon ${passwordVariation && "checked"
                          }`}
                      ></label>
                      <span className="check-text">
                        <Translator path="registration.includeAtleast" />
                      </span>
                    </div>
                  </div>
                  <div className="input-group terms-wrapper mt-4">
                    <Field
                      className={"terms-and-conditions"}
                      type="checkbox"
                      name="toggle"
                      id="terms-check"
                    />
                    <label htmlFor="terms-check">
                      <Translator path="registration.byContinuing" />{" "}
                      <button
                        className="visit-consent-modals-btn"
                        type="button"
                        onClick={() =>
                          setShowTermsOfUseModal(!showTermsOfUseModal)
                        }
                      >
                        <Translator path="registration.termsOfUse" />
                      </button>{" "}
                      <Translator path="registration.andPrivacy" />{" "}
                      <button
                        className="visit-consent-modals-btn"
                        type="button"
                        onClick={() => setShowPrivacyModal(!showPrivacyModal)}
                      >
                        <Translator path="registration.policy" />
                      </button>
                      .
                    </label>
                  </div>
                  {errors.toggle && touched.toggle ? (
                    <div className="terms-conditions-errors">
                      {errors.toggle}
                    </div>
                  ) : null}
                  <button
                    className={`btn btn-secondary ga-lead-event submit-btn mt-2 mb-2 text-uppercase ${shouldButtonDisabled(values) ? "disabled" : ""
                      }`}
                    type="submit"
                    disabled={shouldButtonDisabled(values) || undefined}
                  >
                    <Translator path="registration.createAccountButtonText" />
                  </button>
                </Form>
              )}
            </Formik>
          </>
        )}
        <p className="already-have-account text-color ">
          <Translator path="registration.alreadyHaveAccount" />
          <button
            className="sign-in-link"
            onClick={handleSignInClick}
            type="button"
          >
            <Translator path="registration.signIn" />
          </button>
        </p>
      </div>
      <PrivacyPolicyModal
        show={showPrivacyModal}
        setShow={setShowPrivacyModal}
      />
      <TermsOfUseModal
        show={showTermsOfUseModal}
        setShow={setShowTermsOfUseModal}
        setShowPrivacyModal={setShowPrivacyModal}
      />
    </div>
  );
};

export default Registration;
