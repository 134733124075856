import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import useLatestCart from "hooks/useLatestCart";
import NoBloodPressureIllust from "assets/images/checkout/no_blood_pressure_illust.svg";
import Button from "components/Button";
import { CartsPreviousStepRequest, NoBloodPressureNextStepRequest } from "apis";
import { CartContext } from "Contexts/CartContext";

import "./styles.scss";

const NoBloodPressure = () => {
  const cart = useLatestCart();
  const navigate = useNavigate();
  const { PreviousCartStep } = useContext(CartContext);

  // TODO will keep this commented just in case if we have to revert these changes in future
  const finishVisit = () => {
    NoBloodPressureNextStepRequest(cart?.id)
      .then((res) => {
        navigate(res.data.redirect_path);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleContinueLaterOn = () => {
    CartsPreviousStepRequest(cart?.id).then(() => {
      navigate("/account/current-order");
    });
  };

  return (
    <div className="main">
      <div className="container checkout-page product-page">
        <div className="content false">
          <div className="row justify-content-center pt-0 pt-md-3">
            <div className="col col-lg-6">
              <section>
                <div className="questionnaire-exit-container">
                  <h4 className="mt-0 mb-4">Let's talk about your health</h4>
                  <div className="card w-100 mw-100 mb-3">
                    <div className="card-body">
                      <img
                        src={NoBloodPressureIllust}
                        alt="no-blood-pressure"
                      />
                      <p>
                        Once you get a blood pressure reading. Come back to
                        complete your visit from where you left off.
                      </p>
                    </div>
                  </div>
                  {cart && (
                    <>
                      <Button
                        classes="btn-secondary w-100 mt-4"
                        fullWidth
                        color="secondary"
                        onClick={handleContinueLaterOn}
                      >
                        continue later on
                      </Button>
                      <Button
                        classes="btn-secondary w-100 mt-4 back-to-health-question"
                        fullWidth
                        color="white"
                        onClick={() => PreviousCartStep()}
                      >
                        back to health questions
                      </Button>
                    </>
                  )}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoBloodPressure;
