import React from "react";
import { find, filter, differenceWith, isEqual } from "lodash";

type Props = {
  selectedAnswers: any;
  setSelectedAnswers: any;
  option: any;
  setExtraText: any;
  extraLabel: any;
  questionType: any;
  textAreaBorder: any;
  setContinueButtonDisable: any;
  questionId: any;
  userResponses: any;
  extraText: any;
  isOtherOptions?: any;
};

const TextOption = ({
  selectedAnswers,
  setSelectedAnswers,
  option,
  setExtraText,
  extraLabel,
  questionType,
  textAreaBorder,
  setContinueButtonDisable,
  questionId,
  userResponses,
  extraText,
  isOtherOptions,
}: Props) => {
  const shouldShowExtraLabel = !(option?.allow_allergy || option?.allow_med);

  const filterAnswersDifferentFromLabel = (label: any) => {
    return selectedAnswers.filter((sa: any) => sa.question_label !== label);
  };

  const handleTextChange = (text: any) => {
    setSelectedAnswers([
      ...filterAnswersDifferentFromLabel(option.question_label),
      { ...option, text_value: text },
    ]);
  };

  const handleSideEffectTextChange = (text: any) => {
    const currentOptionAns = filter(
      selectedAnswers,
      (ans) =>
        ans.label === option.label &&
        ans.question_label === option.question_label
    );

    const filteredAnswers = differenceWith(
      selectedAnswers,
      currentOptionAns,
      isEqual
    );

    setSelectedAnswers([...filteredAnswers, { ...option, text_value: text }]);
  };

  const getStoredSideEffectsText = () => {
    const value =
      find(
        selectedAnswers,
        (ans) =>
          ans.label === "Other" && ans.question_label === option.question_label
      )?.text_value || "";

    setContinueButtonDisable(!Boolean(value.length));
    return value;
  };

  const getStoredText = () => {
    const value =
      selectedAnswers.filter(
        (sa: any) => sa.question_id === option.question_id
      )[0]?.text_value || "";
    setContinueButtonDisable(!Boolean(value.length));
    return value;
  };

  const toggleQuestionErrorBorder = () => {
    const filterFields = filter(selectedAnswers, [
      "question_id",
      questionId,
    ])[0];
    return filterFields?.text_value == null ||
      filterFields?.text_value?.length === 0
      ? "textarea-error"
      : "";
  };

  return (
    <div className="answer-option-wrapper">
      {shouldShowExtraLabel && (
        <p className="answer-option-extra-label mt-2">{extraLabel}</p>
      )}
      {questionType === "text" && (
        <textarea
          className={`answer-option-textarea ${
            textAreaBorder ? toggleQuestionErrorBorder() : ""
          }`}
          onChange={(e: any) => handleTextChange(e.target.value)}
          value={getStoredText()}
        />
      )}
      {questionType === "textfield" && (
        <>
          <input
            type="text"
            className="answer-option-textfield"
            onChange={(e: any) => handleTextChange(e.target.value)}
            value={getStoredText()}
          />
        </>
      )}
      {questionType === "textfield_disabled" && (
        <>
          <input
            type="text"
            className="answer-option-textfield"
            onChange={(e: any) => handleTextChange(e.target.value)}
            value={option.label}
            readOnly={questionType === "textfield_disabled"}
          />
        </>
      )}
      {isOtherOptions && (
        <textarea
          className={`answer-option-textarea ${
            textAreaBorder ? toggleQuestionErrorBorder() : ""
          }`}
          onChange={(e: any) => handleSideEffectTextChange(e.target.value)}
          value={getStoredSideEffectsText()}
        />
      )}
    </div>
  );
};

export default TextOption;
