import React, { useContext } from "react";
import { Formik, Form } from "formik";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "components/Button";
import { AuthContext } from "Contexts/AuthContext";
import { CartContext } from "Contexts/CartContext";
import { ResumeCartRequest } from "apis";

import modalCloseIcon from "assets/images/controls/modal-close.svg";
import "../styles.scss";
import useLatestCart from "hooks/useLatestCart";

type Props = {
  defaultProductVariant:
    | {
        id?: any;
      }
    | undefined;
  slug?: boolean;
};

const ResumeVisitModal = ({ defaultProductVariant, slug = false }: Props) => {
  const navigate = useNavigate();
  const { showResumeVisitModal, setShowResumeVisitModal } =
    useContext(AuthContext);
  const { CreateCart, MarketingNextStep, ReloadCart } = useContext(CartContext);
  const latestCart = useLatestCart();

  return (
    <Modal
      centered
      className="new-modal"
      id="resume-visit-modal"
      show={showResumeVisitModal}
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => setShowResumeVisitModal(false)}
      animation={false}
    >
      <Modal.Dialog>
        <Modal.Header>
          <button
            className="ml-auto"
            onClick={() => setShowResumeVisitModal(false)}
          >
            <img src={modalCloseIcon} alt="" />
          </button>
        </Modal.Header>

        <Modal.Body>
          <div className="refill-text mb-2 mt-2">
            <h5 className="modal-title">
              You have an Erectile Dysfunction (ED) diagnosis and treatment
              visit in progress
            </h5>
            <p className="modal-text">
              You can resume your visit from where you left off or start a new
              one.
            </p>
          </div>
          <div className="modal-actions d-flex flex-md-row flex-column mt-1">
            {slug ? (
              <Button
                fullWidth
                classes="medium border btn-outline mr-md-2 mb-2 mb-md-0 new-visit-link"
                color="white"
                onClick={() => {
                  setShowResumeVisitModal(false);
                  MarketingNextStep(latestCart?.id);
                }}
              >
                Start a New Visit
              </Button>
            ) : (
              <>
                <Formik
                  initialValues={{
                    variant: defaultProductVariant?.id ?? null,
                  }}
                  onSubmit={(values) => {
                    setShowResumeVisitModal(false);
                    slug ? navigate("/welcome") : CreateCart(values);
                  }}
                >
                  {() => {
                    return (
                      <Form className="w-100">
                        <Button
                          fullWidth
                          classes="medium border btn-outline mr-md-2 mb-2 mb-md-0 new-visit-link"
                          color="white"
                          type="submit"
                        >
                          Start a New Visit
                        </Button>
                      </Form>
                    );
                  }}
                </Formik>
              </>
            )}
            <Button
              fullWidth
              color="secondary"
              classes="medium ml-md-2"
              onClick={() => {
                ResumeCartRequest()
                  .then((res) => {
                    setShowResumeVisitModal(false);
                    ReloadCart();
                    navigate(res.data.redirect_path);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            >
              Resume Visit
            </Button>
          </div>
          <Link
            to="/account/current-order"
            className="d-flex mx-auto text-secondary text-center small mt-4"
            onClick={() => setShowResumeVisitModal(false)}
          >
            Neither, go to your Account
          </Link>
        </Modal.Body>
      </Modal.Dialog>
    </Modal>
  );
};

export default ResumeVisitModal;
