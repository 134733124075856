import React from "react";
import { ReactSVG } from "react-svg";

import CompletionIcon from "../../../../assets/images/account/completion-icon.svg";
import backButtonIcon from "../../../../assets/images/account/back-button-arrow.svg";

const Submitted = ({ removeActiveVisit }: any) => {
  return (
    <>
      <div className="active-visit-heading">
        <button className="back-button" onClick={() => removeActiveVisit()}>
          <ReactSVG src={backButtonIcon} />
        </button>
        <h3 className="mb-4">We're on it</h3>
      </div>
      <div className="account-card">
        <div className="row visitDetailStatusRow">
          <div className="col d-flex flex-column visitDetailStatusCol">
            <ReactSVG src={CompletionIcon} />
          </div>
          <div className="col d-flex flex-column justify-content-center">
            <p className="mb-0 completion-text">
              Your visit is now being processed and we’ll notify you when the
              doctor has reviewed it. Check your messages in case our clinical
              team has any follow up question.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Submitted;
