import React from "react";
import IdPhotoPath from "../../../assets/images/home/id-upload.png";
import Button from "../../../components/Button";

const WhyNeedPhoto = () => (
  <>
    <div
      className="modal"
      id="whyNeedPhotoModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="whyNeedPhotoModalTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div className="header-image">
              <h5 className="whyNeedPhotoModalLongTitle">
                Why do you need a photo of my ID?
              </h5>
              <img src={IdPhotoPath} alt="" />
            </div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="body-text">
              <p>
                We use your ID photo to verify your identity, for example your
                name and date of birth. Telemedicine laws require us to this
                information before prescribing medication.
              </p>
            </div>
            <strong>Who see this</strong>
            <p>
              The photo of your ID wil be stored securely and will only be
              shared with the patient support team and our identity verification
              platform.
            </p>
          </div>
          <div className="modal-footer">
            <Button
              type="button"
              fullWidth={true}
              color="secondary"
              modalDismiss="modal"
            >
              I understand
            </Button>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default WhyNeedPhoto;
