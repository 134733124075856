import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";

import { AuthContext } from "Contexts/AuthContext";
import modalCloseIcon from "assets/images/controls/modal-close.svg";
import "../styles.scss";

const UnderReviewVisitModal = () => {
  const { showUnderReviewVisitModal, setShowUnderReviewVisitModal } =
    useContext(AuthContext);

  return (
    <Modal
      centered
      className="new-modal"
      id="resume-visit-modal"
      show={showUnderReviewVisitModal}
      aria-labelledby="contained-modal-title-vcenter"
      animation={false}
      onHide={() => setShowUnderReviewVisitModal(false)}
    >
      <Modal.Dialog>
        <Modal.Header>
          <button
            className="ml-auto"
            onClick={() => setShowUnderReviewVisitModal(false)}
          >
            <img src={modalCloseIcon} alt="" />
          </button>
        </Modal.Header>

        <Modal.Body>
          <div className="refill-text mb-2 mt-2">
            <h5 className="modal-title">
              Your visit for Erectile Dysfunction (ED) is currently under review
            </h5>
            <p className="modal-text">
              We will contact you as soon as there is an update. If you have any
              questions please contact us by phone.
            </p>
          </div>
        </Modal.Body>
      </Modal.Dialog>
    </Modal>
  );
};

export default UnderReviewVisitModal;
