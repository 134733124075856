import React from "react";
import { ReactSVG } from "react-svg";
import cancelOrder from "assets/images/dashboard/cancelOrder.svg";

const CancelOrderProcess = () => {
  return (
    <div className="empty-screen">
      <div className="empty-illustration-ellipse">
        <ReactSVG className="dashboard-svg p-4" src={cancelOrder} />
      </div>
      <div className="cancel-order">
      <span className="title-box">Cancel Order Process</span>
      <div className="detail-box mt-2 w-100">
        <span>For order cancellations, call <a href="tel:8447453362">(714) 276-2040</a>,</span>
        Mon - Fri - 9 am - 6pm PT
      </div>
      </div>
    </div>
  );
};

export default CancelOrderProcess;
