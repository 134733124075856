import React, { useContext, useEffect, useState } from "react";
import { compact } from "lodash";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import DnaLoader from "../components/DnaLoader";
import TagManager from "react-gtm-module";

import {
  Dashboard,
  Home,
  Welcome,
  PatientInfo,
  VisitIntro,
  IntroQuestions,
  VisitConsent,
  VisitConsultation,
  ProductDetail,
  Login,
  Registration,
  Unmatched,
  Account,
  Profile,
  MedicalVisits,
  PaymentOptions,
  Shipping,
  IdentityVerification,
  OrderVerification,
  CancelOrderProcess,
  ShippingAddress,
  IdUpload,
  SelfieUpload,
  NoCheckup,
  NoBloodPressure,
  Notifications,
  ForgotPassword,
  PasswordEdit,
  EditPatientInfo,
  EditShipping,
  OrderConfirmation,
  RetakePhotos,
  OrderRefill,
  RefillProductDetail,
  CurrentOrder,
  OrderStatus,
  OrdersHistory,
  FinishSetup,
  PrivacyPolicy,
  TermsOfUse,
  Hipaa,
  EmailConfirmation,
  ShippingConfirmation,
  OrderShippingConfirmation,
  MarketingLandingPage,
  SsnVerification,
} from "../containers";

import { CartContext } from "../Contexts/CartContext";
import { AuthContext } from "../Contexts/AuthContext";
import useCurrentUser from "../hooks/useCurrentUser";
import useLatestCart from "../hooks/useLatestCart";
import ScrollToTop from "helpers/ScrollToTop";
import { isEmpty } from "lodash";
import { FbApiDataExtraction } from "helpers/FbApiDataExtraction";
import { useSearchParams } from "react-router-dom";
import Disclaimer from "containers/Disclaimer";
import ShippingPolicy from "containers/ShippingPolicy";
import ReturnAndRefundPolicy from "containers/ReturnAndRefundPolicy";
import ContactUs from "containers/ContactUs";

const AllRoutes = () => {
  const currentUser = useCurrentUser();
  const latestCart = useLatestCart();
  const location: any = useLocation();
  const navigate = useNavigate();
  const cartProductVariant = useContext(CartContext);
  const { loading, LoginUser, setLoading } = useContext(AuthContext);
  const isAuthRoute = location.pathname.includes("sign");
  const [onBoardingLoader, setOnBoardingLoader] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const utmData: any = FbApiDataExtraction(location.href);
    let utmReferrer = "";
    let sourceReferrer = "";
    let sourceUrl = "";

    switch (utmData?.site_source_name) {
      case "fb":
        utmReferrer = "facebook.com";
        break;
      case "ig":
        utmReferrer = "instagram.com";
        break;
    }

    // Backend was expecting all referrers in facebook.com form
    const source = searchParams.get("source");

    switch (source) {
      case 'ml-1':
        sourceUrl = 'neutral';
        break;
      case 'ml-2':
        sourceUrl = 'facebook';
        break;
      case 'ml-3':
        sourceUrl = 'google-search';
        break;
      case 'ml-4':
        sourceUrl = 'google-pmax';
        break;
      case 'ml-5':
        sourceUrl = 'google-shopping';
        break;
    }

    if (source && ["ml-3", "ml-4", "ml-5"].includes(source)) {
      sourceReferrer = "google.com";
    } else if (source && source.includes("ml-2")) {
      sourceReferrer = "facebook.com";
    }

    if (isEmpty(localStorage.getItem('referrer'))) {
      localStorage.setItem(
        "referrer",
        sourceReferrer || document.referrer || utmReferrer
      );
    }


    if (isEmpty(localStorage.getItem('sourceUrl'))) {
      localStorage.setItem(
        "sourceUrl", sourceUrl || ""
      );
    }
  }, [location]);

  useEffect(() => {
    if (currentUser?.provider === "facebook" && !currentUser.terms_of_service) {
      setOnBoardingLoader(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, location]);

  useEffect(() => {
    if (onBoardingLoader) {
      navigate("/finish-setup", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onBoardingLoader, location]);

  useEffect(() => {
    if (location.pathname === "/finish-setup") {
      setOnBoardingLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (location.pathname.includes("/users/become")) {
      localStorage.clear();
      setLoading(true);

      const becomeToken = location.pathname.split("/")[3];
      localStorage.setItem("logInThroughCrm", "true");
      const values = {
        email: "",
        password: "",
        phone: "",
        becomeToken: becomeToken,
      };

      LoginUser(values);
    }

    // Add Class to body for brand new patient
    if (currentUser?.is_brand_new_patient) {
      document.body.classList.add("brand-new-patient");
    } else {
      document.body.classList.remove("brand-new-patient");
    }
  }, [location]);

  const authRoutesArray: string[] = [
    "account",
    "checkout",
    "order-shipping-confirmation",
    "order",
  ];
  const basePath: string = location.pathname.split("/");
  const isLocationUnauthenticated = authRoutesArray.some((route) =>
    compact(basePath)[0]?.includes(route)
  );

  useEffect(() => {
    if (
      isEmpty(localStorage.getItem("authToken")) &&
      isLocationUnauthenticated
    ) {
      const previousLocation = location.pathname;

      navigate(`sign-in?redirect_uri=${encodeURIComponent(previousLocation)}`);
    }
  }, [location, currentUser]);

  return (
    <>
      {loading && <DnaLoader />}
      <ScrollToTop />
      <Routes>
        {currentUser ? (
          <>
            {/* {isEmpty(latestCart) &&
              location.pathname.includes("checkout") &&
              navigate("/", { replace: true })} */}
            <Route path="/dashboard" element={<Dashboard />} />
            {/* Checkout routes */}
            {!isEmpty(latestCart) && (
              <>
                <Route
                  path="/checkout/patient-info"
                  element={<PatientInfo />}
                />
                <Route
                  path="/checkout/visit-consent"
                  element={
                    <VisitConsent
                      user={currentUser}
                      cart={latestCart}
                      productVariant={cartProductVariant}
                    />
                  }
                />
                <Route path="/checkout/visit-intro" element={<VisitIntro />} />
                <Route
                  path="/checkout/visit-consultation"
                  element={<VisitConsultation />}
                />
                <Route
                  path="/checkout/product-detail"
                  element={
                    <ProductDetail cart={latestCart} marketingPage={false} />
                  }
                />
                <Route path="/checkout/shipping" element={<Shipping />} />
                <Route
                  path="/checkout/identity-verification"
                  element={<IdentityVerification />}
                />
                <Route path="/checkout/id-upload" element={<IdUpload />} />
                <Route
                  path="/checkout/selfie-upload"
                  element={<SelfieUpload />}
                />
                <Route
                  path="/checkout/shipping-confirmation"
                  element={<ShippingConfirmation />}
                />
                <Route
                  path="/checkout/order-verification"
                  element={<OrderVerification />}
                />
                <Route path="/checkout/no-checkup" element={<NoCheckup />} />
                <Route
                  path="/checkout/no-blood-pressure"
                  element={<NoBloodPressure />}
                />
                <Route
                  path="/checkout/ssn-verification"
                  element={<SsnVerification />}
                />
              </>
            )}
            <Route path="/order/:id" element={<OrderConfirmation />} />
            {/* Edit routes */}
            <Route path="/edit/patient-info" element={<EditPatientInfo />} />
            <Route path="/edit/shipping" element={<EditShipping />} />
            {/* Account routes */}
            <Route path="/account" element={<Account />}>
              <Route path="profile" element={<Profile />} />
              <Route path="medical-visits" element={<MedicalVisits />} />
              <Route path="payment-options" element={<PaymentOptions />} />
              <Route
                path="cancel-order-process"
                element={<CancelOrderProcess />}
              />
              <Route path="shipping-address" element={<ShippingAddress />} />
              <Route path="notifications" element={<Notifications />} />
              <Route path="order-refill" element={<OrderRefill />} />
              <Route path="current-order" element={<CurrentOrder />} />
              <Route path="order-status" element={<OrderStatus />} />
              <Route path="orders-history" element={<OrdersHistory />} />
            </Route>
            <Route path="retake-photos" element={<RetakePhotos />} />
            <Route
              path="refill-product-detail"
              element={<RefillProductDetail />}
            />
            <Route
              path="order-shipping-confirmation"
              element={<OrderShippingConfirmation />}
            />
            <Route path="finish-setup" element={<FinishSetup />} />
          </>
        ) : (
          <>
            <Route path="/sign-in" element={<Login />} />
            <Route path="/sign-up" element={<Registration />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/edit-password" element={<PasswordEdit />} />
          </>
        )}

        <Route path="/" element={<Home />} />
        <Route
          path={`/product-selection/*`}
          element={<MarketingLandingPage />}
        />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/intro-questions" element={<IntroQuestions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/hipaa" element={<Hipaa />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/shipping_policy" element={<ShippingPolicy />} />
        <Route path="/return_and_refund_policy" element={<ReturnAndRefundPolicy />} />
        <Route path="/contact_us" element={<ContactUs />} />
        <Route path="/confirmation" element={<EmailConfirmation />} />

        {currentUser && isAuthRoute ? (
          <>{navigate("/")}</>
        ) : (
          <Route path="/sign-in" element={<Login />} />
        )}
        <Route path="*" element={<Unmatched />} />
      </Routes>
    </>
  );
};

export default AllRoutes;
