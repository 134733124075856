import { minBy, maxBy } from 'lodash';

const calculateDiscount = (variant: any, quantity: number) => {
  const baseThreshold = minBy(
    variant.price_thresholds,
    (thd: any) => thd.min_quantity
  );

  const basePrice: any = baseThreshold
    ? Number(baseThreshold.price * quantity).toFixed(2)
    : 0.0;


  const possibleThresholds = variant.price_thresholds.filter(
    (thd: any) => quantity >= thd.min_quantity
  );
  const threshold = maxBy(possibleThresholds, (thd: any) => thd.min_quantity);

  const price: any = threshold ? Number(threshold.price * quantity).toFixed(2) : 0.0;

  const priceDifference: any = Number(basePrice - price).toFixed(2);

  const discount = Math.round((priceDifference / basePrice) * 100) || 0;

  return {
    discount,
  };
};


export {
  calculateDiscount,
};
