import { isEmpty } from "lodash";
import { any } from "prop-types";
import Cookies from "js-cookie";

const FbApiDataExtraction = (href: String) => {
  if (isEmpty(href) || !href.includes("utm")) {
    return;
  }

  const extractAdName = (inputString: string) => {
    // Regular expression to match URLs
    const urlRegex = /(https?:\/\/[^\s]+)/;
    const decodedString = decodeURIComponent(inputString);

    // Replace unwanted characters
    const normalizedString = decodedString
      .replace(/LP:/g, "")
      .replaceAll("+", " ");

    const urlMatches = normalizedString.match(urlRegex);

    // Check if a URL is found and return it
    if (urlMatches && urlMatches.length > 0) {
      return urlMatches[0];
    } else {
      // If no URL is found, return null or handle the absence as needed
      return null;
    }
  };

  const splitedUtmString = href
    .split("?")[1]
    .split(/_(.*)/s)[1]
    .split(/&(.*)/s)[1]
    .split("&");

  let utmData: any = {};

  // eslint-disable-next-line array-callback-return
  splitedUtmString.map((data) => {
    const splitedData: any = data.split("=");

    utmData[splitedData[0]] = splitedData[1];
  });

  // Extracting Ad Name
  const campaignName = utmData.utm_campaign
    .replace("%", ": ")
    .replaceAll("%", "")
    .replaceAll("+", " ");

  // Extracting
  const adName = extractAdName(utmData.utm_content);

  // Extracting utm term data

  const utmTermData = utmData.utm_term
    .split("___")
    .filter((item: string) => item !== "")[1]
    .split("_");

  // Array destructuring to extract values from the array
  let [adId, adCampaignId, adSetId, siteSourceName, ...placement] = utmTermData;

  // Convert placement to a string containing all the values after index 3
  placement = placement.join(" ");

  const data = {
    ad_id: adId,
    ad_name: adName,
    ad_campaign_id: adCampaignId,
    campaign_name: campaignName,
    ad_set_id: adSetId,
    site_source_name: siteSourceName,
    placement: placement,
    source: utmData?.utm_source,
    fbclid: utmData?.fbclid,
  };

  return data;
};

const facebookFBP = () => {
  return Cookies.get("_fbp");
};

export { FbApiDataExtraction, facebookFBP };
