import React, { useContext, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import Modal from "react-bootstrap/Modal";
import OtpInput from "react-otp-input";
import { AuthContext } from "Contexts/AuthContext";
import { CartContext } from "Contexts/CartContext";
import useCurrentUser from "hooks/useCurrentUser";
import Button from "components/Button";
import {
  OtpCodeVerificationRequest,
  GenerateOtpRequest,
  LoginViaMobileRequest,
  EmailCodeRequest,
} from "apis";
import modalCloseIcon from "assets/images/controls/modal-close.svg";
import { useRollbar } from "@rollbar/react";
import "../styles.scss";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import otpMobileIcon from "assets/images/home/otp-mobile.svg";
import otpEmailIcon from "assets/images/login/email-otp-icon.svg";

type Props = {
  openOtpModal: boolean;
  setOpenOtpModal: any;
  cart?: any;
  limitExceededError?: boolean;
  setLimitExceededError?: any;
  isProfileModal?: boolean;
  isLoginOtpModal?: boolean;
  phoneNumber?: string;
  setLoginError?: any;
  setOtpCode?: any;
  email?: any;
  isEmailCode?: any;
  phoneCodeLimitCounter: number;
  emailCodeLimitCounter: number;
  setPhoneCodeLimitCounter?: any;
  setEmailCodeLimitCounter?: any;
  setShowLoginWithPhoneForm?: any;
  setShowPasswordForm?: any;
  setPhoneNumber?: any;
};

const OtpModal = ({
  openOtpModal,
  setOpenOtpModal,
  cart,
  limitExceededError,
  setLimitExceededError,
  isProfileModal,
  isLoginOtpModal,
  phoneNumber,
  setLoginError,
  setOtpCode,
  email,
  isEmailCode,
  phoneCodeLimitCounter,
  emailCodeLimitCounter,
  setPhoneCodeLimitCounter,
  setEmailCodeLimitCounter,
  setShowLoginWithPhoneForm,
  setShowPasswordForm,
  setPhoneNumber,
}: Props) => {
  const [otpErrorMessage, setOtpErrorMessage] = useState<string>("");
  const currentUser = useCurrentUser();
  const { PatientInfoNextStep } = useContext(CartContext);
  const [otp, setOtp] = useState<string>("");
  const { reloadUser, LoginUser, setOtpLoginError, otpLoginError } =
    useContext(AuthContext);
  const rollbar = useRollbar();
  const [newCodeAlert, setNewCodeAlert] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (openOtpModal) {
      setOtp("");
      setOtpLoginError("");
    }
  }, [openOtpModal]);

  const handleGenerateNewCode = () => {
    setNewCodeAlert(true);

    if (isEmailCode && !isEmpty(email)) {
      const values: any = { email: email };

      setEmailCodeLimitCounter(emailCodeLimitCounter + 1);

      if (emailCodeLimitCounter < 3) {
        EmailCodeRequest(values).then((res) => {
          setLimitExceededError(false);
        });
      }
    } else if (isLoginOtpModal) {
      const values: any = { phone: phoneNumber };

      setLimitExceededError(false);
      setPhoneCodeLimitCounter(phoneCodeLimitCounter + 1);

      if (phoneCodeLimitCounter < 3) {
        LoginViaMobileRequest(values)
          .then((res) => {
            if (res.data.limit_exceeded) {
              setLimitExceededError(res.data.limit_exceeded);
            } else if (res.data.error_message) {
              setLoginError(res.data.error_message);
            }
          })
          .catch((err) => {
            console.log(err);
            rollbar.error(err);
          });
      }
    } else {
      GenerateOtpRequest()
        .then(() => {
          setLimitExceededError(false);
          setOtp("");
          setOtpErrorMessage("");
          setOtpLoginError("");
        })
        .catch((err) => {
          if (err?.response?.data?.limit_exceeded) {
            setLimitExceededError(err?.response?.data?.limit_exceeded);
          }

          console.log(err);
          rollbar.error(err);
        });
    }
  };

  const handleSubmitOtp = () => {
    setNewCodeAlert(false);

    if (isLoginOtpModal) {
      const values: any = {
        email: isEmailCode ? email : "",
        password: "",
        become_token: "",
        phone: !isEmailCode ? phoneNumber : "",
        otpCode: otp,
        is_otp_request: true,
      };

      LoginUser({ ...values });
    } else {
      OtpCodeVerificationRequest(otp)
        .then((res) => {
          if (!isEmpty(cart) && !isProfileModal) {
            PatientInfoNextStep(cart.id);
            setOtp("");
            setOpenOtpModal(false);
          } else {
            setOpenOtpModal(false);
            setOtp("");
            reloadUser();
          }
        })
        .catch((err) => {
          setOtpErrorMessage(err?.response?.data?.error_message);

          console.log(err);
        });
    }
  };

  const handleModalClose = () => {
    setOpenOtpModal(false);
    setNewCodeAlert(false);
    setOtp("");
    setOtpErrorMessage("");
    setOtpLoginError("");
  };

  const handleChangeEmailOrPhone = () => {
    if (!isLoginOtpModal) {
      setOpenOtpModal(false);
      return;
    }
    setPhoneNumber("");
    setLoginError("");
    handleModalClose();
    if (isEmailCode) {
      setShowLoginWithPhoneForm(false);
      setShowPasswordForm(false);
      navigate("/sign-in", { state: { loginWithEmail: true }, replace: true });
    } else {
      setPhoneCodeLimitCounter(0);
      setShowLoginWithPhoneForm(true);
      setShowPasswordForm(false);
    }
  };

  const handleSetHeadingText = () => {
    if (isEmailCode) {
      return "Please check your email";
    } else if (phoneNumber) {
      return "Please check your phone";
    } else {
      return "Let's make sure it's really you!";
    }
  };

  return (
    <Modal
      centered
      className="otp-modal"
      id="otp-modal"
      show={openOtpModal}
      aria-labelledby="contained-modal-title-vcenter"
      onHide={handleModalClose}
      animation={false}
    >
      <Modal.Dialog>
        {isProfileModal && (
          <Modal.Header>
            <button className="ml-auto p-2" onClick={handleModalClose}>
              <img src={modalCloseIcon} alt="" />
            </button>
          </Modal.Header>
        )}
        <Modal.Body>
          <div className="mb-2 mt-2 pt-3">
            <h5 className="modal-title">{handleSetHeadingText()}</h5>
            <p
              className={`modal-text ${
                phoneNumber || email ? "login-otp-text" : ""
              }`}
            >
              {email && isEmailCode ? (
                <>
                  <span className="d-flex flex-column">
                    Please enter the 4 digit code we emailed to
                    <br />
                    <strong>{email}</strong>
                  </span>
                </>
              ) : (
                <>
                  Please enter the 4 digit code we texted to{" "}
                  <strong>
                    {email
                      ? "(***) *** **" + phoneNumber?.slice(-2)
                      : currentUser?.mobile_phone || phoneNumber}
                  </strong>
                </>
              )}
            </p>
          </div>

          {phoneNumber && !isEmailCode && (
            <div className="d-flex justify-content-center">
              <div className="ellipses-circle">
                <ReactSVG className="otp-mobile-icon" src={otpMobileIcon} />
              </div>
            </div>
          )}

          {email && isEmailCode && (
            <div className="d-flex justify-content-center">
              <div className="ellipses-circle">
                <ReactSVG className="otp-email-icon" src={otpEmailIcon} />
              </div>
            </div>
          )}

          <div className="modal-actions d-flex flex-column mt-1">
            <OtpInput
              value={otp}
              onChange={(otp) => {
                setNewCodeAlert(false);
                setOtpErrorMessage("");
                setOtpLoginError("");
                setOtp(otp);
              }}
              inputType="number"
              numInputs={4}
              containerStyle={`otp-fields ${
                (otpErrorMessage || otpLoginError) && "otp-error-fields"
              }`}
              renderInput={(props) => (
                <input 
                  {...props}
                  disabled={limitExceededError}
                  onKeyDown={(e) => ""}
                />
              )}
            />
            {(otpErrorMessage || otpLoginError) && (
              <div className="otp-error">
                {otpErrorMessage || otpLoginError}
              </div>
            )}
            <div className="extra-options d-flex justify-content-between mb-2">
              <button
                disabled={
                  limitExceededError ||
                  phoneCodeLimitCounter > 3 ||
                  emailCodeLimitCounter > 3
                }
                onClick={() => handleGenerateNewCode()}
                type="button"
              >
                Send new code
              </button>
              <button onClick={handleChangeEmailOrPhone}>
                {isEmailCode ? "Change email" : "Change phone number"}
              </button>
            </div>

            {newCodeAlert &&
              !limitExceededError &&
              (isEmailCode
                ? emailCodeLimitCounter < 4
                : phoneCodeLimitCounter < 4) && (
                <span className="new-code-alert">
                  Your new code has been successfully sent to your provided{" "}
                  {isEmailCode ? "email" : "phone number"}.
                </span>
              )}

            <div className="actions">
              <Button
                type="button"
                color="secondary"
                fullWidth={true}
                disabled={limitExceededError || otp.length < 4}
                onClick={() => handleSubmitOtp()}
              >
                Submit
              </Button>
            </div>
          </div>

          {(limitExceededError ||
            phoneCodeLimitCounter > 3 ||
            emailCodeLimitCounter > 3) && (
            <div className="limit-exceeded-error">
              <span>
                We’re sorry you’re having trouble verifying your{" "}
                {isEmailCode ? "email" : "number"}.
              </span>
              {isLoginOtpModal ? (
                <>
                  <span>
                    {" "}
                    Let’s have you try logging in using an alternative method.{" "}
                  </span>
                  <Button
                    classes=""
                    fullWidth={true}
                    onClick={() => {
                      setOpenOtpModal(false);
                      setLimitExceededError(false);
                      setPhoneCodeLimitCounter(0);
                      setPhoneNumber("");
                      setNewCodeAlert(false);
                      navigate("/sign-in", { state: { loginWithEmail: true }, replace: true });
                    }}
                  >
                    Continue
                  </Button>
                  <span className="sign-up-text">
                    Don’t have an account?{" "}
                    <button
                      className="sign-up-btn"
                      onClick={() => navigate("/sign-up")}
                    >
                      Sign up!
                    </button>
                  </span>
                </>
              ) : (
                <>
                  <span>
                    {" "}
                    Let’s continue with your visit for now, but later on we’ll
                    need you to upload a government-issued ID
                  </span>
                  {!isProfileModal && (
                    <Button
                      classes=""
                      fullWidth={true}
                      onClick={() => PatientInfoNextStep(cart.id)}
                    >
                      Continue with visit
                    </Button>
                  )}
                </>
              )}
            </div>
          )}
          <div className="website-support">
            {phoneNumber ? (
              <span>Having Trouble Signing In? Call </span>
            ) : (
              <span>Website Support:</span>
            )}
            <a href="tel:(844) 745-3362">(844) 745-3362</a>
          </div>
        </Modal.Body>
      </Modal.Dialog>
    </Modal>
  );
};

export default OtpModal;
