import React from "react";

const BloodPressureText = () => (
  <>
    <h3>What was your last blood pressure reading?</h3>
    <p>
      It is essential that you are accurate and honest about your blood
      pressure. When used improperly these medications can cause serious harm,
      or death.
    </p>
    <p className="sample-reading-text">Sample reading</p>
    <div className="sample-reading-box">
      <div className="box">
        <div className="box-row">
          <div className="col text-center text-md-left">
            <p>
              <strong>Top #</strong>
            </p>
          </div>
          <div className="col text-center reading">
            <h1>
              <strong>120</strong>
            </h1>
            <p>mmHg</p>
          </div>
          <div className="col text-center text-md-right type">
            <p>Systolic</p>
          </div>
        </div>
        <div className="box-row">
          <div className="col text-center text-md-left">
            <p>
              <strong>Bottom #</strong>
            </p>
          </div>
          <div className="col text-center reading">
            <h1>
              <strong>80</strong>
            </h1>
            <p>mmHg</p>
          </div>
          <div className="col text-center text-md-right type">
            <p>Diastolic</p>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default BloodPressureText;
