import React from "react";

import IMask from "imask";
import { IMaskInput } from "react-imask";

const ExpirationDateInput = (props) => {
  const { label, icon, customClass, onChange, inputClasses, ...attrs } = props;

  const haveIcon = icon ? "input with-icon" : "input";

  const dateMask = {
    mask: "MM/YY",
    blocks: {
      MM: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
      },
      YY: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 99,
      },
    },
  };

  return (
    <div className={`form-group text-field-wrapper ${customClass}`}>
      {label ? (
        <label className="input-label" htmlFor={attrs.id}>
          {window.innerWidth >= 768 ? label : "Exp Date"}
        </label>
      ) : null}
      <div className={`input-group row mx-0`}>
        <IMaskInput
          className={`no-mouseflow mouseflow-hidden ${haveIcon} ${inputClasses}`}
          mask={dateMask.mask}
          blocks={dateMask.blocks}
          onAccept={onChange}
          {...attrs}
        />
        {icon ? <span className={`input__icon ${icon}`} /> : null}
      </div>
    </div>
  );
};

export default ExpirationDateInput;
