import { calculateDiscount } from "./DiscountCalculator";
import { minBy, maxBy, first } from "lodash";

const calculatePrices = (variant: any, quantity: any) => {
  const baseThreshold = minBy(
    variant.price_thresholds,
    (thd: any) => thd.min_quantity
  );

  const basePrice: any = baseThreshold
    ? Number(baseThreshold.price * quantity).toFixed(2)
    : 0.0;

  const possibleThresholds = variant.price_thresholds.filter(
    (thd: any) => quantity >= thd.min_quantity
  );
  const threshold = maxBy(possibleThresholds, (thd: any) => thd.min_quantity);

  const price: any = threshold
    ? Number(threshold.price * quantity).toFixed(2)
    : 0.0;

  const priceDifference: any = Number(basePrice - price).toFixed(2);
  const discount = Math.round((priceDifference / basePrice) * 100) || 0;

  return {
    basePrice,
    price,
    priceDifference,
    discount,
  };
};

const showMinQuantityInfo = (variant: any, cart: any) =>
  variant && cart && cart.quantity < variant.min_order_quantity;

const isPickUpAvailable = (context: any, shippingAddresses: any) => {
  const shippingAddress = shippingAddresses.find(
    (address: any) => address.id === context.shipping_address_id
  );

  if (shippingAddress) {
    return shippingAddress.pick_up_available;
  } else {
    return null;
  }
};

const getDiscountPercentage = (
  discountPercentage: any,
  calculatedDiscount: any
) => {
  return discountPercentage > calculatedDiscount
    ? discountPercentage
    : calculatedDiscount;
};

const evaluateDiscount = (
  discountPercentage: any,
  current_variant: any,
  opt: any
) => {
  const defaultDiscount = getDiscountPercentage(
    discountPercentage,
    calculateDiscount(current_variant, opt).discount
  );
  let percentage = null;

  if (defaultDiscount > 0) {
    percentage = defaultDiscount;
  }
  return percentage;
};

const evaluateDiscountedPercentage = (
  discountCart: any,
  orderCartsTotalPrice: number,
  totalPrice: number
) => {
  const discount: { type: string } | undefined = first(discountCart.discounts);

  if (discount?.type === "delivery_percentage") {
    const deliveryPrice = parseFloat(discountCart.delivery_price);
    const finalDeliveryPrice = parseFloat(discountCart.final_delivery_price);

    return (1.0 - finalDeliveryPrice / deliveryPrice) * 100;
  }

  return (1.0 - totalPrice / orderCartsTotalPrice) * 100;
};

export {
  calculatePrices,
  showMinQuantityInfo,
  isPickUpAvailable,
  getDiscountPercentage,
  evaluateDiscount,
  evaluateDiscountedPercentage,
};
