import React from "react";

import CheckboxOption from "./CheckboxOption";
import RadioOption from "./RadioOption";
import TextOption from "./TextOption";

type AnswerOptionProps = {
  question: any;
  selectedAnswers: [];
  setSelectedAnswers: any;
  option: any;
  questionType: any;
  setAllowText: any;
  userResponses: any;
  questionsLength: any;
  extraText: any;
  setExtraText: any;
  setAllowAllergy: any;
  activeQuestion: any;
  setUserAction: any;
  setShowContinueButton: any;
  setContinueButtonDisable: any;
  setShowSideEffectText: any;
  setSideEffectsQuestionLabel: any;
  badge: any;
  handleContinue: any;
  textAreaBorder: any;
  questionId: any;
  showSideEffects?: any;
  setShowSideEffects?: any;
  activeSubQuestions?: any;
};

const AnswerOption = ({
  selectedAnswers,
  setSelectedAnswers,
  option,
  question,
  questionType,
  setAllowText,
  userResponses,
  questionsLength,
  extraText,
  setExtraText,
  setAllowAllergy,
  activeQuestion,
  setShowSideEffectText,
  setSideEffectsQuestionLabel,
  badge,
  handleContinue,
  setUserAction,
  setShowContinueButton,
  textAreaBorder,
  setContinueButtonDisable,
  questionId,
  showSideEffects,
  setShowSideEffects,
  activeSubQuestions,
}: AnswerOptionProps) => {
  const optionIsSelected = () => {
    return selectedAnswers?.filter((sa: any) => sa?.id === option?.id).length;
  };

  const typeIsTextBased = () => {
    return (
      questionType === "text" ||
      questionType === "textfield" ||
      questionType === "textfield_disabled"
    );
  };

  return (
    <>
      {questionType === "multi" && (
        <CheckboxOption
          selectedAnswers={selectedAnswers}
          setSelectedAnswers={setSelectedAnswers}
          option={option}
          questionType={questionType}
          optionIsSelected={optionIsSelected}
          userResponses={userResponses}
          activeQuestion={activeQuestion}
          questionsLength={questionsLength}
          setUserAction={setUserAction}
          setShowContinueButton={setShowContinueButton}
          setAllowText={setAllowText}
          extraText={extraText}
          setExtraText={setExtraText}
          textAreaBorder={textAreaBorder}
          setContinueButtonDisable={setContinueButtonDisable}
          question={question}
        />
      )}
      {questionType === "radio" && (
        <RadioOption
          selectedAnswers={selectedAnswers}
          setSelectedAnswers={setSelectedAnswers}
          option={option}
          question={question}
          questionType={questionType}
          optionIsSelected={optionIsSelected}
          setAllowText={setAllowText}
          userResponses={userResponses}
          questionsLength={questionsLength}
          setAllowAllergy={setAllowAllergy}
          setShowSideEffectText={setShowSideEffectText}
          setSideEffectsQuestionLabel={setSideEffectsQuestionLabel}
          badge={badge}
          handleContinue={handleContinue}
          setUserAction={setUserAction}
          setContinueButtonDisable={setContinueButtonDisable}
          textAreaBorder={textAreaBorder}
          setShowContinueButton={setShowContinueButton}
          showSideEffects={showSideEffects}
          setShowSideEffects={setShowSideEffects}
          activeSubQuestions={activeSubQuestions}
        />
      )}
      {typeIsTextBased() && (
        <TextOption
          selectedAnswers={selectedAnswers}
          setSelectedAnswers={setSelectedAnswers}
          option={option}
          userResponses={userResponses}
          extraText={extraText}
          setExtraText={setExtraText}
          questionType={questionType}
          extraLabel={option?.extra_label}
          textAreaBorder={textAreaBorder}
          setContinueButtonDisable={setContinueButtonDisable}
          questionId={question ? question.id : activeQuestion.id}
        />
      )}
    </>
  );
};

export default AnswerOption;
