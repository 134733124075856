import React from 'react';
import { ReactSVG } from 'react-svg';
import { isEmpty } from 'lodash';
import { CreditCardType } from '../../../types';
import dayjs from 'dayjs';

import greenCheck from "assets/images/checkout/check-mark-green.svg";
import greyCheck from "assets/images/checkout/check-mark-grey.svg";

type Props = {
  creditCard: CreditCardType;
  onSelect?: any;
  selected?: boolean;
}

const SelectableCardItem = ({ creditCard, onSelect, selected }: Props) => {
  const isNotExpired = dayjs().isBefore(creditCard.expiration_date, 'month');
  return (
    <li
      className={`credit-card-item ${creditCard.default_payment_profile && "default"} ${selected && "selected"} ${!isNotExpired && "isDateExpired"}`}
      onMouseDown={() => onSelect(creditCard.payment_profile_id)}
    >
      <div className={`credit-card-type ${creditCard.default_payment_profile && "default"}`}>
        <span className={`card-img ${(creditCard.card_type || 'Discover').toLowerCase()}`} />
      </div>
      <div className="credit-card-number no-mouseflow mouseflow-hidden">
        {creditCard.card_number}
      </div>
      <div className={`credit-card-expiration no-mouseflow mouseflow-hidden ${!isNotExpired && "isExpired"}`}>
        {isNotExpired ? (
          `${creditCard.expiration_date.split('-')[1]}/${creditCard.expiration_date.split('-')[0]}`
        ):(
          "expired"
        )
        }
      </div>
      <div>
        {selected ? (
          <img src={greenCheck} className="checkmark-icon" />
        ):(
          <img src={greyCheck} className="checkmark-icon" />
        )}
      </div>
    </li>
  );
}

export default SelectableCardItem;
