import React, { useEffect, useState } from "react";
import { filter, find } from "lodash";

import MultipleQuestionItem from "./MultipleQuestionItem";
import BloodPressureText from "./BloodPressureText";

type Props = {
  selectedAnswers: any;
  setSelectedAnswers: any;
  questions: any;
  allowText: any;
  setAllowText: any;
  extraText: any;
  setExtraText: any;
  allowAllergy: any;
  setAllowAllergy: any;
  allowMed: any;
  setAllowMed: any;
  isProcessing: any;
  setIsProcessing: any;
  queriedMeds: any;
  setQueriedMeds: any;
  addedMeds: any;
  setAddedMeds: any;
  errors: any;
  setErrors: any;
  questionRenderType: any;
  subQuestions: any;
  setSubQuestions: any;
  overrideMultiDisplay: any;
  setOverrideMultiDisplay: any;
  activeSubQuestions: any;
  showSideEffectText: any;
  setShowSideEffectText: any;
  sideEffectsQuestionLabel: any;
  setSideEffectsQuestionLabel: any;
  setUserAction: any;
  setShowContinueButton: any;
  setContinueButtonDisable: any;
  textAreaBorder: any;
};

const MultipleQuestions = ({
  selectedAnswers,
  setSelectedAnswers,
  questions,
  allowText,
  setAllowText,
  extraText,
  setExtraText,
  allowAllergy,
  setAllowAllergy,
  allowMed,
  setAllowMed,
  isProcessing,
  setIsProcessing,
  queriedMeds,
  setQueriedMeds,
  addedMeds,
  setAddedMeds,
  errors,
  setErrors,
  questionRenderType,
  subQuestions,
  setSubQuestions,
  overrideMultiDisplay,
  setOverrideMultiDisplay,
  activeSubQuestions,
  showSideEffectText,
  setShowSideEffectText,
  sideEffectsQuestionLabel,
  setSideEffectsQuestionLabel,
  setUserAction,
  setShowContinueButton,
  setContinueButtonDisable,
  textAreaBorder,
}: Props) => {
  const [showSideEffects, setShowSideEffects] = useState<boolean>(false);

  const filteredQuestions = () => {
    let subQuestions = questions.filter((q: any, idx: number) => {
      if (idx > 0) {
        return q.question_id === questions[0].id;
      }
    });

    return questions[0].label !== "16.01" ? subQuestions : questions;
  };

  const subTextQuestions = () => {
    let parentSubQuestion = activeSubQuestions[0]?.id;
    let activeQuestionSubQuestions = filter(activeSubQuestions, [
      "question_id",
      parentSubQuestion,
    ]);

    let textQuestionsIds = filter(activeQuestionSubQuestions, [
      "question_type",
      "text",
    ]).map((sub) => {
      return sub.id;
    });

    let radioQuestionsIds = filter(activeQuestionSubQuestions, [
      "question_type",
      "radio",
    ]).map((sub) => {
      return sub.id;
    });

    let selectedTextQuestions = textQuestionsIds.map((c) => {
      return find(selectedAnswers, ["question_id", c]);
    });

    let selectedRadioQuestions = radioQuestionsIds.map((c) => {
      return find(selectedAnswers, ["question_id", c]);
    });

    selectedTextQuestions = selectedTextQuestions.filter(Boolean);
    selectedRadioQuestions = selectedRadioQuestions.filter(Boolean);
    let emptyTextAreaSelectedAnswer = find(selectedTextQuestions, [
      "text_value",
      "",
    ]);
    let extraTextAreaSubQuestion = filter(selectedRadioQuestions, [
      "allow_text",
      true,
    ]);

    selectedTextQuestions?.length === textQuestionsIds?.length &&
    selectedRadioQuestions?.length === radioQuestionsIds?.length &&
    (extraTextAreaSubQuestion.length
      ? Boolean(extraTextAreaSubQuestion[0].text_value?.length)
      : true) &&
    !emptyTextAreaSelectedAnswer
      ? setContinueButtonDisable(false)
      : setContinueButtonDisable(true);
  };

  useEffect(() => {
    subTextQuestions();
  });

  const renderSideEffects = (question: any) => {
    const sideEffectQuestion = find(activeSubQuestions, [
      "data_type",
      "side_effects",
    ]);
    const shouldShowSideEffects =
      find(selectedAnswers, ["question_id", sideEffectQuestion?.id])?.value ===
      "1";

    if (
      question.data_type === "side_effects_options" &&
      !shouldShowSideEffects
    ) {
      return !showSideEffects ? "d-none" : "";
    }
  };

  return (
    <>
      {filteredQuestions()[0]?.label === "8.01.01" && <BloodPressureText />}
      {overrideMultiDisplay
        ? activeSubQuestions?.map((question: any, idx: number) => (
            <div key={idx} className={`${renderSideEffects(question)}`}>
              <MultipleQuestionItem
                index={idx}
                question={question}
                selectedAnswers={selectedAnswers}
                setSelectedAnswers={setSelectedAnswers}
                questions={questions}
                activeSubQuestions={activeSubQuestions}
                allowText={allowText}
                setAllowText={setAllowText}
                extraText={extraText}
                setExtraText={setExtraText}
                allowAllergy={allowAllergy}
                setAllowAllergy={setAllowAllergy}
                allowMed={allowMed}
                setAllowMed={setAllowMed}
                isProcessing={isProcessing}
                setIsProcessing={setIsProcessing}
                queriedMeds={queriedMeds}
                setQueriedMeds={setQueriedMeds}
                addedMeds={addedMeds}
                setAddedMeds={setAddedMeds}
                errors={errors}
                setErrors={setErrors}
                subQuestions={subQuestions}
                setSubQuestions={setSubQuestions}
                questionRenderType={questionRenderType}
                overrideMultiDisplay={overrideMultiDisplay}
                setOverrideMultiDisplay={setOverrideMultiDisplay}
                showSideEffectText={showSideEffectText}
                setShowSideEffectText={setShowSideEffectText}
                sideEffectsQuestionLabel={sideEffectsQuestionLabel}
                setSideEffectsQuestionLabel={setSideEffectsQuestionLabel}
                setUserAction={setUserAction}
                setShowContinueButton={setShowContinueButton}
                setContinueButtonDisable={setContinueButtonDisable}
                textAreaBorder={textAreaBorder}
                showSideEffects={showSideEffects}
                setShowSideEffects={setShowSideEffects}
              />
            </div>
          ))
        : filteredQuestions()?.map((question: any, idx: number) => (
            <div key={idx} className={`${renderSideEffects(question)}`}>
              <MultipleQuestionItem
                index={idx}
                question={question}
                selectedAnswers={selectedAnswers}
                setSelectedAnswers={setSelectedAnswers}
                questions={questions}
                allowText={allowText}
                setAllowText={setAllowText}
                extraText={extraText}
                setExtraText={setExtraText}
                allowAllergy={allowAllergy}
                setAllowAllergy={setAllowAllergy}
                allowMed={allowMed}
                setAllowMed={setAllowMed}
                isProcessing={isProcessing}
                setIsProcessing={setIsProcessing}
                queriedMeds={queriedMeds}
                setQueriedMeds={setQueriedMeds}
                addedMeds={addedMeds}
                setAddedMeds={setAddedMeds}
                errors={errors}
                setErrors={setErrors}
                subQuestions={subQuestions}
                setSubQuestions={setSubQuestions}
                questionRenderType={questionRenderType}
                overrideMultiDisplay={overrideMultiDisplay}
                setOverrideMultiDisplay={setOverrideMultiDisplay}
                setUserAction={setUserAction}
                setShowContinueButton={setShowContinueButton}
                setContinueButtonDisable={setContinueButtonDisable}
                showSideEffectText={null}
                setShowSideEffectText={null}
                sideEffectsQuestionLabel={null}
                setSideEffectsQuestionLabel={null}
                textAreaBorder={null}
                showSideEffects={showSideEffects}
                setShowSideEffects={setShowSideEffects}
              />
            </div>
          ))}
    </>
  );
};

export default MultipleQuestions;
