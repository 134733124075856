import React from "react";
import "./styles.scss";

type Props = {
  color?: "primary" | "secondary" | "ghost" | "white";
  outline?: boolean;
  classes?: string;
  disabled?: true | false;
  id?: string;
  size?: "small" | "medium" | "large";
  type?: "button" | "submit" | "reset";
  fullWidth?: Boolean;
  children: any;
  dataAttribute?: string;
  section?: string;
  name?: string;
  modalDismiss?: string;
  onClick?: () => void;
  onMouseDown?: () => void;
};

const Button = ({
  children,
  color,
  outline = false,
  disabled = false,
  size,
  fullWidth = false,
  type = "button",
  id,
  dataAttribute = "",
  section,
  classes,
  modalDismiss,
  onClick,
  onMouseDown,
}: Props) => {
  return (
    <button
      type={type}
      id={id}
      disabled={disabled}
      className={`btn-aum text-uppercase ${section ?? ""} ${size ?? ""} ${color ?? ""} ${
        classes ?? ""
      } ${outline && "outline"} ${fullWidth && "wide"}`}
      data-ref={dataAttribute}
      onClick={onClick}
      onMouseDown={onMouseDown}
      data-dismiss={modalDismiss}>
      {children}
    </button>
  );
};

export default Button;
