import React, { useContext, useState } from "react";
import { AuthContext } from "Contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { SsnVerificationRequest } from "apis";
import { useRollbar } from "@rollbar/react";
import Modal from "react-bootstrap/Modal";
import OtpInput from "react-otp-input";
import useCurrentUser from "hooks/useCurrentUser";
import Button from "components/Button";
import ModalLoader from "./ModalLoader";
import modalCloseIcon from "assets/images/controls/modal-close.svg";
import { SsnVisitContinueRequest }  from "apis";


type Props = {
  openSsnModal: boolean;
  setOpenSsnModal: any;
  cart?: any;
  limitExceededError?: boolean;
  setLimitExceededError?: any;
  setSelectedOption: any;
};

const SsnModal = ({
  openSsnModal,
  setOpenSsnModal,
  cart,
  limitExceededError,
  setLimitExceededError,
  setSelectedOption,
}: Props) => {
  const [ssnErrorMessage, setSsnErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [ssn, setSsn] = useState<string>("");
  const { reloadUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmitSsn = () => {
    setIsLoading(true);
    SsnVerificationRequest(ssn, cart?.id)
      .then((res) => {
        if (res.data.status.plaid_status) {
          navigate(`/${res.data.redirect_path}`);
          setIsLoading(false);
          setSsn("");
          setSsnErrorMessage("");
          setOpenSsnModal(false);
        } else {
          setSsn("");
          setIsLoading(false);
          setSsnErrorMessage("The ssn number you entered is invalid");
          if (res.data.status.limit_exceeded) {
            setLimitExceededError(res.data.status.limit_exceeded);
            setSsnErrorMessage("");
            setSelectedOption("id_review");
          }
        }
      })
      .catch((err) => {
        setSsnErrorMessage(err?.response?.data?.limit_exceeded);
        setIsLoading(false);
        console.log(err);
      })
  };

  const handleContinueVisit = () => {
    SsnVisitContinueRequest(cart?.id)
    .then((res) => {
      reloadUser();
      navigate(`/${res?.data?.redirect_path}`)
    })
    .catch((err) => {
      console.log(err);
    })
  }

  const handleModalClose = () => {
    setOpenSsnModal(false);
    setSsn("");
    setSsnErrorMessage("");
  };

  return (
    <Modal
      centered
      className="otp-modal"
      id="otp-modal"
      show={openSsnModal}
      aria-labelledby="contained-modal-title-vcenter"
      onHide={!isLoading ? handleModalClose : undefined}
      animation={false}
      >
      <ModalLoader isLoading={isLoading} setIsLoading={setIsLoading} />
      <Modal.Dialog>
        <Modal.Header>
        <button className="ml-auto" onClick={handleModalClose}>
            <img src={modalCloseIcon} alt="" />
          </button>
        </Modal.Header>
        <Modal.Body>
        {!limitExceededError && (
          <>
            <div className="mb-2 mt-2">
                <h5 className="modal-title">Please Provide Your SSN Number</h5>
                <p className="modal-text">
                  Verifying your identity is crucial to ensuring the right person
                  receives the right medical advice and treatment. We apologize for
                  any inconvenience and appreciate your cooperation.
                </p>
                <h2 className="ssn-modal-headding">Input the last 4 of your Social Security Number:</h2>
              </div>
              <div className="modal-actions d-flex flex-column mt-1">
                  <OtpInput
                    value={ssn}
                    onChange={(ssn) => {
                      setSsnErrorMessage("");
                      setSsn(ssn);
                    } }
                    inputType="number"
                    numInputs={4}
                    containerStyle={`otp-fields ${ssnErrorMessage && "otp-error-fields"}`}
                    renderInput={(props) => (
                      <input
                        {...props}
                        disabled={limitExceededError}
                        onKeyDown={(e) => ""}
                      />
                    )} />
                  {ssnErrorMessage && (
                    <div className="otp-error">{ssnErrorMessage}</div>
                  )}
                  <div className="actions modal-submit">
                    <Button
                      type="button"
                      color="secondary"
                      fullWidth={true}
                      disabled={limitExceededError || ssn.length < 4}
                      onClick={() => handleSubmitSsn()}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </>
          )}

          {limitExceededError && (
            <div className="limit-exceeded-error mt-3">
              <span>
                We’re sorry you’re having trouble verifying your SSN.
              </span>
              <span>
                {" "}
                Let’s continue with your visit for now, but later on we’ll need
                you to upload a government-issued ID
              </span>
              <Button
                classes=""
                fullWidth={true}
                onClick={() => handleContinueVisit()}
              >
                Continue with visit
              </Button>
            </div>
          )}
          <div className="website-support">
            <span>Website Support:</span>
            <a href="tel:(844) 745-3362">(844) 745-3362</a>
          </div>
        </Modal.Body>
      </Modal.Dialog>
    </Modal>
  );
};

export default SsnModal;
