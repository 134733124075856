import React from "react";
import { isEmpty } from "lodash";

const PharmacyRequest = (props: any) => {
  const { cart } = props;

  return (
    <div className="account-card__status">
      <h3>Order Status</h3>
      {!isEmpty(cart) &&
        (cart.faxes_count > 0) &&
        <p>
          {`${cart?.faxes_count} Prescription Request: ${cart?.sent_or_failed_faxes[0].created_at}`}
        </p>
      }
      {
        cart?.user_fax_status === "not_received" &&
        <React.Fragment>
          <p>There was a problem sending the fax.</p>
          <p className="info">
            It is important that you contact your doctor's office to confirm the fax was received.
          </p>
        </React.Fragment>
      }
      {!isEmpty(cart) &&
        <address>
          Dr. {cart.doctor?.first_name}{" "}{cart.doctor?.last_name}
          <br /> Phone: {cart?.doctor?.phone}
          <br />
          Fax: {cart?.doctor?.fax}
        </address>
      }
      {/* TODO fax send status FaxSendStatus cart={cart} */}
    </div>
  )
};

export default PharmacyRequest;
