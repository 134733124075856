import React, { useState } from "react";
import { startCase, isEmpty } from "lodash";
import DayJS from "dayjs";

import PharmacyModal from "./PharmacyModal";
import MedicationDetailsModal from "./MedicationDetailsModal";
import Statuses from "./Statuses";
import MiniBottleImage from "../../../assets/images/account/mini-bottle.png";
import TadalafilMiniBottleImage from "../../../assets/images/account/mini-bottle-tadalafil.png";
import Button from "../../../components/Button";
import useCurrentUser from "hooks/useCurrentUser";

const VisitDetail = ({ visit, removeActiveVisit }: any) => {
  const [showPharmacyModal, setShowPharmacyModal] = useState(false);
  const [showMedicationDetailsModal, setShowMedicationDetailsModal] =
    useState(false);
  const currentUser = useCurrentUser();
  const formatMedicationName = (name: string) => {
    if (!isEmpty(name)) {
      return name.match(/[S-s]ildenafil/) ? "Sildenafil" : name;
    }
  };

  return (
    <>
      <div className="visit-detail-heading">
        <Statuses visit={visit} removeActiveVisit={removeActiveVisit} />
      </div>

      <div className="account-card">
        <div className="visit-details-box p-3 m-1 m-md-3">
          <div className="row flex-row">
            <div className="col-6 col-md-3">
              <p className="detail-heading">
                <strong>Visit Date</strong>
              </p>
              <p>{DayJS(visit.created_at).format("MM/DD/YY")}</p>
            </div>
            <div className="col-6 col-md-3">
              <p className="detail-heading">
                <strong>Visit Type</strong>
              </p>
              <p>{startCase(visit.visit_type?.split("_")?.join(" "))}</p>
            </div>
            <div className="col-6 col-md-3">
              <p className="detail-heading">
                <strong>Cost</strong>
              </p>
              <p>${visit.cost}</p>
            </div>
            <div className="col-6 col-md-3">
              <p className="detail-heading">
                <strong>Visit #</strong>
              </p>
              <p>{visit.uniq_token}</p>
            </div>
          </div>
        </div>
        <div className="medication-info-container d-flex flex-column flex-md-row align-items-md-center mx-1 mx-md-3 mt-3">
          <div className="medication-info-wrapper d-flex align-items-center">
            <div className="img-wrapper">
              {formatMedicationName(visit?.medication_name) === "Sildenafil" ? (
                <img
                  src={MiniBottleImage}
                  alt="Medicine"
                  className="img-fluid"
                />
              ) : (
                <img
                  src={TadalafilMiniBottleImage}
                  alt="Medicine"
                  className="img-fluid"
                />
              )}
            </div>
            <div className="medication-info">
              <h6 className="mb-1">Medication Preference</h6>
              <p className="mb-0">
                {formatMedicationName(visit?.medication_name)}{" "}
                {visit?.medication_dosage}
              </p>
              <p className="mb-0">{visit?.medication_quantity}</p>
            </div>
          </div>
          {visit.pharmacy && visit.status === "completed" && (
            <div className="info-actions ml-md-auto mt-2 mt-md-0 d-flex">
              <Button
                classes="btn-outline btn-outline-white mr-2 mb-md-0"
                size="medium"
                onClick={() => setShowPharmacyModal(true)}>
                Pharmacy Info
              </Button>

              <Button
                classes="btn-outline btn-outline-white info-action-btn-tabletview"
                size="medium"
                onClick={() => setShowMedicationDetailsModal(true)}>
                Medication Info
              </Button>
            </div>
          )}
        </div>
      </div>
      {!currentUser?.can_show_photos &&
      <div className="images-wrapper">
        <div className="image-box no-mouseflow mouseflow-hidden">
          {visit.id_card_url ? (
            <img src={visit.id_card_url} alt="ID Card" className="img-fluid" />
          ) : (
            "ID Skipped"
          )}
        </div>
        <div className="image-box no-mouseflow mouseflow-hidden">
          {visit.selfie_url ? (
            <img src={visit.selfie_url} alt="Selfie" className="img-fluid" />
          ) : (
            "Selfie Skipped"
          )}
        </div>
      </div> }

      <PharmacyModal
        pharmacy={visit.pharmacy}
        showPharmacyModal={showPharmacyModal}
        setShowPharmacyModal={setShowPharmacyModal}
      />
      <MedicationDetailsModal
        medication={visit.medication_name}
        showMedicationDetailsModal={showMedicationDetailsModal}
        setShowMedicationDetailsModal={setShowMedicationDetailsModal}
      />
    </>
  );
};

export default VisitDetail;
