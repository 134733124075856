import React, { useContext } from "react";
import { Formik, Form, Field } from "formik";
import useCurrentUser from "../../hooks/useCurrentUser";
import Button from "../../components/Button";
import { AuthContext } from "../../Contexts/AuthContext";
import { isEmpty } from "lodash";

const Notifications = () => {
  const { UpdateUser } = useContext(AuthContext);
  const currentUser = useCurrentUser();

  return (
    <div className="account-card padded">
      <Formik
        initialValues={{
          phone_contact_allowed: currentUser?.phone_contact_allowed || false,
          drugs_names_included: currentUser?.drugs_names_included || false,
        }}
        onSubmit={(values) => {
          UpdateUser(values);
        }}
      >
        {({ values, handleChange }) => (
          <>
            <p>
              Received
              <strong> SMS Notifications</strong>:
            </p>
            {isEmpty(currentUser?.mobile_phone) && (
              <p className="text-warning">
                To accept SMS notifications please provide your mobile phone
                number.
              </p>
            )}
            <Form className="notifications">
              <div className="form-group checkout-option">
                <Field type="checkbox" name="phone_contact_allowed">
                  {({ field }: any) => {
                    return (
                      <>
                        <input
                          className="notifications-checkout-option__input"
                          type="checkbox"
                          id="phone-contacts"
                          {...field}
                        />
                        <label
                          className="notifications-checkout-option__label label__checkbox"
                          htmlFor="phone-contacts"
                        >
                          I agree to receive SMS (text messages) from{" "}
                          {process.env.REACT_APP_UAT_SITE} and it’s partner
                          pharmacy. We'll notify you with important updates to
                          your order and refill reminders.
                        </label>
                      </>
                    );
                  }}
                </Field>
              </div>

              <div className="form-group checkout-option">
                <Field type="checkbox" name="drugs_names_included">
                  {({ field }: any) => {
                    return (
                      <>
                        <input
                          className="notifications-checkout-option__input"
                          type="checkbox"
                          id="include-drugs"
                          {...field}
                        />
                        <label
                          className="notifications-checkout-option__label label__checkbox"
                          htmlFor="include-drugs"
                        >
                          Include medication names in email and SMS from{" "}
                          {process.env.REACT_APP_UAT_SITE} and it’s partner
                          pharmacy (recommended)
                        </label>
                      </>
                    );
                  }}
                </Field>
              </div>
              <div className="actions">
                <Button
                  type="submit"
                  color="primary"
                  classes="ghost"
                  size="small"
                >
                  SAVE
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default Notifications;
