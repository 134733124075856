import React, { useState, useContext, useEffect } from "react";
import { CartContext } from "Contexts/CartContext";
import { isEmpty, find } from "lodash";
import Translator from "components/Translator";

import uspsSvg from "assets/images/checkout/usps.svg";
import { OrderUpdateRequest } from "apis";
import { AuthContext } from "Contexts/AuthContext";
import { OrderContext } from "Contexts/OrderContext";

type DeliveryPickerProps = {
  options: any;
  cart: any;
};

const DeliveryPicker = ({ cart, options }: DeliveryPickerProps) => {
  const [selectedOption, setSelectedOption] = useState<any>({});
  const [errors, setErrors] = useState<any>([]);
  const [deliveryOptions, setDeliveryOptions] = useState<[]>([]);

  const { UpdateCart } = useContext(CartContext);
  const { reloadUser } = useContext(AuthContext);
  const { setCurrentOrder } = useContext(OrderContext);

  useEffect(() => {
    if (!isEmpty(cart)) {
      const pickUpAvailable = cart.shipping_address
        ? cart.shipping_address.pick_up_available
        : false;
      // TODO: maybe we should take these calculations to server side
      options?.map((option: any, index: number) => {
        let returningOption: any;

        switch (option.delivery_type) {
          case "standard":
            returningOption = options[index].price_value =
              cart.standard_delivery_price;
            break;
          case "express":
            returningOption = options[index].price_value =
              cart.express_delivery_price;
            break;
        }

        return returningOption;
      });

      if (pickUpAvailable) {
        setDeliveryOptions(options);
      } else {
        setDeliveryOptions(
          options?.filter((option: any) => option.delivery_type !== "personal")
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  useEffect(() => {
    if (!isEmpty(deliveryOptions) && !isEmpty(cart?.delivery_type)) {
      const defaultOption = find(deliveryOptions, {
        delivery_type: cart.delivery_type,
      });

      setSelectedOption(defaultOption);
    }
  }, [cart, deliveryOptions]);

  const handleDeliveryChange = (e: any) => {
    const deliveryType = e.target.value;

    if (cart.order_id) {
      const values = {
        orderId: cart.order_id,
        deliveryType: e.target.value,
        addressId: undefined,
      };
      OrderUpdateRequest(values)
        .then((res) => {
          reloadUser();
          setCurrentOrder(res.data.order);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      UpdateCart({
        id: cart.id,
        delivery_type: deliveryType,
      });
    }
  };

  const formatPriceValue = (option: any) =>
    Number(option.price_value) ? (
      `$${Number(option.price_value).toFixed(2)}`
    ) : (
      <Translator path="checkout.deliveryPicker.free" />
    );

  return (
    <div className="delivery-picker">
      {!isEmpty(deliveryOptions) &&
        deliveryOptions.map((option: any) => (
          <div className="checkout-option" key={option.id}>
            <input
              type="radio"
              id={`delivery-${option.delivery_type}`}
              name="delivery"
              value={option.delivery_type}
              className="checkout-option__input"
              onChange={handleDeliveryChange}
              defaultChecked={cart.delivery_type === option.delivery_type}
            />
            <label
              htmlFor={`delivery-${option.delivery_type}`}
              className="checkout-option__label"
            >
              <img src={uspsSvg} alt="" className="mr-3" />
              <div className="d-flex justify-content-between w-100">
                <span className="checkout-option__title">
                  <span className="font-weight-600">{option.label_info}</span>{" "}
                  <span>{option.delivery_days_label}</span>
                </span>
                <span className="font-weight-600">
                  {formatPriceValue(option)}
                </span>
              </div>
            </label>
          </div>
        ))}
    </div>
  );
};

export default DeliveryPicker;
