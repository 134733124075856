import { filter, find, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";

type Props = {
  selectedAnswers: [];
  setSelectedAnswers: any;
  option: any;
  question: any;
  questionType: any;
  setAllowText: any;
  questionsLength: any;
  setAllowAllergy: any;
  setShowSideEffectText: any;
  setSideEffectsQuestionLabel: any;
  badge: any;
  handleContinue: any;
  setUserAction: any;
  setShowContinueButton: any;
  setContinueButtonDisable: any;
  optionIsSelected: any;
  userResponses: any;
  textAreaBorder: any;
  showSideEffects?: any;
  setShowSideEffects?: any;
  activeSubQuestions?: any;
};

const RadioOption = ({
  selectedAnswers,
  setSelectedAnswers,
  option,
  question,
  questionType,
  setAllowText,
  questionsLength,
  setAllowAllergy,
  setShowSideEffectText,
  setSideEffectsQuestionLabel,
  badge,
  handleContinue,
  setUserAction,
  setShowContinueButton,
  setContinueButtonDisable,
  optionIsSelected,
  userResponses,
  textAreaBorder,
  showSideEffects,
  setShowSideEffects,
  activeSubQuestions,
}: Props) => {
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (question?.data_type === "side_effects") {
      const sideEffectsAnswer: any = find(selectedAnswers, [
        "question_id",
        question.id,
      ]);
      if (!isEmpty(sideEffectsAnswer)) {
        setShowSideEffects(sideEffectsAnswer?.value === "1");
      } else {
        setShowSideEffects(false);
      }
    }
  }, [question]);

  const filterAnswersDifferentFromLabel = (label: any, newAnswers: any) => {
    if (!isEmpty(newAnswers)) {
      return newAnswers.filter((sa: any) => sa.question_label !== label);
    } else {
      return selectedAnswers.filter((sa: any) => sa.question_label !== label);
    }
  };

  // For handling radio button interactions
  const handleRadioChange = (e: any, selectedOption: any) => {
    const nextQuestionHasExtraOptions =
      selectedOption?.allow_med ||
      selectedOption?.allow_allergy ||
      selectedOption?.allow_text;
    const shouldShowSideEffects =
      question?.data_type === "side_effects" && selectedOption?.value === "1";
    let newAnswers: any;

    if (
      question?.data_type === "side_effects" &&
      shouldShowSideEffects === false
    ) {
      const sideEffectOptionsQue = find(activeSubQuestions, [
        "data_type",
        "side_effects_options",
      ]);

      newAnswers = filter(
        selectedAnswers,
        (ans: any) => sideEffectOptionsQue?.id !== ans.question_id
      );
    }

    if (!isProcessing) {
      setIsProcessing(true);
      setUserAction("optionSelect");

      // Add exception for questionType null
      if (option.super_parent_question_type === null || questionsLength > 1) {
        if (e.target.checked) {
          // Keep the previously added option and only toggle the current one
          setSelectedAnswers([
            ...filterAnswersDifferentFromLabel(
              option.question_label,
              newAnswers
            ),
            selectedOption,
          ]);
        }
      } else {
        if (!isEmpty(newAnswers)) {
          setSelectedAnswers([selectedOption, ...newAnswers]);
        } else {
          setSelectedAnswers([selectedOption]);
        }
      }

      setAllowText(!!selectedOption.allow_text);
      setContinueButtonDisable(Boolean(selectedOption.allow_text));
      setAllowAllergy(option?.allow_allergy);

      // In case of a non-Q6 question
      if (
        setShowSideEffectText &&
        setSideEffectsQuestionLabel &&
        question.data_type === "side_effects"
      ) {
        setShowSideEffectText(!!selectedOption.allow_text);
        setSideEffectsQuestionLabel(selectedOption.question_label);
      }

      // this should only get called when there are continuous questions up ahead; only hides the option if necessary
      if (questionsLength > 1 || nextQuestionHasExtraOptions) {
        setShowContinueButton(
          nextQuestionHasExtraOptions ||
            option.super_parent_question_type === null
        );
      }
    }

    if (question?.data_type === "side_effects") {
      setShowSideEffects(shouldShowSideEffects);
    }

    setIsProcessing(false);
  };

  return (
    <div className="answer-option-wrapper">
      <input
        type="radio"
        name={`option_${option.question_id}_${questionType}`}
        id={`option_${option.id}`}
        value={option.value}
        checked={Boolean(
          selectedAnswers.filter((sa: any) => sa.id === option.id).length
        )}
        onChange={(e: any) => handleRadioChange(e, option)}
        onClick={(e: any) => handleRadioChange(e, option)}
      />
      <label className={`answer-option`} htmlFor={`option_${option.id}`}>
        {option.label}
        <span className={`badge badge-questionnaire badge-${badge} ml-auto`}>
          {badge}
        </span>
      </label>
    </div>
  );
};

export default RadioOption;
