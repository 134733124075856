import React, { useState, useContext, useEffect } from "react";
import { isEmpty, find, first } from "lodash";
import { CartContext, CartType } from "Contexts/CartContext";
import useCurrentUser from "hooks/useCurrentUser";
import useLatestCart from "hooks/useLatestCart";
import { AddressType } from "types";
import DeliveryPicker from "../Shared/DeliveryPicker";
import ChevronDown from "assets/images/home/chevron-down.svg";
import Button from "components/Button";
import DnaLoader from "components/DnaLoader";
import NewShippingAddressForm from "./NewShippingAddressForm";
import NewShippingAddressItem from "./NewShippingAddressItem";
import NewAddressTile from "./NewAddressTile";
import {
  FetchDeliveryOptonsRequest,
  OrderUpdateRequest,
  UpdateCartRequest,
} from "apis";
import { AuthContext } from "Contexts/AuthContext";
import { OrderContext } from "Contexts/OrderContext";
import { useLocation, useNavigate } from "react-router-dom";

import "./styles.scss";
import Translator from "components/Translator";
import useCurrentOrder from "hooks/useCurrentOrder";

type Props = {
  editMode?: boolean;
};

const Shipping = ({ editMode }: Props) => {
  const cart = useLatestCart();
  const user = useCurrentUser();
  const currentOrder = useCurrentOrder();
  const navigate = useNavigate();
  const { reloadUser } = useContext(AuthContext);
  const { ShippingNextStep, setLatestCart } = useContext(CartContext);
  const [isAddressFormDialogOpen, setIsAddressFormDialogOpen] =
    useState<boolean>(false);
  const [showSelectableAddresses, setShowSelectableAddresses] =
    useState<boolean>(false);
  const [selectedAddress, setSelectedAddress] = useState<any>();
  const [options, setOptions] = useState<[]>([]);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showNewAddressForm, setShowNewAddressForm] = useState<boolean>(false);
  const [selectableAddresses, setSelectableAddresses] =
    useState<any>(undefined);
  const [addressDropDownHeading, setAddressDropDownHeading] =
    useState<string>("+ Add new address");
  const [dataCart, setDataCart] = useState<any>();
  const { setCurrentOrder } = useContext(OrderContext);

  useEffect(() => {
    if (!isEmpty(cart)) {
      setDataCart(cart);
    } else if (isEmpty(cart) && !isEmpty(currentOrder)) {
      setDataCart(first(currentOrder.carts));
    }
  }, [cart, currentOrder]);

  const loadDeliveries = (allowSettingLoader = true) => {
    FetchDeliveryOptonsRequest()
      .then((res) => {
        setOptions(res.data.delivery_options);
        if (allowSettingLoader) {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (isEmpty(options)) {
      loadDeliveries();
    }
  }, [options]);

  const formatSelectedAddress = (address: AddressType) => {
    if (!isEmpty(address)) {
      return `${address?.street_1} ${address?.street_2}, ${address?.city}, ${address?.state}, ${address?.zip}`;
    }
  };

  useEffect(() => {
    if (!isEmpty(user?.shipping_addresses)) {
      setSelectableAddresses(user?.shipping_addresses);
      const cartAddress: any = find(user?.shipping_addresses, {
        id: dataCart?.shipping_address_id,
      });
      setSelectedAddress(cartAddress);
      const formatedAddrss: any = formatSelectedAddress(cartAddress);
      setAddressDropDownHeading(
        !isEmpty(formatedAddrss) ? formatedAddrss : "Select Address"
      );
    } else {
      setShowNewAddressForm(isEmpty(user?.shipping_addresses));
    }
  }, [dataCart]);

  if (isEmpty(currentOrder) && isEmpty(cart)) return <></>;

  const onSuccessfulSubmission = (
    address: AddressType,
    pickUpAvailAble: boolean
  ) => {
    if (!isEmpty(address)) {
      if (address.is_valid) {
        if (!pickUpAvailAble) {
          reloadUser().then(() => {
            setIsProcessing(true);
            handleUserReloadAndUpdateCart(dataCart?.id, address);
            setIsLoading(true);

            if (editMode) {
              navigate(
                `${
                  dataCart.order_id
                    ? "/order-shipping-confirmation"
                    : "/checkout/shipping-confirmation"
                }`
              );
            } else {
              ShippingNextStep(dataCart.id, resetLoader);
            }
          });
        } else if (pickUpAvailAble) {
          reloadUser().then(() => {
            handleUserReloadAndUpdateCart(dataCart?.id, address);
          });
          setIsProcessing(false);
          setIsLoading(false);
        }
      } else {
        handleSelect(address);
        setShowNewAddressForm(false);
      }
    }
  };

  const handleUserReloadAndUpdateCart = (cartId: number, address: any) => {
    const newCart: CartType = { id: cartId, shipping_address_id: address.id };

    handleUpdateCart(newCart, address);
    loadDeliveries(false);
    setShowNewAddressForm(false);
  };

  const handleUpdateCart = (dataCart: CartType, address: any) => {
    UpdateCartRequest(dataCart)
      .then((res) => {
        const cart = res.data.cart;

        if (!isEmpty(cart)) {
          setLatestCart(cart);

          if (cart.order_id) {
            const values = {
              orderId: cart.order_id,
              deliveryType: undefined,
              addressId: address.id,
            };
            OrderUpdateRequest(values)
              .then((res) => {
                reloadUser();
                setCurrentOrder(res.data.order);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      })
      .catch((err) => {
        reloadUser().finally(() => {
          const duplicateAddress = find(selectableAddresses, {
            id: address.id,
          });

          if (!isEmpty(selectableAddresses) && isEmpty(duplicateAddress)) {
            setSelectableAddresses([...selectableAddresses, address]);
          } else if (isEmpty(selectableAddresses)) {
            setSelectableAddresses([address]);
          }
        });

        console.error(err);
      });
  };

  const resetLoader = () => {
    setIsLoading(false);
    setIsProcessing(false);
  };

  const handleContinue = () => {
    setIsLoading(true);

    if (editMode) {
      navigate(
        `${
          dataCart.order_id
            ? "/order-shipping-confirmation"
            : "/checkout/shipping-confirmation"
        }`
      );
    } else {
      ShippingNextStep(dataCart.id, resetLoader);
    }
  };

  const handleInvalidAddress = () => {};

  const handleSelect = (address: AddressType) => {
    const newCart: CartType = {
      id: dataCart.id,
      shipping_address_id: address.id,
    };

    setSelectedAddress(address);
    setShowNewAddressForm(false);
    handleUpdateCart(newCart, address);
  };

  if (isLoading) return <DnaLoader />;

  return (
    <div className="container checkout-page product-page">
      <div className="content false">
        <div className="row shipping-address-content pt-0 pt-md-3 justify-content-center product-overview">
          <div className="col col-lg-6 shipping-address-content__info">
            <div className="content-heading">
              <Translator path="checkout.shipping.shippingAddressTitle" />
            </div>
            <section>
              <div className="shipping-addresses">
                <p className="content-subheading">
                  <Translator path="checkout.shipping.chooseDeliveryOptions" />{" "}
                </p>
                <div className="delivery-options shipping-address-content__option">
                  <div id="delivery-picker">
                    {!isEmpty(options) && (
                      <DeliveryPicker cart={dataCart} options={options} />
                    )}
                  </div>
                </div>
                <p className="content-subheading">
                  <Translator path="checkout.shipping.enterShippingAddress" />
                </p>
                {!isEmpty(user?.shipping_addresses) && (
                  <div className="shipping-addresses__list">
                    <div className={`position-relative list-content`}>
                      <input
                        type="text"
                        readOnly
                        className="shipping-addresses__dropdown no-mouseflow mosueflow-hidden"
                        value={addressDropDownHeading}
                        onFocus={() => setShowSelectableAddresses(true)}
                        onBlur={() => setShowSelectableAddresses(false)}
                      />
                      <img
                        src={ChevronDown}
                        className="shipping-address-item__chevron"
                        alt="down-arrow"
                      />
                    </div>
                    <div
                      className={`address-menu ${
                        !showSelectableAddresses && "d-none"
                      }`}
                      aria-labelledby="dropdownMenuButton"
                    >
                      {selectableAddresses?.map(
                        (address: any, index: number) => (
                          <NewShippingAddressItem
                            key={address.id}
                            address={address}
                            index={index}
                            selectedAddressId={dataCart?.shipping_address_id}
                            onSelect={() => handleSelect(address)}
                          />
                        )
                      )}
                      <NewAddressTile
                        onNewAddress={() => {
                          setShowNewAddressForm(true);
                          setSelectedAddress({});
                          setAddressDropDownHeading("+ Add new address");
                        }}
                      />
                    </div>
                    {!isEmpty(selectedAddress) &&
                      !selectedAddress.is_valid &&
                      !isAddressFormDialogOpen && (
                        <div className="invalid-address-error mt-2">
                          <span>
                            <Translator path="checkout.shipping.notAvailableState" />
                            <br />
                            <Translator path="checkout.shipping.haveAnyQuestions" />{" "}
                            1-844-745-3362.{" "}
                            <Translator path="checkout.shipping.thankYou" />
                          </span>
                        </div>
                      )}
                  </div>
                )}
                {showNewAddressForm && (
                  <div className={`form-content`}>
                    <NewShippingAddressForm
                      user={user}
                      onCancel={() => {
                        // closeDialog("isAddressFormDialogOpen")
                      }}
                      onSuccessSubmit={(values, pickUpAvailAble) =>
                        onSuccessfulSubmission(values, pickUpAvailAble)
                      }
                      onInvalidAddress={handleInvalidAddress}
                    />
                  </div>
                )}
              </div>
              <Button
                color="secondary"
                fullWidth
                classes={`mt-4 ${showNewAddressForm ? "d-none" : ""}`}
                disabled={
                  isEmpty(selectedAddress) ||
                  !selectedAddress.is_valid ||
                  isProcessing
                }
                onClick={handleContinue}
              >
                <Translator path="checkout.shipping.continueButtonText" />
              </Button>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shipping;
