import React, { useState } from "react";
import fancyCross from "assets/images/checkout/fancy-cross.svg";
import { ReactSVG } from "react-svg";
import { AddressType } from "types";

type AddressNotFoundProps = {
  selectedAddress: any;
  setSelectedAddress?: any;
  setDeliveryInfoModal: any;
  handleSubmitAddress: (values: AddressType) => void;
};

const AddressNotFound = ({
  selectedAddress,
  setSelectedAddress,
  setDeliveryInfoModal,
  handleSubmitAddress,
}: AddressNotFoundProps) => {
  const [selectedValue, setSelectedValue] = useState<string>("added");

  const handleRadioChange = (e: any) => {
    setSelectedValue(e.target.value);
  };

  return (
    <>
      <div>
        <h5 className="sub-header">Sorry, we can’t find the address.</h5>
        <div className="d-flex instruct">
          <ReactSVG src={fancyCross} className="fancy-cross" />
          <span>
            For proper delivery of medication by the pharmacy and accurate
            address is required.
          </span>
        </div>
        <div className="d-flex instruct instruct-2">
          <ReactSVG src={fancyCross} className="fancy-cross" />
          <span>Please include an apartment or a suite number is needed</span>
        </div>
        <input
          type="radio"
          className="address-radio-btn"
          id="added-address"
          name="choice"
          value="added"
          checked={true}
          onChange={handleRadioChange}
        />
        <label
          className={`address-box d-flex ${
            selectedValue === "added" ? "active" : ""
          }`}
          htmlFor="added-address"
        >
          <div className="d-flex flex-column">
            <span className="entered-address">Address entered</span>
            <span>
              {selectedAddress.street_1}{""}
              {selectedAddress.street_2}
            </span>
            <span>
              {selectedAddress.city}, {selectedAddress.state} {selectedAddress.zip}
            </span>
          </div>
        </label>
      </div>
      <div className="button-options">
        <button
          className="re-enter-btn"
          onClick={() => setDeliveryInfoModal(false)}
        >
          Re-Enter Address
        </button>
        <button
          className="re-enter-btn continue-btn"
          onClick={() => handleSubmitAddress(selectedAddress)}
        >
          Continue with address
        </button>
      </div>
    </>
  );
};

export default AddressNotFound;
