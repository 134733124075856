import React from "react";
import Translator from "../../components/Translator";
import viagraTab from "../../assets/images/home/viagra_tab.png";
import sildenafilTab from "../../assets/images/home/sildenafil_tab.png";

const ViagraVsSildenafil = () => {
  return (
    <>
      <div className="ab-wrapper ab-1">
        <div className="homepage-container">
          <div className="viagra-vs-sildenafil">
            <div className="content justify-content-center">
              <div className="row w-100">
                <div className="col d-none d-md-block">
                  <div className="d-none d-md-block">
                    <div className="versus-images">
                      <div className="card medicine-card p-0">
                        <div className="card-body d-flex justify-content-center align-items-center p-0">
                          <img
                            className="img-fluid"
                            src={viagraTab}
                            alt="viagra tab"
                          />
                        </div>
                        <div className="card-footer text-center">
                          <span className="medicine-text">
                            <Translator path="homepage.viagraVsSildenafil.cards.one.viagra" />
                          </span>
                        </div>
                      </div>
                      <h5 className="align-middle ml-2 mr-2">Vs</h5>
                      <div className="card medicine-card p-0">
                        <div className="card-body d-flex justify-content-center align-items-center p-0">
                          <img
                            className="img-fluid"
                            src={sildenafilTab}
                            alt="sildenafil tab"
                          />
                        </div>
                        <div className="card-footer text-center">
                          <span className="medicine-text">
                            <Translator path="homepage.viagraVsSildenafil.cards.two.sildenafil" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col mobile">
                  <h2 className="h2 mt-5 mt-md-0">
                    <Translator path="homepage.viagraVsSildenafil.headings.viagraVsSildenafil" />
                  </h2>
                  <div className="modile-versus d-md-none">
                    <div className="versus-images">
                      <div className="card drug-card">
                        <div className="card-body">
                          <img
                            className="img-fluid"
                            src={viagraTab}
                            alt="viagra tab"
                          />
                        </div>
                      </div>
                      <div className="card drug-card drug-text">
                        <div className="card-body">
                          <span className="medicine-text">
                            <Translator path="homepage.viagraVsSildenafil.viagraText" />
                          </span>
                        </div>
                      </div>
                    </div>
                    <h5 className="mobile-vs-text">
                      <Translator path="homepage.viagraVsSildenafil.vs" />
                    </h5>
                    <div className="versus-images">
                      <div className="card drug-card sildenafil-card">
                        <div className="card-body">
                          <span className="medicine-text">
                            <Translator path="homepage.viagraVsSildenafil.sildenafilText" />
                          </span>
                        </div>
                      </div>
                      <div className="card drug-card drug-text sildenafil-text">
                        <div className="card-body">
                          <img
                            className="img-fluid"
                            src={sildenafilTab}
                            alt="sildenafil tab"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="mt-10">
                    <Translator path="homepage.viagraVsSildenafil.paragraphs.bothAreGreat" />
                  </p>
                  <p>
                    <Translator path="homepage.viagraVsSildenafil.paragraphs.sildenafilFdaApproved" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViagraVsSildenafil;
