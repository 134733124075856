import React from "react";
import { isEmpty, maxBy } from "lodash";

import { calculatePrices } from "../Shared/Utils";

type PackageItemProps = {
  onQuantitySelect: any;
  onCloseInput: any;
  customInputFieldOpen: any;
  validCustomInput: any;
  setShowCustomField: any;
};

const OtherPackageItem = ({
  onQuantitySelect,
  onCloseInput,
  customInputFieldOpen,
  setShowCustomField,
}: PackageItemProps) => {
  const handlePackageSelect = (e: any) => {
    e.preventDefault();
    onQuantitySelect(true);
    // onCloseInput(false);
  };

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      className={`package-item__row package-item__add ${
        customInputFieldOpen && "package-item__add--selected"
      }`}
      href="#"
      onClick={(e: any) => handlePackageSelect(e)}>
      <div className="package-item__radio"></div>
      <p className="package-item__quantity">Other</p>
    </a>
  );
};

export default OtherPackageItem;
