import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const scrollToPageTop = () => {
  document.documentElement.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth", // Optional if you want to skip the scrolling animation
  });
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    scrollToPageTop();
  }, [pathname]);

  return null;
};

export default ScrollToTop;
