import React from 'react';
import Shipping from '../Checkout/Shipping';

const EditShipping = () => {
  return(
    <Shipping editMode={true} />
  )
}

export default EditShipping;
