import React, { useState } from "react";
import { isEmpty, find } from "lodash";
import { ReactSVG } from "react-svg";
import starIcon from "assets/images/icons/star.svg";

type ProductSelectorProps = {
  label: any;
  selected: any;
  options: any;
  disabled: any;
  onSelect: any;
  prescriptions?: any;
  cart: any;
};

const ProductSelector = ({
  label,
  selected,
  options,
  disabled,
  onSelect,
  prescriptions,
  cart,
}: ProductSelectorProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectedOption = options.find(
    (option: any) => option.id === selected.id
  );
  const [localVal, setLocalVal] = useState(selectedOption?.name);
  const mainClass = "product-selector__input";
  const optionHasRefill = !isEmpty(
    find(prescriptions, {
      product_variant: {
        product_id: selectedOption.id,
      },
      has_an_active_cart: false,
    })
  );

  const showDropdown = () => {
    if (!disabled) {
      setIsOpen(true);
    }
  };

  const hideDropdown = () => {
    setIsOpen(false);
  };

  const formattedProductName = (product: any) => {
    if (product?.basic_drug_name === "sildenafil") {
      return "Sildenafil (Generic Viagra)";
    } else {
      return "Tadalafil (Generic Cialis)";
    }
  };

  return (
    <div className={`product-selector mb-0 ${disabled && "disabled"}`}>
      <label
        className={`input-label product-detail-picker`}
        htmlFor="product-selector"
        onClick={showDropdown}>
        <div className={`select ${mainClass}`}>
          {/* Putting the following text underneath the input field breaks the dropdown */}
          {optionHasRefill && (
            <div className="select-product-refill-text">
              <div className="text-danger d-flex align-items-center ml-2">
                Refill
                <ReactSVG src={starIcon} className="refill-star-icon ml-2" />
              </div>
            </div>
          )}

          <input
            id="product-selector"
            className={`input input-select ${isOpen ? "touched" : ""}`}
            name="product-selector"
            value={formattedProductName(selected)}
            onBlur={hideDropdown}
            readOnly
          />

          <div className="select-dropdown drugs">
            {options.map((product: any) => {
              const prescription: any = find(prescriptions, {
                product_variant: {
                  product_id: product.id,
                },
                has_an_active_cart: false,
              });

              return (
                <button
                  className="generic d-flex justify-content-between"
                  onMouseDown={(e: any) => {
                    setLocalVal(product?.name);
                    setIsOpen(false);
                    onSelect(product?.id);
                  }}
                  data-selected={product?.id}
                  data-name={product?.name}
                  key={product?.id}>
                  {product?.name === "Sildenafil"
                    ? "Sildenafil (Generic Viagra)"
                    : "Tadalafil (Generic Cialis)"}
                  {prescription?.product_variant?.product_id ===
                    product?.id && (
                    <div className="text-danger d-flex align-items-center ml-2">
                      Refill
                      <ReactSVG
                        src={starIcon}
                        className="refill-star-icon ml-2"
                      />
                    </div>
                  )}
                </button>
              );
            })}
          </div>
        </div>
      </label>
    </div>
  );
};

export default ProductSelector;
