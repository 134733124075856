import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { Link, useNavigate } from "react-router-dom";
import { GetOrderStatusRequest } from "apis";
import Button from "components/Button";
import printIcon from "assets/images/home/print-solid.png";
import "./styles.scss";

const OrderStatus = () => {
  const [subAddress, setSubAddress] = useState<any>(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    GetOrderStatusRequest()
      .then((res) => {
        setSubAddress(res.data.subAddress);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="checkout-page cart-page">
      <div className="content">
        <div className="product-card thank-you" id="printable-section">
          <div className="thank-you__header">
            <h2 className="h2">How to Send Us a Prescription</h2>
          </div>
          <div className="thank-you__body">
            {!isEmpty(subAddress) && (
              <React.Fragment>
                <ol>
                  <li>
                    <strong>E-Script (preferred): </strong>
                    <address>
                      {subAddress.address.name}
                      <br />
                      {subAddress.address.street}
                      <br />
                      {subAddress.address.city}
                      {subAddress.address.zip}
                      <br />
                      NCPDP: 5639351
                    </address>
                  </li>
                  <li>
                    <strong>Fax: </strong>
                    {subAddress.fax_to}
                  </li>
                  <li>
                    <strong>Call: </strong>
                    {subAddress.call_to}
                  </li>
                </ol>
                <p>
                  We will contact you the moment your prescription is received
                  so that you may place your order. If your doctor has sent us a
                  prescription and you have not heard from us, please give us a
                  call at {subAddress.phone} for assistance.
                </p>
              </React.Fragment>
            )}
            {/* // TODO: Fix Print */}
            <Link to={""} className="print">
              <img src={printIcon} alt="print-icon" />
              Print this page
            </Link>
          </div>
          <div className="thank-you__footer not-printable">
            <Button classes="" color="secondary" onClick={() => navigate("/")}>
              BACK TO HOMEPAGE
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderStatus;
