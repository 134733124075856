import { minBy, maxBy } from 'lodash';

const calculatePrices = (variant: any, quantity: any) => {
  const baseThreshold = minBy(
    variant.price_thresholds,
    (thd: any) => thd.min_quantity
  );

  const basePrice = baseThreshold
    ? Number(baseThreshold.price * quantity).toFixed(2)
    : 0.0;

  const possibleThresholds = variant.price_thresholds.filter(
    (thd: any) => quantity >= thd.min_quantity
  );
  const threshold = maxBy(possibleThresholds, (thd: any) => thd.min_quantity);

  const price = threshold ? Number(threshold.price * quantity).toFixed(2) : 0.0;

  const priceDifference = (Number(basePrice) - Number(price)).toFixed(2);
  const discount = Math.round((Number(priceDifference) / Number(basePrice)) * 100) || 0;

  return {
    basePrice,
    price,
    priceDifference,
    discount,
  };
};

const showMinQuantityInfo = (variant: any, cart: any) =>
  variant && cart && cart.quantity < variant.min_order_quantity;

const isPickUpAvailable = (context: any, shippingAddresses: any) => {
  const shippingAddress = shippingAddresses.find(
    (address: any) => address.id === context.shipping_address_id
  );

  if (shippingAddress) {
    return shippingAddress.pick_up_available;
  } else {
    return null;
  }
};

export {
  calculatePrices,
  showMinQuantityInfo,
  isPickUpAvailable,
};
