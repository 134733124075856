import React from "react";
import TextOption from "./TextOption";
import AllergySearch from "./allergy/AllergySearch";
import MedSearch from "./med/MedSearch";

type ExtraOptionProps = {
  allowText: boolean;
  allowAllergy: boolean;
  allowMed: boolean;
  activeQuestion: any;
  isProcessing: any;
  setIsProcessing: any;
  selectedAnswers: any;
  setSelectedAnswers: any;
  extraText: any;
  setExtraText: any;
  queriedMeds: any;
  setQueriedMeds: any;
  queriedAllergies: any;
  setQueriedAllergies: any;
  addedAllergies: any;
  setAddedAllergies: any;
  addedMeds: any;
  setAddedMeds: any;
  errors: any;
  setErrors: any;
  setTextAreaBorder: any;
  setContinueButtonDisable: any;
  textAreaBorder: any;
};

const ExtraOptions = ({
  allowText,
  allowAllergy,
  allowMed,
  activeQuestion,
  isProcessing,
  setIsProcessing,
  selectedAnswers,
  setSelectedAnswers,
  extraText,
  setExtraText,
  queriedMeds,
  setQueriedMeds,
  queriedAllergies,
  setQueriedAllergies,
  addedAllergies,
  setAddedAllergies,
  addedMeds,
  setAddedMeds,
  errors,
  setErrors,
  setTextAreaBorder,
  setContinueButtonDisable,
  textAreaBorder,
}: ExtraOptionProps) => {
  return (
    <>
      {allowText && !activeQuestion?.med_api && (
        <TextOption
          selectedAnswers={selectedAnswers}
          setSelectedAnswers={setSelectedAnswers}
          option={selectedAnswers[0]}
          questionType="text"
          userResponses={activeQuestion.responses}
          extraText={extraText}
          setExtraText={setExtraText}
          extraLabel={selectedAnswers[0]?.extra_label}
          setContinueButtonDisable={setContinueButtonDisable}
          textAreaBorder={textAreaBorder}
          questionId={activeQuestion.id}
        />
      )}
      {allowAllergy && (
        <AllergySearch
          selectedAnswers={selectedAnswers}
          isProcessing={isProcessing}
          setIsProcessing={setIsProcessing}
          queriedAllergies={queriedAllergies}
          setQueriedAllergies={setQueriedAllergies}
          addedAllergies={addedAllergies}
          setAddedAllergies={setAddedAllergies}
          setErrors={setErrors}
          setContinueButtonDisable={setContinueButtonDisable}
        />
      )}
      {allowMed && (
        <MedSearch
          selectedAnswers={selectedAnswers}
          isProcessing={isProcessing}
          setIsProcessing={setIsProcessing}
          queriedMeds={queriedMeds}
          setQueriedMeds={setQueriedMeds}
          addedMeds={addedMeds}
          setAddedMeds={setAddedMeds}
          errors={errors}
          setErrors={setErrors}
          condition={activeQuestion?.condition}
          questionId={activeQuestion?.id}
          activeQuestion={activeQuestion}
          option={activeQuestion?.answer_options[0]}
          setTextAreaBorder={setTextAreaBorder}
          setContinueButtonDisable={setContinueButtonDisable}
          setSelectedAnswers={setSelectedAnswers}
        />
      )}
    </>
  );
};

export default ExtraOptions;
