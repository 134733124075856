import React from "react";
import Footer from "components/Footer";

const Disclaimer = () => {
  return (
    <>
      <div className="content mt-5 py-5">
        <p className="text-center">
          <strong>
            <u>Disclaimer</u>
          </strong>
        </p>
        <p className="text-center">&nbsp;</p>
        <p>
          <strong>Interpretation</strong>
        </p>
        <p>
          The words with initial capital letters have meanings defined under the
          following conditions. These definitions shall have the same meaning
          regardless of whether they appear in singular or in plural form.
        </p>
        <p>
          <strong>Definitions</strong>
        </p>
        <p>For the purposes of this Disclaimer:</p>
        <ol>
          <li>
            <strong>Company&nbsp;</strong>(referred to as either "the Company,"
            "We," "Us," or "Our" in this Disclaimer) refers to Sildenafil.com
            LLC, 2372 Morse Avenue, Suite #377, Irvine, CA 92614.
            <br />
          </li>
          <li>
            <strong>Service&nbsp;</strong>refers to the Website.
          </li>
          <li>
            <strong>You&nbsp;</strong>means the individual accessing the
            Service, or the company, or other legal entity on behalf of which
            such individual is accessing or using the Service, as applicable.
          </li>
          <li>
            <strong>Website&nbsp;</strong>refers to sildenafil.com, accessible
            from <a href="/">http://www.sildenafil.com</a>
          </li>
        </ol>
        <p>
          <strong>Medical Information Disclaimer</strong>
        </p>
        <p>
          The information provided on the Service is for general informational
          purposes only and is not intended to be a substitute for professional
          medical advice, diagnosis, or treatment. Always seek the advice of
          your physician or other qualified healthcare provider with any
          questions you may have regarding a medical condition. Never disregard
          professional medical advice or delay in seeking it because of
          something you have read on the Service.
          <br />
        </p>
        <p>
          The Company does not recommend or endorse any specific tests,
          physicians, products, procedures, opinions, or other information that
          may be mentioned on the Service. Reliance on any information provided
          by the Service, employees of the Company, or other visitors to the
          Service is solely at your own risk.
          <br />
        </p>
        <p>
          <strong>Additional Disclaimer</strong>
        </p>
        <p>
          All information provided herein is for informational purposes only and
          is not meant to be a substitute for professional medical advice,
          diagnosis, or treatment, nor is it intended to be relied upon as
          making any representations as to the efficacy or safety of any
          specific drug. Please seek medical advice before starting, changing,
          or terminating any medical treatment. Sildenafil.com provides no
          warranty for any of the pricing data or other information. All
          trademarks, brands, logos, and copyright images are property of their
          respective owners and rights holders and are used solely to represent
          the products of these rights holders.
        </p>
        <p>
          <strong>Disclaimer</strong>
        </p>
        <p>
          The information contained on the Service is for general information
          purposes only.
          <br />
        </p>
        <p>
          The Company assumes no responsibility for errors or omissions in the
          contents of the Service.
          <br />
        </p>
        <p>
          In no event shall the Company be liable for any special, direct,
          indirect, consequential, or incidental damages, or any damages
          whatsoever, whether in an action of contract, negligence, or other
          tort, arising out of or in connection with the use of the Service or
          the contents of the Service. The Company reserves the right to make
          additions, deletions, or modifications to the contents of the Service
          at any time without prior notice.
          <br />
        </p>
        <p>
          The Company does not warrant that the Service is free of viruses or
          other harmful components.
          <br />
        </p>
        <p>
          <strong>Google Ads Disclaimer</strong>
        </p>
        <p>
          The Service may display advertisements provided by Google or other
          third-party advertisers. These ads are provided by third-party vendors
          and ad networks that use cookies to serve ads based on a user’s prior
          visits to the Website or other websites. Users may opt-out of
          personalized advertising by visiting Google’s Ads Settings. The
          Company does not endorse any of the products or services advertised
          and is not responsible for the content or claims made in these ads.
        </p>
        <p>
          <strong>External Links Disclaimer</strong>
        </p>
        <p>
          The Service may contain links to external websites that are not
          provided, maintained, or affiliated with the Company.
          <br />
        </p>
        <p>
          Please note that the Company does not guarantee the accuracy,
          relevance, timeliness, or completeness of any information on these
          external websites.
          <br />
        </p>
        <p>
          <strong>Errors and Omissions Disclaimer</strong>
        </p>
        <p>
          The information provided by the Service is for general guidance on
          matters of interest only. Even though the Company takes every
          precaution to ensure that the content of the Service is both current
          and accurate, errors can occur. Additionally, given the changing
          nature of laws, rules, and regulations, there may be delays,
          omissions, or inaccuracies in the information contained on the
          Service.
          <br />
        </p>
        <p>
          The Company is not responsible for any errors or omissions, or for the
          results obtained from the use of this information.
          <br />
        </p>
        <p>
          <strong>Fair Use Disclaimer</strong>
        </p>
        <p>
          The Company may use copyrighted material which has not always been
          specifically authorized by the copyright owner. The Company is making
          such material available for criticism, comment, news reporting,
          teaching, scholarship, or research.
          <br />
        </p>
        <p>
          The Company believes this constitutes "fair use" of any such
          copyrighted material as provided for in section 107 of the United
          States Copyright law.
          <br />
        </p>
        <p>
          If You wish to use copyrighted material from the Service for purposes
          that go beyond fair use, You must obtain permission from the copyright
          owner.
          <br />
        </p>
        <p>
          <strong>Views Expressed Disclaimer</strong>
        </p>
        <p>
          The Service may contain views and opinions that are those of the
          authors and do not necessarily reflect the official policy or position
          of any other author, agency, organization, employer, or company,
          including the Company.
          <br />
        </p>
        <p>
          Comments published by users are their sole responsibility, and users
          will take full responsibility, liability, and blame for any libel or
          litigation that results from something written in or as a direct
          result of something written in a comment. The Company is not liable
          for any comment published by users and reserves the right to delete
          any comment for any reason whatsoever.
          <br />
        </p>
        <p>
          <strong>No Responsibility Disclaimer</strong>
        </p>
        <p>
          The information on the Service is provided with the understanding that
          the Company is not engaged in rendering legal, accounting, tax, or
          other professional advice and services. Therefore, it should not be
          used as a substitute for consultation with professional accounting,
          tax, legal, or other competent advisers.
          <br />
        </p>
        <p>
          In no event shall the Company or its suppliers be liable for any
          special, incidental, indirect, or consequential damages whatsoever
          arising out of or in connection with Your access or use or inability
          to access or use the Service.
          <br />
        </p>
        <p>
          <strong>"Use at Your Own Risk" Disclaimer</strong>
        </p>
        <p>
          All information in the Service is provided "as is," with no guarantee
          of completeness, accuracy, timeliness, or of the results obtained from
          the use of this information, and without warranty of any kind, express
          or implied, including, but not limited to, warranties of performance,
          merchantability, and fitness for a particular purpose.
          <br />
        </p>
        <p>
          The Company will not be liable to You or anyone else for any decision
          made or action taken in reliance on the information given by the
          Service, or for any consequential, special, or similar damages, even
          if advised of the possibility of such damages.
          <br />
        </p>
        <p>
          <strong>CCPA/CPRA, GDPR, and CalOPPA Compliance</strong>
        </p>
        <p>
          The Company is committed to protecting Your personal information and
          privacy rights under the California Consumer Privacy Act (CCPA) and
          the California Privacy Rights Act (CPRA), the General Data Protection
          Regulation (GDPR), and the California Online Privacy Protection Act
          (CalOPPA). For more information about how we collect, use, and protect
          your personal data, please refer to our Privacy Policy.
          <br />
        </p>
        <p>
          <strong>Cookies</strong>
        </p>
        <p>
          The Website uses cookies to enhance Your experience. By continuing to
          use the Website, You consent to the use of cookies in accordance with
          our Cookies Policy. You can control and manage cookies through Your
          browser settings.
          <br />
        </p>
        <p>
          <strong>User-Generated Content</strong>
        </p>
        <p>
          The Service may allow You to post content, including comments,
          reviews, or other forms of user-generated content. You are solely
          responsible for the content You create and share, and You agree that
          Your content will not infringe on any third-party rights, including
          intellectual property rights. The Company reserves the right to remove
          any content that violates these terms.
          <br />
        </p>
        <p>
          <strong>Termination</strong>
        </p>
        <p>
          We may terminate or suspend Your access to the Service without prior
          notice or liability for any reason, including if You breach this
          Disclaimer or our Terms of Service. Upon termination, Your right to
          use the Service will immediately cease, and all provisions of this
          Disclaimer that should survive termination, including limitations of
          liability and disclaimers of warranties, will remain in effect.
          <br />
        </p>
        <p>
          <strong>Contact Us</strong>
        </p>
        <p>
          If you have any questions about this Disclaimer, You can contact Us:
          <br />
        </p>
        <ol>
          <li>
            By phone number: (844) 745-3362
            <br />
          </li>
        </ol>
      </div>
      <Footer />
    </>
  );
};

export default Disclaimer;
