import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Button from "components/Button";
import "./styles.scss";

type Props = {
  allergy: any;
  showAllergyModal: any;
  setShowAllergyModal: any;
  addedAllergies: any;
  setAddedAllergies: any;
};

const AllergyModal = ({
  allergy,
  showAllergyModal,
  setShowAllergyModal,
  addedAllergies,
  setAddedAllergies,
}: Props) => {
  const [allergyName, setAllergyName] = useState(allergy?.name);
  const [allergyDescription, setAllergyDescription] = useState(
    allergy?.description
  );

  // Set the base values when the modal is opened
  useEffect(() => {
    let defaultName = addedAllergies?.filter(
      (a: any) => a?.local_id === allergy?.local_id
    )[0]?.name;
    let defaultDescription = addedAllergies?.filter(
      (a: any) => a?.local_id === allergy?.local_id
    )[0]?.description;

    defaultName = defaultName !== allergy?.name ? defaultName : allergy?.name;
    defaultDescription =
      defaultDescription !== allergy?.description
        ? defaultDescription
        : allergy?.description;

    setAllergyName(defaultName);
    setAllergyDescription(defaultDescription);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAllergyModal]);

  const onSave = () => {
    const updatedAllergy = {
      name: allergyName,
      description: allergyDescription,
      local_id: allergy?.local_id,
    };
    const otherAllergies = addedAllergies.filter(
      (a: any) => a?.local_id !== allergy?.local_id
    );

    setAddedAllergies([...otherAllergies, updatedAllergy]);
    setShowAllergyModal(false);
  };

  const onRemove = (local_id: any) => {
    const otherAllergies = addedAllergies?.filter(
      (a: any) => a?.local_id !== local_id
    );

    setAddedAllergies(otherAllergies);
    setShowAllergyModal(false);
  };

  return (
    <Modal
      show={showAllergyModal}
      onHide={() => setShowAllergyModal(false)}
      aria-labelledby="info-dialog__title"
      className="info-dialog allergy-dialog"
      centered>
      <Modal.Header className="form-dialog__title" style={{ border: 0 }}>
        Edit Allergy Details
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={() => setShowAllergyModal(false)}>
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>

      <Modal.Body>
        <div className="allergy-form">
          <div className="form-group">
            <label htmlFor="allergy-name">Name</label>
            <input
              type="text"
              id="allergy-name"
              value={allergyName || ""}
              onChange={(e: any) => setAllergyName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="allergy-description">Description</label>
            <textarea
              id="allergy-description"
              value={allergyDescription}
              onChange={(e: any) =>
                setAllergyDescription(e.target.value)
              }></textarea>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer style={{ border: 0, flexWrap: "nowrap" }}>
        <Button
          classes="inverted w-100"
          fullWidth={true}
          color="secondary"
          onClick={() => onRemove(allergy?.local_id)}>
          Remove this allergy
        </Button>
        <Button
          classes="w-100"
          fullWidth={true}
          color="secondary"
          onClick={onSave}>
          save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AllergyModal;
